export const generalOrderPayTime = 2880;
export const ypOrderPayTime = 30;
export const payTypeMap = {
  notKnow: 0, //未知
  balance: 1, //余额
  weChat: 2, //微信
  offline: 4, //线下
  combine: 10 //组合支付
};
export const express_type_map = {
  delivery: 0,
  noDelivery: 1
};
export const order_type_map = {
  yp: 10, //服务型
  product: 20, //电商型
  offlineYp: 110, //到店拍摄
  offlineGeneral: 120, //到店普通商品
  groupYp: 210, //预约型拼团
  groupGeneral: 220 //电商型拼团
};
export const order_status_map = {
  refunded: -3, //已退款
  refund: -2, //退款中
  closed: -1, //已关闭
  notPay: 0, //待支付
  paying: 1, //支付中
  payed: 2, //已支付
  inService: 3, //待发货
  complete: 4 //交易完成
};

export const orderDetailSource = {
  xcx: {
    value: 1,
    name: '小程序'
  },
  web: {
    value: 2,
    name: '网页'
  },
  mall: {
    value: 3,
    name: '总商城'
  }
};
export function getOrderDetailSourceName(source) {
  let name = '';
  const { xcx, web, mall } = orderDetailSource;
  switch (Number(source)) {
    case xcx.value:
      name = xcx.name;
      break;
    case web.value:
      name = web.name;
      break;
    case mall.value:
      name = mall.name;
      break;
  }
  return name;
}
