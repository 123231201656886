/**
 * Created by Administrator on 2018/9/28.
 */
const commonConfig = {
  businessDomain: '',
  webUrl: '', //网址
  apiBase: '',
  imgBase: '',
  yxtOss: '',
  socketUrl: '',
  xcxApiBase: '',
  aixiutuOss: ''
};

let businessDomain = 'https://xcx-manage.homy.cc';
// let businessDomain = 'http://106.55.105.165';
commonConfig.businessDomain = businessDomain;
commonConfig.webUrl = 'https://xcx-manage.homy.cc';
commonConfig.apiBase = `${businessDomain}/business-admin`;
commonConfig.imgBase = 'https://xcx-oss.homy.cc/';
commonConfig.aixiutuOss = 'https://retouch-xt.homy.cc/';
commonConfig.yxtOss = 'https://retouch.homy.cc/';
commonConfig.socketUrl = 'wss://im.homy.cc';
commonConfig.xcxApiBase = 'https://xcx.homy.cc';

module.exports = commonConfig;
