/**
 * Created by lsf on 2018/8/19.
 */
export default {
  methods: {
    //    上传图片
    app_upload_img({
      fileType = '',
      imageMaxBytes = '',
      userId = '',
      file,
      progress = () => {}
    } = {}) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        for (let pic of file) {
          formData.append('files', pic);
        }
        formData.append('fileType', fileType);
        formData.append('imageMaxBytes', imageMaxBytes);
        formData.append('userId', userId);
        this.$http({
          url: '/fileResources/uploadPicture',
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData,
          onUploadProgress: (progressEvent) => {
            progress(progressEvent);
          }
        })
          .then((res) => {
            if (res.data.success) {
              let result = res.data.data || [];
              if (result.length > 0) {
                resolve(result);
              } else {
                reject({
                  message: '上传失败'
                });
              }
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    //    获取图片素材
    get_pics({ pageNum = 1, pageSize = 10, keyword = '', resourcesType = '' } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/page/decorate/resources',
          method: 'get',
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            keyword: keyword,
            resourcesType: resourcesType
          }
        })
          .then((res) => {
            if (res.data.success) {
              let result = res.data.data;
              resolve({
                total: result.total,
                list: result.list
              });
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    //    保存素材
    save_resource({ resUrl, linkUrl, resourcesType } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/page/decorate/resources/saveMaterial',
          method: 'post',
          data: {
            resUrl: resUrl,
            linkUrl: linkUrl,
            resourcesType: resourcesType
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve();
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    //    获取回传照片
    get_shoot_img({ orderNo } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/order/manage/service/getShootOrderImg',
          params: {
            orderNo: orderNo
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .then((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 获取营销数据
    request_index_data() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/index',
          method: 'get'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 请求小程序信息
    request_xcx_info() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/xcx/auth',
          method: 'get'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 请求验证码
    request_auth_code({ phone, smsType } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: 'common/getVerificationCode',
          method: 'post',
          data: {
            mobile: phone,
            smsType: smsType
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve();
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求失败'
            });
          });
      });
    },
    // 请求上下架数量
    request_product_num({ storeId, productTypes } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/product/ec/products/getPutawayNum',
          params: {
            storeId: storeId,
            productTypes: productTypes
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 当前登录管理员信息
    request_admin_info() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/common/admin'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
