export const sms_type_map = {
  bindPhone: 1,
  registVip: 2,
  registBusiness: 3
};
export const withdraw_status_map = {
  fail: -1, //提现失败
  not: 0, //未提现
  ing: 1, //提现中
  success: 2 //提现成功
};
export const trade_type_map = {
  unfreeze: {
    value: -4,
    name: '解冻'
  }, //解冻
  makeUp: {
    value: -1,
    name: '消费补偿'
  }, //消费补偿
  consume: {
    value: 1,
    name: '消费'
  }, //消费
  recharge: {
    value: 2,
    name: '充值'
  }, //充值
  withdraw: {
    value: 3,
    name: '提现'
  }, //提现
  freeze: {
    value: 4,
    name: '冻结'
  }, //冻结
  refund: {
    value: 5,
    name: '退款'
  }, //退款
  gift: {
    value: 6,
    name: '充值赠送'
  } //充值赠送
};
export const resource_type_map = {
  img: 1, //普通图片素材
  page: 2, //页面
  activity: 3 //活动图片
};
export const order_group_status_map = {
  fail: -1, //失败
  ing: 0, //拼团中
  success: 1 //拼团成功
};
export const platFormMap = {
  xcx: 0,
  gzh: 1,
  business: 2
};
