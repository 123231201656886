export default [
  {
    id:1,parentId:0,parentIdAll:"1",regionName:"中华人民共和国",regionType:0,
    children:[
      {
        id: 2,
        parentId: 1,
        regionType: 1,
        regionName: '河南省',
        parentIdAll: '1,2',
        children: [
          {
            id: 3,
            parentId: 2,
            regionType: 2,
            regionName: '濮阳市',
            parentIdAll: '1,2,3',
            children: [
              {
                id: 4,
                parentId: 3,
                regionType: 3,
                regionName: '南乐县',
                parentIdAll: '1,2,3,4',
                children: []
              },
              {
                id: 5,
                parentId: 3,
                regionType: 3,
                regionName: '清丰县',
                parentIdAll: '1,2,3,5',
                children: []
              },
              {
                id: 6,
                parentId: 3,
                regionType: 3,
                regionName: '台前县',
                parentIdAll: '1,2,3,6',
                children: []
              },
              {
                id: 7,
                parentId: 3,
                regionType: 3,
                regionName: '华龙区',
                parentIdAll: '1,2,3,7',
                children: []
              },
              {
                id: 8,
                parentId: 3,
                regionType: 3,
                regionName: '范县',
                parentIdAll: '1,2,3,8',
                children: []
              },
              {
                id: 9,
                parentId: 3,
                regionType: 3,
                regionName: '濮阳县',
                parentIdAll: '1,2,3,9',
                children: []
              }
            ]
          },
          {
            id: 10,
            parentId: 2,
            regionType: 2,
            regionName: '洛阳市',
            parentIdAll: '1,2,10',
            children: [
              {
                id: 11,
                parentId: 10,
                regionType: 3,
                regionName: '孟津县',
                parentIdAll: '1,2,10,11',
                children: []
              },
              {
                id: 12,
                parentId: 10,
                regionType: 3,
                regionName: '新安县',
                parentIdAll: '1,2,10,12',
                children: []
              },
              {
                id: 13,
                parentId: 10,
                regionType: 3,
                regionName: '嵩县',
                parentIdAll: '1,2,10,13',
                children: []
              },
              {
                id: 14,
                parentId: 10,
                regionType: 3,
                regionName: '栾川县',
                parentIdAll: '1,2,10,14',
                children: []
              },
              {
                id: 15,
                parentId: 10,
                regionType: 3,
                regionName: '汝阳县',
                parentIdAll: '1,2,10,15',
                children: []
              },
              {
                id: 16,
                parentId: 10,
                regionType: 3,
                regionName: '涧西区',
                parentIdAll: '1,2,10,16',
                children: []
              },
              {
                id: 17,
                parentId: 10,
                regionType: 3,
                regionName: '伊川县',
                parentIdAll: '1,2,10,17',
                children: []
              },
              {
                id: 18,
                parentId: 10,
                regionType: 3,
                regionName: '吉利区',
                parentIdAll: '1,2,10,18',
                children: []
              },
              {
                id: 19,
                parentId: 10,
                regionType: 3,
                regionName: '宜阳县',
                parentIdAll: '1,2,10,19',
                children: []
              },
              {
                id: 20,
                parentId: 10,
                regionType: 3,
                regionName: '洛宁县',
                parentIdAll: '1,2,10,20',
                children: []
              },
              {
                id: 21,
                parentId: 10,
                regionType: 3,
                regionName: '偃师市',
                parentIdAll: '1,2,10,21',
                children: []
              },
              {
                id: 22,
                parentId: 10,
                regionType: 3,
                regionName: '瀍河回族区',
                parentIdAll: '1,2,10,22',
                children: []
              },
              {
                id: 23,
                parentId: 10,
                regionType: 3,
                regionName: '洛龙区',
                parentIdAll: '1,2,10,23',
                children: []
              },
              {
                id: 24,
                parentId: 10,
                regionType: 3,
                regionName: '老城区',
                parentIdAll: '1,2,10,24',
                children: []
              },
              {
                id: 25,
                parentId: 10,
                regionType: 3,
                regionName: '西工区',
                parentIdAll: '1,2,10,25',
                children: []
              }
            ]
          },
          {
            id: 26,
            parentId: 2,
            regionType: 2,
            regionName: '三门峡市',
            parentIdAll: '1,2,26',
            children: [
              {
                id: 27,
                parentId: 26,
                regionType: 3,
                regionName: '渑池县',
                parentIdAll: '1,2,26,27',
                children: []
              },
              {
                id: 28,
                parentId: 26,
                regionType: 3,
                regionName: '湖滨区',
                parentIdAll: '1,2,26,28',
                children: []
              },
              {
                id: 29,
                parentId: 26,
                regionType: 3,
                regionName: '义马市',
                parentIdAll: '1,2,26,29',
                children: []
              },
              {
                id: 30,
                parentId: 26,
                regionType: 3,
                regionName: '卢氏县',
                parentIdAll: '1,2,26,30',
                children: []
              },
              {
                id: 31,
                parentId: 26,
                regionType: 3,
                regionName: '陕州区',
                parentIdAll: '1,2,26,31',
                children: []
              },
              {
                id: 32,
                parentId: 26,
                regionType: 3,
                regionName: '灵宝市',
                parentIdAll: '1,2,26,32',
                children: []
              }
            ]
          },
          {
            id: 33,
            parentId: 2,
            regionType: 2,
            regionName: '郑州市',
            parentIdAll: '1,2,33',
            children: [
              {
                id: 34,
                parentId: 33,
                regionType: 3,
                regionName: '巩义市',
                parentIdAll: '1,2,33,34',
                children: []
              },
              {
                id: 35,
                parentId: 33,
                regionType: 3,
                regionName: '登封市',
                parentIdAll: '1,2,33,35',
                children: []
              },
              {
                id: 36,
                parentId: 33,
                regionType: 3,
                regionName: '荥阳市',
                parentIdAll: '1,2,33,36',
                children: []
              },
              {
                id: 37,
                parentId: 33,
                regionType: 3,
                regionName: '惠济区',
                parentIdAll: '1,2,33,37',
                children: []
              },
              {
                id: 38,
                parentId: 33,
                regionType: 3,
                regionName: '上街区',
                parentIdAll: '1,2,33,38',
                children: []
              },
              {
                id: 39,
                parentId: 33,
                regionType: 3,
                regionName: '金水区',
                parentIdAll: '1,2,33,39',
                children: []
              },
              {
                id: 40,
                parentId: 33,
                regionType: 3,
                regionName: '新郑市',
                parentIdAll: '1,2,33,40',
                children: []
              },
              {
                id: 41,
                parentId: 33,
                regionType: 3,
                regionName: '中原区',
                parentIdAll: '1,2,33,41',
                children: []
              },
              {
                id: 42,
                parentId: 33,
                regionType: 3,
                regionName: '二七区',
                parentIdAll: '1,2,33,42',
                children: []
              },
              {
                id: 43,
                parentId: 33,
                regionType: 3,
                regionName: '新密市',
                parentIdAll: '1,2,33,43',
                children: []
              },
              {
                id: 44,
                parentId: 33,
                regionType: 3,
                regionName: '管城回族区',
                parentIdAll: '1,2,33,44',
                children: []
              },
              {
                id: 45,
                parentId: 33,
                regionType: 3,
                regionName: '中牟县',
                parentIdAll: '1,2,33,45',
                children: []
              }
            ]
          },
          {
            id: 46,
            parentId: 2,
            regionType: 2,
            regionName: '许昌市',
            parentIdAll: '1,2,46',
            children: [
              {
                id: 47,
                parentId: 46,
                regionType: 3,
                regionName: '建安区',
                parentIdAll: '1,2,46,47',
                children: []
              },
              {
                id: 48,
                parentId: 46,
                regionType: 3,
                regionName: '襄城县',
                parentIdAll: '1,2,46,48',
                children: []
              },
              {
                id: 49,
                parentId: 46,
                regionType: 3,
                regionName: '魏都区',
                parentIdAll: '1,2,46,49',
                children: []
              },
              {
                id: 50,
                parentId: 46,
                regionType: 3,
                regionName: '长葛市',
                parentIdAll: '1,2,46,50',
                children: []
              },
              {
                id: 51,
                parentId: 46,
                regionType: 3,
                regionName: '禹州市',
                parentIdAll: '1,2,46,51',
                children: []
              },
              {
                id: 52,
                parentId: 46,
                regionType: 3,
                regionName: '鄢陵县',
                parentIdAll: '1,2,46,52',
                children: []
              }
            ]
          },
          {
            id: 53,
            parentId: 2,
            regionType: 2,
            regionName: '南阳市',
            parentIdAll: '1,2,53',
            children: [
              {
                id: 54,
                parentId: 53,
                regionType: 3,
                regionName: '西峡县',
                parentIdAll: '1,2,53,54',
                children: []
              },
              {
                id: 55,
                parentId: 53,
                regionType: 3,
                regionName: '南召县',
                parentIdAll: '1,2,53,55',
                children: []
              },
              {
                id: 56,
                parentId: 53,
                regionType: 3,
                regionName: '淅川县',
                parentIdAll: '1,2,53,56',
                children: []
              },
              {
                id: 57,
                parentId: 53,
                regionType: 3,
                regionName: '卧龙区',
                parentIdAll: '1,2,53,57',
                children: []
              },
              {
                id: 58,
                parentId: 53,
                regionType: 3,
                regionName: '唐河县',
                parentIdAll: '1,2,53,58',
                children: []
              },
              {
                id: 59,
                parentId: 53,
                regionType: 3,
                regionName: '桐柏县',
                parentIdAll: '1,2,53,59',
                children: []
              },
              {
                id: 60,
                parentId: 53,
                regionType: 3,
                regionName: '社旗县',
                parentIdAll: '1,2,53,60',
                children: []
              },
              {
                id: 61,
                parentId: 53,
                regionType: 3,
                regionName: '方城县',
                parentIdAll: '1,2,53,61',
                children: []
              },
              {
                id: 62,
                parentId: 53,
                regionType: 3,
                regionName: '镇平县',
                parentIdAll: '1,2,53,62',
                children: []
              },
              {
                id: 63,
                parentId: 53,
                regionType: 3,
                regionName: '内乡县',
                parentIdAll: '1,2,53,63',
                children: []
              },
              {
                id: 64,
                parentId: 53,
                regionType: 3,
                regionName: '新野县',
                parentIdAll: '1,2,53,64',
                children: []
              },
              {
                id: 65,
                parentId: 53,
                regionType: 3,
                regionName: '邓州市',
                parentIdAll: '1,2,53,65',
                children: []
              },
              {
                id: 66,
                parentId: 53,
                regionType: 3,
                regionName: '宛城区',
                parentIdAll: '1,2,53,66',
                children: []
              }
            ]
          },
          {
            id: 67,
            parentId: 2,
            regionType: 2,
            regionName: '漯河市',
            parentIdAll: '1,2,67',
            children: [
              {
                id: 68,
                parentId: 67,
                regionType: 3,
                regionName: '临颍县',
                parentIdAll: '1,2,67,68',
                children: []
              },
              {
                id: 69,
                parentId: 67,
                regionType: 3,
                regionName: '郾城区',
                parentIdAll: '1,2,67,69',
                children: []
              },
              {
                id: 70,
                parentId: 67,
                regionType: 3,
                regionName: '舞阳县',
                parentIdAll: '1,2,67,70',
                children: []
              },
              {
                id: 71,
                parentId: 67,
                regionType: 3,
                regionName: '召陵区',
                parentIdAll: '1,2,67,71',
                children: []
              },
              {
                id: 72,
                parentId: 67,
                regionType: 3,
                regionName: '源汇区',
                parentIdAll: '1,2,67,72',
                children: []
              }
            ]
          },
          {
            id: 73,
            parentId: 2,
            regionType: 2,
            regionName: '济源市',
            parentIdAll: '1,2,73',
            children: [
              {
                id: 3259,
                parentId: 73,
                regionType: 3,
                regionName: '济源市',
                parentIdAll: '1,2,73,3259',
                children: []
              }
            ]
          },
          {
            id: 74,
            parentId: 2,
            regionType: 2,
            regionName: '平顶山市',
            parentIdAll: '1,2,74',
            children: [
              {
                id: 75,
                parentId: 74,
                regionType: 3,
                regionName: '郏县',
                parentIdAll: '1,2,74,75',
                children: []
              },
              {
                id: 76,
                parentId: 74,
                regionType: 3,
                regionName: '宝丰县',
                parentIdAll: '1,2,74,76',
                children: []
              },
              {
                id: 77,
                parentId: 74,
                regionType: 3,
                regionName: '石龙区',
                parentIdAll: '1,2,74,77',
                children: []
              },
              {
                id: 78,
                parentId: 74,
                regionType: 3,
                regionName: '卫东区',
                parentIdAll: '1,2,74,78',
                children: []
              },
              {
                id: 79,
                parentId: 74,
                regionType: 3,
                regionName: '湛河区',
                parentIdAll: '1,2,74,79',
                children: []
              },
              {
                id: 80,
                parentId: 74,
                regionType: 3,
                regionName: '鲁山县',
                parentIdAll: '1,2,74,80',
                children: []
              },
              {
                id: 81,
                parentId: 74,
                regionType: 3,
                regionName: '叶县',
                parentIdAll: '1,2,74,81',
                children: []
              },
              {
                id: 82,
                parentId: 74,
                regionType: 3,
                regionName: '舞钢市',
                parentIdAll: '1,2,74,82',
                children: []
              },
              {
                id: 83,
                parentId: 74,
                regionType: 3,
                regionName: '新华区',
                parentIdAll: '1,2,74,83',
                children: []
              },
              {
                id: 84,
                parentId: 74,
                regionType: 3,
                regionName: '汝州市',
                parentIdAll: '1,2,74,84',
                children: []
              }
            ]
          },
          {
            id: 85,
            parentId: 2,
            regionType: 2,
            regionName: '信阳市',
            parentIdAll: '1,2,85',
            children: [
              {
                id: 86,
                parentId: 85,
                regionType: 3,
                regionName: '潢川县',
                parentIdAll: '1,2,85,86',
                children: []
              },
              {
                id: 87,
                parentId: 85,
                regionType: 3,
                regionName: '淮滨县',
                parentIdAll: '1,2,85,87',
                children: []
              },
              {
                id: 88,
                parentId: 85,
                regionType: 3,
                regionName: '光山县',
                parentIdAll: '1,2,85,88',
                children: []
              },
              {
                id: 89,
                parentId: 85,
                regionType: 3,
                regionName: '罗山县',
                parentIdAll: '1,2,85,89',
                children: []
              },
              {
                id: 90,
                parentId: 85,
                regionType: 3,
                regionName: '浉河区',
                parentIdAll: '1,2,85,90',
                children: []
              },
              {
                id: 91,
                parentId: 85,
                regionType: 3,
                regionName: '商城县',
                parentIdAll: '1,2,85,91',
                children: []
              },
              {
                id: 92,
                parentId: 85,
                regionType: 3,
                regionName: '固始县',
                parentIdAll: '1,2,85,92',
                children: []
              },
              {
                id: 93,
                parentId: 85,
                regionType: 3,
                regionName: '新县',
                parentIdAll: '1,2,85,93',
                children: []
              },
              {
                id: 94,
                parentId: 85,
                regionType: 3,
                regionName: '息县',
                parentIdAll: '1,2,85,94',
                children: []
              },
              {
                id: 95,
                parentId: 85,
                regionType: 3,
                regionName: '平桥区',
                parentIdAll: '1,2,85,95',
                children: []
              }
            ]
          },
          {
            id: 96,
            parentId: 2,
            regionType: 2,
            regionName: '焦作市',
            parentIdAll: '1,2,96',
            children: [
              {
                id: 97,
                parentId: 96,
                regionType: 3,
                regionName: '沁阳市',
                parentIdAll: '1,2,96,97',
                children: []
              },
              {
                id: 98,
                parentId: 96,
                regionType: 3,
                regionName: '温县',
                parentIdAll: '1,2,96,98',
                children: []
              },
              {
                id: 99,
                parentId: 96,
                regionType: 3,
                regionName: '解放区',
                parentIdAll: '1,2,96,99',
                children: []
              },
              {
                id: 100,
                parentId: 96,
                regionType: 3,
                regionName: '孟州市',
                parentIdAll: '1,2,96,100',
                children: []
              },
              {
                id: 101,
                parentId: 96,
                regionType: 3,
                regionName: '马村区',
                parentIdAll: '1,2,96,101',
                children: []
              },
              {
                id: 102,
                parentId: 96,
                regionType: 3,
                regionName: '山阳区',
                parentIdAll: '1,2,96,102',
                children: []
              },
              {
                id: 103,
                parentId: 96,
                regionType: 3,
                regionName: '博爱县',
                parentIdAll: '1,2,96,103',
                children: []
              },
              {
                id: 104,
                parentId: 96,
                regionType: 3,
                regionName: '武陟县',
                parentIdAll: '1,2,96,104',
                children: []
              },
              {
                id: 105,
                parentId: 96,
                regionType: 3,
                regionName: '修武县',
                parentIdAll: '1,2,96,105',
                children: []
              },
              {
                id: 106,
                parentId: 96,
                regionType: 3,
                regionName: '中站区',
                parentIdAll: '1,2,96,106',
                children: []
              }
            ]
          },
          {
            id: 107,
            parentId: 2,
            regionType: 2,
            regionName: '安阳市',
            parentIdAll: '1,2,107',
            children: [
              {
                id: 108,
                parentId: 107,
                regionType: 3,
                regionName: '殷都区',
                parentIdAll: '1,2,107,108',
                children: []
              },
              {
                id: 109,
                parentId: 107,
                regionType: 3,
                regionName: '内黄县',
                parentIdAll: '1,2,107,109',
                children: []
              },
              {
                id: 110,
                parentId: 107,
                regionType: 3,
                regionName: '龙安区',
                parentIdAll: '1,2,107,110',
                children: []
              },
              {
                id: 111,
                parentId: 107,
                regionType: 3,
                regionName: '汤阴县',
                parentIdAll: '1,2,107,111',
                children: []
              },
              {
                id: 112,
                parentId: 107,
                regionType: 3,
                regionName: '文峰区',
                parentIdAll: '1,2,107,112',
                children: []
              },
              {
                id: 113,
                parentId: 107,
                regionType: 3,
                regionName: '北关区',
                parentIdAll: '1,2,107,113',
                children: []
              },
              {
                id: 114,
                parentId: 107,
                regionType: 3,
                regionName: '滑县',
                parentIdAll: '1,2,107,114',
                children: []
              },
              {
                id: 115,
                parentId: 107,
                regionType: 3,
                regionName: '安阳县',
                parentIdAll: '1,2,107,115',
                children: []
              },
              {
                id: 116,
                parentId: 107,
                regionType: 3,
                regionName: '林州市',
                parentIdAll: '1,2,107,116',
                children: []
              }
            ]
          },
          {
            id: 117,
            parentId: 2,
            regionType: 2,
            regionName: '驻马店市',
            parentIdAll: '1,2,117',
            children: [
              {
                id: 118,
                parentId: 117,
                regionType: 3,
                regionName: '上蔡县',
                parentIdAll: '1,2,117,118',
                children: []
              },
              {
                id: 119,
                parentId: 117,
                regionType: 3,
                regionName: '西平县',
                parentIdAll: '1,2,117,119',
                children: []
              },
              {
                id: 120,
                parentId: 117,
                regionType: 3,
                regionName: '汝南县',
                parentIdAll: '1,2,117,120',
                children: []
              },
              {
                id: 121,
                parentId: 117,
                regionType: 3,
                regionName: '平舆县',
                parentIdAll: '1,2,117,121',
                children: []
              },
              {
                id: 122,
                parentId: 117,
                regionType: 3,
                regionName: '新蔡县',
                parentIdAll: '1,2,117,122',
                children: []
              },
              {
                id: 123,
                parentId: 117,
                regionType: 3,
                regionName: '遂平县',
                parentIdAll: '1,2,117,123',
                children: []
              },
              {
                id: 124,
                parentId: 117,
                regionType: 3,
                regionName: '泌阳县',
                parentIdAll: '1,2,117,124',
                children: []
              },
              {
                id: 125,
                parentId: 117,
                regionType: 3,
                regionName: '驿城区',
                parentIdAll: '1,2,117,125',
                children: []
              },
              {
                id: 126,
                parentId: 117,
                regionType: 3,
                regionName: '确山县',
                parentIdAll: '1,2,117,126',
                children: []
              },
              {
                id: 127,
                parentId: 117,
                regionType: 3,
                regionName: '正阳县',
                parentIdAll: '1,2,117,127',
                children: []
              }
            ]
          },
          {
            id: 128,
            parentId: 2,
            regionType: 2,
            regionName: '商丘市',
            parentIdAll: '1,2,128',
            children: [
              {
                id: 129,
                parentId: 128,
                regionType: 3,
                regionName: '民权县',
                parentIdAll: '1,2,128,129',
                children: []
              },
              {
                id: 130,
                parentId: 128,
                regionType: 3,
                regionName: '宁陵县',
                parentIdAll: '1,2,128,130',
                children: []
              },
              {
                id: 131,
                parentId: 128,
                regionType: 3,
                regionName: '睢阳区',
                parentIdAll: '1,2,128,131',
                children: []
              },
              {
                id: 132,
                parentId: 128,
                regionType: 3,
                regionName: '睢县',
                parentIdAll: '1,2,128,132',
                children: []
              },
              {
                id: 133,
                parentId: 128,
                regionType: 3,
                regionName: '夏邑县',
                parentIdAll: '1,2,128,133',
                children: []
              },
              {
                id: 134,
                parentId: 128,
                regionType: 3,
                regionName: '永城市',
                parentIdAll: '1,2,128,134',
                children: []
              },
              {
                id: 135,
                parentId: 128,
                regionType: 3,
                regionName: '柘城县',
                parentIdAll: '1,2,128,135',
                children: []
              },
              {
                id: 136,
                parentId: 128,
                regionType: 3,
                regionName: '梁园区',
                parentIdAll: '1,2,128,136',
                children: []
              },
              {
                id: 137,
                parentId: 128,
                regionType: 3,
                regionName: '虞城县',
                parentIdAll: '1,2,128,137',
                children: []
              }
            ]
          },
          {
            id: 138,
            parentId: 2,
            regionType: 2,
            regionName: '新乡市',
            parentIdAll: '1,2,138',
            children: [
              {
                id: 139,
                parentId: 138,
                regionType: 3,
                regionName: '红旗区',
                parentIdAll: '1,2,138,139',
                children: []
              },
              {
                id: 140,
                parentId: 138,
                regionType: 3,
                regionName: '辉县市',
                parentIdAll: '1,2,138,140',
                children: []
              },
              {
                id: 141,
                parentId: 138,
                regionType: 3,
                regionName: '凤泉区',
                parentIdAll: '1,2,138,141',
                children: []
              },
              {
                id: 142,
                parentId: 138,
                regionType: 3,
                regionName: '获嘉县',
                parentIdAll: '1,2,138,142',
                children: []
              },
              {
                id: 143,
                parentId: 138,
                regionType: 3,
                regionName: '卫辉市',
                parentIdAll: '1,2,138,143',
                children: []
              },
              {
                id: 144,
                parentId: 138,
                regionType: 3,
                regionName: '延津县',
                parentIdAll: '1,2,138,144',
                children: []
              },
              {
                id: 145,
                parentId: 138,
                regionType: 3,
                regionName: '封丘县',
                parentIdAll: '1,2,138,145',
                children: []
              },
              {
                id: 146,
                parentId: 138,
                regionType: 3,
                regionName: '原阳县',
                parentIdAll: '1,2,138,146',
                children: []
              },
              {
                id: 147,
                parentId: 138,
                regionType: 3,
                regionName: '长垣县',
                parentIdAll: '1,2,138,147',
                children: []
              },
              {
                id: 148,
                parentId: 138,
                regionType: 3,
                regionName: '新乡县',
                parentIdAll: '1,2,138,148',
                children: []
              },
              {
                id: 149,
                parentId: 138,
                regionType: 3,
                regionName: '卫滨区',
                parentIdAll: '1,2,138,149',
                children: []
              },
              {
                id: 150,
                parentId: 138,
                regionType: 3,
                regionName: '牧野区',
                parentIdAll: '1,2,138,150',
                children: []
              }
            ]
          },
          {
            id: 151,
            parentId: 2,
            regionType: 2,
            regionName: '鹤壁市',
            parentIdAll: '1,2,151',
            children: [
              {
                id: 152,
                parentId: 151,
                regionType: 3,
                regionName: '鹤山区',
                parentIdAll: '1,2,151,152',
                children: []
              },
              {
                id: 153,
                parentId: 151,
                regionType: 3,
                regionName: '淇滨区',
                parentIdAll: '1,2,151,153',
                children: []
              },
              {
                id: 154,
                parentId: 151,
                regionType: 3,
                regionName: '浚县',
                parentIdAll: '1,2,151,154',
                children: []
              },
              {
                id: 155,
                parentId: 151,
                regionType: 3,
                regionName: '淇县',
                parentIdAll: '1,2,151,155',
                children: []
              },
              {
                id: 156,
                parentId: 151,
                regionType: 3,
                regionName: '山城区',
                parentIdAll: '1,2,151,156',
                children: []
              }
            ]
          },
          {
            id: 157,
            parentId: 2,
            regionType: 2,
            regionName: '周口市',
            parentIdAll: '1,2,157',
            children: [
              {
                id: 158,
                parentId: 157,
                regionType: 3,
                regionName: '太康县',
                parentIdAll: '1,2,157,158',
                children: []
              },
              {
                id: 159,
                parentId: 157,
                regionType: 3,
                regionName: '西华县',
                parentIdAll: '1,2,157,159',
                children: []
              },
              {
                id: 160,
                parentId: 157,
                regionType: 3,
                regionName: '鹿邑县',
                parentIdAll: '1,2,157,160',
                children: []
              },
              {
                id: 161,
                parentId: 157,
                regionType: 3,
                regionName: '郸城县',
                parentIdAll: '1,2,157,161',
                children: []
              },
              {
                id: 162,
                parentId: 157,
                regionType: 3,
                regionName: '沈丘县',
                parentIdAll: '1,2,157,162',
                children: []
              },
              {
                id: 163,
                parentId: 157,
                regionType: 3,
                regionName: '项城市',
                parentIdAll: '1,2,157,163',
                children: []
              },
              {
                id: 164,
                parentId: 157,
                regionType: 3,
                regionName: '扶沟县',
                parentIdAll: '1,2,157,164',
                children: []
              },
              {
                id: 165,
                parentId: 157,
                regionType: 3,
                regionName: '淮阳县',
                parentIdAll: '1,2,157,165',
                children: []
              },
              {
                id: 166,
                parentId: 157,
                regionType: 3,
                regionName: '川汇区',
                parentIdAll: '1,2,157,166',
                children: []
              },
              {
                id: 167,
                parentId: 157,
                regionType: 3,
                regionName: '商水县',
                parentIdAll: '1,2,157,167',
                children: []
              }
            ]
          },
          {
            id: 168,
            parentId: 2,
            regionType: 2,
            regionName: '开封市',
            parentIdAll: '1,2,168',
            children: [
              {
                id: 169,
                parentId: 168,
                regionType: 3,
                regionName: '兰考县',
                parentIdAll: '1,2,168,169',
                children: []
              },
              {
                id: 170,
                parentId: 168,
                regionType: 3,
                regionName: '祥符区',
                parentIdAll: '1,2,168,170',
                children: []
              },
              {
                id: 171,
                parentId: 168,
                regionType: 3,
                regionName: '顺河回族区',
                parentIdAll: '1,2,168,171',
                children: []
              },
              {
                id: 172,
                parentId: 168,
                regionType: 3,
                regionName: '鼓楼区',
                parentIdAll: '1,2,168,172',
                children: []
              },
              {
                id: 173,
                parentId: 168,
                regionType: 3,
                regionName: '禹王台区',
                parentIdAll: '1,2,168,173',
                children: []
              },
              {
                id: 174,
                parentId: 168,
                regionType: 3,
                regionName: '杞县',
                parentIdAll: '1,2,168,174',
                children: []
              },
              {
                id: 175,
                parentId: 168,
                regionType: 3,
                regionName: '龙亭区',
                parentIdAll: '1,2,168,175',
                children: []
              },
              {
                id: 176,
                parentId: 168,
                regionType: 3,
                regionName: '尉氏县',
                parentIdAll: '1,2,168,176',
                children: []
              },
              {
                id: 177,
                parentId: 168,
                regionType: 3,
                regionName: '通许县',
                parentIdAll: '1,2,168,177',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 178,
        parentId: 1,
        regionType: 1,
        regionName: '广东省',
        parentIdAll: '1,178',
        children: [
          {
            id: 179,
            parentId: 178,
            regionType: 2,
            regionName: '汕头市',
            parentIdAll: '1,178,179',
            children: [
              {
                id: 180,
                parentId: 179,
                regionType: 3,
                regionName: '南澳县',
                parentIdAll: '1,178,179,180',
                children: []
              },
              {
                id: 181,
                parentId: 179,
                regionType: 3,
                regionName: '澄海区',
                parentIdAll: '1,178,179,181',
                children: []
              },
              {
                id: 182,
                parentId: 179,
                regionType: 3,
                regionName: '濠江区',
                parentIdAll: '1,178,179,182',
                children: []
              },
              {
                id: 183,
                parentId: 179,
                regionType: 3,
                regionName: '潮阳区',
                parentIdAll: '1,178,179,183',
                children: []
              },
              {
                id: 184,
                parentId: 179,
                regionType: 3,
                regionName: '潮南区',
                parentIdAll: '1,178,179,184',
                children: []
              },
              {
                id: 185,
                parentId: 179,
                regionType: 3,
                regionName: '龙湖区',
                parentIdAll: '1,178,179,185',
                children: []
              },
              {
                id: 186,
                parentId: 179,
                regionType: 3,
                regionName: '金平区',
                parentIdAll: '1,178,179,186',
                children: []
              }
            ]
          },
          {
            id: 187,
            parentId: 178,
            regionType: 2,
            regionName: '佛山市',
            parentIdAll: '1,178,187',
            children: [
              {
                id: 188,
                parentId: 187,
                regionType: 3,
                regionName: '三水区',
                parentIdAll: '1,178,187,188',
                children: []
              },
              {
                id: 189,
                parentId: 187,
                regionType: 3,
                regionName: '高明区',
                parentIdAll: '1,178,187,189',
                children: []
              },
              {
                id: 190,
                parentId: 187,
                regionType: 3,
                regionName: '顺德区',
                parentIdAll: '1,178,187,190',
                children: []
              },
              {
                id: 191,
                parentId: 187,
                regionType: 3,
                regionName: '禅城区',
                parentIdAll: '1,178,187,191',
                children: []
              },
              {
                id: 192,
                parentId: 187,
                regionType: 3,
                regionName: '南海区',
                parentIdAll: '1,178,187,192',
                children: []
              }
            ]
          },
          {
            id: 193,
            parentId: 178,
            regionType: 2,
            regionName: '珠海市',
            parentIdAll: '1,178,193',
            children: [
              {
                id: 194,
                parentId: 193,
                regionType: 3,
                regionName: '斗门区',
                parentIdAll: '1,178,193,194',
                children: []
              },
              {
                id: 195,
                parentId: 193,
                regionType: 3,
                regionName: '金湾区',
                parentIdAll: '1,178,193,195',
                children: []
              },
              {
                id: 196,
                parentId: 193,
                regionType: 3,
                regionName: '香洲区',
                parentIdAll: '1,178,193,196',
                children: []
              },
              {
                id: 197,
                parentId: 193,
                regionType: 3,
                regionName: '澳门大学横琴校区(由澳门管辖)',
                parentIdAll: '1,178,193,197',
                children: []
              }
            ]
          },
          {
            id: 198,
            parentId: 178,
            regionType: 2,
            regionName: '江门市',
            parentIdAll: '1,178,198',
            children: [
              {
                id: 199,
                parentId: 198,
                regionType: 3,
                regionName: '鹤山市',
                parentIdAll: '1,178,198,199',
                children: []
              },
              {
                id: 200,
                parentId: 198,
                regionType: 3,
                regionName: '江海区',
                parentIdAll: '1,178,198,200',
                children: []
              },
              {
                id: 201,
                parentId: 198,
                regionType: 3,
                regionName: '开平市',
                parentIdAll: '1,178,198,201',
                children: []
              },
              {
                id: 202,
                parentId: 198,
                regionType: 3,
                regionName: '台山市',
                parentIdAll: '1,178,198,202',
                children: []
              },
              {
                id: 203,
                parentId: 198,
                regionType: 3,
                regionName: '恩平市',
                parentIdAll: '1,178,198,203',
                children: []
              },
              {
                id: 204,
                parentId: 198,
                regionType: 3,
                regionName: '新会区',
                parentIdAll: '1,178,198,204',
                children: []
              },
              {
                id: 205,
                parentId: 198,
                regionType: 3,
                regionName: '蓬江区',
                parentIdAll: '1,178,198,205',
                children: []
              }
            ]
          },
          {
            id: 206,
            parentId: 178,
            regionType: 2,
            regionName: '湛江市',
            parentIdAll: '1,178,206',
            children: [
              {
                id: 207,
                parentId: 206,
                regionType: 3,
                regionName: '吴川市',
                parentIdAll: '1,178,206,207',
                children: []
              },
              {
                id: 208,
                parentId: 206,
                regionType: 3,
                regionName: '廉江市',
                parentIdAll: '1,178,206,208',
                children: []
              },
              {
                id: 209,
                parentId: 206,
                regionType: 3,
                regionName: '雷州市',
                parentIdAll: '1,178,206,209',
                children: []
              },
              {
                id: 210,
                parentId: 206,
                regionType: 3,
                regionName: '徐闻县',
                parentIdAll: '1,178,206,210',
                children: []
              },
              {
                id: 211,
                parentId: 206,
                regionType: 3,
                regionName: '霞山区',
                parentIdAll: '1,178,206,211',
                children: []
              },
              {
                id: 212,
                parentId: 206,
                regionType: 3,
                regionName: '麻章区',
                parentIdAll: '1,178,206,212',
                children: []
              },
              {
                id: 213,
                parentId: 206,
                regionType: 3,
                regionName: '坡头区',
                parentIdAll: '1,178,206,213',
                children: []
              },
              {
                id: 214,
                parentId: 206,
                regionType: 3,
                regionName: '赤坎区',
                parentIdAll: '1,178,206,214',
                children: []
              },
              {
                id: 215,
                parentId: 206,
                regionType: 3,
                regionName: '遂溪县',
                parentIdAll: '1,178,206,215',
                children: []
              }
            ]
          },
          {
            id: 216,
            parentId: 178,
            regionType: 2,
            regionName: '惠州市',
            parentIdAll: '1,178,216',
            children: [
              {
                id: 217,
                parentId: 216,
                regionType: 3,
                regionName: '龙门县',
                parentIdAll: '1,178,216,217',
                children: []
              },
              {
                id: 218,
                parentId: 216,
                regionType: 3,
                regionName: '博罗县',
                parentIdAll: '1,178,216,218',
                children: []
              },
              {
                id: 219,
                parentId: 216,
                regionType: 3,
                regionName: '惠东县',
                parentIdAll: '1,178,216,219',
                children: []
              },
              {
                id: 220,
                parentId: 216,
                regionType: 3,
                regionName: '惠阳区',
                parentIdAll: '1,178,216,220',
                children: []
              },
              {
                id: 221,
                parentId: 216,
                regionType: 3,
                regionName: '惠城区',
                parentIdAll: '1,178,216,221',
                children: []
              }
            ]
          },
          {
            id: 222,
            parentId: 178,
            regionType: 2,
            regionName: '肇庆市',
            parentIdAll: '1,178,222',
            children: [
              {
                id: 223,
                parentId: 222,
                regionType: 3,
                regionName: '德庆县',
                parentIdAll: '1,178,222,223',
                children: []
              },
              {
                id: 224,
                parentId: 222,
                regionType: 3,
                regionName: '鼎湖区',
                parentIdAll: '1,178,222,224',
                children: []
              },
              {
                id: 225,
                parentId: 222,
                regionType: 3,
                regionName: '端州区',
                parentIdAll: '1,178,222,225',
                children: []
              },
              {
                id: 226,
                parentId: 222,
                regionType: 3,
                regionName: '封开县',
                parentIdAll: '1,178,222,226',
                children: []
              },
              {
                id: 227,
                parentId: 222,
                regionType: 3,
                regionName: '高要区',
                parentIdAll: '1,178,222,227',
                children: []
              },
              {
                id: 228,
                parentId: 222,
                regionType: 3,
                regionName: '广宁县',
                parentIdAll: '1,178,222,228',
                children: []
              },
              {
                id: 229,
                parentId: 222,
                regionType: 3,
                regionName: '怀集县',
                parentIdAll: '1,178,222,229',
                children: []
              },
              {
                id: 230,
                parentId: 222,
                regionType: 3,
                regionName: '四会市',
                parentIdAll: '1,178,222,230',
                children: []
              }
            ]
          },
          {
            id: 231,
            parentId: 178,
            regionType: 2,
            regionName: '汕尾市',
            parentIdAll: '1,178,231',
            children: [
              {
                id: 232,
                parentId: 231,
                regionType: 3,
                regionName: '陆河县',
                parentIdAll: '1,178,231,232',
                children: []
              },
              {
                id: 233,
                parentId: 231,
                regionType: 3,
                regionName: '陆丰市',
                parentIdAll: '1,178,231,233',
                children: []
              },
              {
                id: 234,
                parentId: 231,
                regionType: 3,
                regionName: '海丰县',
                parentIdAll: '1,178,231,234',
                children: []
              },
              {
                id: 235,
                parentId: 231,
                regionType: 3,
                regionName: '城区',
                parentIdAll: '1,178,231,235',
                children: []
              }
            ]
          },
          {
            id: 236,
            parentId: 178,
            regionType: 2,
            regionName: '茂名市',
            parentIdAll: '1,178,236',
            children: [
              {
                id: 237,
                parentId: 236,
                regionType: 3,
                regionName: '信宜市',
                parentIdAll: '1,178,236,237',
                children: []
              },
              {
                id: 238,
                parentId: 236,
                regionType: 3,
                regionName: '高州市',
                parentIdAll: '1,178,236,238',
                children: []
              },
              {
                id: 239,
                parentId: 236,
                regionType: 3,
                regionName: '化州市',
                parentIdAll: '1,178,236,239',
                children: []
              },
              {
                id: 240,
                parentId: 236,
                regionType: 3,
                regionName: '电白区',
                parentIdAll: '1,178,236,240',
                children: []
              },
              {
                id: 241,
                parentId: 236,
                regionType: 3,
                regionName: '茂南区',
                parentIdAll: '1,178,236,241',
                children: []
              }
            ]
          },
          {
            id: 242,
            parentId: 178,
            regionType: 2,
            regionName: '深圳市',
            parentIdAll: '1,178,242',
            children: [
              {
                id: 243,
                parentId: 242,
                regionType: 3,
                regionName: '宝安区',
                parentIdAll: '1,178,242,243',
                children: []
              },
              {
                id: 244,
                parentId: 242,
                regionType: 3,
                regionName: '福田区',
                parentIdAll: '1,178,242,244',
                children: []
              },
              {
                id: 245,
                parentId: 242,
                regionType: 3,
                regionName: '龙岗区',
                parentIdAll: '1,178,242,245',
                children: []
              },
              {
                id: 246,
                parentId: 242,
                regionType: 3,
                regionName: '龙华区',
                parentIdAll: '1,178,242,246',
                children: []
              },
              {
                id: 247,
                parentId: 242,
                regionType: 3,
                regionName: '罗湖区',
                parentIdAll: '1,178,242,247',
                children: []
              },
              {
                id: 248,
                parentId: 242,
                regionType: 3,
                regionName: '南山区',
                parentIdAll: '1,178,242,248',
                children: []
              },
              {
                id: 249,
                parentId: 242,
                regionType: 3,
                regionName: '坪山区',
                parentIdAll: '1,178,242,249',
                children: []
              },
              {
                id: 250,
                parentId: 242,
                regionType: 3,
                regionName: '盐田区',
                parentIdAll: '1,178,242,250',
                children: []
              },
              {
                id: 3320,
                parentId: 242,
                regionType: 3,
                regionName: '光明新区',
                parentIdAll: '1,178,242,3320',
                children: []
              }
            ]
          },
          {
            id: 251,
            parentId: 178,
            regionType: 2,
            regionName: '阳江市',
            parentIdAll: '1,178,251',
            children: [
              {
                id: 252,
                parentId: 251,
                regionType: 3,
                regionName: '阳春市',
                parentIdAll: '1,178,251,252',
                children: []
              },
              {
                id: 253,
                parentId: 251,
                regionType: 3,
                regionName: '江城区',
                parentIdAll: '1,178,251,253',
                children: []
              },
              {
                id: 254,
                parentId: 251,
                regionType: 3,
                regionName: '阳西县',
                parentIdAll: '1,178,251,254',
                children: []
              },
              {
                id: 255,
                parentId: 251,
                regionType: 3,
                regionName: '阳东区',
                parentIdAll: '1,178,251,255',
                children: []
              }
            ]
          },
          {
            id: 256,
            parentId: 178,
            regionType: 2,
            regionName: '潮州市',
            parentIdAll: '1,178,256',
            children: [
              {
                id: 257,
                parentId: 256,
                regionType: 3,
                regionName: '饶平县',
                parentIdAll: '1,178,256,257',
                children: []
              },
              {
                id: 258,
                parentId: 256,
                regionType: 3,
                regionName: '湘桥区',
                parentIdAll: '1,178,256,258',
                children: []
              },
              {
                id: 259,
                parentId: 256,
                regionType: 3,
                regionName: '潮安区',
                parentIdAll: '1,178,256,259',
                children: []
              }
            ]
          },
          {
            id: 260,
            parentId: 178,
            regionType: 2,
            regionName: '韶关市',
            parentIdAll: '1,178,260',
            children: [
              {
                id: 261,
                parentId: 260,
                regionType: 3,
                regionName: '仁化县',
                parentIdAll: '1,178,260,261',
                children: []
              },
              {
                id: 262,
                parentId: 260,
                regionType: 3,
                regionName: '曲江区',
                parentIdAll: '1,178,260,262',
                children: []
              },
              {
                id: 263,
                parentId: 260,
                regionType: 3,
                regionName: '南雄市',
                parentIdAll: '1,178,260,263',
                children: []
              },
              {
                id: 264,
                parentId: 260,
                regionType: 3,
                regionName: '乳源瑶族自治县',
                parentIdAll: '1,178,260,264',
                children: []
              },
              {
                id: 265,
                parentId: 260,
                regionType: 3,
                regionName: '浈江区',
                parentIdAll: '1,178,260,265',
                children: []
              },
              {
                id: 266,
                parentId: 260,
                regionType: 3,
                regionName: '武江区',
                parentIdAll: '1,178,260,266',
                children: []
              },
              {
                id: 267,
                parentId: 260,
                regionType: 3,
                regionName: '始兴县',
                parentIdAll: '1,178,260,267',
                children: []
              },
              {
                id: 268,
                parentId: 260,
                regionType: 3,
                regionName: '翁源县',
                parentIdAll: '1,178,260,268',
                children: []
              },
              {
                id: 269,
                parentId: 260,
                regionType: 3,
                regionName: '新丰县',
                parentIdAll: '1,178,260,269',
                children: []
              },
              {
                id: 270,
                parentId: 260,
                regionType: 3,
                regionName: '乐昌市',
                parentIdAll: '1,178,260,270',
                children: []
              }
            ]
          },
          {
            id: 271,
            parentId: 178,
            regionType: 2,
            regionName: '清远市',
            parentIdAll: '1,178,271',
            children: [
              {
                id: 272,
                parentId: 271,
                regionType: 3,
                regionName: '连南瑶族自治县',
                parentIdAll: '1,178,271,272',
                children: []
              },
              {
                id: 273,
                parentId: 271,
                regionType: 3,
                regionName: '连州市',
                parentIdAll: '1,178,271,273',
                children: []
              },
              {
                id: 274,
                parentId: 271,
                regionType: 3,
                regionName: '连山壮族瑶族自治县',
                parentIdAll: '1,178,271,274',
                children: []
              },
              {
                id: 275,
                parentId: 271,
                regionType: 3,
                regionName: '英德市',
                parentIdAll: '1,178,271,275',
                children: []
              },
              {
                id: 276,
                parentId: 271,
                regionType: 3,
                regionName: '佛冈县',
                parentIdAll: '1,178,271,276',
                children: []
              },
              {
                id: 277,
                parentId: 271,
                regionType: 3,
                regionName: '阳山县',
                parentIdAll: '1,178,271,277',
                children: []
              },
              {
                id: 278,
                parentId: 271,
                regionType: 3,
                regionName: '清新区',
                parentIdAll: '1,178,271,278',
                children: []
              },
              {
                id: 279,
                parentId: 271,
                regionType: 3,
                regionName: '清城区',
                parentIdAll: '1,178,271,279',
                children: []
              }
            ]
          },
          {
            id: 280,
            parentId: 178,
            regionType: 2,
            regionName: '梅州市',
            parentIdAll: '1,178,280',
            children: [
              {
                id: 281,
                parentId: 280,
                regionType: 3,
                regionName: '平远县',
                parentIdAll: '1,178,280,281',
                children: []
              },
              {
                id: 282,
                parentId: 280,
                regionType: 3,
                regionName: '蕉岭县',
                parentIdAll: '1,178,280,282',
                children: []
              },
              {
                id: 283,
                parentId: 280,
                regionType: 3,
                regionName: '兴宁市',
                parentIdAll: '1,178,280,283',
                children: []
              },
              {
                id: 284,
                parentId: 280,
                regionType: 3,
                regionName: '五华县',
                parentIdAll: '1,178,280,284',
                children: []
              },
              {
                id: 285,
                parentId: 280,
                regionType: 3,
                regionName: '梅县区',
                parentIdAll: '1,178,280,285',
                children: []
              },
              {
                id: 286,
                parentId: 280,
                regionType: 3,
                regionName: '丰顺县',
                parentIdAll: '1,178,280,286',
                children: []
              },
              {
                id: 287,
                parentId: 280,
                regionType: 3,
                regionName: '大埔县',
                parentIdAll: '1,178,280,287',
                children: []
              },
              {
                id: 288,
                parentId: 280,
                regionType: 3,
                regionName: '梅江区',
                parentIdAll: '1,178,280,288',
                children: []
              }
            ]
          },
          {
            id: 289,
            parentId: 178,
            regionType: 2,
            regionName: '云浮市',
            parentIdAll: '1,178,289',
            children: [
              {
                id: 290,
                parentId: 289,
                regionType: 3,
                regionName: '郁南县',
                parentIdAll: '1,178,289,290',
                children: []
              },
              {
                id: 291,
                parentId: 289,
                regionType: 3,
                regionName: '罗定市',
                parentIdAll: '1,178,289,291',
                children: []
              },
              {
                id: 292,
                parentId: 289,
                regionType: 3,
                regionName: '新兴县',
                parentIdAll: '1,178,289,292',
                children: []
              },
              {
                id: 293,
                parentId: 289,
                regionType: 3,
                regionName: '云安区',
                parentIdAll: '1,178,289,293',
                children: []
              },
              {
                id: 294,
                parentId: 289,
                regionType: 3,
                regionName: '云城区',
                parentIdAll: '1,178,289,294',
                children: []
              }
            ]
          },
          {
            id: 295,
            parentId: 178,
            regionType: 2,
            regionName: '河源市',
            parentIdAll: '1,178,295',
            children: [
              {
                id: 296,
                parentId: 295,
                regionType: 3,
                regionName: '和平县',
                parentIdAll: '1,178,295,296',
                children: []
              },
              {
                id: 297,
                parentId: 295,
                regionType: 3,
                regionName: '龙川县',
                parentIdAll: '1,178,295,297',
                children: []
              },
              {
                id: 298,
                parentId: 295,
                regionType: 3,
                regionName: '连平县',
                parentIdAll: '1,178,295,298',
                children: []
              },
              {
                id: 299,
                parentId: 295,
                regionType: 3,
                regionName: '东源县',
                parentIdAll: '1,178,295,299',
                children: []
              },
              {
                id: 300,
                parentId: 295,
                regionType: 3,
                regionName: '源城区',
                parentIdAll: '1,178,295,300',
                children: []
              },
              {
                id: 301,
                parentId: 295,
                regionType: 3,
                regionName: '紫金县',
                parentIdAll: '1,178,295,301',
                children: []
              }
            ]
          },
          {
            id: 302,
            parentId: 178,
            regionType: 2,
            regionName: '东莞市',
            parentIdAll: '1,178,302',
            children: [
              {
                id: 3260,
                parentId: 302,
                regionType: 3,
                regionName: '东莞市',
                parentIdAll: '1,178,302,3260',
                children: []
              }
            ]
          },
          {
            id: 303,
            parentId: 178,
            regionType: 2,
            regionName: '广州市',
            parentIdAll: '1,178,303',
            children: [
              {
                id: 304,
                parentId: 303,
                regionType: 3,
                regionName: '从化区',
                parentIdAll: '1,178,303,304',
                children: []
              },
              {
                id: 305,
                parentId: 303,
                regionType: 3,
                regionName: '增城区',
                parentIdAll: '1,178,303,305',
                children: []
              },
              {
                id: 306,
                parentId: 303,
                regionType: 3,
                regionName: '花都区',
                parentIdAll: '1,178,303,306',
                children: []
              },
              {
                id: 307,
                parentId: 303,
                regionType: 3,
                regionName: '南沙区',
                parentIdAll: '1,178,303,307',
                children: []
              },
              {
                id: 308,
                parentId: 303,
                regionType: 3,
                regionName: '黄埔区',
                parentIdAll: '1,178,303,308',
                children: []
              },
              {
                id: 309,
                parentId: 303,
                regionType: 3,
                regionName: '白云区',
                parentIdAll: '1,178,303,309',
                children: []
              },
              {
                id: 310,
                parentId: 303,
                regionType: 3,
                regionName: '天河区',
                parentIdAll: '1,178,303,310',
                children: []
              },
              {
                id: 311,
                parentId: 303,
                regionType: 3,
                regionName: '海珠区',
                parentIdAll: '1,178,303,311',
                children: []
              },
              {
                id: 312,
                parentId: 303,
                regionType: 3,
                regionName: '番禺区',
                parentIdAll: '1,178,303,312',
                children: []
              },
              {
                id: 313,
                parentId: 303,
                regionType: 3,
                regionName: '荔湾区',
                parentIdAll: '1,178,303,313',
                children: []
              },
              {
                id: 314,
                parentId: 303,
                regionType: 3,
                regionName: '越秀区',
                parentIdAll: '1,178,303,314',
                children: []
              }
            ]
          },
          {
            id: 315,
            parentId: 178,
            regionType: 2,
            regionName: '揭阳市',
            parentIdAll: '1,178,315',
            children: [
              {
                id: 316,
                parentId: 315,
                regionType: 3,
                regionName: '揭西县',
                parentIdAll: '1,178,315,316',
                children: []
              },
              {
                id: 317,
                parentId: 315,
                regionType: 3,
                regionName: '普宁市',
                parentIdAll: '1,178,315,317',
                children: []
              },
              {
                id: 318,
                parentId: 315,
                regionType: 3,
                regionName: '惠来县',
                parentIdAll: '1,178,315,318',
                children: []
              },
              {
                id: 319,
                parentId: 315,
                regionType: 3,
                regionName: '揭东区',
                parentIdAll: '1,178,315,319',
                children: []
              },
              {
                id: 320,
                parentId: 315,
                regionType: 3,
                regionName: '榕城区',
                parentIdAll: '1,178,315,320',
                children: []
              }
            ]
          },
          {
            id: 321,
            parentId: 178,
            regionType: 2,
            regionName: '中山市',
            parentIdAll: '1,178,321',
            children: [
              {
                id: 3261,
                parentId: 321,
                regionType: 3,
                regionName: '中山市',
                parentIdAll: '1,178,321,3261',
                children: []
              }
            ]
          },
          {
            id: 322,
            parentId: 178,
            regionType: 2,
            regionName: '东沙群岛',
            parentIdAll: '1,178,322',
            children: [
              {
                id: 3262,
                parentId: 322,
                regionType: 3,
                regionName: '东沙群岛',
                parentIdAll: '1,178,322,3262',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 323,
        parentId: 1,
        regionType: 1,
        regionName: '内蒙古自治区',
        parentIdAll: '1,323',
        children: [
          {
            id: 324,
            parentId: 323,
            regionType: 2,
            regionName: '乌海市',
            parentIdAll: '1,323,324',
            children: [
              {
                id: 325,
                parentId: 324,
                regionType: 3,
                regionName: '海勃湾区',
                parentIdAll: '1,323,324,325',
                children: []
              },
              {
                id: 326,
                parentId: 324,
                regionType: 3,
                regionName: '乌达区',
                parentIdAll: '1,323,324,326',
                children: []
              },
              {
                id: 327,
                parentId: 324,
                regionType: 3,
                regionName: '海南区',
                parentIdAll: '1,323,324,327',
                children: []
              }
            ]
          },
          {
            id: 328,
            parentId: 323,
            regionType: 2,
            regionName: '巴彦淖尔市',
            parentIdAll: '1,323,328',
            children: [
              {
                id: 329,
                parentId: 328,
                regionType: 3,
                regionName: '乌拉特中旗',
                parentIdAll: '1,323,328,329',
                children: []
              },
              {
                id: 330,
                parentId: 328,
                regionType: 3,
                regionName: '五原县',
                parentIdAll: '1,323,328,330',
                children: []
              },
              {
                id: 331,
                parentId: 328,
                regionType: 3,
                regionName: '临河区',
                parentIdAll: '1,323,328,331',
                children: []
              },
              {
                id: 332,
                parentId: 328,
                regionType: 3,
                regionName: '磴口县',
                parentIdAll: '1,323,328,332',
                children: []
              },
              {
                id: 333,
                parentId: 328,
                regionType: 3,
                regionName: '杭锦后旗',
                parentIdAll: '1,323,328,333',
                children: []
              },
              {
                id: 334,
                parentId: 328,
                regionType: 3,
                regionName: '乌拉特后旗',
                parentIdAll: '1,323,328,334',
                children: []
              },
              {
                id: 335,
                parentId: 328,
                regionType: 3,
                regionName: '乌拉特前旗',
                parentIdAll: '1,323,328,335',
                children: []
              }
            ]
          },
          {
            id: 336,
            parentId: 323,
            regionType: 2,
            regionName: '鄂尔多斯市',
            parentIdAll: '1,323,336',
            children: [
              {
                id: 337,
                parentId: 336,
                regionType: 3,
                regionName: '准格尔旗',
                parentIdAll: '1,323,336,337',
                children: []
              },
              {
                id: 338,
                parentId: 336,
                regionType: 3,
                regionName: '鄂托克旗',
                parentIdAll: '1,323,336,338',
                children: []
              },
              {
                id: 339,
                parentId: 336,
                regionType: 3,
                regionName: '杭锦旗',
                parentIdAll: '1,323,336,339',
                children: []
              },
              {
                id: 340,
                parentId: 336,
                regionType: 3,
                regionName: '达拉特旗',
                parentIdAll: '1,323,336,340',
                children: []
              },
              {
                id: 341,
                parentId: 336,
                regionType: 3,
                regionName: '乌审旗',
                parentIdAll: '1,323,336,341',
                children: []
              },
              {
                id: 342,
                parentId: 336,
                regionType: 3,
                regionName: '鄂托克前旗',
                parentIdAll: '1,323,336,342',
                children: []
              },
              {
                id: 343,
                parentId: 336,
                regionType: 3,
                regionName: '康巴什区',
                parentIdAll: '1,323,336,343',
                children: []
              },
              {
                id: 344,
                parentId: 336,
                regionType: 3,
                regionName: '东胜区',
                parentIdAll: '1,323,336,344',
                children: []
              },
              {
                id: 345,
                parentId: 336,
                regionType: 3,
                regionName: '伊金霍洛旗',
                parentIdAll: '1,323,336,345',
                children: []
              }
            ]
          },
          {
            id: 346,
            parentId: 323,
            regionType: 2,
            regionName: '呼伦贝尔市',
            parentIdAll: '1,323,346',
            children: [
              {
                id: 347,
                parentId: 346,
                regionType: 3,
                regionName: '额尔古纳市',
                parentIdAll: '1,323,346,347',
                children: []
              },
              {
                id: 348,
                parentId: 346,
                regionType: 3,
                regionName: '陈巴尔虎旗',
                parentIdAll: '1,323,346,348',
                children: []
              },
              {
                id: 349,
                parentId: 346,
                regionType: 3,
                regionName: '鄂温克族自治旗',
                parentIdAll: '1,323,346,349',
                children: []
              },
              {
                id: 350,
                parentId: 346,
                regionType: 3,
                regionName: '海拉尔区',
                parentIdAll: '1,323,346,350',
                children: []
              },
              {
                id: 351,
                parentId: 346,
                regionType: 3,
                regionName: '阿荣旗',
                parentIdAll: '1,323,346,351',
                children: []
              },
              {
                id: 352,
                parentId: 346,
                regionType: 3,
                regionName: '扎兰屯市',
                parentIdAll: '1,323,346,352',
                children: []
              },
              {
                id: 353,
                parentId: 346,
                regionType: 3,
                regionName: '牙克石市',
                parentIdAll: '1,323,346,353',
                children: []
              },
              {
                id: 354,
                parentId: 346,
                regionType: 3,
                regionName: '根河市',
                parentIdAll: '1,323,346,354',
                children: []
              },
              {
                id: 355,
                parentId: 346,
                regionType: 3,
                regionName: '莫力达瓦达斡尔族自治旗',
                parentIdAll: '1,323,346,355',
                children: []
              },
              {
                id: 356,
                parentId: 346,
                regionType: 3,
                regionName: '扎赉诺尔区',
                parentIdAll: '1,323,346,356',
                children: []
              },
              {
                id: 357,
                parentId: 346,
                regionType: 3,
                regionName: '新巴尔虎左旗',
                parentIdAll: '1,323,346,357',
                children: []
              },
              {
                id: 358,
                parentId: 346,
                regionType: 3,
                regionName: '满洲里市',
                parentIdAll: '1,323,346,358',
                children: []
              },
              {
                id: 359,
                parentId: 346,
                regionType: 3,
                regionName: '新巴尔虎右旗',
                parentIdAll: '1,323,346,359',
                children: []
              },
              {
                id: 360,
                parentId: 346,
                regionType: 3,
                regionName: '鄂伦春自治旗',
                parentIdAll: '1,323,346,360',
                children: []
              }
            ]
          },
          {
            id: 361,
            parentId: 323,
            regionType: 2,
            regionName: '包头市',
            parentIdAll: '1,323,361',
            children: [
              {
                id: 362,
                parentId: 361,
                regionType: 3,
                regionName: '土默特右旗',
                parentIdAll: '1,323,361,362',
                children: []
              },
              {
                id: 363,
                parentId: 361,
                regionType: 3,
                regionName: '达尔罕茂明安联合旗',
                parentIdAll: '1,323,361,363',
                children: []
              },
              {
                id: 364,
                parentId: 361,
                regionType: 3,
                regionName: '白云鄂博矿区',
                parentIdAll: '1,323,361,364',
                children: []
              },
              {
                id: 365,
                parentId: 361,
                regionType: 3,
                regionName: '石拐区',
                parentIdAll: '1,323,361,365',
                children: []
              },
              {
                id: 366,
                parentId: 361,
                regionType: 3,
                regionName: '九原区',
                parentIdAll: '1,323,361,366',
                children: []
              },
              {
                id: 367,
                parentId: 361,
                regionType: 3,
                regionName: '东河区',
                parentIdAll: '1,323,361,367',
                children: []
              },
              {
                id: 368,
                parentId: 361,
                regionType: 3,
                regionName: '青山区',
                parentIdAll: '1,323,361,368',
                children: []
              },
              {
                id: 369,
                parentId: 361,
                regionType: 3,
                regionName: '固阳县',
                parentIdAll: '1,323,361,369',
                children: []
              },
              {
                id: 370,
                parentId: 361,
                regionType: 3,
                regionName: '昆都仑区',
                parentIdAll: '1,323,361,370',
                children: []
              }
            ]
          },
          {
            id: 371,
            parentId: 323,
            regionType: 2,
            regionName: '通辽市',
            parentIdAll: '1,323,371',
            children: [
              {
                id: 372,
                parentId: 371,
                regionType: 3,
                regionName: '霍林郭勒市',
                parentIdAll: '1,323,371,372',
                children: []
              },
              {
                id: 373,
                parentId: 371,
                regionType: 3,
                regionName: '扎鲁特旗',
                parentIdAll: '1,323,371,373',
                children: []
              },
              {
                id: 374,
                parentId: 371,
                regionType: 3,
                regionName: '奈曼旗',
                parentIdAll: '1,323,371,374',
                children: []
              },
              {
                id: 375,
                parentId: 371,
                regionType: 3,
                regionName: '科尔沁左翼中旗',
                parentIdAll: '1,323,371,375',
                children: []
              },
              {
                id: 376,
                parentId: 371,
                regionType: 3,
                regionName: '科尔沁区',
                parentIdAll: '1,323,371,376',
                children: []
              },
              {
                id: 377,
                parentId: 371,
                regionType: 3,
                regionName: '开鲁县',
                parentIdAll: '1,323,371,377',
                children: []
              },
              {
                id: 378,
                parentId: 371,
                regionType: 3,
                regionName: '库伦旗',
                parentIdAll: '1,323,371,378',
                children: []
              },
              {
                id: 379,
                parentId: 371,
                regionType: 3,
                regionName: '科尔沁左翼后旗',
                parentIdAll: '1,323,371,379',
                children: []
              }
            ]
          },
          {
            id: 380,
            parentId: 323,
            regionType: 2,
            regionName: '呼和浩特市',
            parentIdAll: '1,323,380',
            children: [
              {
                id: 381,
                parentId: 380,
                regionType: 3,
                regionName: '和林格尔县',
                parentIdAll: '1,323,380,381',
                children: []
              },
              {
                id: 382,
                parentId: 380,
                regionType: 3,
                regionName: '清水河县',
                parentIdAll: '1,323,380,382',
                children: []
              },
              {
                id: 383,
                parentId: 380,
                regionType: 3,
                regionName: '玉泉区',
                parentIdAll: '1,323,380,383',
                children: []
              },
              {
                id: 384,
                parentId: 380,
                regionType: 3,
                regionName: '回民区',
                parentIdAll: '1,323,380,384',
                children: []
              },
              {
                id: 385,
                parentId: 380,
                regionType: 3,
                regionName: '托克托县',
                parentIdAll: '1,323,380,385',
                children: []
              },
              {
                id: 386,
                parentId: 380,
                regionType: 3,
                regionName: '武川县',
                parentIdAll: '1,323,380,386',
                children: []
              },
              {
                id: 387,
                parentId: 380,
                regionType: 3,
                regionName: '土默特左旗',
                parentIdAll: '1,323,380,387',
                children: []
              },
              {
                id: 388,
                parentId: 380,
                regionType: 3,
                regionName: '新城区',
                parentIdAll: '1,323,380,388',
                children: []
              },
              {
                id: 389,
                parentId: 380,
                regionType: 3,
                regionName: '赛罕区',
                parentIdAll: '1,323,380,389',
                children: []
              }
            ]
          },
          {
            id: 390,
            parentId: 323,
            regionType: 2,
            regionName: '赤峰市',
            parentIdAll: '1,323,390',
            children: [
              {
                id: 391,
                parentId: 390,
                regionType: 3,
                regionName: '阿鲁科尔沁旗',
                parentIdAll: '1,323,390,391',
                children: []
              },
              {
                id: 392,
                parentId: 390,
                regionType: 3,
                regionName: '巴林左旗',
                parentIdAll: '1,323,390,392',
                children: []
              },
              {
                id: 393,
                parentId: 390,
                regionType: 3,
                regionName: '巴林右旗',
                parentIdAll: '1,323,390,393',
                children: []
              },
              {
                id: 394,
                parentId: 390,
                regionType: 3,
                regionName: '林西县',
                parentIdAll: '1,323,390,394',
                children: []
              },
              {
                id: 395,
                parentId: 390,
                regionType: 3,
                regionName: '克什克腾旗',
                parentIdAll: '1,323,390,395',
                children: []
              },
              {
                id: 396,
                parentId: 390,
                regionType: 3,
                regionName: '翁牛特旗',
                parentIdAll: '1,323,390,396',
                children: []
              },
              {
                id: 397,
                parentId: 390,
                regionType: 3,
                regionName: '松山区',
                parentIdAll: '1,323,390,397',
                children: []
              },
              {
                id: 398,
                parentId: 390,
                regionType: 3,
                regionName: '宁城县',
                parentIdAll: '1,323,390,398',
                children: []
              },
              {
                id: 399,
                parentId: 390,
                regionType: 3,
                regionName: '红山区',
                parentIdAll: '1,323,390,399',
                children: []
              },
              {
                id: 400,
                parentId: 390,
                regionType: 3,
                regionName: '喀喇沁旗',
                parentIdAll: '1,323,390,400',
                children: []
              },
              {
                id: 401,
                parentId: 390,
                regionType: 3,
                regionName: '元宝山区',
                parentIdAll: '1,323,390,401',
                children: []
              },
              {
                id: 402,
                parentId: 390,
                regionType: 3,
                regionName: '敖汉旗',
                parentIdAll: '1,323,390,402',
                children: []
              }
            ]
          },
          {
            id: 403,
            parentId: 323,
            regionType: 2,
            regionName: '阿拉善盟',
            parentIdAll: '1,323,403',
            children: [
              {
                id: 404,
                parentId: 403,
                regionType: 3,
                regionName: '额济纳旗',
                parentIdAll: '1,323,403,404',
                children: []
              },
              {
                id: 405,
                parentId: 403,
                regionType: 3,
                regionName: '阿拉善右旗',
                parentIdAll: '1,323,403,405',
                children: []
              },
              {
                id: 406,
                parentId: 403,
                regionType: 3,
                regionName: '阿拉善左旗',
                parentIdAll: '1,323,403,406',
                children: []
              }
            ]
          },
          {
            id: 407,
            parentId: 323,
            regionType: 2,
            regionName: '兴安盟',
            parentIdAll: '1,323,407',
            children: [
              {
                id: 408,
                parentId: 407,
                regionType: 3,
                regionName: '阿尔山市',
                parentIdAll: '1,323,407,408',
                children: []
              },
              {
                id: 409,
                parentId: 407,
                regionType: 3,
                regionName: '扎赉特旗',
                parentIdAll: '1,323,407,409',
                children: []
              },
              {
                id: 410,
                parentId: 407,
                regionType: 3,
                regionName: '突泉县',
                parentIdAll: '1,323,407,410',
                children: []
              },
              {
                id: 411,
                parentId: 407,
                regionType: 3,
                regionName: '科尔沁右翼中旗',
                parentIdAll: '1,323,407,411',
                children: []
              },
              {
                id: 412,
                parentId: 407,
                regionType: 3,
                regionName: '乌兰浩特市',
                parentIdAll: '1,323,407,412',
                children: []
              },
              {
                id: 413,
                parentId: 407,
                regionType: 3,
                regionName: '科尔沁右翼前旗',
                parentIdAll: '1,323,407,413',
                children: []
              }
            ]
          },
          {
            id: 414,
            parentId: 323,
            regionType: 2,
            regionName: '锡林郭勒盟',
            parentIdAll: '1,323,414',
            children: [
              {
                id: 415,
                parentId: 414,
                regionType: 3,
                regionName: '东乌珠穆沁旗',
                parentIdAll: '1,323,414,415',
                children: []
              },
              {
                id: 416,
                parentId: 414,
                regionType: 3,
                regionName: '阿巴嘎旗',
                parentIdAll: '1,323,414,416',
                children: []
              },
              {
                id: 417,
                parentId: 414,
                regionType: 3,
                regionName: '苏尼特左旗',
                parentIdAll: '1,323,414,417',
                children: []
              },
              {
                id: 418,
                parentId: 414,
                regionType: 3,
                regionName: '西乌珠穆沁旗',
                parentIdAll: '1,323,414,418',
                children: []
              },
              {
                id: 419,
                parentId: 414,
                regionType: 3,
                regionName: '锡林浩特市',
                parentIdAll: '1,323,414,419',
                children: []
              },
              {
                id: 420,
                parentId: 414,
                regionType: 3,
                regionName: '苏尼特右旗',
                parentIdAll: '1,323,414,420',
                children: []
              },
              {
                id: 421,
                parentId: 414,
                regionType: 3,
                regionName: '二连浩特市',
                parentIdAll: '1,323,414,421',
                children: []
              },
              {
                id: 422,
                parentId: 414,
                regionType: 3,
                regionName: '镶黄旗',
                parentIdAll: '1,323,414,422',
                children: []
              },
              {
                id: 423,
                parentId: 414,
                regionType: 3,
                regionName: '正蓝旗',
                parentIdAll: '1,323,414,423',
                children: []
              },
              {
                id: 424,
                parentId: 414,
                regionType: 3,
                regionName: '正镶白旗',
                parentIdAll: '1,323,414,424',
                children: []
              },
              {
                id: 425,
                parentId: 414,
                regionType: 3,
                regionName: '多伦县',
                parentIdAll: '1,323,414,425',
                children: []
              },
              {
                id: 426,
                parentId: 414,
                regionType: 3,
                regionName: '太仆寺旗',
                parentIdAll: '1,323,414,426',
                children: []
              }
            ]
          },
          {
            id: 427,
            parentId: 323,
            regionType: 2,
            regionName: '乌兰察布市',
            parentIdAll: '1,323,427',
            children: [
              {
                id: 428,
                parentId: 427,
                regionType: 3,
                regionName: '四子王旗',
                parentIdAll: '1,323,427,428',
                children: []
              },
              {
                id: 429,
                parentId: 427,
                regionType: 3,
                regionName: '察哈尔右翼后旗',
                parentIdAll: '1,323,427,429',
                children: []
              },
              {
                id: 430,
                parentId: 427,
                regionType: 3,
                regionName: '商都县',
                parentIdAll: '1,323,427,430',
                children: []
              },
              {
                id: 431,
                parentId: 427,
                regionType: 3,
                regionName: '察哈尔右翼中旗',
                parentIdAll: '1,323,427,431',
                children: []
              },
              {
                id: 432,
                parentId: 427,
                regionType: 3,
                regionName: '化德县',
                parentIdAll: '1,323,427,432',
                children: []
              },
              {
                id: 433,
                parentId: 427,
                regionType: 3,
                regionName: '兴和县',
                parentIdAll: '1,323,427,433',
                children: []
              },
              {
                id: 434,
                parentId: 427,
                regionType: 3,
                regionName: '卓资县',
                parentIdAll: '1,323,427,434',
                children: []
              },
              {
                id: 435,
                parentId: 427,
                regionType: 3,
                regionName: '凉城县',
                parentIdAll: '1,323,427,435',
                children: []
              },
              {
                id: 436,
                parentId: 427,
                regionType: 3,
                regionName: '丰镇市',
                parentIdAll: '1,323,427,436',
                children: []
              },
              {
                id: 437,
                parentId: 427,
                regionType: 3,
                regionName: '集宁区',
                parentIdAll: '1,323,427,437',
                children: []
              },
              {
                id: 438,
                parentId: 427,
                regionType: 3,
                regionName: '察哈尔右翼前旗',
                parentIdAll: '1,323,427,438',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 439,
        parentId: 1,
        regionType: 1,
        regionName: '黑龙江省',
        parentIdAll: '1,439',
        children: [
          {
            id: 440,
            parentId: 439,
            regionType: 2,
            regionName: '大兴安岭地区',
            parentIdAll: '1,439,440',
            children: [
              {
                id: 441,
                parentId: 440,
                regionType: 3,
                regionName: '漠河县',
                parentIdAll: '1,439,440,441',
                children: []
              },
              {
                id: 442,
                parentId: 440,
                regionType: 3,
                regionName: '塔河县',
                parentIdAll: '1,439,440,442',
                children: []
              },
              {
                id: 443,
                parentId: 440,
                regionType: 3,
                regionName: '呼玛县',
                parentIdAll: '1,439,440,443',
                children: []
              },
              {
                id: 444,
                parentId: 440,
                regionType: 3,
                regionName: '加格达奇区',
                parentIdAll: '1,439,440,444',
                children: []
              }
            ]
          },
          {
            id: 445,
            parentId: 439,
            regionType: 2,
            regionName: '七台河市',
            parentIdAll: '1,439,445',
            children: [
              {
                id: 446,
                parentId: 445,
                regionType: 3,
                regionName: '茄子河区',
                parentIdAll: '1,439,445,446',
                children: []
              },
              {
                id: 447,
                parentId: 445,
                regionType: 3,
                regionName: '勃利县',
                parentIdAll: '1,439,445,447',
                children: []
              },
              {
                id: 448,
                parentId: 445,
                regionType: 3,
                regionName: '桃山区',
                parentIdAll: '1,439,445,448',
                children: []
              },
              {
                id: 449,
                parentId: 445,
                regionType: 3,
                regionName: '新兴区',
                parentIdAll: '1,439,445,449',
                children: []
              }
            ]
          },
          {
            id: 450,
            parentId: 439,
            regionType: 2,
            regionName: '大庆市',
            parentIdAll: '1,439,450',
            children: [
              {
                id: 451,
                parentId: 450,
                regionType: 3,
                regionName: '让胡路区',
                parentIdAll: '1,439,450,451',
                children: []
              },
              {
                id: 452,
                parentId: 450,
                regionType: 3,
                regionName: '红岗区',
                parentIdAll: '1,439,450,452',
                children: []
              },
              {
                id: 453,
                parentId: 450,
                regionType: 3,
                regionName: '肇源县',
                parentIdAll: '1,439,450,453',
                children: []
              },
              {
                id: 454,
                parentId: 450,
                regionType: 3,
                regionName: '大同区',
                parentIdAll: '1,439,450,454',
                children: []
              },
              {
                id: 455,
                parentId: 450,
                regionType: 3,
                regionName: '杜尔伯特蒙古族自治县',
                parentIdAll: '1,439,450,455',
                children: []
              },
              {
                id: 456,
                parentId: 450,
                regionType: 3,
                regionName: '林甸县',
                parentIdAll: '1,439,450,456',
                children: []
              },
              {
                id: 457,
                parentId: 450,
                regionType: 3,
                regionName: '肇州县',
                parentIdAll: '1,439,450,457',
                children: []
              },
              {
                id: 458,
                parentId: 450,
                regionType: 3,
                regionName: '萨尔图区',
                parentIdAll: '1,439,450,458',
                children: []
              },
              {
                id: 459,
                parentId: 450,
                regionType: 3,
                regionName: '龙凤区',
                parentIdAll: '1,439,450,459',
                children: []
              }
            ]
          },
          {
            id: 460,
            parentId: 439,
            regionType: 2,
            regionName: '鹤岗市',
            parentIdAll: '1,439,460',
            children: [
              {
                id: 461,
                parentId: 460,
                regionType: 3,
                regionName: '萝北县',
                parentIdAll: '1,439,460,461',
                children: []
              },
              {
                id: 462,
                parentId: 460,
                regionType: 3,
                regionName: '绥滨县',
                parentIdAll: '1,439,460,462',
                children: []
              },
              {
                id: 463,
                parentId: 460,
                regionType: 3,
                regionName: '工农区',
                parentIdAll: '1,439,460,463',
                children: []
              },
              {
                id: 464,
                parentId: 460,
                regionType: 3,
                regionName: '东山区',
                parentIdAll: '1,439,460,464',
                children: []
              },
              {
                id: 465,
                parentId: 460,
                regionType: 3,
                regionName: '向阳区',
                parentIdAll: '1,439,460,465',
                children: []
              },
              {
                id: 466,
                parentId: 460,
                regionType: 3,
                regionName: '南山区',
                parentIdAll: '1,439,460,466',
                children: []
              },
              {
                id: 467,
                parentId: 460,
                regionType: 3,
                regionName: '兴山区',
                parentIdAll: '1,439,460,467',
                children: []
              },
              {
                id: 468,
                parentId: 460,
                regionType: 3,
                regionName: '兴安区',
                parentIdAll: '1,439,460,468',
                children: []
              }
            ]
          },
          {
            id: 469,
            parentId: 439,
            regionType: 2,
            regionName: '伊春市',
            parentIdAll: '1,439,469',
            children: [
              {
                id: 470,
                parentId: 469,
                regionType: 3,
                regionName: '乌伊岭区',
                parentIdAll: '1,439,469,470',
                children: []
              },
              {
                id: 471,
                parentId: 469,
                regionType: 3,
                regionName: '嘉荫县',
                parentIdAll: '1,439,469,471',
                children: []
              },
              {
                id: 472,
                parentId: 469,
                regionType: 3,
                regionName: '友好区',
                parentIdAll: '1,439,469,472',
                children: []
              },
              {
                id: 473,
                parentId: 469,
                regionType: 3,
                regionName: '红星区',
                parentIdAll: '1,439,469,473',
                children: []
              },
              {
                id: 474,
                parentId: 469,
                regionType: 3,
                regionName: '五营区',
                parentIdAll: '1,439,469,474',
                children: []
              },
              {
                id: 475,
                parentId: 469,
                regionType: 3,
                regionName: '汤旺河区',
                parentIdAll: '1,439,469,475',
                children: []
              },
              {
                id: 476,
                parentId: 469,
                regionType: 3,
                regionName: '新青区',
                parentIdAll: '1,439,469,476',
                children: []
              },
              {
                id: 477,
                parentId: 469,
                regionType: 3,
                regionName: '上甘岭区',
                parentIdAll: '1,439,469,477',
                children: []
              },
              {
                id: 478,
                parentId: 469,
                regionType: 3,
                regionName: '美溪区',
                parentIdAll: '1,439,469,478',
                children: []
              },
              {
                id: 479,
                parentId: 469,
                regionType: 3,
                regionName: '翠峦区',
                parentIdAll: '1,439,469,479',
                children: []
              },
              {
                id: 480,
                parentId: 469,
                regionType: 3,
                regionName: '乌马河区',
                parentIdAll: '1,439,469,480',
                children: []
              },
              {
                id: 481,
                parentId: 469,
                regionType: 3,
                regionName: '铁力市',
                parentIdAll: '1,439,469,481',
                children: []
              },
              {
                id: 482,
                parentId: 469,
                regionType: 3,
                regionName: '伊春区',
                parentIdAll: '1,439,469,482',
                children: []
              },
              {
                id: 483,
                parentId: 469,
                regionType: 3,
                regionName: '金山屯区',
                parentIdAll: '1,439,469,483',
                children: []
              },
              {
                id: 484,
                parentId: 469,
                regionType: 3,
                regionName: '西林区',
                parentIdAll: '1,439,469,484',
                children: []
              },
              {
                id: 485,
                parentId: 469,
                regionType: 3,
                regionName: '带岭区',
                parentIdAll: '1,439,469,485',
                children: []
              },
              {
                id: 486,
                parentId: 469,
                regionType: 3,
                regionName: '南岔区',
                parentIdAll: '1,439,469,486',
                children: []
              }
            ]
          },
          {
            id: 487,
            parentId: 439,
            regionType: 2,
            regionName: '绥化市',
            parentIdAll: '1,439,487',
            children: [
              {
                id: 488,
                parentId: 487,
                regionType: 3,
                regionName: '绥棱县',
                parentIdAll: '1,439,487,488',
                children: []
              },
              {
                id: 489,
                parentId: 487,
                regionType: 3,
                regionName: '海伦市',
                parentIdAll: '1,439,487,489',
                children: []
              },
              {
                id: 490,
                parentId: 487,
                regionType: 3,
                regionName: '庆安县',
                parentIdAll: '1,439,487,490',
                children: []
              },
              {
                id: 491,
                parentId: 487,
                regionType: 3,
                regionName: '北林区',
                parentIdAll: '1,439,487,491',
                children: []
              },
              {
                id: 492,
                parentId: 487,
                regionType: 3,
                regionName: '青冈县',
                parentIdAll: '1,439,487,492',
                children: []
              },
              {
                id: 493,
                parentId: 487,
                regionType: 3,
                regionName: '望奎县',
                parentIdAll: '1,439,487,493',
                children: []
              },
              {
                id: 494,
                parentId: 487,
                regionType: 3,
                regionName: '安达市',
                parentIdAll: '1,439,487,494',
                children: []
              },
              {
                id: 495,
                parentId: 487,
                regionType: 3,
                regionName: '明水县',
                parentIdAll: '1,439,487,495',
                children: []
              },
              {
                id: 496,
                parentId: 487,
                regionType: 3,
                regionName: '肇东市',
                parentIdAll: '1,439,487,496',
                children: []
              },
              {
                id: 497,
                parentId: 487,
                regionType: 3,
                regionName: '兰西县',
                parentIdAll: '1,439,487,497',
                children: []
              }
            ]
          },
          {
            id: 498,
            parentId: 439,
            regionType: 2,
            regionName: '佳木斯市',
            parentIdAll: '1,439,498',
            children: [
              {
                id: 499,
                parentId: 498,
                regionType: 3,
                regionName: '抚远市',
                parentIdAll: '1,439,498,499',
                children: []
              },
              {
                id: 500,
                parentId: 498,
                regionType: 3,
                regionName: '同江市',
                parentIdAll: '1,439,498,500',
                children: []
              },
              {
                id: 501,
                parentId: 498,
                regionType: 3,
                regionName: '桦川县',
                parentIdAll: '1,439,498,501',
                children: []
              },
              {
                id: 502,
                parentId: 498,
                regionType: 3,
                regionName: '桦南县',
                parentIdAll: '1,439,498,502',
                children: []
              },
              {
                id: 503,
                parentId: 498,
                regionType: 3,
                regionName: '汤原县',
                parentIdAll: '1,439,498,503',
                children: []
              },
              {
                id: 504,
                parentId: 498,
                regionType: 3,
                regionName: '富锦市',
                parentIdAll: '1,439,498,504',
                children: []
              },
              {
                id: 505,
                parentId: 498,
                regionType: 3,
                regionName: '前进区',
                parentIdAll: '1,439,498,505',
                children: []
              },
              {
                id: 506,
                parentId: 498,
                regionType: 3,
                regionName: '向阳区',
                parentIdAll: '1,439,498,506',
                children: []
              },
              {
                id: 507,
                parentId: 498,
                regionType: 3,
                regionName: '东风区',
                parentIdAll: '1,439,498,507',
                children: []
              },
              {
                id: 508,
                parentId: 498,
                regionType: 3,
                regionName: '郊区',
                parentIdAll: '1,439,498,508',
                children: []
              }
            ]
          },
          {
            id: 509,
            parentId: 439,
            regionType: 2,
            regionName: '黑河市',
            parentIdAll: '1,439,509',
            children: [
              {
                id: 510,
                parentId: 509,
                regionType: 3,
                regionName: '逊克县',
                parentIdAll: '1,439,509,510',
                children: []
              },
              {
                id: 511,
                parentId: 509,
                regionType: 3,
                regionName: '五大连池市',
                parentIdAll: '1,439,509,511',
                children: []
              },
              {
                id: 512,
                parentId: 509,
                regionType: 3,
                regionName: '孙吴县',
                parentIdAll: '1,439,509,512',
                children: []
              },
              {
                id: 513,
                parentId: 509,
                regionType: 3,
                regionName: '爱辉区',
                parentIdAll: '1,439,509,513',
                children: []
              },
              {
                id: 514,
                parentId: 509,
                regionType: 3,
                regionName: '嫩江县',
                parentIdAll: '1,439,509,514',
                children: []
              },
              {
                id: 515,
                parentId: 509,
                regionType: 3,
                regionName: '北安市',
                parentIdAll: '1,439,509,515',
                children: []
              }
            ]
          },
          {
            id: 516,
            parentId: 439,
            regionType: 2,
            regionName: '鸡西市',
            parentIdAll: '1,439,516',
            children: [
              {
                id: 517,
                parentId: 516,
                regionType: 3,
                regionName: '虎林市',
                parentIdAll: '1,439,516,517',
                children: []
              },
              {
                id: 518,
                parentId: 516,
                regionType: 3,
                regionName: '城子河区',
                parentIdAll: '1,439,516,518',
                children: []
              },
              {
                id: 519,
                parentId: 516,
                regionType: 3,
                regionName: '滴道区',
                parentIdAll: '1,439,516,519',
                children: []
              },
              {
                id: 520,
                parentId: 516,
                regionType: 3,
                regionName: '麻山区',
                parentIdAll: '1,439,516,520',
                children: []
              },
              {
                id: 521,
                parentId: 516,
                regionType: 3,
                regionName: '鸡冠区',
                parentIdAll: '1,439,516,521',
                children: []
              },
              {
                id: 522,
                parentId: 516,
                regionType: 3,
                regionName: '恒山区',
                parentIdAll: '1,439,516,522',
                children: []
              },
              {
                id: 523,
                parentId: 516,
                regionType: 3,
                regionName: '梨树区',
                parentIdAll: '1,439,516,523',
                children: []
              },
              {
                id: 524,
                parentId: 516,
                regionType: 3,
                regionName: '密山市',
                parentIdAll: '1,439,516,524',
                children: []
              },
              {
                id: 525,
                parentId: 516,
                regionType: 3,
                regionName: '鸡东县',
                parentIdAll: '1,439,516,525',
                children: []
              }
            ]
          },
          {
            id: 526,
            parentId: 439,
            regionType: 2,
            regionName: '哈尔滨市',
            parentIdAll: '1,439,526',
            children: [
              {
                id: 527,
                parentId: 526,
                regionType: 3,
                regionName: '巴彦县',
                parentIdAll: '1,439,526,527',
                children: []
              },
              {
                id: 528,
                parentId: 526,
                regionType: 3,
                regionName: '依兰县',
                parentIdAll: '1,439,526,528',
                children: []
              },
              {
                id: 529,
                parentId: 526,
                regionType: 3,
                regionName: '通河县',
                parentIdAll: '1,439,526,529',
                children: []
              },
              {
                id: 530,
                parentId: 526,
                regionType: 3,
                regionName: '呼兰区',
                parentIdAll: '1,439,526,530',
                children: []
              },
              {
                id: 531,
                parentId: 526,
                regionType: 3,
                regionName: '木兰县',
                parentIdAll: '1,439,526,531',
                children: []
              },
              {
                id: 532,
                parentId: 526,
                regionType: 3,
                regionName: '方正县',
                parentIdAll: '1,439,526,532',
                children: []
              },
              {
                id: 533,
                parentId: 526,
                regionType: 3,
                regionName: '延寿县',
                parentIdAll: '1,439,526,533',
                children: []
              },
              {
                id: 534,
                parentId: 526,
                regionType: 3,
                regionName: '宾县',
                parentIdAll: '1,439,526,534',
                children: []
              },
              {
                id: 535,
                parentId: 526,
                regionType: 3,
                regionName: '双城区',
                parentIdAll: '1,439,526,535',
                children: []
              },
              {
                id: 536,
                parentId: 526,
                regionType: 3,
                regionName: '平房区',
                parentIdAll: '1,439,526,536',
                children: []
              },
              {
                id: 537,
                parentId: 526,
                regionType: 3,
                regionName: '尚志市',
                parentIdAll: '1,439,526,537',
                children: []
              },
              {
                id: 538,
                parentId: 526,
                regionType: 3,
                regionName: '香坊区',
                parentIdAll: '1,439,526,538',
                children: []
              },
              {
                id: 539,
                parentId: 526,
                regionType: 3,
                regionName: '道里区',
                parentIdAll: '1,439,526,539',
                children: []
              },
              {
                id: 540,
                parentId: 526,
                regionType: 3,
                regionName: '松北区',
                parentIdAll: '1,439,526,540',
                children: []
              },
              {
                id: 541,
                parentId: 526,
                regionType: 3,
                regionName: '五常市',
                parentIdAll: '1,439,526,541',
                children: []
              },
              {
                id: 542,
                parentId: 526,
                regionType: 3,
                regionName: '阿城区',
                parentIdAll: '1,439,526,542',
                children: []
              },
              {
                id: 543,
                parentId: 526,
                regionType: 3,
                regionName: '南岗区',
                parentIdAll: '1,439,526,543',
                children: []
              },
              {
                id: 544,
                parentId: 526,
                regionType: 3,
                regionName: '道外区',
                parentIdAll: '1,439,526,544',
                children: []
              }
            ]
          },
          {
            id: 545,
            parentId: 439,
            regionType: 2,
            regionName: '齐齐哈尔市',
            parentIdAll: '1,439,545',
            children: [
              {
                id: 546,
                parentId: 545,
                regionType: 3,
                regionName: '讷河市',
                parentIdAll: '1,439,545,546',
                children: []
              },
              {
                id: 547,
                parentId: 545,
                regionType: 3,
                regionName: '克山县',
                parentIdAll: '1,439,545,547',
                children: []
              },
              {
                id: 548,
                parentId: 545,
                regionType: 3,
                regionName: '甘南县',
                parentIdAll: '1,439,545,548',
                children: []
              },
              {
                id: 549,
                parentId: 545,
                regionType: 3,
                regionName: '克东县',
                parentIdAll: '1,439,545,549',
                children: []
              },
              {
                id: 550,
                parentId: 545,
                regionType: 3,
                regionName: '富裕县',
                parentIdAll: '1,439,545,550',
                children: []
              },
              {
                id: 551,
                parentId: 545,
                regionType: 3,
                regionName: '依安县',
                parentIdAll: '1,439,545,551',
                children: []
              },
              {
                id: 552,
                parentId: 545,
                regionType: 3,
                regionName: '梅里斯达斡尔族区',
                parentIdAll: '1,439,545,552',
                children: []
              },
              {
                id: 553,
                parentId: 545,
                regionType: 3,
                regionName: '龙江县',
                parentIdAll: '1,439,545,553',
                children: []
              },
              {
                id: 554,
                parentId: 545,
                regionType: 3,
                regionName: '碾子山区',
                parentIdAll: '1,439,545,554',
                children: []
              },
              {
                id: 555,
                parentId: 545,
                regionType: 3,
                regionName: '铁锋区',
                parentIdAll: '1,439,545,555',
                children: []
              },
              {
                id: 556,
                parentId: 545,
                regionType: 3,
                regionName: '龙沙区',
                parentIdAll: '1,439,545,556',
                children: []
              },
              {
                id: 557,
                parentId: 545,
                regionType: 3,
                regionName: '富拉尔基区',
                parentIdAll: '1,439,545,557',
                children: []
              },
              {
                id: 558,
                parentId: 545,
                regionType: 3,
                regionName: '建华区',
                parentIdAll: '1,439,545,558',
                children: []
              },
              {
                id: 559,
                parentId: 545,
                regionType: 3,
                regionName: '昂昂溪区',
                parentIdAll: '1,439,545,559',
                children: []
              },
              {
                id: 560,
                parentId: 545,
                regionType: 3,
                regionName: '泰来县',
                parentIdAll: '1,439,545,560',
                children: []
              },
              {
                id: 561,
                parentId: 545,
                regionType: 3,
                regionName: '拜泉县',
                parentIdAll: '1,439,545,561',
                children: []
              }
            ]
          },
          {
            id: 562,
            parentId: 439,
            regionType: 2,
            regionName: '双鸭山市',
            parentIdAll: '1,439,562',
            children: [
              {
                id: 563,
                parentId: 562,
                regionType: 3,
                regionName: '饶河县',
                parentIdAll: '1,439,562,563',
                children: []
              },
              {
                id: 564,
                parentId: 562,
                regionType: 3,
                regionName: '四方台区',
                parentIdAll: '1,439,562,564',
                children: []
              },
              {
                id: 565,
                parentId: 562,
                regionType: 3,
                regionName: '友谊县',
                parentIdAll: '1,439,562,565',
                children: []
              },
              {
                id: 566,
                parentId: 562,
                regionType: 3,
                regionName: '尖山区',
                parentIdAll: '1,439,562,566',
                children: []
              },
              {
                id: 567,
                parentId: 562,
                regionType: 3,
                regionName: '集贤县',
                parentIdAll: '1,439,562,567',
                children: []
              },
              {
                id: 568,
                parentId: 562,
                regionType: 3,
                regionName: '岭东区',
                parentIdAll: '1,439,562,568',
                children: []
              },
              {
                id: 569,
                parentId: 562,
                regionType: 3,
                regionName: '宝山区',
                parentIdAll: '1,439,562,569',
                children: []
              },
              {
                id: 570,
                parentId: 562,
                regionType: 3,
                regionName: '宝清县',
                parentIdAll: '1,439,562,570',
                children: []
              }
            ]
          },
          {
            id: 571,
            parentId: 439,
            regionType: 2,
            regionName: '牡丹江市',
            parentIdAll: '1,439,571',
            children: [
              {
                id: 572,
                parentId: 571,
                regionType: 3,
                regionName: '东安区',
                parentIdAll: '1,439,571,572',
                children: []
              },
              {
                id: 573,
                parentId: 571,
                regionType: 3,
                regionName: '爱民区',
                parentIdAll: '1,439,571,573',
                children: []
              },
              {
                id: 574,
                parentId: 571,
                regionType: 3,
                regionName: '绥芬河市',
                parentIdAll: '1,439,571,574',
                children: []
              },
              {
                id: 575,
                parentId: 571,
                regionType: 3,
                regionName: '宁安市',
                parentIdAll: '1,439,571,575',
                children: []
              },
              {
                id: 576,
                parentId: 571,
                regionType: 3,
                regionName: '东宁市',
                parentIdAll: '1,439,571,576',
                children: []
              },
              {
                id: 577,
                parentId: 571,
                regionType: 3,
                regionName: '穆棱市',
                parentIdAll: '1,439,571,577',
                children: []
              },
              {
                id: 578,
                parentId: 571,
                regionType: 3,
                regionName: '阳明区',
                parentIdAll: '1,439,571,578',
                children: []
              },
              {
                id: 579,
                parentId: 571,
                regionType: 3,
                regionName: '林口县',
                parentIdAll: '1,439,571,579',
                children: []
              },
              {
                id: 580,
                parentId: 571,
                regionType: 3,
                regionName: '西安区',
                parentIdAll: '1,439,571,580',
                children: []
              },
              {
                id: 581,
                parentId: 571,
                regionType: 3,
                regionName: '海林市',
                parentIdAll: '1,439,571,581',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 582,
        parentId: 1,
        regionType: 1,
        regionName: '新疆维吾尔自治区',
        parentIdAll: '1,582',
        children: [
          {
            id: 583,
            parentId: 582,
            regionType: 2,
            regionName: '双河市',
            parentIdAll: '1,582,583',
            children: [
              {
                id: 3263,
                parentId: 583,
                regionType: 3,
                regionName: '双河市',
                parentIdAll: '1,582,583,3263',
                children: []
              }
            ]
          },
          {
            id: 584,
            parentId: 582,
            regionType: 2,
            regionName: '北屯市',
            parentIdAll: '1,582,584',
            children: [
              {
                id: 3264,
                parentId: 584,
                regionType: 3,
                regionName: '北屯市',
                parentIdAll: '1,582,584,3264',
                children: []
              }
            ]
          },
          {
            id: 585,
            parentId: 582,
            regionType: 2,
            regionName: '铁门关市',
            parentIdAll: '1,582,585',
            children: [
              {
                id: 3265,
                parentId: 585,
                regionType: 3,
                regionName: '铁门关市',
                parentIdAll: '1,582,585,3265',
                children: []
              }
            ]
          },
          {
            id: 586,
            parentId: 582,
            regionType: 2,
            regionName: '博尔塔拉蒙古自治州',
            parentIdAll: '1,582,586',
            children: [
              {
                id: 587,
                parentId: 586,
                regionType: 3,
                regionName: '温泉县',
                parentIdAll: '1,582,586,587',
                children: []
              },
              {
                id: 588,
                parentId: 586,
                regionType: 3,
                regionName: '阿拉山口市',
                parentIdAll: '1,582,586,588',
                children: []
              },
              {
                id: 589,
                parentId: 586,
                regionType: 3,
                regionName: '博乐市',
                parentIdAll: '1,582,586,589',
                children: []
              },
              {
                id: 590,
                parentId: 586,
                regionType: 3,
                regionName: '精河县',
                parentIdAll: '1,582,586,590',
                children: []
              }
            ]
          },
          {
            id: 591,
            parentId: 582,
            regionType: 2,
            regionName: '和田地区',
            parentIdAll: '1,582,591',
            children: [
              {
                id: 592,
                parentId: 591,
                regionType: 3,
                regionName: '民丰县',
                parentIdAll: '1,582,591,592',
                children: []
              },
              {
                id: 593,
                parentId: 591,
                regionType: 3,
                regionName: '于田县',
                parentIdAll: '1,582,591,593',
                children: []
              },
              {
                id: 594,
                parentId: 591,
                regionType: 3,
                regionName: '和田市',
                parentIdAll: '1,582,591,594',
                children: []
              },
              {
                id: 595,
                parentId: 591,
                regionType: 3,
                regionName: '洛浦县',
                parentIdAll: '1,582,591,595',
                children: []
              },
              {
                id: 596,
                parentId: 591,
                regionType: 3,
                regionName: '和田县',
                parentIdAll: '1,582,591,596',
                children: []
              },
              {
                id: 597,
                parentId: 591,
                regionType: 3,
                regionName: '皮山县',
                parentIdAll: '1,582,591,597',
                children: []
              },
              {
                id: 598,
                parentId: 591,
                regionType: 3,
                regionName: '墨玉县',
                parentIdAll: '1,582,591,598',
                children: []
              },
              {
                id: 599,
                parentId: 591,
                regionType: 3,
                regionName: '策勒县',
                parentIdAll: '1,582,591,599',
                children: []
              }
            ]
          },
          {
            id: 600,
            parentId: 582,
            regionType: 2,
            regionName: '塔城地区',
            parentIdAll: '1,582,600',
            children: [
              {
                id: 601,
                parentId: 600,
                regionType: 3,
                regionName: '塔城市',
                parentIdAll: '1,582,600,601',
                children: []
              },
              {
                id: 602,
                parentId: 600,
                regionType: 3,
                regionName: '裕民县',
                parentIdAll: '1,582,600,602',
                children: []
              },
              {
                id: 603,
                parentId: 600,
                regionType: 3,
                regionName: '和布克赛尔蒙古自治县',
                parentIdAll: '1,582,600,603',
                children: []
              },
              {
                id: 604,
                parentId: 600,
                regionType: 3,
                regionName: '托里县',
                parentIdAll: '1,582,600,604',
                children: []
              },
              {
                id: 605,
                parentId: 600,
                regionType: 3,
                regionName: '额敏县',
                parentIdAll: '1,582,600,605',
                children: []
              },
              {
                id: 606,
                parentId: 600,
                regionType: 3,
                regionName: '乌苏市',
                parentIdAll: '1,582,600,606',
                children: []
              },
              {
                id: 607,
                parentId: 600,
                regionType: 3,
                regionName: '沙湾县',
                parentIdAll: '1,582,600,607',
                children: []
              }
            ]
          },
          {
            id: 608,
            parentId: 582,
            regionType: 2,
            regionName: '昆玉市',
            parentIdAll: '1,582,608',
            children: [
              {
                id: 3266,
                parentId: 608,
                regionType: 3,
                regionName: '昆玉市',
                parentIdAll: '1,582,608,3266',
                children: []
              }
            ]
          },
          {
            id: 609,
            parentId: 582,
            regionType: 2,
            regionName: '可克达拉市',
            parentIdAll: '1,582,609',
            children: [
              {
                id: 3267,
                parentId: 609,
                regionType: 3,
                regionName: '可克达拉市',
                parentIdAll: '1,582,609,3267',
                children: []
              }
            ]
          },
          {
            id: 610,
            parentId: 582,
            regionType: 2,
            regionName: '石河子市',
            parentIdAll: '1,582,610',
            children: [
              {
                id: 3268,
                parentId: 610,
                regionType: 3,
                regionName: '石河子市',
                parentIdAll: '1,582,610,3268',
                children: []
              }
            ]
          },
          {
            id: 611,
            parentId: 582,
            regionType: 2,
            regionName: '阿勒泰地区',
            parentIdAll: '1,582,611',
            children: [
              {
                id: 612,
                parentId: 611,
                regionType: 3,
                regionName: '富蕴县',
                parentIdAll: '1,582,611,612',
                children: []
              },
              {
                id: 613,
                parentId: 611,
                regionType: 3,
                regionName: '吉木乃县',
                parentIdAll: '1,582,611,613',
                children: []
              },
              {
                id: 614,
                parentId: 611,
                regionType: 3,
                regionName: '哈巴河县',
                parentIdAll: '1,582,611,614',
                children: []
              },
              {
                id: 615,
                parentId: 611,
                regionType: 3,
                regionName: '布尔津县',
                parentIdAll: '1,582,611,615',
                children: []
              },
              {
                id: 616,
                parentId: 611,
                regionType: 3,
                regionName: '青河县',
                parentIdAll: '1,582,611,616',
                children: []
              },
              {
                id: 617,
                parentId: 611,
                regionType: 3,
                regionName: '阿勒泰市',
                parentIdAll: '1,582,611,617',
                children: []
              },
              {
                id: 618,
                parentId: 611,
                regionType: 3,
                regionName: '福海县',
                parentIdAll: '1,582,611,618',
                children: []
              }
            ]
          },
          {
            id: 619,
            parentId: 582,
            regionType: 2,
            regionName: '克拉玛依市',
            parentIdAll: '1,582,619',
            children: [
              {
                id: 620,
                parentId: 619,
                regionType: 3,
                regionName: '乌尔禾区',
                parentIdAll: '1,582,619,620',
                children: []
              },
              {
                id: 621,
                parentId: 619,
                regionType: 3,
                regionName: '克拉玛依区',
                parentIdAll: '1,582,619,621',
                children: []
              },
              {
                id: 622,
                parentId: 619,
                regionType: 3,
                regionName: '白碱滩区',
                parentIdAll: '1,582,619,622',
                children: []
              },
              {
                id: 623,
                parentId: 619,
                regionType: 3,
                regionName: '独山子区',
                parentIdAll: '1,582,619,623',
                children: []
              }
            ]
          },
          {
            id: 624,
            parentId: 582,
            regionType: 2,
            regionName: '五家渠市',
            parentIdAll: '1,582,624',
            children: [
              {
                id: 3269,
                parentId: 624,
                regionType: 3,
                regionName: '五家渠市',
                parentIdAll: '1,582,624,3269',
                children: []
              }
            ]
          },
          {
            id: 625,
            parentId: 582,
            regionType: 2,
            regionName: '昌吉回族自治州',
            parentIdAll: '1,582,625',
            children: [
              {
                id: 626,
                parentId: 625,
                regionType: 3,
                regionName: '木垒哈萨克自治县',
                parentIdAll: '1,582,625,626',
                children: []
              },
              {
                id: 627,
                parentId: 625,
                regionType: 3,
                regionName: '奇台县',
                parentIdAll: '1,582,625,627',
                children: []
              },
              {
                id: 628,
                parentId: 625,
                regionType: 3,
                regionName: '玛纳斯县',
                parentIdAll: '1,582,625,628',
                children: []
              },
              {
                id: 629,
                parentId: 625,
                regionType: 3,
                regionName: '呼图壁县',
                parentIdAll: '1,582,625,629',
                children: []
              },
              {
                id: 630,
                parentId: 625,
                regionType: 3,
                regionName: '阜康市',
                parentIdAll: '1,582,625,630',
                children: []
              },
              {
                id: 631,
                parentId: 625,
                regionType: 3,
                regionName: '昌吉市',
                parentIdAll: '1,582,625,631',
                children: []
              },
              {
                id: 632,
                parentId: 625,
                regionType: 3,
                regionName: '吉木萨尔县',
                parentIdAll: '1,582,625,632',
                children: []
              }
            ]
          },
          {
            id: 633,
            parentId: 582,
            regionType: 2,
            regionName: '巴音郭楞蒙古自治州',
            parentIdAll: '1,582,633',
            children: [
              {
                id: 634,
                parentId: 633,
                regionType: 3,
                regionName: '和硕县',
                parentIdAll: '1,582,633,634',
                children: []
              },
              {
                id: 635,
                parentId: 633,
                regionType: 3,
                regionName: '和静县',
                parentIdAll: '1,582,633,635',
                children: []
              },
              {
                id: 636,
                parentId: 633,
                regionType: 3,
                regionName: '尉犁县',
                parentIdAll: '1,582,633,636',
                children: []
              },
              {
                id: 637,
                parentId: 633,
                regionType: 3,
                regionName: '博湖县',
                parentIdAll: '1,582,633,637',
                children: []
              },
              {
                id: 638,
                parentId: 633,
                regionType: 3,
                regionName: '焉耆回族自治县',
                parentIdAll: '1,582,633,638',
                children: []
              },
              {
                id: 639,
                parentId: 633,
                regionType: 3,
                regionName: '若羌县',
                parentIdAll: '1,582,633,639',
                children: []
              },
              {
                id: 640,
                parentId: 633,
                regionType: 3,
                regionName: '且末县',
                parentIdAll: '1,582,633,640',
                children: []
              },
              {
                id: 641,
                parentId: 633,
                regionType: 3,
                regionName: '库尔勒市',
                parentIdAll: '1,582,633,641',
                children: []
              },
              {
                id: 642,
                parentId: 633,
                regionType: 3,
                regionName: '轮台县',
                parentIdAll: '1,582,633,642',
                children: []
              }
            ]
          },
          {
            id: 643,
            parentId: 582,
            regionType: 2,
            regionName: '阿拉尔市',
            parentIdAll: '1,582,643',
            children: [
              {
                id: 3270,
                parentId: 643,
                regionType: 3,
                regionName: '阿拉尔市',
                parentIdAll: '1,582,643,3270',
                children: []
              }
            ]
          },
          {
            id: 644,
            parentId: 582,
            regionType: 2,
            regionName: '乌鲁木齐市',
            parentIdAll: '1,582,644',
            children: [
              {
                id: 645,
                parentId: 644,
                regionType: 3,
                regionName: '乌鲁木齐县',
                parentIdAll: '1,582,644,645',
                children: []
              },
              {
                id: 646,
                parentId: 644,
                regionType: 3,
                regionName: '达坂城区',
                parentIdAll: '1,582,644,646',
                children: []
              },
              {
                id: 647,
                parentId: 644,
                regionType: 3,
                regionName: '天山区',
                parentIdAll: '1,582,644,647',
                children: []
              },
              {
                id: 648,
                parentId: 644,
                regionType: 3,
                regionName: '头屯河区',
                parentIdAll: '1,582,644,648',
                children: []
              },
              {
                id: 649,
                parentId: 644,
                regionType: 3,
                regionName: '沙依巴克区',
                parentIdAll: '1,582,644,649',
                children: []
              },
              {
                id: 650,
                parentId: 644,
                regionType: 3,
                regionName: '新市区',
                parentIdAll: '1,582,644,650',
                children: []
              },
              {
                id: 651,
                parentId: 644,
                regionType: 3,
                regionName: '米东区',
                parentIdAll: '1,582,644,651',
                children: []
              },
              {
                id: 652,
                parentId: 644,
                regionType: 3,
                regionName: '水磨沟区',
                parentIdAll: '1,582,644,652',
                children: []
              }
            ]
          },
          {
            id: 653,
            parentId: 582,
            regionType: 2,
            regionName: '伊犁哈萨克自治州',
            parentIdAll: '1,582,653',
            children: [
              {
                id: 654,
                parentId: 653,
                regionType: 3,
                regionName: '奎屯市',
                parentIdAll: '1,582,653,654',
                children: []
              },
              {
                id: 655,
                parentId: 653,
                regionType: 3,
                regionName: '巩留县',
                parentIdAll: '1,582,653,655',
                children: []
              },
              {
                id: 656,
                parentId: 653,
                regionType: 3,
                regionName: '特克斯县',
                parentIdAll: '1,582,653,656',
                children: []
              },
              {
                id: 657,
                parentId: 653,
                regionType: 3,
                regionName: '尼勒克县',
                parentIdAll: '1,582,653,657',
                children: []
              },
              {
                id: 658,
                parentId: 653,
                regionType: 3,
                regionName: '伊宁县',
                parentIdAll: '1,582,653,658',
                children: []
              },
              {
                id: 659,
                parentId: 653,
                regionType: 3,
                regionName: '新源县',
                parentIdAll: '1,582,653,659',
                children: []
              },
              {
                id: 660,
                parentId: 653,
                regionType: 3,
                regionName: '察布查尔锡伯自治县',
                parentIdAll: '1,582,653,660',
                children: []
              },
              {
                id: 661,
                parentId: 653,
                regionType: 3,
                regionName: '伊宁市',
                parentIdAll: '1,582,653,661',
                children: []
              },
              {
                id: 662,
                parentId: 653,
                regionType: 3,
                regionName: '昭苏县',
                parentIdAll: '1,582,653,662',
                children: []
              },
              {
                id: 663,
                parentId: 653,
                regionType: 3,
                regionName: '霍城县',
                parentIdAll: '1,582,653,663',
                children: []
              },
              {
                id: 664,
                parentId: 653,
                regionType: 3,
                regionName: '霍尔果斯市',
                parentIdAll: '1,582,653,664',
                children: []
              }
            ]
          },
          {
            id: 665,
            parentId: 582,
            regionType: 2,
            regionName: '图木舒克市',
            parentIdAll: '1,582,665',
            children: [
              {
                id: 3271,
                parentId: 665,
                regionType: 3,
                regionName: '图木舒克市',
                parentIdAll: '1,582,665,3271',
                children: []
              }
            ]
          },
          {
            id: 666,
            parentId: 582,
            regionType: 2,
            regionName: '喀什地区',
            parentIdAll: '1,582,666',
            children: [
              {
                id: 667,
                parentId: 666,
                regionType: 3,
                regionName: '疏勒县',
                parentIdAll: '1,582,666,667',
                children: []
              },
              {
                id: 668,
                parentId: 666,
                regionType: 3,
                regionName: '伽师县',
                parentIdAll: '1,582,666,668',
                children: []
              },
              {
                id: 669,
                parentId: 666,
                regionType: 3,
                regionName: '麦盖提县',
                parentIdAll: '1,582,666,669',
                children: []
              },
              {
                id: 670,
                parentId: 666,
                regionType: 3,
                regionName: '叶城县',
                parentIdAll: '1,582,666,670',
                children: []
              },
              {
                id: 671,
                parentId: 666,
                regionType: 3,
                regionName: '莎车县',
                parentIdAll: '1,582,666,671',
                children: []
              },
              {
                id: 672,
                parentId: 666,
                regionType: 3,
                regionName: '泽普县',
                parentIdAll: '1,582,666,672',
                children: []
              },
              {
                id: 673,
                parentId: 666,
                regionType: 3,
                regionName: '塔什库尔干塔吉克自治县',
                parentIdAll: '1,582,666,673',
                children: []
              },
              {
                id: 674,
                parentId: 666,
                regionType: 3,
                regionName: '岳普湖县',
                parentIdAll: '1,582,666,674',
                children: []
              },
              {
                id: 675,
                parentId: 666,
                regionType: 3,
                regionName: '巴楚县',
                parentIdAll: '1,582,666,675',
                children: []
              },
              {
                id: 676,
                parentId: 666,
                regionType: 3,
                regionName: '英吉沙县',
                parentIdAll: '1,582,666,676',
                children: []
              },
              {
                id: 677,
                parentId: 666,
                regionType: 3,
                regionName: '疏附县',
                parentIdAll: '1,582,666,677',
                children: []
              },
              {
                id: 678,
                parentId: 666,
                regionType: 3,
                regionName: '喀什市',
                parentIdAll: '1,582,666,678',
                children: []
              }
            ]
          },
          {
            id: 679,
            parentId: 582,
            regionType: 2,
            regionName: '阿克苏地区',
            parentIdAll: '1,582,679',
            children: [
              {
                id: 680,
                parentId: 679,
                regionType: 3,
                regionName: '沙雅县',
                parentIdAll: '1,582,679,680',
                children: []
              },
              {
                id: 681,
                parentId: 679,
                regionType: 3,
                regionName: '温宿县',
                parentIdAll: '1,582,679,681',
                children: []
              },
              {
                id: 682,
                parentId: 679,
                regionType: 3,
                regionName: '库车县',
                parentIdAll: '1,582,679,682',
                children: []
              },
              {
                id: 683,
                parentId: 679,
                regionType: 3,
                regionName: '新和县',
                parentIdAll: '1,582,679,683',
                children: []
              },
              {
                id: 684,
                parentId: 679,
                regionType: 3,
                regionName: '拜城县',
                parentIdAll: '1,582,679,684',
                children: []
              },
              {
                id: 685,
                parentId: 679,
                regionType: 3,
                regionName: '阿克苏市',
                parentIdAll: '1,582,679,685',
                children: []
              },
              {
                id: 686,
                parentId: 679,
                regionType: 3,
                regionName: '阿瓦提县',
                parentIdAll: '1,582,679,686',
                children: []
              },
              {
                id: 687,
                parentId: 679,
                regionType: 3,
                regionName: '柯坪县',
                parentIdAll: '1,582,679,687',
                children: []
              },
              {
                id: 688,
                parentId: 679,
                regionType: 3,
                regionName: '乌什县',
                parentIdAll: '1,582,679,688',
                children: []
              }
            ]
          },
          {
            id: 689,
            parentId: 582,
            regionType: 2,
            regionName: '克孜勒苏柯尔克孜自治州',
            parentIdAll: '1,582,689',
            children: [
              {
                id: 690,
                parentId: 689,
                regionType: 3,
                regionName: '阿图什市',
                parentIdAll: '1,582,689,690',
                children: []
              },
              {
                id: 691,
                parentId: 689,
                regionType: 3,
                regionName: '乌恰县',
                parentIdAll: '1,582,689,691',
                children: []
              },
              {
                id: 692,
                parentId: 689,
                regionType: 3,
                regionName: '阿合奇县',
                parentIdAll: '1,582,689,692',
                children: []
              },
              {
                id: 693,
                parentId: 689,
                regionType: 3,
                regionName: '阿克陶县',
                parentIdAll: '1,582,689,693',
                children: []
              }
            ]
          },
          {
            id: 694,
            parentId: 582,
            regionType: 2,
            regionName: '吐鲁番市',
            parentIdAll: '1,582,694',
            children: [
              {
                id: 695,
                parentId: 694,
                regionType: 3,
                regionName: '高昌区',
                parentIdAll: '1,582,694,695',
                children: []
              },
              {
                id: 696,
                parentId: 694,
                regionType: 3,
                regionName: '鄯善县',
                parentIdAll: '1,582,694,696',
                children: []
              },
              {
                id: 697,
                parentId: 694,
                regionType: 3,
                regionName: '托克逊县',
                parentIdAll: '1,582,694,697',
                children: []
              }
            ]
          },
          {
            id: 698,
            parentId: 582,
            regionType: 2,
            regionName: '哈密市',
            parentIdAll: '1,582,698',
            children: [
              {
                id: 699,
                parentId: 698,
                regionType: 3,
                regionName: '伊州区',
                parentIdAll: '1,582,698,699',
                children: []
              },
              {
                id: 700,
                parentId: 698,
                regionType: 3,
                regionName: '伊吾县',
                parentIdAll: '1,582,698,700',
                children: []
              },
              {
                id: 701,
                parentId: 698,
                regionType: 3,
                regionName: '巴里坤哈萨克自治县',
                parentIdAll: '1,582,698,701',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 702,
        parentId: 1,
        regionType: 1,
        regionName: '湖北省',
        parentIdAll: '1,702',
        children: [
          {
            id: 703,
            parentId: 702,
            regionType: 2,
            regionName: '十堰市',
            parentIdAll: '1,702,703',
            children: [
              {
                id: 704,
                parentId: 703,
                regionType: 3,
                regionName: '丹江口市',
                parentIdAll: '1,702,703,704',
                children: []
              },
              {
                id: 705,
                parentId: 703,
                regionType: 3,
                regionName: '房县',
                parentIdAll: '1,702,703,705',
                children: []
              },
              {
                id: 706,
                parentId: 703,
                regionType: 3,
                regionName: '茅箭区',
                parentIdAll: '1,702,703,706',
                children: []
              },
              {
                id: 707,
                parentId: 703,
                regionType: 3,
                regionName: '郧西县',
                parentIdAll: '1,702,703,707',
                children: []
              },
              {
                id: 708,
                parentId: 703,
                regionType: 3,
                regionName: '郧阳区',
                parentIdAll: '1,702,703,708',
                children: []
              },
              {
                id: 709,
                parentId: 703,
                regionType: 3,
                regionName: '张湾区',
                parentIdAll: '1,702,703,709',
                children: []
              },
              {
                id: 710,
                parentId: 703,
                regionType: 3,
                regionName: '竹山县',
                parentIdAll: '1,702,703,710',
                children: []
              },
              {
                id: 711,
                parentId: 703,
                regionType: 3,
                regionName: '竹溪县',
                parentIdAll: '1,702,703,711',
                children: []
              }
            ]
          },
          {
            id: 712,
            parentId: 702,
            regionType: 2,
            regionName: '孝感市',
            parentIdAll: '1,702,712',
            children: [
              {
                id: 713,
                parentId: 712,
                regionType: 3,
                regionName: '孝昌县',
                parentIdAll: '1,702,712,713',
                children: []
              },
              {
                id: 714,
                parentId: 712,
                regionType: 3,
                regionName: '大悟县',
                parentIdAll: '1,702,712,714',
                children: []
              },
              {
                id: 715,
                parentId: 712,
                regionType: 3,
                regionName: '安陆市',
                parentIdAll: '1,702,712,715',
                children: []
              },
              {
                id: 716,
                parentId: 712,
                regionType: 3,
                regionName: '云梦县',
                parentIdAll: '1,702,712,716',
                children: []
              },
              {
                id: 717,
                parentId: 712,
                regionType: 3,
                regionName: '应城市',
                parentIdAll: '1,702,712,717',
                children: []
              },
              {
                id: 718,
                parentId: 712,
                regionType: 3,
                regionName: '孝南区',
                parentIdAll: '1,702,712,718',
                children: []
              },
              {
                id: 719,
                parentId: 712,
                regionType: 3,
                regionName: '汉川市',
                parentIdAll: '1,702,712,719',
                children: []
              }
            ]
          },
          {
            id: 720,
            parentId: 702,
            regionType: 2,
            regionName: '宜昌市',
            parentIdAll: '1,702,720',
            children: [
              {
                id: 721,
                parentId: 720,
                regionType: 3,
                regionName: '远安县',
                parentIdAll: '1,702,720,721',
                children: []
              },
              {
                id: 722,
                parentId: 720,
                regionType: 3,
                regionName: '兴山县',
                parentIdAll: '1,702,720,722',
                children: []
              },
              {
                id: 723,
                parentId: 720,
                regionType: 3,
                regionName: '夷陵区',
                parentIdAll: '1,702,720,723',
                children: []
              },
              {
                id: 724,
                parentId: 720,
                regionType: 3,
                regionName: '秭归县',
                parentIdAll: '1,702,720,724',
                children: []
              },
              {
                id: 725,
                parentId: 720,
                regionType: 3,
                regionName: '当阳市',
                parentIdAll: '1,702,720,725',
                children: []
              },
              {
                id: 726,
                parentId: 720,
                regionType: 3,
                regionName: '西陵区',
                parentIdAll: '1,702,720,726',
                children: []
              },
              {
                id: 727,
                parentId: 720,
                regionType: 3,
                regionName: '点军区',
                parentIdAll: '1,702,720,727',
                children: []
              },
              {
                id: 728,
                parentId: 720,
                regionType: 3,
                regionName: '长阳土家族自治县',
                parentIdAll: '1,702,720,728',
                children: []
              },
              {
                id: 729,
                parentId: 720,
                regionType: 3,
                regionName: '五峰土家族自治县',
                parentIdAll: '1,702,720,729',
                children: []
              },
              {
                id: 730,
                parentId: 720,
                regionType: 3,
                regionName: '猇亭区',
                parentIdAll: '1,702,720,730',
                children: []
              },
              {
                id: 731,
                parentId: 720,
                regionType: 3,
                regionName: '宜都市',
                parentIdAll: '1,702,720,731',
                children: []
              },
              {
                id: 732,
                parentId: 720,
                regionType: 3,
                regionName: '枝江市',
                parentIdAll: '1,702,720,732',
                children: []
              },
              {
                id: 733,
                parentId: 720,
                regionType: 3,
                regionName: '伍家岗区',
                parentIdAll: '1,702,720,733',
                children: []
              }
            ]
          },
          {
            id: 734,
            parentId: 702,
            regionType: 2,
            regionName: '恩施土家族苗族自治州',
            parentIdAll: '1,702,734',
            children: [
              {
                id: 735,
                parentId: 734,
                regionType: 3,
                regionName: '建始县',
                parentIdAll: '1,702,734,735',
                children: []
              },
              {
                id: 736,
                parentId: 734,
                regionType: 3,
                regionName: '利川市',
                parentIdAll: '1,702,734,736',
                children: []
              },
              {
                id: 737,
                parentId: 734,
                regionType: 3,
                regionName: '恩施市',
                parentIdAll: '1,702,734,737',
                children: []
              },
              {
                id: 738,
                parentId: 734,
                regionType: 3,
                regionName: '鹤峰县',
                parentIdAll: '1,702,734,738',
                children: []
              },
              {
                id: 739,
                parentId: 734,
                regionType: 3,
                regionName: '来凤县',
                parentIdAll: '1,702,734,739',
                children: []
              },
              {
                id: 740,
                parentId: 734,
                regionType: 3,
                regionName: '巴东县',
                parentIdAll: '1,702,734,740',
                children: []
              },
              {
                id: 741,
                parentId: 734,
                regionType: 3,
                regionName: '宣恩县',
                parentIdAll: '1,702,734,741',
                children: []
              },
              {
                id: 742,
                parentId: 734,
                regionType: 3,
                regionName: '咸丰县',
                parentIdAll: '1,702,734,742',
                children: []
              }
            ]
          },
          {
            id: 743,
            parentId: 702,
            regionType: 2,
            regionName: '武汉市',
            parentIdAll: '1,702,743',
            children: [
              {
                id: 744,
                parentId: 743,
                regionType: 3,
                regionName: '黄陂区',
                parentIdAll: '1,702,743,744',
                children: []
              },
              {
                id: 745,
                parentId: 743,
                regionType: 3,
                regionName: '新洲区',
                parentIdAll: '1,702,743,745',
                children: []
              },
              {
                id: 746,
                parentId: 743,
                regionType: 3,
                regionName: '东西湖区',
                parentIdAll: '1,702,743,746',
                children: []
              },
              {
                id: 747,
                parentId: 743,
                regionType: 3,
                regionName: '江岸区',
                parentIdAll: '1,702,743,747',
                children: []
              },
              {
                id: 748,
                parentId: 743,
                regionType: 3,
                regionName: '汉阳区',
                parentIdAll: '1,702,743,748',
                children: []
              },
              {
                id: 749,
                parentId: 743,
                regionType: 3,
                regionName: '汉南区',
                parentIdAll: '1,702,743,749',
                children: []
              },
              {
                id: 750,
                parentId: 743,
                regionType: 3,
                regionName: '蔡甸区',
                parentIdAll: '1,702,743,750',
                children: []
              },
              {
                id: 751,
                parentId: 743,
                regionType: 3,
                regionName: '硚口区',
                parentIdAll: '1,702,743,751',
                children: []
              },
              {
                id: 752,
                parentId: 743,
                regionType: 3,
                regionName: '江汉区',
                parentIdAll: '1,702,743,752',
                children: []
              },
              {
                id: 753,
                parentId: 743,
                regionType: 3,
                regionName: '江夏区',
                parentIdAll: '1,702,743,753',
                children: []
              },
              {
                id: 754,
                parentId: 743,
                regionType: 3,
                regionName: '青山区',
                parentIdAll: '1,702,743,754',
                children: []
              },
              {
                id: 755,
                parentId: 743,
                regionType: 3,
                regionName: '武昌区',
                parentIdAll: '1,702,743,755',
                children: []
              },
              {
                id: 756,
                parentId: 743,
                regionType: 3,
                regionName: '洪山区',
                parentIdAll: '1,702,743,756',
                children: []
              }
            ]
          },
          {
            id: 757,
            parentId: 702,
            regionType: 2,
            regionName: '天门市',
            parentIdAll: '1,702,757',
            children: [
              {
                id: 3272,
                parentId: 757,
                regionType: 3,
                regionName: '天门市',
                parentIdAll: '1,702,757,3272',
                children: []
              }
            ]
          },
          {
            id: 758,
            parentId: 702,
            regionType: 2,
            regionName: '襄阳市',
            parentIdAll: '1,702,758',
            children: [
              {
                id: 759,
                parentId: 758,
                regionType: 3,
                regionName: '老河口市',
                parentIdAll: '1,702,758,759',
                children: []
              },
              {
                id: 760,
                parentId: 758,
                regionType: 3,
                regionName: '谷城县',
                parentIdAll: '1,702,758,760',
                children: []
              },
              {
                id: 761,
                parentId: 758,
                regionType: 3,
                regionName: '南漳县',
                parentIdAll: '1,702,758,761',
                children: []
              },
              {
                id: 762,
                parentId: 758,
                regionType: 3,
                regionName: '枣阳市',
                parentIdAll: '1,702,758,762',
                children: []
              },
              {
                id: 763,
                parentId: 758,
                regionType: 3,
                regionName: '襄州区',
                parentIdAll: '1,702,758,763',
                children: []
              },
              {
                id: 764,
                parentId: 758,
                regionType: 3,
                regionName: '宜城市',
                parentIdAll: '1,702,758,764',
                children: []
              },
              {
                id: 765,
                parentId: 758,
                regionType: 3,
                regionName: '保康县',
                parentIdAll: '1,702,758,765',
                children: []
              },
              {
                id: 766,
                parentId: 758,
                regionType: 3,
                regionName: '樊城区',
                parentIdAll: '1,702,758,766',
                children: []
              },
              {
                id: 767,
                parentId: 758,
                regionType: 3,
                regionName: '襄城区',
                parentIdAll: '1,702,758,767',
                children: []
              }
            ]
          },
          {
            id: 768,
            parentId: 702,
            regionType: 2,
            regionName: '黄冈市',
            parentIdAll: '1,702,768',
            children: [
              {
                id: 769,
                parentId: 768,
                regionType: 3,
                regionName: '红安县',
                parentIdAll: '1,702,768,769',
                children: []
              },
              {
                id: 770,
                parentId: 768,
                regionType: 3,
                regionName: '英山县',
                parentIdAll: '1,702,768,770',
                children: []
              },
              {
                id: 771,
                parentId: 768,
                regionType: 3,
                regionName: '罗田县',
                parentIdAll: '1,702,768,771',
                children: []
              },
              {
                id: 772,
                parentId: 768,
                regionType: 3,
                regionName: '团风县',
                parentIdAll: '1,702,768,772',
                children: []
              },
              {
                id: 773,
                parentId: 768,
                regionType: 3,
                regionName: '浠水县',
                parentIdAll: '1,702,768,773',
                children: []
              },
              {
                id: 774,
                parentId: 768,
                regionType: 3,
                regionName: '麻城市',
                parentIdAll: '1,702,768,774',
                children: []
              },
              {
                id: 775,
                parentId: 768,
                regionType: 3,
                regionName: '蕲春县',
                parentIdAll: '1,702,768,775',
                children: []
              },
              {
                id: 776,
                parentId: 768,
                regionType: 3,
                regionName: '黄梅县',
                parentIdAll: '1,702,768,776',
                children: []
              },
              {
                id: 777,
                parentId: 768,
                regionType: 3,
                regionName: '黄州区',
                parentIdAll: '1,702,768,777',
                children: []
              },
              {
                id: 778,
                parentId: 768,
                regionType: 3,
                regionName: '武穴市',
                parentIdAll: '1,702,768,778',
                children: []
              }
            ]
          },
          {
            id: 779,
            parentId: 702,
            regionType: 2,
            regionName: '潜江市',
            parentIdAll: '1,702,779',
            children: [
              {
                id: 3273,
                parentId: 779,
                regionType: 3,
                regionName: '潜江市',
                parentIdAll: '1,702,779,3273',
                children: []
              }
            ]
          },
          {
            id: 780,
            parentId: 702,
            regionType: 2,
            regionName: '荆门市',
            parentIdAll: '1,702,780',
            children: [
              {
                id: 781,
                parentId: 780,
                regionType: 3,
                regionName: '京山县',
                parentIdAll: '1,702,780,781',
                children: []
              },
              {
                id: 782,
                parentId: 780,
                regionType: 3,
                regionName: '东宝区',
                parentIdAll: '1,702,780,782',
                children: []
              },
              {
                id: 783,
                parentId: 780,
                regionType: 3,
                regionName: '钟祥市',
                parentIdAll: '1,702,780,783',
                children: []
              },
              {
                id: 784,
                parentId: 780,
                regionType: 3,
                regionName: '掇刀区',
                parentIdAll: '1,702,780,784',
                children: []
              },
              {
                id: 785,
                parentId: 780,
                regionType: 3,
                regionName: '沙洋县',
                parentIdAll: '1,702,780,785',
                children: []
              }
            ]
          },
          {
            id: 786,
            parentId: 702,
            regionType: 2,
            regionName: '仙桃市',
            parentIdAll: '1,702,786',
            children: [
              {
                id: 3274,
                parentId: 786,
                regionType: 3,
                regionName: '仙桃市',
                parentIdAll: '1,702,786,3274',
                children: []
              }
            ]
          },
          {
            id: 787,
            parentId: 702,
            regionType: 2,
            regionName: '神农架林区',
            parentIdAll: '1,702,787',
            children: [
              {
                id: 3275,
                parentId: 787,
                regionType: 3,
                regionName: '神农架林区',
                parentIdAll: '1,702,787,3275',
                children: []
              }
            ]
          },
          {
            id: 788,
            parentId: 702,
            regionType: 2,
            regionName: '随州市',
            parentIdAll: '1,702,788',
            children: [
              {
                id: 789,
                parentId: 788,
                regionType: 3,
                regionName: '广水市',
                parentIdAll: '1,702,788,789',
                children: []
              },
              {
                id: 790,
                parentId: 788,
                regionType: 3,
                regionName: '曾都区',
                parentIdAll: '1,702,788,790',
                children: []
              },
              {
                id: 791,
                parentId: 788,
                regionType: 3,
                regionName: '随县',
                parentIdAll: '1,702,788,791',
                children: []
              }
            ]
          },
          {
            id: 792,
            parentId: 702,
            regionType: 2,
            regionName: '荆州市',
            parentIdAll: '1,702,792',
            children: [
              {
                id: 793,
                parentId: 792,
                regionType: 3,
                regionName: '荆州区',
                parentIdAll: '1,702,792,793',
                children: []
              },
              {
                id: 794,
                parentId: 792,
                regionType: 3,
                regionName: '江陵县',
                parentIdAll: '1,702,792,794',
                children: []
              },
              {
                id: 795,
                parentId: 792,
                regionType: 3,
                regionName: '监利县',
                parentIdAll: '1,702,792,795',
                children: []
              },
              {
                id: 796,
                parentId: 792,
                regionType: 3,
                regionName: '石首市',
                parentIdAll: '1,702,792,796',
                children: []
              },
              {
                id: 797,
                parentId: 792,
                regionType: 3,
                regionName: '洪湖市',
                parentIdAll: '1,702,792,797',
                children: []
              },
              {
                id: 798,
                parentId: 792,
                regionType: 3,
                regionName: '松滋市',
                parentIdAll: '1,702,792,798',
                children: []
              },
              {
                id: 799,
                parentId: 792,
                regionType: 3,
                regionName: '沙市区',
                parentIdAll: '1,702,792,799',
                children: []
              },
              {
                id: 800,
                parentId: 792,
                regionType: 3,
                regionName: '公安县',
                parentIdAll: '1,702,792,800',
                children: []
              }
            ]
          },
          {
            id: 801,
            parentId: 702,
            regionType: 2,
            regionName: '咸宁市',
            parentIdAll: '1,702,801',
            children: [
              {
                id: 802,
                parentId: 801,
                regionType: 3,
                regionName: '嘉鱼县',
                parentIdAll: '1,702,801,802',
                children: []
              },
              {
                id: 803,
                parentId: 801,
                regionType: 3,
                regionName: '崇阳县',
                parentIdAll: '1,702,801,803',
                children: []
              },
              {
                id: 804,
                parentId: 801,
                regionType: 3,
                regionName: '通山县',
                parentIdAll: '1,702,801,804',
                children: []
              },
              {
                id: 805,
                parentId: 801,
                regionType: 3,
                regionName: '咸安区',
                parentIdAll: '1,702,801,805',
                children: []
              },
              {
                id: 806,
                parentId: 801,
                regionType: 3,
                regionName: '赤壁市',
                parentIdAll: '1,702,801,806',
                children: []
              },
              {
                id: 807,
                parentId: 801,
                regionType: 3,
                regionName: '通城县',
                parentIdAll: '1,702,801,807',
                children: []
              }
            ]
          },
          {
            id: 808,
            parentId: 702,
            regionType: 2,
            regionName: '鄂州市',
            parentIdAll: '1,702,808',
            children: [
              {
                id: 809,
                parentId: 808,
                regionType: 3,
                regionName: '华容区',
                parentIdAll: '1,702,808,809',
                children: []
              },
              {
                id: 810,
                parentId: 808,
                regionType: 3,
                regionName: '梁子湖区',
                parentIdAll: '1,702,808,810',
                children: []
              },
              {
                id: 811,
                parentId: 808,
                regionType: 3,
                regionName: '鄂城区',
                parentIdAll: '1,702,808,811',
                children: []
              }
            ]
          },
          {
            id: 812,
            parentId: 702,
            regionType: 2,
            regionName: '黄石市',
            parentIdAll: '1,702,812',
            children: [
              {
                id: 813,
                parentId: 812,
                regionType: 3,
                regionName: '铁山区',
                parentIdAll: '1,702,812,813',
                children: []
              },
              {
                id: 814,
                parentId: 812,
                regionType: 3,
                regionName: '下陆区',
                parentIdAll: '1,702,812,814',
                children: []
              },
              {
                id: 815,
                parentId: 812,
                regionType: 3,
                regionName: '西塞山区',
                parentIdAll: '1,702,812,815',
                children: []
              },
              {
                id: 816,
                parentId: 812,
                regionType: 3,
                regionName: '阳新县',
                parentIdAll: '1,702,812,816',
                children: []
              },
              {
                id: 817,
                parentId: 812,
                regionType: 3,
                regionName: '大冶市',
                parentIdAll: '1,702,812,817',
                children: []
              },
              {
                id: 818,
                parentId: 812,
                regionType: 3,
                regionName: '黄石港区',
                parentIdAll: '1,702,812,818',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 819,
        parentId: 1,
        regionType: 1,
        regionName: '辽宁省',
        parentIdAll: '1,819',
        children: [
          {
            id: 820,
            parentId: 819,
            regionType: 2,
            regionName: '大连市',
            parentIdAll: '1,819,820',
            children: [
              {
                id: 821,
                parentId: 820,
                regionType: 3,
                regionName: '瓦房店市',
                parentIdAll: '1,819,820,821',
                children: []
              },
              {
                id: 822,
                parentId: 820,
                regionType: 3,
                regionName: '普兰店区',
                parentIdAll: '1,819,820,822',
                children: []
              },
              {
                id: 823,
                parentId: 820,
                regionType: 3,
                regionName: '长海县',
                parentIdAll: '1,819,820,823',
                children: []
              },
              {
                id: 824,
                parentId: 820,
                regionType: 3,
                regionName: '金州区',
                parentIdAll: '1,819,820,824',
                children: []
              },
              {
                id: 825,
                parentId: 820,
                regionType: 3,
                regionName: '甘井子区',
                parentIdAll: '1,819,820,825',
                children: []
              },
              {
                id: 826,
                parentId: 820,
                regionType: 3,
                regionName: '中山区',
                parentIdAll: '1,819,820,826',
                children: []
              },
              {
                id: 827,
                parentId: 820,
                regionType: 3,
                regionName: '庄河市',
                parentIdAll: '1,819,820,827',
                children: []
              },
              {
                id: 828,
                parentId: 820,
                regionType: 3,
                regionName: '旅顺口区',
                parentIdAll: '1,819,820,828',
                children: []
              },
              {
                id: 829,
                parentId: 820,
                regionType: 3,
                regionName: '西岗区',
                parentIdAll: '1,819,820,829',
                children: []
              },
              {
                id: 830,
                parentId: 820,
                regionType: 3,
                regionName: '沙河口区',
                parentIdAll: '1,819,820,830',
                children: []
              }
            ]
          },
          {
            id: 831,
            parentId: 819,
            regionType: 2,
            regionName: '锦州市',
            parentIdAll: '1,819,831',
            children: [
              {
                id: 832,
                parentId: 831,
                regionType: 3,
                regionName: '黑山县',
                parentIdAll: '1,819,831,832',
                children: []
              },
              {
                id: 833,
                parentId: 831,
                regionType: 3,
                regionName: '义县',
                parentIdAll: '1,819,831,833',
                children: []
              },
              {
                id: 834,
                parentId: 831,
                regionType: 3,
                regionName: '凌海市',
                parentIdAll: '1,819,831,834',
                children: []
              },
              {
                id: 835,
                parentId: 831,
                regionType: 3,
                regionName: '太和区',
                parentIdAll: '1,819,831,835',
                children: []
              },
              {
                id: 836,
                parentId: 831,
                regionType: 3,
                regionName: '北镇市',
                parentIdAll: '1,819,831,836',
                children: []
              },
              {
                id: 837,
                parentId: 831,
                regionType: 3,
                regionName: '凌河区',
                parentIdAll: '1,819,831,837',
                children: []
              },
              {
                id: 838,
                parentId: 831,
                regionType: 3,
                regionName: '古塔区',
                parentIdAll: '1,819,831,838',
                children: []
              }
            ]
          },
          {
            id: 839,
            parentId: 819,
            regionType: 2,
            regionName: '葫芦岛市',
            parentIdAll: '1,819,839',
            children: [
              {
                id: 840,
                parentId: 839,
                regionType: 3,
                regionName: '建昌县',
                parentIdAll: '1,819,839,840',
                children: []
              },
              {
                id: 841,
                parentId: 839,
                regionType: 3,
                regionName: '兴城市',
                parentIdAll: '1,819,839,841',
                children: []
              },
              {
                id: 842,
                parentId: 839,
                regionType: 3,
                regionName: '龙港区',
                parentIdAll: '1,819,839,842',
                children: []
              },
              {
                id: 843,
                parentId: 839,
                regionType: 3,
                regionName: '绥中县',
                parentIdAll: '1,819,839,843',
                children: []
              },
              {
                id: 844,
                parentId: 839,
                regionType: 3,
                regionName: '南票区',
                parentIdAll: '1,819,839,844',
                children: []
              },
              {
                id: 845,
                parentId: 839,
                regionType: 3,
                regionName: '连山区',
                parentIdAll: '1,819,839,845',
                children: []
              }
            ]
          },
          {
            id: 846,
            parentId: 819,
            regionType: 2,
            regionName: '丹东市',
            parentIdAll: '1,819,846',
            children: [
              {
                id: 847,
                parentId: 846,
                regionType: 3,
                regionName: '凤城市',
                parentIdAll: '1,819,846,847',
                children: []
              },
              {
                id: 848,
                parentId: 846,
                regionType: 3,
                regionName: '振安区',
                parentIdAll: '1,819,846,848',
                children: []
              },
              {
                id: 849,
                parentId: 846,
                regionType: 3,
                regionName: '东港市',
                parentIdAll: '1,819,846,849',
                children: []
              },
              {
                id: 850,
                parentId: 846,
                regionType: 3,
                regionName: '振兴区',
                parentIdAll: '1,819,846,850',
                children: []
              },
              {
                id: 851,
                parentId: 846,
                regionType: 3,
                regionName: '元宝区',
                parentIdAll: '1,819,846,851',
                children: []
              },
              {
                id: 852,
                parentId: 846,
                regionType: 3,
                regionName: '宽甸满族自治县',
                parentIdAll: '1,819,846,852',
                children: []
              }
            ]
          },
          {
            id: 853,
            parentId: 819,
            regionType: 2,
            regionName: '抚顺市',
            parentIdAll: '1,819,853',
            children: [
              {
                id: 854,
                parentId: 853,
                regionType: 3,
                regionName: '清原满族自治县',
                parentIdAll: '1,819,853,854',
                children: []
              },
              {
                id: 855,
                parentId: 853,
                regionType: 3,
                regionName: '新宾满族自治县',
                parentIdAll: '1,819,853,855',
                children: []
              },
              {
                id: 856,
                parentId: 853,
                regionType: 3,
                regionName: '抚顺县',
                parentIdAll: '1,819,853,856',
                children: []
              },
              {
                id: 857,
                parentId: 853,
                regionType: 3,
                regionName: '东洲区',
                parentIdAll: '1,819,853,857',
                children: []
              },
              {
                id: 858,
                parentId: 853,
                regionType: 3,
                regionName: '顺城区',
                parentIdAll: '1,819,853,858',
                children: []
              },
              {
                id: 859,
                parentId: 853,
                regionType: 3,
                regionName: '望花区',
                parentIdAll: '1,819,853,859',
                children: []
              },
              {
                id: 860,
                parentId: 853,
                regionType: 3,
                regionName: '新抚区',
                parentIdAll: '1,819,853,860',
                children: []
              }
            ]
          },
          {
            id: 861,
            parentId: 819,
            regionType: 2,
            regionName: '沈阳市',
            parentIdAll: '1,819,861',
            children: [
              {
                id: 862,
                parentId: 861,
                regionType: 3,
                regionName: '新民市',
                parentIdAll: '1,819,861,862',
                children: []
              },
              {
                id: 863,
                parentId: 861,
                regionType: 3,
                regionName: '沈北新区',
                parentIdAll: '1,819,861,863',
                children: []
              },
              {
                id: 864,
                parentId: 861,
                regionType: 3,
                regionName: '浑南区',
                parentIdAll: '1,819,861,864',
                children: []
              },
              {
                id: 865,
                parentId: 861,
                regionType: 3,
                regionName: '辽中区',
                parentIdAll: '1,819,861,865',
                children: []
              },
              {
                id: 866,
                parentId: 861,
                regionType: 3,
                regionName: '于洪区',
                parentIdAll: '1,819,861,866',
                children: []
              },
              {
                id: 867,
                parentId: 861,
                regionType: 3,
                regionName: '皇姑区',
                parentIdAll: '1,819,861,867',
                children: []
              },
              {
                id: 868,
                parentId: 861,
                regionType: 3,
                regionName: '和平区',
                parentIdAll: '1,819,861,868',
                children: []
              },
              {
                id: 869,
                parentId: 861,
                regionType: 3,
                regionName: '铁西区',
                parentIdAll: '1,819,861,869',
                children: []
              },
              {
                id: 870,
                parentId: 861,
                regionType: 3,
                regionName: '苏家屯区',
                parentIdAll: '1,819,861,870',
                children: []
              },
              {
                id: 871,
                parentId: 861,
                regionType: 3,
                regionName: '康平县',
                parentIdAll: '1,819,861,871',
                children: []
              },
              {
                id: 872,
                parentId: 861,
                regionType: 3,
                regionName: '法库县',
                parentIdAll: '1,819,861,872',
                children: []
              },
              {
                id: 873,
                parentId: 861,
                regionType: 3,
                regionName: '大东区',
                parentIdAll: '1,819,861,873',
                children: []
              },
              {
                id: 874,
                parentId: 861,
                regionType: 3,
                regionName: '沈河区',
                parentIdAll: '1,819,861,874',
                children: []
              }
            ]
          },
          {
            id: 875,
            parentId: 819,
            regionType: 2,
            regionName: '辽阳市',
            parentIdAll: '1,819,875',
            children: [
              {
                id: 876,
                parentId: 875,
                regionType: 3,
                regionName: '宏伟区',
                parentIdAll: '1,819,875,876',
                children: []
              },
              {
                id: 877,
                parentId: 875,
                regionType: 3,
                regionName: '文圣区',
                parentIdAll: '1,819,875,877',
                children: []
              },
              {
                id: 878,
                parentId: 875,
                regionType: 3,
                regionName: '弓长岭区',
                parentIdAll: '1,819,875,878',
                children: []
              },
              {
                id: 879,
                parentId: 875,
                regionType: 3,
                regionName: '辽阳县',
                parentIdAll: '1,819,875,879',
                children: []
              },
              {
                id: 880,
                parentId: 875,
                regionType: 3,
                regionName: '灯塔市',
                parentIdAll: '1,819,875,880',
                children: []
              },
              {
                id: 881,
                parentId: 875,
                regionType: 3,
                regionName: '白塔区',
                parentIdAll: '1,819,875,881',
                children: []
              },
              {
                id: 882,
                parentId: 875,
                regionType: 3,
                regionName: '太子河区',
                parentIdAll: '1,819,875,882',
                children: []
              }
            ]
          },
          {
            id: 883,
            parentId: 819,
            regionType: 2,
            regionName: '铁岭市',
            parentIdAll: '1,819,883',
            children: [
              {
                id: 884,
                parentId: 883,
                regionType: 3,
                regionName: '调兵山市',
                parentIdAll: '1,819,883,884',
                children: []
              },
              {
                id: 885,
                parentId: 883,
                regionType: 3,
                regionName: '开原市',
                parentIdAll: '1,819,883,885',
                children: []
              },
              {
                id: 886,
                parentId: 883,
                regionType: 3,
                regionName: '清河区',
                parentIdAll: '1,819,883,886',
                children: []
              },
              {
                id: 887,
                parentId: 883,
                regionType: 3,
                regionName: '银州区',
                parentIdAll: '1,819,883,887',
                children: []
              },
              {
                id: 888,
                parentId: 883,
                regionType: 3,
                regionName: '铁岭县',
                parentIdAll: '1,819,883,888',
                children: []
              },
              {
                id: 889,
                parentId: 883,
                regionType: 3,
                regionName: '西丰县',
                parentIdAll: '1,819,883,889',
                children: []
              },
              {
                id: 890,
                parentId: 883,
                regionType: 3,
                regionName: '昌图县',
                parentIdAll: '1,819,883,890',
                children: []
              }
            ]
          },
          {
            id: 891,
            parentId: 819,
            regionType: 2,
            regionName: '鞍山市',
            parentIdAll: '1,819,891',
            children: [
              {
                id: 892,
                parentId: 891,
                regionType: 3,
                regionName: '台安县',
                parentIdAll: '1,819,891,892',
                children: []
              },
              {
                id: 893,
                parentId: 891,
                regionType: 3,
                regionName: '岫岩满族自治县',
                parentIdAll: '1,819,891,893',
                children: []
              },
              {
                id: 894,
                parentId: 891,
                regionType: 3,
                regionName: '海城市',
                parentIdAll: '1,819,891,894',
                children: []
              },
              {
                id: 895,
                parentId: 891,
                regionType: 3,
                regionName: '铁东区',
                parentIdAll: '1,819,891,895',
                children: []
              },
              {
                id: 896,
                parentId: 891,
                regionType: 3,
                regionName: '铁西区',
                parentIdAll: '1,819,891,896',
                children: []
              },
              {
                id: 897,
                parentId: 891,
                regionType: 3,
                regionName: '千山区',
                parentIdAll: '1,819,891,897',
                children: []
              },
              {
                id: 898,
                parentId: 891,
                regionType: 3,
                regionName: '立山区',
                parentIdAll: '1,819,891,898',
                children: []
              }
            ]
          },
          {
            id: 899,
            parentId: 819,
            regionType: 2,
            regionName: '盘锦市',
            parentIdAll: '1,819,899',
            children: [
              {
                id: 900,
                parentId: 899,
                regionType: 3,
                regionName: '兴隆台区',
                parentIdAll: '1,819,899,900',
                children: []
              },
              {
                id: 901,
                parentId: 899,
                regionType: 3,
                regionName: '双台子区',
                parentIdAll: '1,819,899,901',
                children: []
              },
              {
                id: 902,
                parentId: 899,
                regionType: 3,
                regionName: '盘山县',
                parentIdAll: '1,819,899,902',
                children: []
              },
              {
                id: 903,
                parentId: 899,
                regionType: 3,
                regionName: '大洼区',
                parentIdAll: '1,819,899,903',
                children: []
              }
            ]
          },
          {
            id: 904,
            parentId: 819,
            regionType: 2,
            regionName: '朝阳市',
            parentIdAll: '1,819,904',
            children: [
              {
                id: 905,
                parentId: 904,
                regionType: 3,
                regionName: '建平县',
                parentIdAll: '1,819,904,905',
                children: []
              },
              {
                id: 906,
                parentId: 904,
                regionType: 3,
                regionName: '北票市',
                parentIdAll: '1,819,904,906',
                children: []
              },
              {
                id: 907,
                parentId: 904,
                regionType: 3,
                regionName: '朝阳县',
                parentIdAll: '1,819,904,907',
                children: []
              },
              {
                id: 908,
                parentId: 904,
                regionType: 3,
                regionName: '双塔区',
                parentIdAll: '1,819,904,908',
                children: []
              },
              {
                id: 909,
                parentId: 904,
                regionType: 3,
                regionName: '龙城区',
                parentIdAll: '1,819,904,909',
                children: []
              },
              {
                id: 910,
                parentId: 904,
                regionType: 3,
                regionName: '凌源市',
                parentIdAll: '1,819,904,910',
                children: []
              },
              {
                id: 911,
                parentId: 904,
                regionType: 3,
                regionName: '喀喇沁左翼蒙古族自治县',
                parentIdAll: '1,819,904,911',
                children: []
              }
            ]
          },
          {
            id: 912,
            parentId: 819,
            regionType: 2,
            regionName: '营口市',
            parentIdAll: '1,819,912',
            children: [
              {
                id: 913,
                parentId: 912,
                regionType: 3,
                regionName: '鲅鱼圈区',
                parentIdAll: '1,819,912,913',
                children: []
              },
              {
                id: 914,
                parentId: 912,
                regionType: 3,
                regionName: '大石桥市',
                parentIdAll: '1,819,912,914',
                children: []
              },
              {
                id: 915,
                parentId: 912,
                regionType: 3,
                regionName: '西市区',
                parentIdAll: '1,819,912,915',
                children: []
              },
              {
                id: 916,
                parentId: 912,
                regionType: 3,
                regionName: '盖州市',
                parentIdAll: '1,819,912,916',
                children: []
              },
              {
                id: 917,
                parentId: 912,
                regionType: 3,
                regionName: '老边区',
                parentIdAll: '1,819,912,917',
                children: []
              },
              {
                id: 918,
                parentId: 912,
                regionType: 3,
                regionName: '站前区',
                parentIdAll: '1,819,912,918',
                children: []
              }
            ]
          },
          {
            id: 919,
            parentId: 819,
            regionType: 2,
            regionName: '阜新市',
            parentIdAll: '1,819,919',
            children: [
              {
                id: 920,
                parentId: 919,
                regionType: 3,
                regionName: '海州区',
                parentIdAll: '1,819,919,920',
                children: []
              },
              {
                id: 921,
                parentId: 919,
                regionType: 3,
                regionName: '细河区',
                parentIdAll: '1,819,919,921',
                children: []
              },
              {
                id: 922,
                parentId: 919,
                regionType: 3,
                regionName: '清河门区',
                parentIdAll: '1,819,919,922',
                children: []
              },
              {
                id: 923,
                parentId: 919,
                regionType: 3,
                regionName: '太平区',
                parentIdAll: '1,819,919,923',
                children: []
              },
              {
                id: 924,
                parentId: 919,
                regionType: 3,
                regionName: '阜新蒙古族自治县',
                parentIdAll: '1,819,919,924',
                children: []
              },
              {
                id: 925,
                parentId: 919,
                regionType: 3,
                regionName: '新邱区',
                parentIdAll: '1,819,919,925',
                children: []
              },
              {
                id: 926,
                parentId: 919,
                regionType: 3,
                regionName: '彰武县',
                parentIdAll: '1,819,919,926',
                children: []
              }
            ]
          },
          {
            id: 927,
            parentId: 819,
            regionType: 2,
            regionName: '本溪市',
            parentIdAll: '1,819,927',
            children: [
              {
                id: 928,
                parentId: 927,
                regionType: 3,
                regionName: '溪湖区',
                parentIdAll: '1,819,927,928',
                children: []
              },
              {
                id: 929,
                parentId: 927,
                regionType: 3,
                regionName: '桓仁满族自治县',
                parentIdAll: '1,819,927,929',
                children: []
              },
              {
                id: 930,
                parentId: 927,
                regionType: 3,
                regionName: '南芬区',
                parentIdAll: '1,819,927,930',
                children: []
              },
              {
                id: 931,
                parentId: 927,
                regionType: 3,
                regionName: '明山区',
                parentIdAll: '1,819,927,931',
                children: []
              },
              {
                id: 932,
                parentId: 927,
                regionType: 3,
                regionName: '平山区',
                parentIdAll: '1,819,927,932',
                children: []
              },
              {
                id: 933,
                parentId: 927,
                regionType: 3,
                regionName: '本溪满族自治县',
                parentIdAll: '1,819,927,933',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 934,
        parentId: 1,
        regionType: 1,
        regionName: '山东省',
        parentIdAll: '1,934',
        children: [
          {
            id: 935,
            parentId: 934,
            regionType: 2,
            regionName: '威海市',
            parentIdAll: '1,934,935',
            children: [
              {
                id: 936,
                parentId: 935,
                regionType: 3,
                regionName: '环翠区',
                parentIdAll: '1,934,935,936',
                children: []
              },
              {
                id: 937,
                parentId: 935,
                regionType: 3,
                regionName: '荣成市',
                parentIdAll: '1,934,935,937',
                children: []
              },
              {
                id: 938,
                parentId: 935,
                regionType: 3,
                regionName: '文登区',
                parentIdAll: '1,934,935,938',
                children: []
              },
              {
                id: 939,
                parentId: 935,
                regionType: 3,
                regionName: '乳山市',
                parentIdAll: '1,934,935,939',
                children: []
              }
            ]
          },
          {
            id: 940,
            parentId: 934,
            regionType: 2,
            regionName: '烟台市',
            parentIdAll: '1,934,940',
            children: [
              {
                id: 941,
                parentId: 940,
                regionType: 3,
                regionName: '龙口市',
                parentIdAll: '1,934,940,941',
                children: []
              },
              {
                id: 942,
                parentId: 940,
                regionType: 3,
                regionName: '长岛县',
                parentIdAll: '1,934,940,942',
                children: []
              },
              {
                id: 943,
                parentId: 940,
                regionType: 3,
                regionName: '福山区',
                parentIdAll: '1,934,940,943',
                children: []
              },
              {
                id: 944,
                parentId: 940,
                regionType: 3,
                regionName: '招远市',
                parentIdAll: '1,934,940,944',
                children: []
              },
              {
                id: 945,
                parentId: 940,
                regionType: 3,
                regionName: '芝罘区',
                parentIdAll: '1,934,940,945',
                children: []
              },
              {
                id: 946,
                parentId: 940,
                regionType: 3,
                regionName: '栖霞市',
                parentIdAll: '1,934,940,946',
                children: []
              },
              {
                id: 947,
                parentId: 940,
                regionType: 3,
                regionName: '莱州市',
                parentIdAll: '1,934,940,947',
                children: []
              },
              {
                id: 948,
                parentId: 940,
                regionType: 3,
                regionName: '海阳市',
                parentIdAll: '1,934,940,948',
                children: []
              },
              {
                id: 949,
                parentId: 940,
                regionType: 3,
                regionName: '牟平区',
                parentIdAll: '1,934,940,949',
                children: []
              },
              {
                id: 950,
                parentId: 940,
                regionType: 3,
                regionName: '莱山区',
                parentIdAll: '1,934,940,950',
                children: []
              },
              {
                id: 951,
                parentId: 940,
                regionType: 3,
                regionName: '莱阳市',
                parentIdAll: '1,934,940,951',
                children: []
              },
              {
                id: 952,
                parentId: 940,
                regionType: 3,
                regionName: '蓬莱市',
                parentIdAll: '1,934,940,952',
                children: []
              }
            ]
          },
          {
            id: 956,
            parentId: 934,
            regionType: 2,
            regionName: '滨州市',
            parentIdAll: '1,934,956',
            children: [
              {
                id: 957,
                parentId: 956,
                regionType: 3,
                regionName: '沾化区',
                parentIdAll: '1,934,956,957',
                children: []
              },
              {
                id: 958,
                parentId: 956,
                regionType: 3,
                regionName: '滨城区',
                parentIdAll: '1,934,956,958',
                children: []
              },
              {
                id: 959,
                parentId: 956,
                regionType: 3,
                regionName: '博兴县',
                parentIdAll: '1,934,956,959',
                children: []
              },
              {
                id: 960,
                parentId: 956,
                regionType: 3,
                regionName: '邹平县',
                parentIdAll: '1,934,956,960',
                children: []
              },
              {
                id: 961,
                parentId: 956,
                regionType: 3,
                regionName: '无棣县',
                parentIdAll: '1,934,956,961',
                children: []
              },
              {
                id: 962,
                parentId: 956,
                regionType: 3,
                regionName: '惠民县',
                parentIdAll: '1,934,956,962',
                children: []
              },
              {
                id: 963,
                parentId: 956,
                regionType: 3,
                regionName: '阳信县',
                parentIdAll: '1,934,956,963',
                children: []
              }
            ]
          },
          {
            id: 964,
            parentId: 934,
            regionType: 2,
            regionName: '临沂市',
            parentIdAll: '1,934,964',
            children: [
              {
                id: 965,
                parentId: 964,
                regionType: 3,
                regionName: '蒙阴县',
                parentIdAll: '1,934,964,965',
                children: []
              },
              {
                id: 966,
                parentId: 964,
                regionType: 3,
                regionName: '沂水县',
                parentIdAll: '1,934,964,966',
                children: []
              },
              {
                id: 967,
                parentId: 964,
                regionType: 3,
                regionName: '平邑县',
                parentIdAll: '1,934,964,967',
                children: []
              },
              {
                id: 968,
                parentId: 964,
                regionType: 3,
                regionName: '兰山区',
                parentIdAll: '1,934,964,968',
                children: []
              },
              {
                id: 969,
                parentId: 964,
                regionType: 3,
                regionName: '沂南县',
                parentIdAll: '1,934,964,969',
                children: []
              },
              {
                id: 970,
                parentId: 964,
                regionType: 3,
                regionName: '费县',
                parentIdAll: '1,934,964,970',
                children: []
              },
              {
                id: 971,
                parentId: 964,
                regionType: 3,
                regionName: '郯城县',
                parentIdAll: '1,934,964,971',
                children: []
              },
              {
                id: 972,
                parentId: 964,
                regionType: 3,
                regionName: '兰陵县',
                parentIdAll: '1,934,964,972',
                children: []
              },
              {
                id: 973,
                parentId: 964,
                regionType: 3,
                regionName: '莒南县',
                parentIdAll: '1,934,964,973',
                children: []
              },
              {
                id: 974,
                parentId: 964,
                regionType: 3,
                regionName: '罗庄区',
                parentIdAll: '1,934,964,974',
                children: []
              },
              {
                id: 975,
                parentId: 964,
                regionType: 3,
                regionName: '临沭县',
                parentIdAll: '1,934,964,975',
                children: []
              },
              {
                id: 976,
                parentId: 964,
                regionType: 3,
                regionName: '河东区',
                parentIdAll: '1,934,964,976',
                children: []
              }
            ]
          },
          {
            id: 977,
            parentId: 934,
            regionType: 2,
            regionName: '日照市',
            parentIdAll: '1,934,977',
            children: [
              {
                id: 978,
                parentId: 977,
                regionType: 3,
                regionName: '五莲县',
                parentIdAll: '1,934,977,978',
                children: []
              },
              {
                id: 979,
                parentId: 977,
                regionType: 3,
                regionName: '岚山区',
                parentIdAll: '1,934,977,979',
                children: []
              },
              {
                id: 980,
                parentId: 977,
                regionType: 3,
                regionName: '莒县',
                parentIdAll: '1,934,977,980',
                children: []
              },
              {
                id: 981,
                parentId: 977,
                regionType: 3,
                regionName: '东港区',
                parentIdAll: '1,934,977,981',
                children: []
              }
            ]
          },
          {
            id: 982,
            parentId: 934,
            regionType: 2,
            regionName: '淄博市',
            parentIdAll: '1,934,982',
            children: [
              {
                id: 983,
                parentId: 982,
                regionType: 3,
                regionName: '高青县',
                parentIdAll: '1,934,982,983',
                children: []
              },
              {
                id: 984,
                parentId: 982,
                regionType: 3,
                regionName: '临淄区',
                parentIdAll: '1,934,982,984',
                children: []
              },
              {
                id: 985,
                parentId: 982,
                regionType: 3,
                regionName: '桓台县',
                parentIdAll: '1,934,982,985',
                children: []
              },
              {
                id: 986,
                parentId: 982,
                regionType: 3,
                regionName: '周村区',
                parentIdAll: '1,934,982,986',
                children: []
              },
              {
                id: 987,
                parentId: 982,
                regionType: 3,
                regionName: '张店区',
                parentIdAll: '1,934,982,987',
                children: []
              },
              {
                id: 988,
                parentId: 982,
                regionType: 3,
                regionName: '淄川区',
                parentIdAll: '1,934,982,988',
                children: []
              },
              {
                id: 989,
                parentId: 982,
                regionType: 3,
                regionName: '沂源县',
                parentIdAll: '1,934,982,989',
                children: []
              },
              {
                id: 990,
                parentId: 982,
                regionType: 3,
                regionName: '博山区',
                parentIdAll: '1,934,982,990',
                children: []
              }
            ]
          },
          {
            id: 991,
            parentId: 934,
            regionType: 2,
            regionName: '德州市',
            parentIdAll: '1,934,991',
            children: [
              {
                id: 992,
                parentId: 991,
                regionType: 3,
                regionName: '宁津县',
                parentIdAll: '1,934,991,992',
                children: []
              },
              {
                id: 993,
                parentId: 991,
                regionType: 3,
                regionName: '陵城区',
                parentIdAll: '1,934,991,993',
                children: []
              },
              {
                id: 994,
                parentId: 991,
                regionType: 3,
                regionName: '庆云县',
                parentIdAll: '1,934,991,994',
                children: []
              },
              {
                id: 995,
                parentId: 991,
                regionType: 3,
                regionName: '乐陵市',
                parentIdAll: '1,934,991,995',
                children: []
              },
              {
                id: 996,
                parentId: 991,
                regionType: 3,
                regionName: '德城区',
                parentIdAll: '1,934,991,996',
                children: []
              },
              {
                id: 997,
                parentId: 991,
                regionType: 3,
                regionName: '临邑县',
                parentIdAll: '1,934,991,997',
                children: []
              },
              {
                id: 998,
                parentId: 991,
                regionType: 3,
                regionName: '武城县',
                parentIdAll: '1,934,991,998',
                children: []
              },
              {
                id: 999,
                parentId: 991,
                regionType: 3,
                regionName: '禹城市',
                parentIdAll: '1,934,991,999',
                children: []
              },
              {
                id: 1000,
                parentId: 991,
                regionType: 3,
                regionName: '平原县',
                parentIdAll: '1,934,991,1000',
                children: []
              },
              {
                id: 1001,
                parentId: 991,
                regionType: 3,
                regionName: '夏津县',
                parentIdAll: '1,934,991,1001',
                children: []
              },
              {
                id: 1002,
                parentId: 991,
                regionType: 3,
                regionName: '齐河县',
                parentIdAll: '1,934,991,1002',
                children: []
              }
            ]
          },
          {
            id: 1003,
            parentId: 934,
            regionType: 2,
            regionName: '青岛市',
            parentIdAll: '1,934,1003',
            children: [
              {
                id: 1004,
                parentId: 1003,
                regionType: 3,
                regionName: '莱西市',
                parentIdAll: '1,934,1003,1004',
                children: []
              },
              {
                id: 1005,
                parentId: 1003,
                regionType: 3,
                regionName: '平度市',
                parentIdAll: '1,934,1003,1005',
                children: []
              },
              {
                id: 1006,
                parentId: 1003,
                regionType: 3,
                regionName: '即墨区',
                parentIdAll: '1,934,1003,1006',
                children: []
              },
              {
                id: 1007,
                parentId: 1003,
                regionType: 3,
                regionName: '崂山区',
                parentIdAll: '1,934,1003,1007',
                children: []
              },
              {
                id: 1008,
                parentId: 1003,
                regionType: 3,
                regionName: '城阳区',
                parentIdAll: '1,934,1003,1008',
                children: []
              },
              {
                id: 1009,
                parentId: 1003,
                regionType: 3,
                regionName: '李沧区',
                parentIdAll: '1,934,1003,1009',
                children: []
              },
              {
                id: 1010,
                parentId: 1003,
                regionType: 3,
                regionName: '黄岛区',
                parentIdAll: '1,934,1003,1010',
                children: []
              },
              {
                id: 1011,
                parentId: 1003,
                regionType: 3,
                regionName: '市南区',
                parentIdAll: '1,934,1003,1011',
                children: []
              },
              {
                id: 1012,
                parentId: 1003,
                regionType: 3,
                regionName: '胶州市',
                parentIdAll: '1,934,1003,1012',
                children: []
              },
              {
                id: 1013,
                parentId: 1003,
                regionType: 3,
                regionName: '市北区',
                parentIdAll: '1,934,1003,1013',
                children: []
              }
            ]
          },
          {
            id: 1014,
            parentId: 934,
            regionType: 2,
            regionName: '聊城市',
            parentIdAll: '1,934,1014',
            children: [
              {
                id: 1015,
                parentId: 1014,
                regionType: 3,
                regionName: '茌平县',
                parentIdAll: '1,934,1014,1015',
                children: []
              },
              {
                id: 1016,
                parentId: 1014,
                regionType: 3,
                regionName: '东昌府区',
                parentIdAll: '1,934,1014,1016',
                children: []
              },
              {
                id: 1017,
                parentId: 1014,
                regionType: 3,
                regionName: '东阿县',
                parentIdAll: '1,934,1014,1017',
                children: []
              },
              {
                id: 1018,
                parentId: 1014,
                regionType: 3,
                regionName: '高唐县',
                parentIdAll: '1,934,1014,1018',
                children: []
              },
              {
                id: 1019,
                parentId: 1014,
                regionType: 3,
                regionName: '冠县',
                parentIdAll: '1,934,1014,1019',
                children: []
              },
              {
                id: 1020,
                parentId: 1014,
                regionType: 3,
                regionName: '临清市',
                parentIdAll: '1,934,1014,1020',
                children: []
              },
              {
                id: 1021,
                parentId: 1014,
                regionType: 3,
                regionName: '莘县',
                parentIdAll: '1,934,1014,1021',
                children: []
              },
              {
                id: 1022,
                parentId: 1014,
                regionType: 3,
                regionName: '阳谷县',
                parentIdAll: '1,934,1014,1022',
                children: []
              }
            ]
          },
          {
            id: 1023,
            parentId: 934,
            regionType: 2,
            regionName: '菏泽市',
            parentIdAll: '1,934,1023',
            children: [
              {
                id: 1024,
                parentId: 1023,
                regionType: 3,
                regionName: '牡丹区',
                parentIdAll: '1,934,1023,1024',
                children: []
              },
              {
                id: 1025,
                parentId: 1023,
                regionType: 3,
                regionName: '鄄城县',
                parentIdAll: '1,934,1023,1025',
                children: []
              },
              {
                id: 1026,
                parentId: 1023,
                regionType: 3,
                regionName: '定陶区',
                parentIdAll: '1,934,1023,1026',
                children: []
              },
              {
                id: 1027,
                parentId: 1023,
                regionType: 3,
                regionName: '东明县',
                parentIdAll: '1,934,1023,1027',
                children: []
              },
              {
                id: 1028,
                parentId: 1023,
                regionType: 3,
                regionName: '曹县',
                parentIdAll: '1,934,1023,1028',
                children: []
              },
              {
                id: 1029,
                parentId: 1023,
                regionType: 3,
                regionName: '成武县',
                parentIdAll: '1,934,1023,1029',
                children: []
              },
              {
                id: 1030,
                parentId: 1023,
                regionType: 3,
                regionName: '单县',
                parentIdAll: '1,934,1023,1030',
                children: []
              },
              {
                id: 1031,
                parentId: 1023,
                regionType: 3,
                regionName: '郓城县',
                parentIdAll: '1,934,1023,1031',
                children: []
              },
              {
                id: 1032,
                parentId: 1023,
                regionType: 3,
                regionName: '巨野县',
                parentIdAll: '1,934,1023,1032',
                children: []
              }
            ]
          },
          {
            id: 1033,
            parentId: 934,
            regionType: 2,
            regionName: '潍坊市',
            parentIdAll: '1,934,1033',
            children: [
              {
                id: 1034,
                parentId: 1033,
                regionType: 3,
                regionName: '安丘市',
                parentIdAll: '1,934,1033,1034',
                children: []
              },
              {
                id: 1035,
                parentId: 1033,
                regionType: 3,
                regionName: '昌乐县',
                parentIdAll: '1,934,1033,1035',
                children: []
              },
              {
                id: 1036,
                parentId: 1033,
                regionType: 3,
                regionName: '昌邑市',
                parentIdAll: '1,934,1033,1036',
                children: []
              },
              {
                id: 1037,
                parentId: 1033,
                regionType: 3,
                regionName: '坊子区',
                parentIdAll: '1,934,1033,1037',
                children: []
              },
              {
                id: 1038,
                parentId: 1033,
                regionType: 3,
                regionName: '高密市',
                parentIdAll: '1,934,1033,1038',
                children: []
              },
              {
                id: 1039,
                parentId: 1033,
                regionType: 3,
                regionName: '寒亭区',
                parentIdAll: '1,934,1033,1039',
                children: []
              },
              {
                id: 1040,
                parentId: 1033,
                regionType: 3,
                regionName: '奎文区',
                parentIdAll: '1,934,1033,1040',
                children: []
              },
              {
                id: 1041,
                parentId: 1033,
                regionType: 3,
                regionName: '临朐县',
                parentIdAll: '1,934,1033,1041',
                children: []
              },
              {
                id: 1042,
                parentId: 1033,
                regionType: 3,
                regionName: '青州市',
                parentIdAll: '1,934,1033,1042',
                children: []
              },
              {
                id: 1043,
                parentId: 1033,
                regionType: 3,
                regionName: '寿光市',
                parentIdAll: '1,934,1033,1043',
                children: []
              },
              {
                id: 1044,
                parentId: 1033,
                regionType: 3,
                regionName: '潍城区',
                parentIdAll: '1,934,1033,1044',
                children: []
              },
              {
                id: 1045,
                parentId: 1033,
                regionType: 3,
                regionName: '诸城市',
                parentIdAll: '1,934,1033,1045',
                children: []
              }
            ]
          },
          {
            id: 1046,
            parentId: 934,
            regionType: 2,
            regionName: '济南市',
            parentIdAll: '1,934,1046',
            children: [
              {
                id: 953,
                parentId: 1046,
                regionType: 3,
                regionName: '莱芜区',
                parentIdAll: '1,934,1046,953',
                children: [
                  {
                    id: 954,
                    parentId: 953,
                    regionType: 3,
                    regionName: '莱城区',
                    parentIdAll: '1,934,953,954',
                    children: []
                  }
                ]
              },
              {
                id: 955,
                parentId: 1046,
                regionType: 3,
                regionName: '钢城区',
                parentIdAll: '1,934,1046,955',
                children: []
              },
              {
                id: 1047,
                parentId: 1046,
                regionType: 3,
                regionName: '槐荫区',
                parentIdAll: '1,934,1046,1047',
                children: []
              },
              {
                id: 1048,
                parentId: 1046,
                regionType: 3,
                regionName: '济阳县',
                parentIdAll: '1,934,1046,1048',
                children: []
              },
              {
                id: 1049,
                parentId: 1046,
                regionType: 3,
                regionName: '历下区',
                parentIdAll: '1,934,1046,1049',
                children: []
              },
              {
                id: 1050,
                parentId: 1046,
                regionType: 3,
                regionName: '历城区',
                parentIdAll: '1,934,1046,1050',
                children: []
              },
              {
                id: 1051,
                parentId: 1046,
                regionType: 3,
                regionName: '平阴县',
                parentIdAll: '1,934,1046,1051',
                children: []
              },
              {
                id: 1052,
                parentId: 1046,
                regionType: 3,
                regionName: '商河县',
                parentIdAll: '1,934,1046,1052',
                children: []
              },
              {
                id: 1053,
                parentId: 1046,
                regionType: 3,
                regionName: '市中区',
                parentIdAll: '1,934,1046,1053',
                children: []
              },
              {
                id: 1054,
                parentId: 1046,
                regionType: 3,
                regionName: '天桥区',
                parentIdAll: '1,934,1046,1054',
                children: []
              },
              {
                id: 1055,
                parentId: 1046,
                regionType: 3,
                regionName: '章丘区',
                parentIdAll: '1,934,1046,1055',
                children: []
              },
              {
                id: 1056,
                parentId: 1046,
                regionType: 3,
                regionName: '长清区',
                parentIdAll: '1,934,1046,1056',
                children: []
              }
            ]
          },
          {
            id: 1057,
            parentId: 934,
            regionType: 2,
            regionName: '泰安市',
            parentIdAll: '1,934,1057',
            children: [
              {
                id: 1058,
                parentId: 1057,
                regionType: 3,
                regionName: '岱岳区',
                parentIdAll: '1,934,1057,1058',
                children: []
              },
              {
                id: 1059,
                parentId: 1057,
                regionType: 3,
                regionName: '东平县',
                parentIdAll: '1,934,1057,1059',
                children: []
              },
              {
                id: 1060,
                parentId: 1057,
                regionType: 3,
                regionName: '新泰市',
                parentIdAll: '1,934,1057,1060',
                children: []
              },
              {
                id: 1061,
                parentId: 1057,
                regionType: 3,
                regionName: '泰山区',
                parentIdAll: '1,934,1057,1061',
                children: []
              },
              {
                id: 1062,
                parentId: 1057,
                regionType: 3,
                regionName: '肥城市',
                parentIdAll: '1,934,1057,1062',
                children: []
              },
              {
                id: 1063,
                parentId: 1057,
                regionType: 3,
                regionName: '宁阳县',
                parentIdAll: '1,934,1057,1063',
                children: []
              }
            ]
          },
          {
            id: 1064,
            parentId: 934,
            regionType: 2,
            regionName: '东营市',
            parentIdAll: '1,934,1064',
            children: [
              {
                id: 1065,
                parentId: 1064,
                regionType: 3,
                regionName: '利津县',
                parentIdAll: '1,934,1064,1065',
                children: []
              },
              {
                id: 1066,
                parentId: 1064,
                regionType: 3,
                regionName: '广饶县',
                parentIdAll: '1,934,1064,1066',
                children: []
              },
              {
                id: 1067,
                parentId: 1064,
                regionType: 3,
                regionName: '东营区',
                parentIdAll: '1,934,1064,1067',
                children: []
              },
              {
                id: 1068,
                parentId: 1064,
                regionType: 3,
                regionName: '垦利区',
                parentIdAll: '1,934,1064,1068',
                children: []
              },
              {
                id: 1069,
                parentId: 1064,
                regionType: 3,
                regionName: '河口区',
                parentIdAll: '1,934,1064,1069',
                children: []
              }
            ]
          },
          {
            id: 1070,
            parentId: 934,
            regionType: 2,
            regionName: '济宁市',
            parentIdAll: '1,934,1070',
            children: [
              {
                id: 1071,
                parentId: 1070,
                regionType: 3,
                regionName: '梁山县',
                parentIdAll: '1,934,1070,1071',
                children: []
              },
              {
                id: 1072,
                parentId: 1070,
                regionType: 3,
                regionName: '曲阜市',
                parentIdAll: '1,934,1070,1072',
                children: []
              },
              {
                id: 1073,
                parentId: 1070,
                regionType: 3,
                regionName: '兖州区',
                parentIdAll: '1,934,1070,1073',
                children: []
              },
              {
                id: 1074,
                parentId: 1070,
                regionType: 3,
                regionName: '邹城市',
                parentIdAll: '1,934,1070,1074',
                children: []
              },
              {
                id: 1075,
                parentId: 1070,
                regionType: 3,
                regionName: '汶上县',
                parentIdAll: '1,934,1070,1075',
                children: []
              },
              {
                id: 1076,
                parentId: 1070,
                regionType: 3,
                regionName: '泗水县',
                parentIdAll: '1,934,1070,1076',
                children: []
              },
              {
                id: 1077,
                parentId: 1070,
                regionType: 3,
                regionName: '鱼台县',
                parentIdAll: '1,934,1070,1077',
                children: []
              },
              {
                id: 1078,
                parentId: 1070,
                regionType: 3,
                regionName: '金乡县',
                parentIdAll: '1,934,1070,1078',
                children: []
              },
              {
                id: 1079,
                parentId: 1070,
                regionType: 3,
                regionName: '任城区',
                parentIdAll: '1,934,1070,1079',
                children: []
              },
              {
                id: 1080,
                parentId: 1070,
                regionType: 3,
                regionName: '嘉祥县',
                parentIdAll: '1,934,1070,1080',
                children: []
              },
              {
                id: 1081,
                parentId: 1070,
                regionType: 3,
                regionName: '微山县',
                parentIdAll: '1,934,1070,1081',
                children: []
              }
            ]
          },
          {
            id: 1082,
            parentId: 934,
            regionType: 2,
            regionName: '枣庄市',
            parentIdAll: '1,934,1082',
            children: [
              {
                id: 1083,
                parentId: 1082,
                regionType: 3,
                regionName: '山亭区',
                parentIdAll: '1,934,1082,1083',
                children: []
              },
              {
                id: 1084,
                parentId: 1082,
                regionType: 3,
                regionName: '市中区',
                parentIdAll: '1,934,1082,1084',
                children: []
              },
              {
                id: 1085,
                parentId: 1082,
                regionType: 3,
                regionName: '台儿庄区',
                parentIdAll: '1,934,1082,1085',
                children: []
              },
              {
                id: 1086,
                parentId: 1082,
                regionType: 3,
                regionName: '滕州市',
                parentIdAll: '1,934,1082,1086',
                children: []
              },
              {
                id: 1087,
                parentId: 1082,
                regionType: 3,
                regionName: '薛城区',
                parentIdAll: '1,934,1082,1087',
                children: []
              },
              {
                id: 1088,
                parentId: 1082,
                regionType: 3,
                regionName: '峄城区',
                parentIdAll: '1,934,1082,1088',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1089,
        parentId: 1,
        regionType: 1,
        regionName: '陕西省',
        parentIdAll: '1,1089',
        children: [
          {
            id: 1090,
            parentId: 1089,
            regionType: 2,
            regionName: '商洛市',
            parentIdAll: '1,1089,1090',
            children: [
              {
                id: 1091,
                parentId: 1090,
                regionType: 3,
                regionName: '洛南县',
                parentIdAll: '1,1089,1090,1091',
                children: []
              },
              {
                id: 1092,
                parentId: 1090,
                regionType: 3,
                regionName: '柞水县',
                parentIdAll: '1,1089,1090,1092',
                children: []
              },
              {
                id: 1093,
                parentId: 1090,
                regionType: 3,
                regionName: '丹凤县',
                parentIdAll: '1,1089,1090,1093',
                children: []
              },
              {
                id: 1094,
                parentId: 1090,
                regionType: 3,
                regionName: '商州区',
                parentIdAll: '1,1089,1090,1094',
                children: []
              },
              {
                id: 1095,
                parentId: 1090,
                regionType: 3,
                regionName: '商南县',
                parentIdAll: '1,1089,1090,1095',
                children: []
              },
              {
                id: 1096,
                parentId: 1090,
                regionType: 3,
                regionName: '山阳县',
                parentIdAll: '1,1089,1090,1096',
                children: []
              },
              {
                id: 1097,
                parentId: 1090,
                regionType: 3,
                regionName: '镇安县',
                parentIdAll: '1,1089,1090,1097',
                children: []
              }
            ]
          },
          {
            id: 1098,
            parentId: 1089,
            regionType: 2,
            regionName: '西安市',
            parentIdAll: '1,1089,1098',
            children: [
              {
                id: 1099,
                parentId: 1098,
                regionType: 3,
                regionName: '高陵区',
                parentIdAll: '1,1089,1098,1099',
                children: []
              },
              {
                id: 1100,
                parentId: 1098,
                regionType: 3,
                regionName: '临潼区',
                parentIdAll: '1,1089,1098,1100',
                children: []
              },
              {
                id: 1101,
                parentId: 1098,
                regionType: 3,
                regionName: '阎良区',
                parentIdAll: '1,1089,1098,1101',
                children: []
              },
              {
                id: 1102,
                parentId: 1098,
                regionType: 3,
                regionName: '长安区',
                parentIdAll: '1,1089,1098,1102',
                children: []
              },
              {
                id: 1103,
                parentId: 1098,
                regionType: 3,
                regionName: '鄠邑区',
                parentIdAll: '1,1089,1098,1103',
                children: []
              },
              {
                id: 1104,
                parentId: 1098,
                regionType: 3,
                regionName: '蓝田县',
                parentIdAll: '1,1089,1098,1104',
                children: []
              },
              {
                id: 1105,
                parentId: 1098,
                regionType: 3,
                regionName: '未央区',
                parentIdAll: '1,1089,1098,1105',
                children: []
              },
              {
                id: 1106,
                parentId: 1098,
                regionType: 3,
                regionName: '碑林区',
                parentIdAll: '1,1089,1098,1106',
                children: []
              },
              {
                id: 1107,
                parentId: 1098,
                regionType: 3,
                regionName: '莲湖区',
                parentIdAll: '1,1089,1098,1107',
                children: []
              },
              {
                id: 1108,
                parentId: 1098,
                regionType: 3,
                regionName: '雁塔区',
                parentIdAll: '1,1089,1098,1108',
                children: []
              },
              {
                id: 1109,
                parentId: 1098,
                regionType: 3,
                regionName: '周至县',
                parentIdAll: '1,1089,1098,1109',
                children: []
              },
              {
                id: 1110,
                parentId: 1098,
                regionType: 3,
                regionName: '新城区',
                parentIdAll: '1,1089,1098,1110',
                children: []
              },
              {
                id: 1111,
                parentId: 1098,
                regionType: 3,
                regionName: '灞桥区',
                parentIdAll: '1,1089,1098,1111',
                children: []
              }
            ]
          },
          {
            id: 1112,
            parentId: 1089,
            regionType: 2,
            regionName: '汉中市',
            parentIdAll: '1,1089,1112',
            children: [
              {
                id: 1113,
                parentId: 1112,
                regionType: 3,
                regionName: '留坝县',
                parentIdAll: '1,1089,1112,1113',
                children: []
              },
              {
                id: 1114,
                parentId: 1112,
                regionType: 3,
                regionName: '洋县',
                parentIdAll: '1,1089,1112,1114',
                children: []
              },
              {
                id: 1115,
                parentId: 1112,
                regionType: 3,
                regionName: '城固县',
                parentIdAll: '1,1089,1112,1115',
                children: []
              },
              {
                id: 1116,
                parentId: 1112,
                regionType: 3,
                regionName: '佛坪县',
                parentIdAll: '1,1089,1112,1116',
                children: []
              },
              {
                id: 1117,
                parentId: 1112,
                regionType: 3,
                regionName: '汉台区',
                parentIdAll: '1,1089,1112,1117',
                children: []
              },
              {
                id: 1118,
                parentId: 1112,
                regionType: 3,
                regionName: '勉县',
                parentIdAll: '1,1089,1112,1118',
                children: []
              },
              {
                id: 1119,
                parentId: 1112,
                regionType: 3,
                regionName: '略阳县',
                parentIdAll: '1,1089,1112,1119',
                children: []
              },
              {
                id: 1120,
                parentId: 1112,
                regionType: 3,
                regionName: '南郑区',
                parentIdAll: '1,1089,1112,1120',
                children: []
              },
              {
                id: 1121,
                parentId: 1112,
                regionType: 3,
                regionName: '西乡县',
                parentIdAll: '1,1089,1112,1121',
                children: []
              },
              {
                id: 1122,
                parentId: 1112,
                regionType: 3,
                regionName: '镇巴县',
                parentIdAll: '1,1089,1112,1122',
                children: []
              },
              {
                id: 1123,
                parentId: 1112,
                regionType: 3,
                regionName: '宁强县',
                parentIdAll: '1,1089,1112,1123',
                children: []
              }
            ]
          },
          {
            id: 1124,
            parentId: 1089,
            regionType: 2,
            regionName: '安康市',
            parentIdAll: '1,1089,1124',
            children: [
              {
                id: 1125,
                parentId: 1124,
                regionType: 3,
                regionName: '宁陕县',
                parentIdAll: '1,1089,1124,1125',
                children: []
              },
              {
                id: 1126,
                parentId: 1124,
                regionType: 3,
                regionName: '石泉县',
                parentIdAll: '1,1089,1124,1126',
                children: []
              },
              {
                id: 1127,
                parentId: 1124,
                regionType: 3,
                regionName: '汉滨区',
                parentIdAll: '1,1089,1124,1127',
                children: []
              },
              {
                id: 1128,
                parentId: 1124,
                regionType: 3,
                regionName: '旬阳县',
                parentIdAll: '1,1089,1124,1128',
                children: []
              },
              {
                id: 1129,
                parentId: 1124,
                regionType: 3,
                regionName: '汉阴县',
                parentIdAll: '1,1089,1124,1129',
                children: []
              },
              {
                id: 1130,
                parentId: 1124,
                regionType: 3,
                regionName: '紫阳县',
                parentIdAll: '1,1089,1124,1130',
                children: []
              },
              {
                id: 1131,
                parentId: 1124,
                regionType: 3,
                regionName: '白河县',
                parentIdAll: '1,1089,1124,1131',
                children: []
              },
              {
                id: 1132,
                parentId: 1124,
                regionType: 3,
                regionName: '镇坪县',
                parentIdAll: '1,1089,1124,1132',
                children: []
              },
              {
                id: 1133,
                parentId: 1124,
                regionType: 3,
                regionName: '平利县',
                parentIdAll: '1,1089,1124,1133',
                children: []
              },
              {
                id: 1134,
                parentId: 1124,
                regionType: 3,
                regionName: '岚皋县',
                parentIdAll: '1,1089,1124,1134',
                children: []
              }
            ]
          },
          {
            id: 1135,
            parentId: 1089,
            regionType: 2,
            regionName: '铜川市',
            parentIdAll: '1,1089,1135',
            children: [
              {
                id: 1136,
                parentId: 1135,
                regionType: 3,
                regionName: '耀州区',
                parentIdAll: '1,1089,1135,1136',
                children: []
              },
              {
                id: 1137,
                parentId: 1135,
                regionType: 3,
                regionName: '宜君县',
                parentIdAll: '1,1089,1135,1137',
                children: []
              },
              {
                id: 1138,
                parentId: 1135,
                regionType: 3,
                regionName: '王益区',
                parentIdAll: '1,1089,1135,1138',
                children: []
              },
              {
                id: 1139,
                parentId: 1135,
                regionType: 3,
                regionName: '印台区',
                parentIdAll: '1,1089,1135,1139',
                children: []
              }
            ]
          },
          {
            id: 1140,
            parentId: 1089,
            regionType: 2,
            regionName: '榆林市',
            parentIdAll: '1,1089,1140',
            children: [
              {
                id: 1141,
                parentId: 1140,
                regionType: 3,
                regionName: '神木市',
                parentIdAll: '1,1089,1140,1141',
                children: []
              },
              {
                id: 1142,
                parentId: 1140,
                regionType: 3,
                regionName: '府谷县',
                parentIdAll: '1,1089,1140,1142',
                children: []
              },
              {
                id: 1143,
                parentId: 1140,
                regionType: 3,
                regionName: '榆阳区',
                parentIdAll: '1,1089,1140,1143',
                children: []
              },
              {
                id: 1144,
                parentId: 1140,
                regionType: 3,
                regionName: '子洲县',
                parentIdAll: '1,1089,1140,1144',
                children: []
              },
              {
                id: 1145,
                parentId: 1140,
                regionType: 3,
                regionName: '横山区',
                parentIdAll: '1,1089,1140,1145',
                children: []
              },
              {
                id: 1146,
                parentId: 1140,
                regionType: 3,
                regionName: '靖边县',
                parentIdAll: '1,1089,1140,1146',
                children: []
              },
              {
                id: 1147,
                parentId: 1140,
                regionType: 3,
                regionName: '佳县',
                parentIdAll: '1,1089,1140,1147',
                children: []
              },
              {
                id: 1148,
                parentId: 1140,
                regionType: 3,
                regionName: '清涧县',
                parentIdAll: '1,1089,1140,1148',
                children: []
              },
              {
                id: 1149,
                parentId: 1140,
                regionType: 3,
                regionName: '绥德县',
                parentIdAll: '1,1089,1140,1149',
                children: []
              },
              {
                id: 1150,
                parentId: 1140,
                regionType: 3,
                regionName: '吴堡县',
                parentIdAll: '1,1089,1140,1150',
                children: []
              },
              {
                id: 1151,
                parentId: 1140,
                regionType: 3,
                regionName: '米脂县',
                parentIdAll: '1,1089,1140,1151',
                children: []
              },
              {
                id: 1152,
                parentId: 1140,
                regionType: 3,
                regionName: '定边县',
                parentIdAll: '1,1089,1140,1152',
                children: []
              }
            ]
          },
          {
            id: 1153,
            parentId: 1089,
            regionType: 2,
            regionName: '咸阳市',
            parentIdAll: '1,1089,1153',
            children: [
              {
                id: 1154,
                parentId: 1153,
                regionType: 3,
                regionName: '旬邑县',
                parentIdAll: '1,1089,1153,1154',
                children: []
              },
              {
                id: 1155,
                parentId: 1153,
                regionType: 3,
                regionName: '长武县',
                parentIdAll: '1,1089,1153,1155',
                children: []
              },
              {
                id: 1156,
                parentId: 1153,
                regionType: 3,
                regionName: '彬县',
                parentIdAll: '1,1089,1153,1156',
                children: []
              },
              {
                id: 1157,
                parentId: 1153,
                regionType: 3,
                regionName: '永寿县',
                parentIdAll: '1,1089,1153,1157',
                children: []
              },
              {
                id: 1158,
                parentId: 1153,
                regionType: 3,
                regionName: '淳化县',
                parentIdAll: '1,1089,1153,1158',
                children: []
              },
              {
                id: 1159,
                parentId: 1153,
                regionType: 3,
                regionName: '乾县',
                parentIdAll: '1,1089,1153,1159',
                children: []
              },
              {
                id: 1160,
                parentId: 1153,
                regionType: 3,
                regionName: '礼泉县',
                parentIdAll: '1,1089,1153,1160',
                children: []
              },
              {
                id: 1161,
                parentId: 1153,
                regionType: 3,
                regionName: '三原县',
                parentIdAll: '1,1089,1153,1161',
                children: []
              },
              {
                id: 1162,
                parentId: 1153,
                regionType: 3,
                regionName: '秦都区',
                parentIdAll: '1,1089,1153,1162',
                children: []
              },
              {
                id: 1163,
                parentId: 1153,
                regionType: 3,
                regionName: '渭城区',
                parentIdAll: '1,1089,1153,1163',
                children: []
              },
              {
                id: 1164,
                parentId: 1153,
                regionType: 3,
                regionName: '兴平市',
                parentIdAll: '1,1089,1153,1164',
                children: []
              },
              {
                id: 1165,
                parentId: 1153,
                regionType: 3,
                regionName: '杨陵区',
                parentIdAll: '1,1089,1153,1165',
                children: []
              },
              {
                id: 1166,
                parentId: 1153,
                regionType: 3,
                regionName: '武功县',
                parentIdAll: '1,1089,1153,1166',
                children: []
              },
              {
                id: 1167,
                parentId: 1153,
                regionType: 3,
                regionName: '泾阳县',
                parentIdAll: '1,1089,1153,1167',
                children: []
              }
            ]
          },
          {
            id: 1168,
            parentId: 1089,
            regionType: 2,
            regionName: '宝鸡市',
            parentIdAll: '1,1089,1168',
            children: [
              {
                id: 1169,
                parentId: 1168,
                regionType: 3,
                regionName: '陇县',
                parentIdAll: '1,1089,1168,1169',
                children: []
              },
              {
                id: 1170,
                parentId: 1168,
                regionType: 3,
                regionName: '麟游县',
                parentIdAll: '1,1089,1168,1170',
                children: []
              },
              {
                id: 1171,
                parentId: 1168,
                regionType: 3,
                regionName: '千阳县',
                parentIdAll: '1,1089,1168,1171',
                children: []
              },
              {
                id: 1172,
                parentId: 1168,
                regionType: 3,
                regionName: '渭滨区',
                parentIdAll: '1,1089,1168,1172',
                children: []
              },
              {
                id: 1173,
                parentId: 1168,
                regionType: 3,
                regionName: '金台区',
                parentIdAll: '1,1089,1168,1173',
                children: []
              },
              {
                id: 1174,
                parentId: 1168,
                regionType: 3,
                regionName: '岐山县',
                parentIdAll: '1,1089,1168,1174',
                children: []
              },
              {
                id: 1175,
                parentId: 1168,
                regionType: 3,
                regionName: '凤县',
                parentIdAll: '1,1089,1168,1175',
                children: []
              },
              {
                id: 1176,
                parentId: 1168,
                regionType: 3,
                regionName: '眉县',
                parentIdAll: '1,1089,1168,1176',
                children: []
              },
              {
                id: 1177,
                parentId: 1168,
                regionType: 3,
                regionName: '扶风县',
                parentIdAll: '1,1089,1168,1177',
                children: []
              },
              {
                id: 1178,
                parentId: 1168,
                regionType: 3,
                regionName: '太白县',
                parentIdAll: '1,1089,1168,1178',
                children: []
              },
              {
                id: 1179,
                parentId: 1168,
                regionType: 3,
                regionName: '陈仓区',
                parentIdAll: '1,1089,1168,1179',
                children: []
              },
              {
                id: 1180,
                parentId: 1168,
                regionType: 3,
                regionName: '凤翔县',
                parentIdAll: '1,1089,1168,1180',
                children: []
              }
            ]
          },
          {
            id: 1181,
            parentId: 1089,
            regionType: 2,
            regionName: '延安市',
            parentIdAll: '1,1089,1181',
            children: [
              {
                id: 1182,
                parentId: 1181,
                regionType: 3,
                regionName: '吴起县',
                parentIdAll: '1,1089,1181,1182',
                children: []
              },
              {
                id: 1183,
                parentId: 1181,
                regionType: 3,
                regionName: '子长县',
                parentIdAll: '1,1089,1181,1183',
                children: []
              },
              {
                id: 1184,
                parentId: 1181,
                regionType: 3,
                regionName: '安塞区',
                parentIdAll: '1,1089,1181,1184',
                children: []
              },
              {
                id: 1185,
                parentId: 1181,
                regionType: 3,
                regionName: '延长县',
                parentIdAll: '1,1089,1181,1185',
                children: []
              },
              {
                id: 1186,
                parentId: 1181,
                regionType: 3,
                regionName: '宝塔区',
                parentIdAll: '1,1089,1181,1186',
                children: []
              },
              {
                id: 1187,
                parentId: 1181,
                regionType: 3,
                regionName: '延川县',
                parentIdAll: '1,1089,1181,1187',
                children: []
              },
              {
                id: 1188,
                parentId: 1181,
                regionType: 3,
                regionName: '志丹县',
                parentIdAll: '1,1089,1181,1188',
                children: []
              },
              {
                id: 1189,
                parentId: 1181,
                regionType: 3,
                regionName: '洛川县',
                parentIdAll: '1,1089,1181,1189',
                children: []
              },
              {
                id: 1190,
                parentId: 1181,
                regionType: 3,
                regionName: '甘泉县',
                parentIdAll: '1,1089,1181,1190',
                children: []
              },
              {
                id: 1191,
                parentId: 1181,
                regionType: 3,
                regionName: '宜川县',
                parentIdAll: '1,1089,1181,1191',
                children: []
              },
              {
                id: 1192,
                parentId: 1181,
                regionType: 3,
                regionName: '富县',
                parentIdAll: '1,1089,1181,1192',
                children: []
              },
              {
                id: 1193,
                parentId: 1181,
                regionType: 3,
                regionName: '黄陵县',
                parentIdAll: '1,1089,1181,1193',
                children: []
              },
              {
                id: 1194,
                parentId: 1181,
                regionType: 3,
                regionName: '黄龙县',
                parentIdAll: '1,1089,1181,1194',
                children: []
              }
            ]
          },
          {
            id: 1195,
            parentId: 1089,
            regionType: 2,
            regionName: '渭南市',
            parentIdAll: '1,1089,1195',
            children: [
              {
                id: 1196,
                parentId: 1195,
                regionType: 3,
                regionName: '韩城市',
                parentIdAll: '1,1089,1195,1196',
                children: []
              },
              {
                id: 1197,
                parentId: 1195,
                regionType: 3,
                regionName: '白水县',
                parentIdAll: '1,1089,1195,1197',
                children: []
              },
              {
                id: 1198,
                parentId: 1195,
                regionType: 3,
                regionName: '富平县',
                parentIdAll: '1,1089,1195,1198',
                children: []
              },
              {
                id: 1199,
                parentId: 1195,
                regionType: 3,
                regionName: '大荔县',
                parentIdAll: '1,1089,1195,1199',
                children: []
              },
              {
                id: 1200,
                parentId: 1195,
                regionType: 3,
                regionName: '临渭区',
                parentIdAll: '1,1089,1195,1200',
                children: []
              },
              {
                id: 1201,
                parentId: 1195,
                regionType: 3,
                regionName: '华州区',
                parentIdAll: '1,1089,1195,1201',
                children: []
              },
              {
                id: 1202,
                parentId: 1195,
                regionType: 3,
                regionName: '华阴市',
                parentIdAll: '1,1089,1195,1202',
                children: []
              },
              {
                id: 1203,
                parentId: 1195,
                regionType: 3,
                regionName: '潼关县',
                parentIdAll: '1,1089,1195,1203',
                children: []
              },
              {
                id: 1204,
                parentId: 1195,
                regionType: 3,
                regionName: '合阳县',
                parentIdAll: '1,1089,1195,1204',
                children: []
              },
              {
                id: 1205,
                parentId: 1195,
                regionType: 3,
                regionName: '澄城县',
                parentIdAll: '1,1089,1195,1205',
                children: []
              },
              {
                id: 1206,
                parentId: 1195,
                regionType: 3,
                regionName: '蒲城县',
                parentIdAll: '1,1089,1195,1206',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1207,
        parentId: 1,
        regionType: 1,
        regionName: '上海市',
        parentIdAll: '1,1207',
        children: [
          {
            id: 1208,
            parentId: 1207,
            regionType: 2,
            regionName: '上海城区',
            parentIdAll: '1,1207,1208',
            children: [
              {
                id: 1209,
                parentId: 1208,
                regionType: 3,
                regionName: '崇明区',
                parentIdAll: '1,1207,1208,1209',
                children: []
              },
              {
                id: 1210,
                parentId: 1208,
                regionType: 3,
                regionName: '奉贤区',
                parentIdAll: '1,1207,1208,1210',
                children: []
              },
              {
                id: 1211,
                parentId: 1208,
                regionType: 3,
                regionName: '浦东新区',
                parentIdAll: '1,1207,1208,1211',
                children: []
              },
              {
                id: 1212,
                parentId: 1208,
                regionType: 3,
                regionName: '金山区',
                parentIdAll: '1,1207,1208,1212',
                children: []
              },
              {
                id: 1213,
                parentId: 1208,
                regionType: 3,
                regionName: '普陀区',
                parentIdAll: '1,1207,1208,1213',
                children: []
              },
              {
                id: 1214,
                parentId: 1208,
                regionType: 3,
                regionName: '宝山区',
                parentIdAll: '1,1207,1208,1214',
                children: []
              },
              {
                id: 1215,
                parentId: 1208,
                regionType: 3,
                regionName: '嘉定区',
                parentIdAll: '1,1207,1208,1215',
                children: []
              },
              {
                id: 1216,
                parentId: 1208,
                regionType: 3,
                regionName: '黄浦区',
                parentIdAll: '1,1207,1208,1216',
                children: []
              },
              {
                id: 1217,
                parentId: 1208,
                regionType: 3,
                regionName: '杨浦区',
                parentIdAll: '1,1207,1208,1217',
                children: []
              },
              {
                id: 1218,
                parentId: 1208,
                regionType: 3,
                regionName: '闵行区',
                parentIdAll: '1,1207,1208,1218',
                children: []
              },
              {
                id: 1219,
                parentId: 1208,
                regionType: 3,
                regionName: '徐汇区',
                parentIdAll: '1,1207,1208,1219',
                children: []
              },
              {
                id: 1220,
                parentId: 1208,
                regionType: 3,
                regionName: '青浦区',
                parentIdAll: '1,1207,1208,1220',
                children: []
              },
              {
                id: 1221,
                parentId: 1208,
                regionType: 3,
                regionName: '松江区',
                parentIdAll: '1,1207,1208,1221',
                children: []
              },
              {
                id: 1222,
                parentId: 1208,
                regionType: 3,
                regionName: '虹口区',
                parentIdAll: '1,1207,1208,1222',
                children: []
              },
              {
                id: 1223,
                parentId: 1208,
                regionType: 3,
                regionName: '静安区',
                parentIdAll: '1,1207,1208,1223',
                children: []
              },
              {
                id: 1224,
                parentId: 1208,
                regionType: 3,
                regionName: '长宁区',
                parentIdAll: '1,1207,1208,1224',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1225,
        parentId: 1,
        regionType: 1,
        regionName: '贵州省',
        parentIdAll: '1,1225',
        children: [
          {
            id: 1226,
            parentId: 1225,
            regionType: 2,
            regionName: '铜仁市',
            parentIdAll: '1,1225,1226',
            children: [
              {
                id: 1227,
                parentId: 1226,
                regionType: 3,
                regionName: '印江土家族苗族自治县',
                parentIdAll: '1,1225,1226,1227',
                children: []
              },
              {
                id: 1228,
                parentId: 1226,
                regionType: 3,
                regionName: '石阡县',
                parentIdAll: '1,1225,1226,1228',
                children: []
              },
              {
                id: 1229,
                parentId: 1226,
                regionType: 3,
                regionName: '玉屏侗族自治县',
                parentIdAll: '1,1225,1226,1229',
                children: []
              },
              {
                id: 1230,
                parentId: 1226,
                regionType: 3,
                regionName: '碧江区',
                parentIdAll: '1,1225,1226,1230',
                children: []
              },
              {
                id: 1231,
                parentId: 1226,
                regionType: 3,
                regionName: '沿河土家族自治县',
                parentIdAll: '1,1225,1226,1231',
                children: []
              },
              {
                id: 1232,
                parentId: 1226,
                regionType: 3,
                regionName: '思南县',
                parentIdAll: '1,1225,1226,1232',
                children: []
              },
              {
                id: 1233,
                parentId: 1226,
                regionType: 3,
                regionName: '德江县',
                parentIdAll: '1,1225,1226,1233',
                children: []
              },
              {
                id: 1234,
                parentId: 1226,
                regionType: 3,
                regionName: '江口县',
                parentIdAll: '1,1225,1226,1234',
                children: []
              },
              {
                id: 1235,
                parentId: 1226,
                regionType: 3,
                regionName: '万山区',
                parentIdAll: '1,1225,1226,1235',
                children: []
              },
              {
                id: 1236,
                parentId: 1226,
                regionType: 3,
                regionName: '松桃苗族自治县',
                parentIdAll: '1,1225,1226,1236',
                children: []
              }
            ]
          },
          {
            id: 1237,
            parentId: 1225,
            regionType: 2,
            regionName: '六盘水市',
            parentIdAll: '1,1225,1237',
            children: [
              {
                id: 1238,
                parentId: 1237,
                regionType: 3,
                regionName: '钟山区',
                parentIdAll: '1,1225,1237,1238',
                children: []
              },
              {
                id: 1239,
                parentId: 1237,
                regionType: 3,
                regionName: '六枝特区',
                parentIdAll: '1,1225,1237,1239',
                children: []
              },
              {
                id: 1240,
                parentId: 1237,
                regionType: 3,
                regionName: '盘州市',
                parentIdAll: '1,1225,1237,1240',
                children: []
              },
              {
                id: 1241,
                parentId: 1237,
                regionType: 3,
                regionName: '水城县',
                parentIdAll: '1,1225,1237,1241',
                children: []
              }
            ]
          },
          {
            id: 1242,
            parentId: 1225,
            regionType: 2,
            regionName: '黔东南苗族侗族自治州',
            parentIdAll: '1,1225,1242',
            children: [
              {
                id: 1243,
                parentId: 1242,
                regionType: 3,
                regionName: '剑河县',
                parentIdAll: '1,1225,1242,1243',
                children: []
              },
              {
                id: 1244,
                parentId: 1242,
                regionType: 3,
                regionName: '锦屏县',
                parentIdAll: '1,1225,1242,1244',
                children: []
              },
              {
                id: 1245,
                parentId: 1242,
                regionType: 3,
                regionName: '三穗县',
                parentIdAll: '1,1225,1242,1245',
                children: []
              },
              {
                id: 1246,
                parentId: 1242,
                regionType: 3,
                regionName: '天柱县',
                parentIdAll: '1,1225,1242,1246',
                children: []
              },
              {
                id: 1247,
                parentId: 1242,
                regionType: 3,
                regionName: '从江县',
                parentIdAll: '1,1225,1242,1247',
                children: []
              },
              {
                id: 1248,
                parentId: 1242,
                regionType: 3,
                regionName: '黎平县',
                parentIdAll: '1,1225,1242,1248',
                children: []
              },
              {
                id: 1249,
                parentId: 1242,
                regionType: 3,
                regionName: '榕江县',
                parentIdAll: '1,1225,1242,1249',
                children: []
              },
              {
                id: 1250,
                parentId: 1242,
                regionType: 3,
                regionName: '雷山县',
                parentIdAll: '1,1225,1242,1250',
                children: []
              },
              {
                id: 1251,
                parentId: 1242,
                regionType: 3,
                regionName: '施秉县',
                parentIdAll: '1,1225,1242,1251',
                children: []
              },
              {
                id: 1252,
                parentId: 1242,
                regionType: 3,
                regionName: '镇远县',
                parentIdAll: '1,1225,1242,1252',
                children: []
              },
              {
                id: 1253,
                parentId: 1242,
                regionType: 3,
                regionName: '丹寨县',
                parentIdAll: '1,1225,1242,1253',
                children: []
              },
              {
                id: 1254,
                parentId: 1242,
                regionType: 3,
                regionName: '凯里市',
                parentIdAll: '1,1225,1242,1254',
                children: []
              },
              {
                id: 1255,
                parentId: 1242,
                regionType: 3,
                regionName: '麻江县',
                parentIdAll: '1,1225,1242,1255',
                children: []
              },
              {
                id: 1256,
                parentId: 1242,
                regionType: 3,
                regionName: '黄平县',
                parentIdAll: '1,1225,1242,1256',
                children: []
              },
              {
                id: 1257,
                parentId: 1242,
                regionType: 3,
                regionName: '台江县',
                parentIdAll: '1,1225,1242,1257',
                children: []
              },
              {
                id: 1258,
                parentId: 1242,
                regionType: 3,
                regionName: '岑巩县',
                parentIdAll: '1,1225,1242,1258',
                children: []
              }
            ]
          },
          {
            id: 1259,
            parentId: 1225,
            regionType: 2,
            regionName: '遵义市',
            parentIdAll: '1,1225,1259',
            children: [
              {
                id: 1260,
                parentId: 1259,
                regionType: 3,
                regionName: '湄潭县',
                parentIdAll: '1,1225,1259,1260',
                children: []
              },
              {
                id: 1261,
                parentId: 1259,
                regionType: 3,
                regionName: '桐梓县',
                parentIdAll: '1,1225,1259,1261',
                children: []
              },
              {
                id: 1262,
                parentId: 1259,
                regionType: 3,
                regionName: '绥阳县',
                parentIdAll: '1,1225,1259,1262',
                children: []
              },
              {
                id: 1263,
                parentId: 1259,
                regionType: 3,
                regionName: '余庆县',
                parentIdAll: '1,1225,1259,1263',
                children: []
              },
              {
                id: 1264,
                parentId: 1259,
                regionType: 3,
                regionName: '红花岗区',
                parentIdAll: '1,1225,1259,1264',
                children: []
              },
              {
                id: 1265,
                parentId: 1259,
                regionType: 3,
                regionName: '汇川区',
                parentIdAll: '1,1225,1259,1265',
                children: []
              },
              {
                id: 1266,
                parentId: 1259,
                regionType: 3,
                regionName: '凤冈县',
                parentIdAll: '1,1225,1259,1266',
                children: []
              },
              {
                id: 1267,
                parentId: 1259,
                regionType: 3,
                regionName: '仁怀市',
                parentIdAll: '1,1225,1259,1267',
                children: []
              },
              {
                id: 1268,
                parentId: 1259,
                regionType: 3,
                regionName: '务川仡佬族苗族自治县',
                parentIdAll: '1,1225,1259,1268',
                children: []
              },
              {
                id: 1269,
                parentId: 1259,
                regionType: 3,
                regionName: '播州区',
                parentIdAll: '1,1225,1259,1269',
                children: []
              },
              {
                id: 1270,
                parentId: 1259,
                regionType: 3,
                regionName: '赤水市',
                parentIdAll: '1,1225,1259,1270',
                children: []
              },
              {
                id: 1271,
                parentId: 1259,
                regionType: 3,
                regionName: '习水县',
                parentIdAll: '1,1225,1259,1271',
                children: []
              },
              {
                id: 1272,
                parentId: 1259,
                regionType: 3,
                regionName: '正安县',
                parentIdAll: '1,1225,1259,1272',
                children: []
              },
              {
                id: 1273,
                parentId: 1259,
                regionType: 3,
                regionName: '道真仡佬族苗族自治县',
                parentIdAll: '1,1225,1259,1273',
                children: []
              }
            ]
          },
          {
            id: 1274,
            parentId: 1225,
            regionType: 2,
            regionName: '黔南布依族苗族自治州',
            parentIdAll: '1,1225,1274',
            children: [
              {
                id: 1275,
                parentId: 1274,
                regionType: 3,
                regionName: '都匀市',
                parentIdAll: '1,1225,1274,1275',
                children: []
              },
              {
                id: 1276,
                parentId: 1274,
                regionType: 3,
                regionName: '惠水县',
                parentIdAll: '1,1225,1274,1276',
                children: []
              },
              {
                id: 1277,
                parentId: 1274,
                regionType: 3,
                regionName: '平塘县',
                parentIdAll: '1,1225,1274,1277',
                children: []
              },
              {
                id: 1278,
                parentId: 1274,
                regionType: 3,
                regionName: '独山县',
                parentIdAll: '1,1225,1274,1278',
                children: []
              },
              {
                id: 1279,
                parentId: 1274,
                regionType: 3,
                regionName: '荔波县',
                parentIdAll: '1,1225,1274,1279',
                children: []
              },
              {
                id: 1280,
                parentId: 1274,
                regionType: 3,
                regionName: '罗甸县',
                parentIdAll: '1,1225,1274,1280',
                children: []
              },
              {
                id: 1281,
                parentId: 1274,
                regionType: 3,
                regionName: '长顺县',
                parentIdAll: '1,1225,1274,1281',
                children: []
              },
              {
                id: 1282,
                parentId: 1274,
                regionType: 3,
                regionName: '龙里县',
                parentIdAll: '1,1225,1274,1282',
                children: []
              },
              {
                id: 1283,
                parentId: 1274,
                regionType: 3,
                regionName: '贵定县',
                parentIdAll: '1,1225,1274,1283',
                children: []
              },
              {
                id: 1284,
                parentId: 1274,
                regionType: 3,
                regionName: '三都水族自治县',
                parentIdAll: '1,1225,1274,1284',
                children: []
              },
              {
                id: 1285,
                parentId: 1274,
                regionType: 3,
                regionName: '福泉市',
                parentIdAll: '1,1225,1274,1285',
                children: []
              },
              {
                id: 1286,
                parentId: 1274,
                regionType: 3,
                regionName: '瓮安县',
                parentIdAll: '1,1225,1274,1286',
                children: []
              }
            ]
          },
          {
            id: 1287,
            parentId: 1225,
            regionType: 2,
            regionName: '黔西南布依族苗族自治州',
            parentIdAll: '1,1225,1287',
            children: [
              {
                id: 1288,
                parentId: 1287,
                regionType: 3,
                regionName: '安龙县',
                parentIdAll: '1,1225,1287,1288',
                children: []
              },
              {
                id: 1289,
                parentId: 1287,
                regionType: 3,
                regionName: '兴义市',
                parentIdAll: '1,1225,1287,1289',
                children: []
              },
              {
                id: 1290,
                parentId: 1287,
                regionType: 3,
                regionName: '兴仁县',
                parentIdAll: '1,1225,1287,1290',
                children: []
              },
              {
                id: 1291,
                parentId: 1287,
                regionType: 3,
                regionName: '册亨县',
                parentIdAll: '1,1225,1287,1291',
                children: []
              },
              {
                id: 1292,
                parentId: 1287,
                regionType: 3,
                regionName: '贞丰县',
                parentIdAll: '1,1225,1287,1292',
                children: []
              },
              {
                id: 1293,
                parentId: 1287,
                regionType: 3,
                regionName: '普安县',
                parentIdAll: '1,1225,1287,1293',
                children: []
              },
              {
                id: 1294,
                parentId: 1287,
                regionType: 3,
                regionName: '晴隆县',
                parentIdAll: '1,1225,1287,1294',
                children: []
              },
              {
                id: 1295,
                parentId: 1287,
                regionType: 3,
                regionName: '望谟县',
                parentIdAll: '1,1225,1287,1295',
                children: []
              }
            ]
          },
          {
            id: 1296,
            parentId: 1225,
            regionType: 2,
            regionName: '安顺市',
            parentIdAll: '1,1225,1296',
            children: [
              {
                id: 1297,
                parentId: 1296,
                regionType: 3,
                regionName: '西秀区',
                parentIdAll: '1,1225,1296,1297',
                children: []
              },
              {
                id: 1298,
                parentId: 1296,
                regionType: 3,
                regionName: '普定县',
                parentIdAll: '1,1225,1296,1298',
                children: []
              },
              {
                id: 1299,
                parentId: 1296,
                regionType: 3,
                regionName: '镇宁布依族苗族自治县',
                parentIdAll: '1,1225,1296,1299',
                children: []
              },
              {
                id: 1300,
                parentId: 1296,
                regionType: 3,
                regionName: '紫云苗族布依族自治县',
                parentIdAll: '1,1225,1296,1300',
                children: []
              },
              {
                id: 1301,
                parentId: 1296,
                regionType: 3,
                regionName: '关岭布依族苗族自治县',
                parentIdAll: '1,1225,1296,1301',
                children: []
              },
              {
                id: 1302,
                parentId: 1296,
                regionType: 3,
                regionName: '平坝区',
                parentIdAll: '1,1225,1296,1302',
                children: []
              }
            ]
          },
          {
            id: 1303,
            parentId: 1225,
            regionType: 2,
            regionName: '毕节市',
            parentIdAll: '1,1225,1303',
            children: [
              {
                id: 1304,
                parentId: 1303,
                regionType: 3,
                regionName: '七星关区',
                parentIdAll: '1,1225,1303,1304',
                children: []
              },
              {
                id: 1305,
                parentId: 1303,
                regionType: 3,
                regionName: '大方县',
                parentIdAll: '1,1225,1303,1305',
                children: []
              },
              {
                id: 1306,
                parentId: 1303,
                regionType: 3,
                regionName: '黔西县',
                parentIdAll: '1,1225,1303,1306',
                children: []
              },
              {
                id: 1307,
                parentId: 1303,
                regionType: 3,
                regionName: '金沙县',
                parentIdAll: '1,1225,1303,1307',
                children: []
              },
              {
                id: 1308,
                parentId: 1303,
                regionType: 3,
                regionName: '纳雍县',
                parentIdAll: '1,1225,1303,1308',
                children: []
              },
              {
                id: 1309,
                parentId: 1303,
                regionType: 3,
                regionName: '织金县',
                parentIdAll: '1,1225,1303,1309',
                children: []
              },
              {
                id: 1310,
                parentId: 1303,
                regionType: 3,
                regionName: '威宁彝族回族苗族自治县',
                parentIdAll: '1,1225,1303,1310',
                children: []
              },
              {
                id: 1311,
                parentId: 1303,
                regionType: 3,
                regionName: '赫章县',
                parentIdAll: '1,1225,1303,1311',
                children: []
              }
            ]
          },
          {
            id: 1312,
            parentId: 1225,
            regionType: 2,
            regionName: '贵阳市',
            parentIdAll: '1,1225,1312',
            children: [
              {
                id: 1313,
                parentId: 1312,
                regionType: 3,
                regionName: '开阳县',
                parentIdAll: '1,1225,1312,1313',
                children: []
              },
              {
                id: 1314,
                parentId: 1312,
                regionType: 3,
                regionName: '白云区',
                parentIdAll: '1,1225,1312,1314',
                children: []
              },
              {
                id: 1315,
                parentId: 1312,
                regionType: 3,
                regionName: '乌当区',
                parentIdAll: '1,1225,1312,1315',
                children: []
              },
              {
                id: 1316,
                parentId: 1312,
                regionType: 3,
                regionName: '观山湖区',
                parentIdAll: '1,1225,1312,1316',
                children: []
              },
              {
                id: 1317,
                parentId: 1312,
                regionType: 3,
                regionName: '息烽县',
                parentIdAll: '1,1225,1312,1317',
                children: []
              },
              {
                id: 1318,
                parentId: 1312,
                regionType: 3,
                regionName: '修文县',
                parentIdAll: '1,1225,1312,1318',
                children: []
              },
              {
                id: 1319,
                parentId: 1312,
                regionType: 3,
                regionName: '清镇市',
                parentIdAll: '1,1225,1312,1319',
                children: []
              },
              {
                id: 1320,
                parentId: 1312,
                regionType: 3,
                regionName: '南明区',
                parentIdAll: '1,1225,1312,1320',
                children: []
              },
              {
                id: 1321,
                parentId: 1312,
                regionType: 3,
                regionName: '花溪区',
                parentIdAll: '1,1225,1312,1321',
                children: []
              },
              {
                id: 1322,
                parentId: 1312,
                regionType: 3,
                regionName: '云岩区',
                parentIdAll: '1,1225,1312,1322',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1323,
        parentId: 1,
        regionType: 1,
        regionName: '重庆市',
        parentIdAll: '1,1323',
        children: [
          {
            id: 1324,
            parentId: 1323,
            regionType: 2,
            regionName: '重庆城区',
            parentIdAll: '1,1323,1324',
            children: [
              {
                id: 1325,
                parentId: 1324,
                regionType: 3,
                regionName: '合川区',
                parentIdAll: '1,1323,1324,1325',
                children: []
              },
              {
                id: 1326,
                parentId: 1324,
                regionType: 3,
                regionName: '潼南区',
                parentIdAll: '1,1323,1324,1326',
                children: []
              },
              {
                id: 1327,
                parentId: 1324,
                regionType: 3,
                regionName: '铜梁区',
                parentIdAll: '1,1323,1324,1327',
                children: []
              },
              {
                id: 1328,
                parentId: 1324,
                regionType: 3,
                regionName: '荣昌区',
                parentIdAll: '1,1323,1324,1328',
                children: []
              },
              {
                id: 1329,
                parentId: 1324,
                regionType: 3,
                regionName: '璧山区',
                parentIdAll: '1,1323,1324,1329',
                children: []
              },
              {
                id: 1330,
                parentId: 1324,
                regionType: 3,
                regionName: '永川区',
                parentIdAll: '1,1323,1324,1330',
                children: []
              },
              {
                id: 1331,
                parentId: 1324,
                regionType: 3,
                regionName: '大足区',
                parentIdAll: '1,1323,1324,1331',
                children: []
              },
              {
                id: 1332,
                parentId: 1324,
                regionType: 3,
                regionName: '大渡口区',
                parentIdAll: '1,1323,1324,1332',
                children: []
              },
              {
                id: 1333,
                parentId: 1324,
                regionType: 3,
                regionName: '长寿区',
                parentIdAll: '1,1323,1324,1333',
                children: []
              },
              {
                id: 1334,
                parentId: 1324,
                regionType: 3,
                regionName: '武隆区',
                parentIdAll: '1,1323,1324,1334',
                children: []
              },
              {
                id: 1335,
                parentId: 1324,
                regionType: 3,
                regionName: '江北区',
                parentIdAll: '1,1323,1324,1335',
                children: []
              },
              {
                id: 1336,
                parentId: 1324,
                regionType: 3,
                regionName: '渝中区',
                parentIdAll: '1,1323,1324,1336',
                children: []
              },
              {
                id: 1337,
                parentId: 1324,
                regionType: 3,
                regionName: '涪陵区',
                parentIdAll: '1,1323,1324,1337',
                children: []
              },
              {
                id: 1338,
                parentId: 1324,
                regionType: 3,
                regionName: '万州区',
                parentIdAll: '1,1323,1324,1338',
                children: []
              },
              {
                id: 1339,
                parentId: 1324,
                regionType: 3,
                regionName: '九龙坡区',
                parentIdAll: '1,1323,1324,1339',
                children: []
              },
              {
                id: 1340,
                parentId: 1324,
                regionType: 3,
                regionName: '渝北区',
                parentIdAll: '1,1323,1324,1340',
                children: []
              },
              {
                id: 1341,
                parentId: 1324,
                regionType: 3,
                regionName: '南川区',
                parentIdAll: '1,1323,1324,1341',
                children: []
              },
              {
                id: 1342,
                parentId: 1324,
                regionType: 3,
                regionName: '綦江区',
                parentIdAll: '1,1323,1324,1342',
                children: []
              },
              {
                id: 1343,
                parentId: 1324,
                regionType: 3,
                regionName: '梁平区',
                parentIdAll: '1,1323,1324,1343',
                children: []
              },
              {
                id: 1344,
                parentId: 1324,
                regionType: 3,
                regionName: '开州区',
                parentIdAll: '1,1323,1324,1344',
                children: []
              },
              {
                id: 1345,
                parentId: 1324,
                regionType: 3,
                regionName: '黔江区',
                parentIdAll: '1,1323,1324,1345',
                children: []
              },
              {
                id: 1346,
                parentId: 1324,
                regionType: 3,
                regionName: '江津区',
                parentIdAll: '1,1323,1324,1346',
                children: []
              },
              {
                id: 1347,
                parentId: 1324,
                regionType: 3,
                regionName: '南岸区',
                parentIdAll: '1,1323,1324,1347',
                children: []
              },
              {
                id: 1348,
                parentId: 1324,
                regionType: 3,
                regionName: '巴南区',
                parentIdAll: '1,1323,1324,1348',
                children: []
              },
              {
                id: 1349,
                parentId: 1324,
                regionType: 3,
                regionName: '沙坪坝区',
                parentIdAll: '1,1323,1324,1349',
                children: []
              },
              {
                id: 1350,
                parentId: 1324,
                regionType: 3,
                regionName: '北碚区',
                parentIdAll: '1,1323,1324,1350',
                children: []
              }
            ]
          },
          {
            id: 1351,
            parentId: 1323,
            regionType: 2,
            regionName: '重庆郊县',
            parentIdAll: '1,1323,1351',
            children: [
              {
                id: 1352,
                parentId: 1351,
                regionType: 3,
                regionName: '城口县',
                parentIdAll: '1,1323,1351,1352',
                children: []
              },
              {
                id: 1353,
                parentId: 1351,
                regionType: 3,
                regionName: '巫溪县',
                parentIdAll: '1,1323,1351,1353',
                children: []
              },
              {
                id: 1354,
                parentId: 1351,
                regionType: 3,
                regionName: '奉节县',
                parentIdAll: '1,1323,1351,1354',
                children: []
              },
              {
                id: 1355,
                parentId: 1351,
                regionType: 3,
                regionName: '丰都县',
                parentIdAll: '1,1323,1351,1355',
                children: []
              },
              {
                id: 1356,
                parentId: 1351,
                regionType: 3,
                regionName: '彭水苗族土家族自治县',
                parentIdAll: '1,1323,1351,1356',
                children: []
              },
              {
                id: 1357,
                parentId: 1351,
                regionType: 3,
                regionName: '垫江县',
                parentIdAll: '1,1323,1351,1357',
                children: []
              },
              {
                id: 1358,
                parentId: 1351,
                regionType: 3,
                regionName: '秀山土家族苗族自治县',
                parentIdAll: '1,1323,1351,1358',
                children: []
              },
              {
                id: 1359,
                parentId: 1351,
                regionType: 3,
                regionName: '云阳县',
                parentIdAll: '1,1323,1351,1359',
                children: []
              },
              {
                id: 1360,
                parentId: 1351,
                regionType: 3,
                regionName: '巫山县',
                parentIdAll: '1,1323,1351,1360',
                children: []
              },
              {
                id: 1361,
                parentId: 1351,
                regionType: 3,
                regionName: '酉阳土家族苗族自治县',
                parentIdAll: '1,1323,1351,1361',
                children: []
              },
              {
                id: 1362,
                parentId: 1351,
                regionType: 3,
                regionName: '忠县',
                parentIdAll: '1,1323,1351,1362',
                children: []
              },
              {
                id: 1363,
                parentId: 1351,
                regionType: 3,
                regionName: '石柱土家族自治县',
                parentIdAll: '1,1323,1351,1363',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1364,
        parentId: 1,
        regionType: 1,
        regionName: '西藏自治区',
        parentIdAll: '1,1364',
        children: [
          {
            id: 1365,
            parentId: 1364,
            regionType: 2,
            regionName: '拉萨市',
            parentIdAll: '1,1364,1365',
            children: [
              {
                id: 1366,
                parentId: 1365,
                regionType: 3,
                regionName: '当雄县',
                parentIdAll: '1,1364,1365,1366',
                children: []
              },
              {
                id: 1367,
                parentId: 1365,
                regionType: 3,
                regionName: '墨竹工卡县',
                parentIdAll: '1,1364,1365,1367',
                children: []
              },
              {
                id: 1368,
                parentId: 1365,
                regionType: 3,
                regionName: '林周县',
                parentIdAll: '1,1364,1365,1368',
                children: []
              },
              {
                id: 1369,
                parentId: 1365,
                regionType: 3,
                regionName: '堆龙德庆区',
                parentIdAll: '1,1364,1365,1369',
                children: []
              },
              {
                id: 1370,
                parentId: 1365,
                regionType: 3,
                regionName: '达孜区',
                parentIdAll: '1,1364,1365,1370',
                children: []
              },
              {
                id: 1371,
                parentId: 1365,
                regionType: 3,
                regionName: '城关区',
                parentIdAll: '1,1364,1365,1371',
                children: []
              },
              {
                id: 1372,
                parentId: 1365,
                regionType: 3,
                regionName: '尼木县',
                parentIdAll: '1,1364,1365,1372',
                children: []
              },
              {
                id: 1373,
                parentId: 1365,
                regionType: 3,
                regionName: '曲水县',
                parentIdAll: '1,1364,1365,1373',
                children: []
              }
            ]
          },
          {
            id: 1374,
            parentId: 1364,
            regionType: 2,
            regionName: '日喀则市',
            parentIdAll: '1,1364,1374',
            children: [
              {
                id: 1375,
                parentId: 1374,
                regionType: 3,
                regionName: '仲巴县',
                parentIdAll: '1,1364,1374,1375',
                children: []
              },
              {
                id: 1376,
                parentId: 1374,
                regionType: 3,
                regionName: '昂仁县',
                parentIdAll: '1,1364,1374,1376',
                children: []
              },
              {
                id: 1377,
                parentId: 1374,
                regionType: 3,
                regionName: '谢通门县',
                parentIdAll: '1,1364,1374,1377',
                children: []
              },
              {
                id: 1378,
                parentId: 1374,
                regionType: 3,
                regionName: '南木林县',
                parentIdAll: '1,1364,1374,1378',
                children: []
              },
              {
                id: 1379,
                parentId: 1374,
                regionType: 3,
                regionName: '萨嘎县',
                parentIdAll: '1,1364,1374,1379',
                children: []
              },
              {
                id: 1380,
                parentId: 1374,
                regionType: 3,
                regionName: '拉孜县',
                parentIdAll: '1,1364,1374,1380',
                children: []
              },
              {
                id: 1381,
                parentId: 1374,
                regionType: 3,
                regionName: '桑珠孜区',
                parentIdAll: '1,1364,1374,1381',
                children: []
              },
              {
                id: 1382,
                parentId: 1374,
                regionType: 3,
                regionName: '吉隆县',
                parentIdAll: '1,1364,1374,1382',
                children: []
              },
              {
                id: 1383,
                parentId: 1374,
                regionType: 3,
                regionName: '萨迦县',
                parentIdAll: '1,1364,1374,1383',
                children: []
              },
              {
                id: 1384,
                parentId: 1374,
                regionType: 3,
                regionName: '仁布县',
                parentIdAll: '1,1364,1374,1384',
                children: []
              },
              {
                id: 1385,
                parentId: 1374,
                regionType: 3,
                regionName: '白朗县',
                parentIdAll: '1,1364,1374,1385',
                children: []
              },
              {
                id: 1386,
                parentId: 1374,
                regionType: 3,
                regionName: '江孜县',
                parentIdAll: '1,1364,1374,1386',
                children: []
              },
              {
                id: 1387,
                parentId: 1374,
                regionType: 3,
                regionName: '定日县',
                parentIdAll: '1,1364,1374,1387',
                children: []
              },
              {
                id: 1388,
                parentId: 1374,
                regionType: 3,
                regionName: '聂拉木县',
                parentIdAll: '1,1364,1374,1388',
                children: []
              },
              {
                id: 1389,
                parentId: 1374,
                regionType: 3,
                regionName: '康马县',
                parentIdAll: '1,1364,1374,1389',
                children: []
              },
              {
                id: 1390,
                parentId: 1374,
                regionType: 3,
                regionName: '岗巴县',
                parentIdAll: '1,1364,1374,1390',
                children: []
              },
              {
                id: 1391,
                parentId: 1374,
                regionType: 3,
                regionName: '定结县',
                parentIdAll: '1,1364,1374,1391',
                children: []
              },
              {
                id: 1392,
                parentId: 1374,
                regionType: 3,
                regionName: '亚东县',
                parentIdAll: '1,1364,1374,1392',
                children: []
              }
            ]
          },
          {
            id: 1393,
            parentId: 1364,
            regionType: 2,
            regionName: '那曲市',
            parentIdAll: '1,1364,1393',
            children: [
              {
                id: 1394,
                parentId: 1393,
                regionType: 3,
                regionName: '聂荣县',
                parentIdAll: '1,1364,1393,1394',
                children: []
              },
              {
                id: 1395,
                parentId: 1393,
                regionType: 3,
                regionName: '巴青县',
                parentIdAll: '1,1364,1393,1395',
                children: []
              },
              {
                id: 1396,
                parentId: 1393,
                regionType: 3,
                regionName: '安多县',
                parentIdAll: '1,1364,1393,1396',
                children: []
              },
              {
                id: 1397,
                parentId: 1393,
                regionType: 3,
                regionName: '申扎县',
                parentIdAll: '1,1364,1393,1397',
                children: []
              },
              {
                id: 1398,
                parentId: 1393,
                regionType: 3,
                regionName: '比如县',
                parentIdAll: '1,1364,1393,1398',
                children: []
              },
              {
                id: 1399,
                parentId: 1393,
                regionType: 3,
                regionName: '班戈县',
                parentIdAll: '1,1364,1393,1399',
                children: []
              },
              {
                id: 1400,
                parentId: 1393,
                regionType: 3,
                regionName: '索县',
                parentIdAll: '1,1364,1393,1400',
                children: []
              },
              {
                id: 1401,
                parentId: 1393,
                regionType: 3,
                regionName: '色尼区',
                parentIdAll: '1,1364,1393,1401',
                children: []
              },
              {
                id: 1402,
                parentId: 1393,
                regionType: 3,
                regionName: '尼玛县',
                parentIdAll: '1,1364,1393,1402',
                children: []
              },
              {
                id: 1403,
                parentId: 1393,
                regionType: 3,
                regionName: '嘉黎县',
                parentIdAll: '1,1364,1393,1403',
                children: []
              },
              {
                id: 1404,
                parentId: 1393,
                regionType: 3,
                regionName: '双湖县',
                parentIdAll: '1,1364,1393,1404',
                children: []
              }
            ]
          },
          {
            id: 1405,
            parentId: 1364,
            regionType: 2,
            regionName: '昌都市',
            parentIdAll: '1,1364,1405',
            children: [
              {
                id: 1406,
                parentId: 1405,
                regionType: 3,
                regionName: '江达县',
                parentIdAll: '1,1364,1405,1406',
                children: []
              },
              {
                id: 1407,
                parentId: 1405,
                regionType: 3,
                regionName: '丁青县',
                parentIdAll: '1,1364,1405,1407',
                children: []
              },
              {
                id: 1408,
                parentId: 1405,
                regionType: 3,
                regionName: '卡若区',
                parentIdAll: '1,1364,1405,1408',
                children: []
              },
              {
                id: 1409,
                parentId: 1405,
                regionType: 3,
                regionName: '类乌齐县',
                parentIdAll: '1,1364,1405,1409',
                children: []
              },
              {
                id: 1410,
                parentId: 1405,
                regionType: 3,
                regionName: '边坝县',
                parentIdAll: '1,1364,1405,1410',
                children: []
              },
              {
                id: 1411,
                parentId: 1405,
                regionType: 3,
                regionName: '洛隆县',
                parentIdAll: '1,1364,1405,1411',
                children: []
              },
              {
                id: 1412,
                parentId: 1405,
                regionType: 3,
                regionName: '八宿县',
                parentIdAll: '1,1364,1405,1412',
                children: []
              },
              {
                id: 1413,
                parentId: 1405,
                regionType: 3,
                regionName: '察雅县',
                parentIdAll: '1,1364,1405,1413',
                children: []
              },
              {
                id: 1414,
                parentId: 1405,
                regionType: 3,
                regionName: '贡觉县',
                parentIdAll: '1,1364,1405,1414',
                children: []
              },
              {
                id: 1415,
                parentId: 1405,
                regionType: 3,
                regionName: '左贡县',
                parentIdAll: '1,1364,1405,1415',
                children: []
              },
              {
                id: 1416,
                parentId: 1405,
                regionType: 3,
                regionName: '芒康县',
                parentIdAll: '1,1364,1405,1416',
                children: []
              }
            ]
          },
          {
            id: 1417,
            parentId: 1364,
            regionType: 2,
            regionName: '阿里地区',
            parentIdAll: '1,1364,1417',
            children: [
              {
                id: 1418,
                parentId: 1417,
                regionType: 3,
                regionName: '札达县',
                parentIdAll: '1,1364,1417,1418',
                children: []
              },
              {
                id: 1419,
                parentId: 1417,
                regionType: 3,
                regionName: '改则县',
                parentIdAll: '1,1364,1417,1419',
                children: []
              },
              {
                id: 1420,
                parentId: 1417,
                regionType: 3,
                regionName: '措勤县',
                parentIdAll: '1,1364,1417,1420',
                children: []
              },
              {
                id: 1421,
                parentId: 1417,
                regionType: 3,
                regionName: '普兰县',
                parentIdAll: '1,1364,1417,1421',
                children: []
              },
              {
                id: 1422,
                parentId: 1417,
                regionType: 3,
                regionName: '噶尔县',
                parentIdAll: '1,1364,1417,1422',
                children: []
              },
              {
                id: 1423,
                parentId: 1417,
                regionType: 3,
                regionName: '日土县',
                parentIdAll: '1,1364,1417,1423',
                children: []
              },
              {
                id: 1424,
                parentId: 1417,
                regionType: 3,
                regionName: '革吉县',
                parentIdAll: '1,1364,1417,1424',
                children: []
              }
            ]
          },
          {
            id: 1425,
            parentId: 1364,
            regionType: 2,
            regionName: '山南市',
            parentIdAll: '1,1364,1425',
            children: [
              {
                id: 1426,
                parentId: 1425,
                regionType: 3,
                regionName: '桑日县',
                parentIdAll: '1,1364,1425,1426',
                children: []
              },
              {
                id: 1427,
                parentId: 1425,
                regionType: 3,
                regionName: '加查县',
                parentIdAll: '1,1364,1425,1427',
                children: []
              },
              {
                id: 1428,
                parentId: 1425,
                regionType: 3,
                regionName: '乃东区',
                parentIdAll: '1,1364,1425,1428',
                children: []
              },
              {
                id: 1429,
                parentId: 1425,
                regionType: 3,
                regionName: '贡嘎县',
                parentIdAll: '1,1364,1425,1429',
                children: []
              },
              {
                id: 1430,
                parentId: 1425,
                regionType: 3,
                regionName: '扎囊县',
                parentIdAll: '1,1364,1425,1430',
                children: []
              },
              {
                id: 1431,
                parentId: 1425,
                regionType: 3,
                regionName: '浪卡子县',
                parentIdAll: '1,1364,1425,1431',
                children: []
              },
              {
                id: 1432,
                parentId: 1425,
                regionType: 3,
                regionName: '隆子县',
                parentIdAll: '1,1364,1425,1432',
                children: []
              },
              {
                id: 1433,
                parentId: 1425,
                regionType: 3,
                regionName: '琼结县',
                parentIdAll: '1,1364,1425,1433',
                children: []
              },
              {
                id: 1434,
                parentId: 1425,
                regionType: 3,
                regionName: '曲松县',
                parentIdAll: '1,1364,1425,1434',
                children: []
              },
              {
                id: 1435,
                parentId: 1425,
                regionType: 3,
                regionName: '措美县',
                parentIdAll: '1,1364,1425,1435',
                children: []
              },
              {
                id: 1436,
                parentId: 1425,
                regionType: 3,
                regionName: '洛扎县',
                parentIdAll: '1,1364,1425,1436',
                children: []
              },
              {
                id: 1437,
                parentId: 1425,
                regionType: 3,
                regionName: '错那县',
                parentIdAll: '1,1364,1425,1437',
                children: []
              }
            ]
          },
          {
            id: 1438,
            parentId: 1364,
            regionType: 2,
            regionName: '林芝市',
            parentIdAll: '1,1364,1438',
            children: [
              {
                id: 1439,
                parentId: 1438,
                regionType: 3,
                regionName: '工布江达县',
                parentIdAll: '1,1364,1438,1439',
                children: []
              },
              {
                id: 1440,
                parentId: 1438,
                regionType: 3,
                regionName: '波密县',
                parentIdAll: '1,1364,1438,1440',
                children: []
              },
              {
                id: 1441,
                parentId: 1438,
                regionType: 3,
                regionName: '巴宜区',
                parentIdAll: '1,1364,1438,1441',
                children: []
              },
              {
                id: 1442,
                parentId: 1438,
                regionType: 3,
                regionName: '墨脱县',
                parentIdAll: '1,1364,1438,1442',
                children: []
              },
              {
                id: 1443,
                parentId: 1438,
                regionType: 3,
                regionName: '米林县',
                parentIdAll: '1,1364,1438,1443',
                children: []
              },
              {
                id: 1444,
                parentId: 1438,
                regionType: 3,
                regionName: '朗县',
                parentIdAll: '1,1364,1438,1444',
                children: []
              },
              {
                id: 1445,
                parentId: 1438,
                regionType: 3,
                regionName: '察隅县',
                parentIdAll: '1,1364,1438,1445',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1446,
        parentId: 1,
        regionType: 1,
        regionName: '安徽省',
        parentIdAll: '1,1446',
        children: [
          {
            id: 1447,
            parentId: 1446,
            regionType: 2,
            regionName: '马鞍山市',
            parentIdAll: '1,1446,1447',
            children: [
              {
                id: 1448,
                parentId: 1447,
                regionType: 3,
                regionName: '博望区',
                parentIdAll: '1,1446,1447,1448',
                children: []
              },
              {
                id: 1449,
                parentId: 1447,
                regionType: 3,
                regionName: '当涂县',
                parentIdAll: '1,1446,1447,1449',
                children: []
              },
              {
                id: 1450,
                parentId: 1447,
                regionType: 3,
                regionName: '含山县',
                parentIdAll: '1,1446,1447,1450',
                children: []
              },
              {
                id: 1451,
                parentId: 1447,
                regionType: 3,
                regionName: '和县',
                parentIdAll: '1,1446,1447,1451',
                children: []
              },
              {
                id: 1452,
                parentId: 1447,
                regionType: 3,
                regionName: '花山区',
                parentIdAll: '1,1446,1447,1452',
                children: []
              },
              {
                id: 1453,
                parentId: 1447,
                regionType: 3,
                regionName: '雨山区',
                parentIdAll: '1,1446,1447,1453',
                children: []
              }
            ]
          },
          {
            id: 1454,
            parentId: 1446,
            regionType: 2,
            regionName: '阜阳市',
            parentIdAll: '1,1446,1454',
            children: [
              {
                id: 1455,
                parentId: 1454,
                regionType: 3,
                regionName: '太和县',
                parentIdAll: '1,1446,1454,1455',
                children: []
              },
              {
                id: 1456,
                parentId: 1454,
                regionType: 3,
                regionName: '颍泉区',
                parentIdAll: '1,1446,1454,1456',
                children: []
              },
              {
                id: 1457,
                parentId: 1454,
                regionType: 3,
                regionName: '临泉县',
                parentIdAll: '1,1446,1454,1457',
                children: []
              },
              {
                id: 1458,
                parentId: 1454,
                regionType: 3,
                regionName: '颍州区',
                parentIdAll: '1,1446,1454,1458',
                children: []
              },
              {
                id: 1459,
                parentId: 1454,
                regionType: 3,
                regionName: '颍东区',
                parentIdAll: '1,1446,1454,1459',
                children: []
              },
              {
                id: 1460,
                parentId: 1454,
                regionType: 3,
                regionName: '阜南县',
                parentIdAll: '1,1446,1454,1460',
                children: []
              },
              {
                id: 1461,
                parentId: 1454,
                regionType: 3,
                regionName: '颍上县',
                parentIdAll: '1,1446,1454,1461',
                children: []
              },
              {
                id: 1462,
                parentId: 1454,
                regionType: 3,
                regionName: '界首市',
                parentIdAll: '1,1446,1454,1462',
                children: []
              }
            ]
          },
          {
            id: 1463,
            parentId: 1446,
            regionType: 2,
            regionName: '池州市',
            parentIdAll: '1,1446,1463',
            children: [
              {
                id: 1464,
                parentId: 1463,
                regionType: 3,
                regionName: '青阳县',
                parentIdAll: '1,1446,1463,1464',
                children: []
              },
              {
                id: 1465,
                parentId: 1463,
                regionType: 3,
                regionName: '东至县',
                parentIdAll: '1,1446,1463,1465',
                children: []
              },
              {
                id: 1466,
                parentId: 1463,
                regionType: 3,
                regionName: '贵池区',
                parentIdAll: '1,1446,1463,1466',
                children: []
              },
              {
                id: 1467,
                parentId: 1463,
                regionType: 3,
                regionName: '石台县',
                parentIdAll: '1,1446,1463,1467',
                children: []
              }
            ]
          },
          {
            id: 1468,
            parentId: 1446,
            regionType: 2,
            regionName: '淮北市',
            parentIdAll: '1,1446,1468',
            children: [
              {
                id: 1469,
                parentId: 1468,
                regionType: 3,
                regionName: '杜集区',
                parentIdAll: '1,1446,1468,1469',
                children: []
              },
              {
                id: 1470,
                parentId: 1468,
                regionType: 3,
                regionName: '烈山区',
                parentIdAll: '1,1446,1468,1470',
                children: []
              },
              {
                id: 1471,
                parentId: 1468,
                regionType: 3,
                regionName: '濉溪县',
                parentIdAll: '1,1446,1468,1471',
                children: []
              },
              {
                id: 1472,
                parentId: 1468,
                regionType: 3,
                regionName: '相山区',
                parentIdAll: '1,1446,1468,1472',
                children: []
              }
            ]
          },
          {
            id: 1473,
            parentId: 1446,
            regionType: 2,
            regionName: '铜陵市',
            parentIdAll: '1,1446,1473',
            children: [
              {
                id: 1474,
                parentId: 1473,
                regionType: 3,
                regionName: '枞阳县',
                parentIdAll: '1,1446,1473,1474',
                children: []
              },
              {
                id: 1475,
                parentId: 1473,
                regionType: 3,
                regionName: '郊区',
                parentIdAll: '1,1446,1473,1475',
                children: []
              },
              {
                id: 1476,
                parentId: 1473,
                regionType: 3,
                regionName: '义安区',
                parentIdAll: '1,1446,1473,1476',
                children: []
              },
              {
                id: 1477,
                parentId: 1473,
                regionType: 3,
                regionName: '铜官区',
                parentIdAll: '1,1446,1473,1477',
                children: []
              }
            ]
          },
          {
            id: 1478,
            parentId: 1446,
            regionType: 2,
            regionName: '蚌埠市',
            parentIdAll: '1,1446,1478',
            children: [
              {
                id: 1479,
                parentId: 1478,
                regionType: 3,
                regionName: '固镇县',
                parentIdAll: '1,1446,1478,1479',
                children: []
              },
              {
                id: 1480,
                parentId: 1478,
                regionType: 3,
                regionName: '淮上区',
                parentIdAll: '1,1446,1478,1480',
                children: []
              },
              {
                id: 1481,
                parentId: 1478,
                regionType: 3,
                regionName: '龙子湖区',
                parentIdAll: '1,1446,1478,1481',
                children: []
              },
              {
                id: 1482,
                parentId: 1478,
                regionType: 3,
                regionName: '五河县',
                parentIdAll: '1,1446,1478,1482',
                children: []
              },
              {
                id: 1483,
                parentId: 1478,
                regionType: 3,
                regionName: '怀远县',
                parentIdAll: '1,1446,1478,1483',
                children: []
              },
              {
                id: 1484,
                parentId: 1478,
                regionType: 3,
                regionName: '蚌山区',
                parentIdAll: '1,1446,1478,1484',
                children: []
              },
              {
                id: 1485,
                parentId: 1478,
                regionType: 3,
                regionName: '禹会区',
                parentIdAll: '1,1446,1478,1485',
                children: []
              }
            ]
          },
          {
            id: 1486,
            parentId: 1446,
            regionType: 2,
            regionName: '亳州市',
            parentIdAll: '1,1446,1486',
            children: [
              {
                id: 1487,
                parentId: 1486,
                regionType: 3,
                regionName: '涡阳县',
                parentIdAll: '1,1446,1486,1487',
                children: []
              },
              {
                id: 1488,
                parentId: 1486,
                regionType: 3,
                regionName: '谯城区',
                parentIdAll: '1,1446,1486,1488',
                children: []
              },
              {
                id: 1489,
                parentId: 1486,
                regionType: 3,
                regionName: '蒙城县',
                parentIdAll: '1,1446,1486,1489',
                children: []
              },
              {
                id: 1490,
                parentId: 1486,
                regionType: 3,
                regionName: '利辛县',
                parentIdAll: '1,1446,1486,1490',
                children: []
              }
            ]
          },
          {
            id: 1491,
            parentId: 1446,
            regionType: 2,
            regionName: '滁州市',
            parentIdAll: '1,1446,1491',
            children: [
              {
                id: 1492,
                parentId: 1491,
                regionType: 3,
                regionName: '明光市',
                parentIdAll: '1,1446,1491,1492',
                children: []
              },
              {
                id: 1493,
                parentId: 1491,
                regionType: 3,
                regionName: '天长市',
                parentIdAll: '1,1446,1491,1493',
                children: []
              },
              {
                id: 1494,
                parentId: 1491,
                regionType: 3,
                regionName: '全椒县',
                parentIdAll: '1,1446,1491,1494',
                children: []
              },
              {
                id: 1495,
                parentId: 1491,
                regionType: 3,
                regionName: '凤阳县',
                parentIdAll: '1,1446,1491,1495',
                children: []
              },
              {
                id: 1496,
                parentId: 1491,
                regionType: 3,
                regionName: '定远县',
                parentIdAll: '1,1446,1491,1496',
                children: []
              },
              {
                id: 1497,
                parentId: 1491,
                regionType: 3,
                regionName: '来安县',
                parentIdAll: '1,1446,1491,1497',
                children: []
              },
              {
                id: 1498,
                parentId: 1491,
                regionType: 3,
                regionName: '琅琊区',
                parentIdAll: '1,1446,1491,1498',
                children: []
              },
              {
                id: 1499,
                parentId: 1491,
                regionType: 3,
                regionName: '南谯区',
                parentIdAll: '1,1446,1491,1499',
                children: []
              }
            ]
          },
          {
            id: 1500,
            parentId: 1446,
            regionType: 2,
            regionName: '宣城市',
            parentIdAll: '1,1446,1500',
            children: [
              {
                id: 1501,
                parentId: 1500,
                regionType: 3,
                regionName: '广德县',
                parentIdAll: '1,1446,1500,1501',
                children: []
              },
              {
                id: 1502,
                parentId: 1500,
                regionType: 3,
                regionName: '泾县',
                parentIdAll: '1,1446,1500,1502',
                children: []
              },
              {
                id: 1503,
                parentId: 1500,
                regionType: 3,
                regionName: '旌德县',
                parentIdAll: '1,1446,1500,1503',
                children: []
              },
              {
                id: 1504,
                parentId: 1500,
                regionType: 3,
                regionName: '绩溪县',
                parentIdAll: '1,1446,1500,1504',
                children: []
              },
              {
                id: 1505,
                parentId: 1500,
                regionType: 3,
                regionName: '宣州区',
                parentIdAll: '1,1446,1500,1505',
                children: []
              },
              {
                id: 1506,
                parentId: 1500,
                regionType: 3,
                regionName: '宁国市',
                parentIdAll: '1,1446,1500,1506',
                children: []
              },
              {
                id: 1507,
                parentId: 1500,
                regionType: 3,
                regionName: '郎溪县',
                parentIdAll: '1,1446,1500,1507',
                children: []
              }
            ]
          },
          {
            id: 1508,
            parentId: 1446,
            regionType: 2,
            regionName: '芜湖市',
            parentIdAll: '1,1446,1508',
            children: [
              {
                id: 1509,
                parentId: 1508,
                regionType: 3,
                regionName: '繁昌县',
                parentIdAll: '1,1446,1508,1509',
                children: []
              },
              {
                id: 1510,
                parentId: 1508,
                regionType: 3,
                regionName: '镜湖区',
                parentIdAll: '1,1446,1508,1510',
                children: []
              },
              {
                id: 1511,
                parentId: 1508,
                regionType: 3,
                regionName: '弋江区',
                parentIdAll: '1,1446,1508,1511',
                children: []
              },
              {
                id: 1512,
                parentId: 1508,
                regionType: 3,
                regionName: '无为县',
                parentIdAll: '1,1446,1508,1512',
                children: []
              },
              {
                id: 1513,
                parentId: 1508,
                regionType: 3,
                regionName: '鸠江区',
                parentIdAll: '1,1446,1508,1513',
                children: []
              },
              {
                id: 1514,
                parentId: 1508,
                regionType: 3,
                regionName: '南陵县',
                parentIdAll: '1,1446,1508,1514',
                children: []
              },
              {
                id: 1515,
                parentId: 1508,
                regionType: 3,
                regionName: '三山区',
                parentIdAll: '1,1446,1508,1515',
                children: []
              },
              {
                id: 1516,
                parentId: 1508,
                regionType: 3,
                regionName: '芜湖县',
                parentIdAll: '1,1446,1508,1516',
                children: []
              }
            ]
          },
          {
            id: 1517,
            parentId: 1446,
            regionType: 2,
            regionName: '安庆市',
            parentIdAll: '1,1446,1517',
            children: [
              {
                id: 1518,
                parentId: 1517,
                regionType: 3,
                regionName: '潜山县',
                parentIdAll: '1,1446,1517,1518',
                children: []
              },
              {
                id: 1519,
                parentId: 1517,
                regionType: 3,
                regionName: '岳西县',
                parentIdAll: '1,1446,1517,1519',
                children: []
              },
              {
                id: 1520,
                parentId: 1517,
                regionType: 3,
                regionName: '桐城市',
                parentIdAll: '1,1446,1517,1520',
                children: []
              },
              {
                id: 1521,
                parentId: 1517,
                regionType: 3,
                regionName: '太湖县',
                parentIdAll: '1,1446,1517,1521',
                children: []
              },
              {
                id: 1522,
                parentId: 1517,
                regionType: 3,
                regionName: '宜秀区',
                parentIdAll: '1,1446,1517,1522',
                children: []
              },
              {
                id: 1523,
                parentId: 1517,
                regionType: 3,
                regionName: '迎江区',
                parentIdAll: '1,1446,1517,1523',
                children: []
              },
              {
                id: 1524,
                parentId: 1517,
                regionType: 3,
                regionName: '宿松县',
                parentIdAll: '1,1446,1517,1524',
                children: []
              },
              {
                id: 1525,
                parentId: 1517,
                regionType: 3,
                regionName: '望江县',
                parentIdAll: '1,1446,1517,1525',
                children: []
              },
              {
                id: 1526,
                parentId: 1517,
                regionType: 3,
                regionName: '大观区',
                parentIdAll: '1,1446,1517,1526',
                children: []
              },
              {
                id: 1527,
                parentId: 1517,
                regionType: 3,
                regionName: '怀宁县',
                parentIdAll: '1,1446,1517,1527',
                children: []
              }
            ]
          },
          {
            id: 1528,
            parentId: 1446,
            regionType: 2,
            regionName: '黄山市',
            parentIdAll: '1,1446,1528',
            children: [
              {
                id: 1529,
                parentId: 1528,
                regionType: 3,
                regionName: '黄山区',
                parentIdAll: '1,1446,1528,1529',
                children: []
              },
              {
                id: 1530,
                parentId: 1528,
                regionType: 3,
                regionName: '黟县',
                parentIdAll: '1,1446,1528,1530',
                children: []
              },
              {
                id: 1531,
                parentId: 1528,
                regionType: 3,
                regionName: '休宁县',
                parentIdAll: '1,1446,1528,1531',
                children: []
              },
              {
                id: 1532,
                parentId: 1528,
                regionType: 3,
                regionName: '屯溪区',
                parentIdAll: '1,1446,1528,1532',
                children: []
              },
              {
                id: 1533,
                parentId: 1528,
                regionType: 3,
                regionName: '祁门县',
                parentIdAll: '1,1446,1528,1533',
                children: []
              },
              {
                id: 1534,
                parentId: 1528,
                regionType: 3,
                regionName: '徽州区',
                parentIdAll: '1,1446,1528,1534',
                children: []
              },
              {
                id: 1535,
                parentId: 1528,
                regionType: 3,
                regionName: '歙县',
                parentIdAll: '1,1446,1528,1535',
                children: []
              }
            ]
          },
          {
            id: 1536,
            parentId: 1446,
            regionType: 2,
            regionName: '六安市',
            parentIdAll: '1,1446,1536',
            children: [
              {
                id: 1537,
                parentId: 1536,
                regionType: 3,
                regionName: '霍山县',
                parentIdAll: '1,1446,1536,1537',
                children: []
              },
              {
                id: 1538,
                parentId: 1536,
                regionType: 3,
                regionName: '裕安区',
                parentIdAll: '1,1446,1536,1538',
                children: []
              },
              {
                id: 1539,
                parentId: 1536,
                regionType: 3,
                regionName: '金寨县',
                parentIdAll: '1,1446,1536,1539',
                children: []
              },
              {
                id: 1540,
                parentId: 1536,
                regionType: 3,
                regionName: '金安区',
                parentIdAll: '1,1446,1536,1540',
                children: []
              },
              {
                id: 1541,
                parentId: 1536,
                regionType: 3,
                regionName: '舒城县',
                parentIdAll: '1,1446,1536,1541',
                children: []
              },
              {
                id: 1542,
                parentId: 1536,
                regionType: 3,
                regionName: '叶集区',
                parentIdAll: '1,1446,1536,1542',
                children: []
              },
              {
                id: 1543,
                parentId: 1536,
                regionType: 3,
                regionName: '霍邱县',
                parentIdAll: '1,1446,1536,1543',
                children: []
              }
            ]
          },
          {
            id: 1544,
            parentId: 1446,
            regionType: 2,
            regionName: '淮南市',
            parentIdAll: '1,1446,1544',
            children: [
              {
                id: 1545,
                parentId: 1544,
                regionType: 3,
                regionName: '八公山区',
                parentIdAll: '1,1446,1544,1545',
                children: []
              },
              {
                id: 1546,
                parentId: 1544,
                regionType: 3,
                regionName: '潘集区',
                parentIdAll: '1,1446,1544,1546',
                children: []
              },
              {
                id: 1547,
                parentId: 1544,
                regionType: 3,
                regionName: '凤台县',
                parentIdAll: '1,1446,1544,1547',
                children: []
              },
              {
                id: 1548,
                parentId: 1544,
                regionType: 3,
                regionName: '谢家集区',
                parentIdAll: '1,1446,1544,1548',
                children: []
              },
              {
                id: 1549,
                parentId: 1544,
                regionType: 3,
                regionName: '大通区',
                parentIdAll: '1,1446,1544,1549',
                children: []
              },
              {
                id: 1550,
                parentId: 1544,
                regionType: 3,
                regionName: '田家庵区',
                parentIdAll: '1,1446,1544,1550',
                children: []
              },
              {
                id: 1551,
                parentId: 1544,
                regionType: 3,
                regionName: '寿县',
                parentIdAll: '1,1446,1544,1551',
                children: []
              }
            ]
          },
          {
            id: 1552,
            parentId: 1446,
            regionType: 2,
            regionName: '合肥市',
            parentIdAll: '1,1446,1552',
            children: [
              {
                id: 1553,
                parentId: 1552,
                regionType: 3,
                regionName: '庐阳区',
                parentIdAll: '1,1446,1552,1553',
                children: []
              },
              {
                id: 1554,
                parentId: 1552,
                regionType: 3,
                regionName: '肥东县',
                parentIdAll: '1,1446,1552,1554',
                children: []
              },
              {
                id: 1555,
                parentId: 1552,
                regionType: 3,
                regionName: '庐江县',
                parentIdAll: '1,1446,1552,1555',
                children: []
              },
              {
                id: 1556,
                parentId: 1552,
                regionType: 3,
                regionName: '瑶海区',
                parentIdAll: '1,1446,1552,1556',
                children: []
              },
              {
                id: 1557,
                parentId: 1552,
                regionType: 3,
                regionName: '肥西县',
                parentIdAll: '1,1446,1552,1557',
                children: []
              },
              {
                id: 1558,
                parentId: 1552,
                regionType: 3,
                regionName: '长丰县',
                parentIdAll: '1,1446,1552,1558',
                children: []
              },
              {
                id: 1559,
                parentId: 1552,
                regionType: 3,
                regionName: '蜀山区',
                parentIdAll: '1,1446,1552,1559',
                children: []
              },
              {
                id: 1560,
                parentId: 1552,
                regionType: 3,
                regionName: '包河区',
                parentIdAll: '1,1446,1552,1560',
                children: []
              },
              {
                id: 1561,
                parentId: 1552,
                regionType: 3,
                regionName: '巢湖市',
                parentIdAll: '1,1446,1552,1561',
                children: []
              }
            ]
          },
          {
            id: 1562,
            parentId: 1446,
            regionType: 2,
            regionName: '宿州市',
            parentIdAll: '1,1446,1562',
            children: [
              {
                id: 1563,
                parentId: 1562,
                regionType: 3,
                regionName: '砀山县',
                parentIdAll: '1,1446,1562,1563',
                children: []
              },
              {
                id: 1564,
                parentId: 1562,
                regionType: 3,
                regionName: '埇桥区',
                parentIdAll: '1,1446,1562,1564',
                children: []
              },
              {
                id: 1565,
                parentId: 1562,
                regionType: 3,
                regionName: '灵璧县',
                parentIdAll: '1,1446,1562,1565',
                children: []
              },
              {
                id: 1566,
                parentId: 1562,
                regionType: 3,
                regionName: '泗县',
                parentIdAll: '1,1446,1562,1566',
                children: []
              },
              {
                id: 1567,
                parentId: 1562,
                regionType: 3,
                regionName: '萧县',
                parentIdAll: '1,1446,1562,1567',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1568,
        parentId: 1,
        regionType: 1,
        regionName: '福建省',
        parentIdAll: '1,1568',
        children: [
          {
            id: 1569,
            parentId: 1568,
            regionType: 2,
            regionName: '福州市',
            parentIdAll: '1,1568,1569',
            children: [
              {
                id: 1570,
                parentId: 1569,
                regionType: 3,
                regionName: '罗源县',
                parentIdAll: '1,1568,1569,1570',
                children: []
              },
              {
                id: 1571,
                parentId: 1569,
                regionType: 3,
                regionName: '连江县',
                parentIdAll: '1,1568,1569,1571',
                children: []
              },
              {
                id: 1572,
                parentId: 1569,
                regionType: 3,
                regionName: '闽清县',
                parentIdAll: '1,1568,1569,1572',
                children: []
              },
              {
                id: 1573,
                parentId: 1569,
                regionType: 3,
                regionName: '闽侯县',
                parentIdAll: '1,1568,1569,1573',
                children: []
              },
              {
                id: 1574,
                parentId: 1569,
                regionType: 3,
                regionName: '长乐区',
                parentIdAll: '1,1568,1569,1574',
                children: []
              },
              {
                id: 1575,
                parentId: 1569,
                regionType: 3,
                regionName: '仓山区',
                parentIdAll: '1,1568,1569,1575',
                children: []
              },
              {
                id: 1576,
                parentId: 1569,
                regionType: 3,
                regionName: '永泰县',
                parentIdAll: '1,1568,1569,1576',
                children: []
              },
              {
                id: 1577,
                parentId: 1569,
                regionType: 3,
                regionName: '福清市',
                parentIdAll: '1,1568,1569,1577',
                children: []
              },
              {
                id: 1578,
                parentId: 1569,
                regionType: 3,
                regionName: '平潭县',
                parentIdAll: '1,1568,1569,1578',
                children: []
              },
              {
                id: 1579,
                parentId: 1569,
                regionType: 3,
                regionName: '台江区',
                parentIdAll: '1,1568,1569,1579',
                children: []
              },
              {
                id: 1580,
                parentId: 1569,
                regionType: 3,
                regionName: '鼓楼区',
                parentIdAll: '1,1568,1569,1580',
                children: []
              },
              {
                id: 1581,
                parentId: 1569,
                regionType: 3,
                regionName: '马尾区',
                parentIdAll: '1,1568,1569,1581',
                children: []
              },
              {
                id: 1582,
                parentId: 1569,
                regionType: 3,
                regionName: '晋安区',
                parentIdAll: '1,1568,1569,1582',
                children: []
              }
            ]
          },
          {
            id: 1583,
            parentId: 1568,
            regionType: 2,
            regionName: '龙岩市',
            parentIdAll: '1,1568,1583',
            children: [
              {
                id: 1584,
                parentId: 1583,
                regionType: 3,
                regionName: '连城县',
                parentIdAll: '1,1568,1583,1584',
                children: []
              },
              {
                id: 1585,
                parentId: 1583,
                regionType: 3,
                regionName: '上杭县',
                parentIdAll: '1,1568,1583,1585',
                children: []
              },
              {
                id: 1586,
                parentId: 1583,
                regionType: 3,
                regionName: '武平县',
                parentIdAll: '1,1568,1583,1586',
                children: []
              },
              {
                id: 1587,
                parentId: 1583,
                regionType: 3,
                regionName: '新罗区',
                parentIdAll: '1,1568,1583,1587',
                children: []
              },
              {
                id: 1588,
                parentId: 1583,
                regionType: 3,
                regionName: '永定区',
                parentIdAll: '1,1568,1583,1588',
                children: []
              },
              {
                id: 1589,
                parentId: 1583,
                regionType: 3,
                regionName: '漳平市',
                parentIdAll: '1,1568,1583,1589',
                children: []
              },
              {
                id: 1590,
                parentId: 1583,
                regionType: 3,
                regionName: '长汀县',
                parentIdAll: '1,1568,1583,1590',
                children: []
              }
            ]
          },
          {
            id: 1591,
            parentId: 1568,
            regionType: 2,
            regionName: '宁德市',
            parentIdAll: '1,1568,1591',
            children: [
              {
                id: 1592,
                parentId: 1591,
                regionType: 3,
                regionName: '周宁县',
                parentIdAll: '1,1568,1591,1592',
                children: []
              },
              {
                id: 1593,
                parentId: 1591,
                regionType: 3,
                regionName: '福安市',
                parentIdAll: '1,1568,1591,1593',
                children: []
              },
              {
                id: 1594,
                parentId: 1591,
                regionType: 3,
                regionName: '柘荣县',
                parentIdAll: '1,1568,1591,1594',
                children: []
              },
              {
                id: 1595,
                parentId: 1591,
                regionType: 3,
                regionName: '寿宁县',
                parentIdAll: '1,1568,1591,1595',
                children: []
              },
              {
                id: 1596,
                parentId: 1591,
                regionType: 3,
                regionName: '蕉城区',
                parentIdAll: '1,1568,1591,1596',
                children: []
              },
              {
                id: 1597,
                parentId: 1591,
                regionType: 3,
                regionName: '古田县',
                parentIdAll: '1,1568,1591,1597',
                children: []
              },
              {
                id: 1598,
                parentId: 1591,
                regionType: 3,
                regionName: '霞浦县',
                parentIdAll: '1,1568,1591,1598',
                children: []
              },
              {
                id: 1599,
                parentId: 1591,
                regionType: 3,
                regionName: '屏南县',
                parentIdAll: '1,1568,1591,1599',
                children: []
              },
              {
                id: 1600,
                parentId: 1591,
                regionType: 3,
                regionName: '福鼎市',
                parentIdAll: '1,1568,1591,1600',
                children: []
              }
            ]
          },
          {
            id: 1601,
            parentId: 1568,
            regionType: 2,
            regionName: '莆田市',
            parentIdAll: '1,1568,1601',
            children: [
              {
                id: 1602,
                parentId: 1601,
                regionType: 3,
                regionName: '城厢区',
                parentIdAll: '1,1568,1601,1602',
                children: []
              },
              {
                id: 1603,
                parentId: 1601,
                regionType: 3,
                regionName: '仙游县',
                parentIdAll: '1,1568,1601,1603',
                children: []
              },
              {
                id: 1604,
                parentId: 1601,
                regionType: 3,
                regionName: '荔城区',
                parentIdAll: '1,1568,1601,1604',
                children: []
              },
              {
                id: 1605,
                parentId: 1601,
                regionType: 3,
                regionName: '秀屿区',
                parentIdAll: '1,1568,1601,1605',
                children: []
              },
              {
                id: 1606,
                parentId: 1601,
                regionType: 3,
                regionName: '涵江区',
                parentIdAll: '1,1568,1601,1606',
                children: []
              }
            ]
          },
          {
            id: 1607,
            parentId: 1568,
            regionType: 2,
            regionName: '泉州市',
            parentIdAll: '1,1568,1607',
            children: [
              {
                id: 1608,
                parentId: 1607,
                regionType: 3,
                regionName: '德化县',
                parentIdAll: '1,1568,1607,1608',
                children: []
              },
              {
                id: 1609,
                parentId: 1607,
                regionType: 3,
                regionName: '永春县',
                parentIdAll: '1,1568,1607,1609',
                children: []
              },
              {
                id: 1610,
                parentId: 1607,
                regionType: 3,
                regionName: '南安市',
                parentIdAll: '1,1568,1607,1610',
                children: []
              },
              {
                id: 1611,
                parentId: 1607,
                regionType: 3,
                regionName: '洛江区',
                parentIdAll: '1,1568,1607,1611',
                children: []
              },
              {
                id: 1612,
                parentId: 1607,
                regionType: 3,
                regionName: '安溪县',
                parentIdAll: '1,1568,1607,1612',
                children: []
              },
              {
                id: 1613,
                parentId: 1607,
                regionType: 3,
                regionName: '泉港区',
                parentIdAll: '1,1568,1607,1613',
                children: []
              },
              {
                id: 1614,
                parentId: 1607,
                regionType: 3,
                regionName: '丰泽区',
                parentIdAll: '1,1568,1607,1614',
                children: []
              },
              {
                id: 1615,
                parentId: 1607,
                regionType: 3,
                regionName: '惠安县',
                parentIdAll: '1,1568,1607,1615',
                children: []
              },
              {
                id: 1616,
                parentId: 1607,
                regionType: 3,
                regionName: '石狮市',
                parentIdAll: '1,1568,1607,1616',
                children: []
              },
              {
                id: 1617,
                parentId: 1607,
                regionType: 3,
                regionName: '晋江市',
                parentIdAll: '1,1568,1607,1617',
                children: []
              },
              {
                id: 1618,
                parentId: 1607,
                regionType: 3,
                regionName: '金门县',
                parentIdAll: '1,1568,1607,1618',
                children: []
              },
              {
                id: 1619,
                parentId: 1607,
                regionType: 3,
                regionName: '鲤城区',
                parentIdAll: '1,1568,1607,1619',
                children: []
              }
            ]
          },
          {
            id: 1620,
            parentId: 1568,
            regionType: 2,
            regionName: '厦门市',
            parentIdAll: '1,1568,1620',
            children: [
              {
                id: 1621,
                parentId: 1620,
                regionType: 3,
                regionName: '同安区',
                parentIdAll: '1,1568,1620,1621',
                children: []
              },
              {
                id: 1622,
                parentId: 1620,
                regionType: 3,
                regionName: '海沧区',
                parentIdAll: '1,1568,1620,1622',
                children: []
              },
              {
                id: 1623,
                parentId: 1620,
                regionType: 3,
                regionName: '集美区',
                parentIdAll: '1,1568,1620,1623',
                children: []
              },
              {
                id: 1624,
                parentId: 1620,
                regionType: 3,
                regionName: '湖里区',
                parentIdAll: '1,1568,1620,1624',
                children: []
              },
              {
                id: 1625,
                parentId: 1620,
                regionType: 3,
                regionName: '思明区',
                parentIdAll: '1,1568,1620,1625',
                children: []
              },
              {
                id: 1626,
                parentId: 1620,
                regionType: 3,
                regionName: '翔安区',
                parentIdAll: '1,1568,1620,1626',
                children: []
              }
            ]
          },
          {
            id: 1627,
            parentId: 1568,
            regionType: 2,
            regionName: '南平市',
            parentIdAll: '1,1568,1627',
            children: [
              {
                id: 1628,
                parentId: 1627,
                regionType: 3,
                regionName: '武夷山市',
                parentIdAll: '1,1568,1627,1628',
                children: []
              },
              {
                id: 1629,
                parentId: 1627,
                regionType: 3,
                regionName: '浦城县',
                parentIdAll: '1,1568,1627,1629',
                children: []
              },
              {
                id: 1630,
                parentId: 1627,
                regionType: 3,
                regionName: '松溪县',
                parentIdAll: '1,1568,1627,1630',
                children: []
              },
              {
                id: 1631,
                parentId: 1627,
                regionType: 3,
                regionName: '邵武市',
                parentIdAll: '1,1568,1627,1631',
                children: []
              },
              {
                id: 1632,
                parentId: 1627,
                regionType: 3,
                regionName: '建阳区',
                parentIdAll: '1,1568,1627,1632',
                children: []
              },
              {
                id: 1633,
                parentId: 1627,
                regionType: 3,
                regionName: '建瓯市',
                parentIdAll: '1,1568,1627,1633',
                children: []
              },
              {
                id: 1634,
                parentId: 1627,
                regionType: 3,
                regionName: '光泽县',
                parentIdAll: '1,1568,1627,1634',
                children: []
              },
              {
                id: 1635,
                parentId: 1627,
                regionType: 3,
                regionName: '政和县',
                parentIdAll: '1,1568,1627,1635',
                children: []
              },
              {
                id: 1636,
                parentId: 1627,
                regionType: 3,
                regionName: '顺昌县',
                parentIdAll: '1,1568,1627,1636',
                children: []
              },
              {
                id: 1637,
                parentId: 1627,
                regionType: 3,
                regionName: '延平区',
                parentIdAll: '1,1568,1627,1637',
                children: []
              }
            ]
          },
          {
            id: 1638,
            parentId: 1568,
            regionType: 2,
            regionName: '三明市',
            parentIdAll: '1,1568,1638',
            children: [
              {
                id: 1639,
                parentId: 1638,
                regionType: 3,
                regionName: '宁化县',
                parentIdAll: '1,1568,1638,1639',
                children: []
              },
              {
                id: 1640,
                parentId: 1638,
                regionType: 3,
                regionName: '泰宁县',
                parentIdAll: '1,1568,1638,1640',
                children: []
              },
              {
                id: 1641,
                parentId: 1638,
                regionType: 3,
                regionName: '大田县',
                parentIdAll: '1,1568,1638,1641',
                children: []
              },
              {
                id: 1642,
                parentId: 1638,
                regionType: 3,
                regionName: '永安市',
                parentIdAll: '1,1568,1638,1642',
                children: []
              },
              {
                id: 1643,
                parentId: 1638,
                regionType: 3,
                regionName: '梅列区',
                parentIdAll: '1,1568,1638,1643',
                children: []
              },
              {
                id: 1644,
                parentId: 1638,
                regionType: 3,
                regionName: '三元区',
                parentIdAll: '1,1568,1638,1644',
                children: []
              },
              {
                id: 1645,
                parentId: 1638,
                regionType: 3,
                regionName: '清流县',
                parentIdAll: '1,1568,1638,1645',
                children: []
              },
              {
                id: 1646,
                parentId: 1638,
                regionType: 3,
                regionName: '建宁县',
                parentIdAll: '1,1568,1638,1646',
                children: []
              },
              {
                id: 1647,
                parentId: 1638,
                regionType: 3,
                regionName: '尤溪县',
                parentIdAll: '1,1568,1638,1647',
                children: []
              },
              {
                id: 1648,
                parentId: 1638,
                regionType: 3,
                regionName: '沙县',
                parentIdAll: '1,1568,1638,1648',
                children: []
              },
              {
                id: 1649,
                parentId: 1638,
                regionType: 3,
                regionName: '将乐县',
                parentIdAll: '1,1568,1638,1649',
                children: []
              },
              {
                id: 1650,
                parentId: 1638,
                regionType: 3,
                regionName: '明溪县',
                parentIdAll: '1,1568,1638,1650',
                children: []
              }
            ]
          },
          {
            id: 1651,
            parentId: 1568,
            regionType: 2,
            regionName: '漳州市',
            parentIdAll: '1,1568,1651',
            children: [
              {
                id: 1652,
                parentId: 1651,
                regionType: 3,
                regionName: '华安县',
                parentIdAll: '1,1568,1651,1652',
                children: []
              },
              {
                id: 1653,
                parentId: 1651,
                regionType: 3,
                regionName: '南靖县',
                parentIdAll: '1,1568,1651,1653',
                children: []
              },
              {
                id: 1654,
                parentId: 1651,
                regionType: 3,
                regionName: '长泰县',
                parentIdAll: '1,1568,1651,1654',
                children: []
              },
              {
                id: 1655,
                parentId: 1651,
                regionType: 3,
                regionName: '云霄县',
                parentIdAll: '1,1568,1651,1655',
                children: []
              },
              {
                id: 1656,
                parentId: 1651,
                regionType: 3,
                regionName: '平和县',
                parentIdAll: '1,1568,1651,1656',
                children: []
              },
              {
                id: 1657,
                parentId: 1651,
                regionType: 3,
                regionName: '龙海市',
                parentIdAll: '1,1568,1651,1657',
                children: []
              },
              {
                id: 1658,
                parentId: 1651,
                regionType: 3,
                regionName: '漳浦县',
                parentIdAll: '1,1568,1651,1658',
                children: []
              },
              {
                id: 1659,
                parentId: 1651,
                regionType: 3,
                regionName: '诏安县',
                parentIdAll: '1,1568,1651,1659',
                children: []
              },
              {
                id: 1660,
                parentId: 1651,
                regionType: 3,
                regionName: '东山县',
                parentIdAll: '1,1568,1651,1660',
                children: []
              },
              {
                id: 1661,
                parentId: 1651,
                regionType: 3,
                regionName: '龙文区',
                parentIdAll: '1,1568,1651,1661',
                children: []
              },
              {
                id: 1662,
                parentId: 1651,
                regionType: 3,
                regionName: '芗城区',
                parentIdAll: '1,1568,1651,1662',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1663,
        parentId: 1,
        regionType: 1,
        regionName: '湖南省',
        parentIdAll: '1,1663',
        children: [
          {
            id: 1664,
            parentId: 1663,
            regionType: 2,
            regionName: '岳阳市',
            parentIdAll: '1,1663,1664',
            children: [
              {
                id: 1665,
                parentId: 1664,
                regionType: 3,
                regionName: '华容县',
                parentIdAll: '1,1663,1664,1665',
                children: []
              },
              {
                id: 1666,
                parentId: 1664,
                regionType: 3,
                regionName: '岳阳楼区',
                parentIdAll: '1,1663,1664,1666',
                children: []
              },
              {
                id: 1667,
                parentId: 1664,
                regionType: 3,
                regionName: '云溪区',
                parentIdAll: '1,1663,1664,1667',
                children: []
              },
              {
                id: 1668,
                parentId: 1664,
                regionType: 3,
                regionName: '君山区',
                parentIdAll: '1,1663,1664,1668',
                children: []
              },
              {
                id: 1669,
                parentId: 1664,
                regionType: 3,
                regionName: '汨罗市',
                parentIdAll: '1,1663,1664,1669',
                children: []
              },
              {
                id: 1670,
                parentId: 1664,
                regionType: 3,
                regionName: '湘阴县',
                parentIdAll: '1,1663,1664,1670',
                children: []
              },
              {
                id: 1671,
                parentId: 1664,
                regionType: 3,
                regionName: '岳阳县',
                parentIdAll: '1,1663,1664,1671',
                children: []
              },
              {
                id: 1672,
                parentId: 1664,
                regionType: 3,
                regionName: '临湘市',
                parentIdAll: '1,1663,1664,1672',
                children: []
              },
              {
                id: 1673,
                parentId: 1664,
                regionType: 3,
                regionName: '平江县',
                parentIdAll: '1,1663,1664,1673',
                children: []
              }
            ]
          },
          {
            id: 1674,
            parentId: 1663,
            regionType: 2,
            regionName: '衡阳市',
            parentIdAll: '1,1663,1674',
            children: [
              {
                id: 1675,
                parentId: 1674,
                regionType: 3,
                regionName: '衡山县',
                parentIdAll: '1,1663,1674,1675',
                children: []
              },
              {
                id: 1676,
                parentId: 1674,
                regionType: 3,
                regionName: '衡阳县',
                parentIdAll: '1,1663,1674,1676',
                children: []
              },
              {
                id: 1677,
                parentId: 1674,
                regionType: 3,
                regionName: '衡东县',
                parentIdAll: '1,1663,1674,1677',
                children: []
              },
              {
                id: 1678,
                parentId: 1674,
                regionType: 3,
                regionName: '南岳区',
                parentIdAll: '1,1663,1674,1678',
                children: []
              },
              {
                id: 1679,
                parentId: 1674,
                regionType: 3,
                regionName: '衡南县',
                parentIdAll: '1,1663,1674,1679',
                children: []
              },
              {
                id: 1680,
                parentId: 1674,
                regionType: 3,
                regionName: '雁峰区',
                parentIdAll: '1,1663,1674,1680',
                children: []
              },
              {
                id: 1681,
                parentId: 1674,
                regionType: 3,
                regionName: '珠晖区',
                parentIdAll: '1,1663,1674,1681',
                children: []
              },
              {
                id: 1682,
                parentId: 1674,
                regionType: 3,
                regionName: '石鼓区',
                parentIdAll: '1,1663,1674,1682',
                children: []
              },
              {
                id: 1683,
                parentId: 1674,
                regionType: 3,
                regionName: '祁东县',
                parentIdAll: '1,1663,1674,1683',
                children: []
              },
              {
                id: 1684,
                parentId: 1674,
                regionType: 3,
                regionName: '耒阳市',
                parentIdAll: '1,1663,1674,1684',
                children: []
              },
              {
                id: 1685,
                parentId: 1674,
                regionType: 3,
                regionName: '蒸湘区',
                parentIdAll: '1,1663,1674,1685',
                children: []
              },
              {
                id: 1686,
                parentId: 1674,
                regionType: 3,
                regionName: '常宁市',
                parentIdAll: '1,1663,1674,1686',
                children: []
              }
            ]
          },
          {
            id: 1687,
            parentId: 1663,
            regionType: 2,
            regionName: '娄底市',
            parentIdAll: '1,1663,1687',
            children: [
              {
                id: 1688,
                parentId: 1687,
                regionType: 3,
                regionName: '冷水江市',
                parentIdAll: '1,1663,1687,1688',
                children: []
              },
              {
                id: 1689,
                parentId: 1687,
                regionType: 3,
                regionName: '新化县',
                parentIdAll: '1,1663,1687,1689',
                children: []
              },
              {
                id: 1690,
                parentId: 1687,
                regionType: 3,
                regionName: '双峰县',
                parentIdAll: '1,1663,1687,1690',
                children: []
              },
              {
                id: 1691,
                parentId: 1687,
                regionType: 3,
                regionName: '娄星区',
                parentIdAll: '1,1663,1687,1691',
                children: []
              },
              {
                id: 1692,
                parentId: 1687,
                regionType: 3,
                regionName: '涟源市',
                parentIdAll: '1,1663,1687,1692',
                children: []
              }
            ]
          },
          {
            id: 1693,
            parentId: 1663,
            regionType: 2,
            regionName: '湘潭市',
            parentIdAll: '1,1663,1693',
            children: [
              {
                id: 1694,
                parentId: 1693,
                regionType: 3,
                regionName: '岳塘区',
                parentIdAll: '1,1663,1693,1694',
                children: []
              },
              {
                id: 1695,
                parentId: 1693,
                regionType: 3,
                regionName: '湘潭县',
                parentIdAll: '1,1663,1693,1695',
                children: []
              },
              {
                id: 1696,
                parentId: 1693,
                regionType: 3,
                regionName: '雨湖区',
                parentIdAll: '1,1663,1693,1696',
                children: []
              },
              {
                id: 1697,
                parentId: 1693,
                regionType: 3,
                regionName: '韶山市',
                parentIdAll: '1,1663,1693,1697',
                children: []
              },
              {
                id: 1698,
                parentId: 1693,
                regionType: 3,
                regionName: '湘乡市',
                parentIdAll: '1,1663,1693,1698',
                children: []
              }
            ]
          },
          {
            id: 1699,
            parentId: 1663,
            regionType: 2,
            regionName: '益阳市',
            parentIdAll: '1,1663,1699',
            children: [
              {
                id: 1700,
                parentId: 1699,
                regionType: 3,
                regionName: '沅江市',
                parentIdAll: '1,1663,1699,1700',
                children: []
              },
              {
                id: 1701,
                parentId: 1699,
                regionType: 3,
                regionName: '南县',
                parentIdAll: '1,1663,1699,1701',
                children: []
              },
              {
                id: 1702,
                parentId: 1699,
                regionType: 3,
                regionName: '资阳区',
                parentIdAll: '1,1663,1699,1702',
                children: []
              },
              {
                id: 1703,
                parentId: 1699,
                regionType: 3,
                regionName: '桃江县',
                parentIdAll: '1,1663,1699,1703',
                children: []
              },
              {
                id: 1704,
                parentId: 1699,
                regionType: 3,
                regionName: '赫山区',
                parentIdAll: '1,1663,1699,1704',
                children: []
              },
              {
                id: 1705,
                parentId: 1699,
                regionType: 3,
                regionName: '安化县',
                parentIdAll: '1,1663,1699,1705',
                children: []
              }
            ]
          },
          {
            id: 1706,
            parentId: 1663,
            regionType: 2,
            regionName: '长沙市',
            parentIdAll: '1,1663,1706',
            children: [
              {
                id: 1707,
                parentId: 1706,
                regionType: 3,
                regionName: '芙蓉区',
                parentIdAll: '1,1663,1706,1707',
                children: []
              },
              {
                id: 1708,
                parentId: 1706,
                regionType: 3,
                regionName: '开福区',
                parentIdAll: '1,1663,1706,1708',
                children: []
              },
              {
                id: 1709,
                parentId: 1706,
                regionType: 3,
                regionName: '岳麓区',
                parentIdAll: '1,1663,1706,1709',
                children: []
              },
              {
                id: 1710,
                parentId: 1706,
                regionType: 3,
                regionName: '长沙县',
                parentIdAll: '1,1663,1706,1710',
                children: []
              },
              {
                id: 1711,
                parentId: 1706,
                regionType: 3,
                regionName: '天心区',
                parentIdAll: '1,1663,1706,1711',
                children: []
              },
              {
                id: 1712,
                parentId: 1706,
                regionType: 3,
                regionName: '浏阳市',
                parentIdAll: '1,1663,1706,1712',
                children: []
              },
              {
                id: 1713,
                parentId: 1706,
                regionType: 3,
                regionName: '雨花区',
                parentIdAll: '1,1663,1706,1713',
                children: []
              },
              {
                id: 1714,
                parentId: 1706,
                regionType: 3,
                regionName: '宁乡市',
                parentIdAll: '1,1663,1706,1714',
                children: []
              },
              {
                id: 1715,
                parentId: 1706,
                regionType: 3,
                regionName: '望城区',
                parentIdAll: '1,1663,1706,1715',
                children: []
              }
            ]
          },
          {
            id: 1716,
            parentId: 1663,
            regionType: 2,
            regionName: '常德市',
            parentIdAll: '1,1663,1716',
            children: [
              {
                id: 1717,
                parentId: 1716,
                regionType: 3,
                regionName: '临澧县',
                parentIdAll: '1,1663,1716,1717',
                children: []
              },
              {
                id: 1718,
                parentId: 1716,
                regionType: 3,
                regionName: '石门县',
                parentIdAll: '1,1663,1716,1718',
                children: []
              },
              {
                id: 1719,
                parentId: 1716,
                regionType: 3,
                regionName: '津市市',
                parentIdAll: '1,1663,1716,1719',
                children: []
              },
              {
                id: 1720,
                parentId: 1716,
                regionType: 3,
                regionName: '澧县',
                parentIdAll: '1,1663,1716,1720',
                children: []
              },
              {
                id: 1721,
                parentId: 1716,
                regionType: 3,
                regionName: '鼎城区',
                parentIdAll: '1,1663,1716,1721',
                children: []
              },
              {
                id: 1722,
                parentId: 1716,
                regionType: 3,
                regionName: '武陵区',
                parentIdAll: '1,1663,1716,1722',
                children: []
              },
              {
                id: 1723,
                parentId: 1716,
                regionType: 3,
                regionName: '汉寿县',
                parentIdAll: '1,1663,1716,1723',
                children: []
              },
              {
                id: 1724,
                parentId: 1716,
                regionType: 3,
                regionName: '桃源县',
                parentIdAll: '1,1663,1716,1724',
                children: []
              },
              {
                id: 1725,
                parentId: 1716,
                regionType: 3,
                regionName: '安乡县',
                parentIdAll: '1,1663,1716,1725',
                children: []
              }
            ]
          },
          {
            id: 1726,
            parentId: 1663,
            regionType: 2,
            regionName: '怀化市',
            parentIdAll: '1,1663,1726',
            children: [
              {
                id: 1727,
                parentId: 1726,
                regionType: 3,
                regionName: '芷江侗族自治县',
                parentIdAll: '1,1663,1726,1727',
                children: []
              },
              {
                id: 1728,
                parentId: 1726,
                regionType: 3,
                regionName: '洪江市',
                parentIdAll: '1,1663,1726,1728',
                children: []
              },
              {
                id: 1729,
                parentId: 1726,
                regionType: 3,
                regionName: '通道侗族自治县',
                parentIdAll: '1,1663,1726,1729',
                children: []
              },
              {
                id: 1730,
                parentId: 1726,
                regionType: 3,
                regionName: '沅陵县',
                parentIdAll: '1,1663,1726,1730',
                children: []
              },
              {
                id: 1731,
                parentId: 1726,
                regionType: 3,
                regionName: '辰溪县',
                parentIdAll: '1,1663,1726,1731',
                children: []
              },
              {
                id: 1732,
                parentId: 1726,
                regionType: 3,
                regionName: '溆浦县',
                parentIdAll: '1,1663,1726,1732',
                children: []
              },
              {
                id: 1733,
                parentId: 1726,
                regionType: 3,
                regionName: '麻阳苗族自治县',
                parentIdAll: '1,1663,1726,1733',
                children: []
              },
              {
                id: 1734,
                parentId: 1726,
                regionType: 3,
                regionName: '靖州苗族侗族自治县',
                parentIdAll: '1,1663,1726,1734',
                children: []
              },
              {
                id: 1735,
                parentId: 1726,
                regionType: 3,
                regionName: '新晃侗族自治县',
                parentIdAll: '1,1663,1726,1735',
                children: []
              },
              {
                id: 1736,
                parentId: 1726,
                regionType: 3,
                regionName: '会同县',
                parentIdAll: '1,1663,1726,1736',
                children: []
              },
              {
                id: 1737,
                parentId: 1726,
                regionType: 3,
                regionName: '鹤城区',
                parentIdAll: '1,1663,1726,1737',
                children: []
              },
              {
                id: 1738,
                parentId: 1726,
                regionType: 3,
                regionName: '中方县',
                parentIdAll: '1,1663,1726,1738',
                children: []
              }
            ]
          },
          {
            id: 1739,
            parentId: 1663,
            regionType: 2,
            regionName: '株洲市',
            parentIdAll: '1,1663,1739',
            children: [
              {
                id: 1740,
                parentId: 1739,
                regionType: 3,
                regionName: '荷塘区',
                parentIdAll: '1,1663,1739,1740',
                children: []
              },
              {
                id: 1741,
                parentId: 1739,
                regionType: 3,
                regionName: '茶陵县',
                parentIdAll: '1,1663,1739,1741',
                children: []
              },
              {
                id: 1742,
                parentId: 1739,
                regionType: 3,
                regionName: '攸县',
                parentIdAll: '1,1663,1739,1742',
                children: []
              },
              {
                id: 1743,
                parentId: 1739,
                regionType: 3,
                regionName: '炎陵县',
                parentIdAll: '1,1663,1739,1743',
                children: []
              },
              {
                id: 1744,
                parentId: 1739,
                regionType: 3,
                regionName: '天元区',
                parentIdAll: '1,1663,1739,1744',
                children: []
              },
              {
                id: 1745,
                parentId: 1739,
                regionType: 3,
                regionName: '芦淞区',
                parentIdAll: '1,1663,1739,1745',
                children: []
              },
              {
                id: 1746,
                parentId: 1739,
                regionType: 3,
                regionName: '株洲县',
                parentIdAll: '1,1663,1739,1746',
                children: []
              },
              {
                id: 1747,
                parentId: 1739,
                regionType: 3,
                regionName: '石峰区',
                parentIdAll: '1,1663,1739,1747',
                children: []
              },
              {
                id: 1748,
                parentId: 1739,
                regionType: 3,
                regionName: '醴陵市',
                parentIdAll: '1,1663,1739,1748',
                children: []
              }
            ]
          },
          {
            id: 1749,
            parentId: 1663,
            regionType: 2,
            regionName: '邵阳市',
            parentIdAll: '1,1663,1749',
            children: [
              {
                id: 1750,
                parentId: 1749,
                regionType: 3,
                regionName: '邵东县',
                parentIdAll: '1,1663,1749,1750',
                children: []
              },
              {
                id: 1751,
                parentId: 1749,
                regionType: 3,
                regionName: '隆回县',
                parentIdAll: '1,1663,1749,1751',
                children: []
              },
              {
                id: 1752,
                parentId: 1749,
                regionType: 3,
                regionName: '北塔区',
                parentIdAll: '1,1663,1749,1752',
                children: []
              },
              {
                id: 1753,
                parentId: 1749,
                regionType: 3,
                regionName: '邵阳县',
                parentIdAll: '1,1663,1749,1753',
                children: []
              },
              {
                id: 1754,
                parentId: 1749,
                regionType: 3,
                regionName: '绥宁县',
                parentIdAll: '1,1663,1749,1754',
                children: []
              },
              {
                id: 1755,
                parentId: 1749,
                regionType: 3,
                regionName: '双清区',
                parentIdAll: '1,1663,1749,1755',
                children: []
              },
              {
                id: 1756,
                parentId: 1749,
                regionType: 3,
                regionName: '大祥区',
                parentIdAll: '1,1663,1749,1756',
                children: []
              },
              {
                id: 1757,
                parentId: 1749,
                regionType: 3,
                regionName: '新宁县',
                parentIdAll: '1,1663,1749,1757',
                children: []
              },
              {
                id: 1758,
                parentId: 1749,
                regionType: 3,
                regionName: '城步苗族自治县',
                parentIdAll: '1,1663,1749,1758',
                children: []
              },
              {
                id: 1759,
                parentId: 1749,
                regionType: 3,
                regionName: '新邵县',
                parentIdAll: '1,1663,1749,1759',
                children: []
              },
              {
                id: 1760,
                parentId: 1749,
                regionType: 3,
                regionName: '洞口县',
                parentIdAll: '1,1663,1749,1760',
                children: []
              },
              {
                id: 1761,
                parentId: 1749,
                regionType: 3,
                regionName: '武冈市',
                parentIdAll: '1,1663,1749,1761',
                children: []
              }
            ]
          },
          {
            id: 1762,
            parentId: 1663,
            regionType: 2,
            regionName: '湘西土家族苗族自治州',
            parentIdAll: '1,1663,1762',
            children: [
              {
                id: 1763,
                parentId: 1762,
                regionType: 3,
                regionName: '永顺县',
                parentIdAll: '1,1663,1762,1763',
                children: []
              },
              {
                id: 1764,
                parentId: 1762,
                regionType: 3,
                regionName: '保靖县',
                parentIdAll: '1,1663,1762,1764',
                children: []
              },
              {
                id: 1765,
                parentId: 1762,
                regionType: 3,
                regionName: '花垣县',
                parentIdAll: '1,1663,1762,1765',
                children: []
              },
              {
                id: 1766,
                parentId: 1762,
                regionType: 3,
                regionName: '吉首市',
                parentIdAll: '1,1663,1762,1766',
                children: []
              },
              {
                id: 1767,
                parentId: 1762,
                regionType: 3,
                regionName: '古丈县',
                parentIdAll: '1,1663,1762,1767',
                children: []
              },
              {
                id: 1768,
                parentId: 1762,
                regionType: 3,
                regionName: '泸溪县',
                parentIdAll: '1,1663,1762,1768',
                children: []
              },
              {
                id: 1769,
                parentId: 1762,
                regionType: 3,
                regionName: '凤凰县',
                parentIdAll: '1,1663,1762,1769',
                children: []
              },
              {
                id: 1770,
                parentId: 1762,
                regionType: 3,
                regionName: '龙山县',
                parentIdAll: '1,1663,1762,1770',
                children: []
              }
            ]
          },
          {
            id: 1771,
            parentId: 1663,
            regionType: 2,
            regionName: '永州市',
            parentIdAll: '1,1663,1771',
            children: [
              {
                id: 1772,
                parentId: 1771,
                regionType: 3,
                regionName: '冷水滩区',
                parentIdAll: '1,1663,1771,1772',
                children: []
              },
              {
                id: 1773,
                parentId: 1771,
                regionType: 3,
                regionName: '东安县',
                parentIdAll: '1,1663,1771,1773',
                children: []
              },
              {
                id: 1774,
                parentId: 1771,
                regionType: 3,
                regionName: '祁阳县',
                parentIdAll: '1,1663,1771,1774',
                children: []
              },
              {
                id: 1775,
                parentId: 1771,
                regionType: 3,
                regionName: '零陵区',
                parentIdAll: '1,1663,1771,1775',
                children: []
              },
              {
                id: 1776,
                parentId: 1771,
                regionType: 3,
                regionName: '新田县',
                parentIdAll: '1,1663,1771,1776',
                children: []
              },
              {
                id: 1777,
                parentId: 1771,
                regionType: 3,
                regionName: '道县',
                parentIdAll: '1,1663,1771,1777',
                children: []
              },
              {
                id: 1778,
                parentId: 1771,
                regionType: 3,
                regionName: '蓝山县',
                parentIdAll: '1,1663,1771,1778',
                children: []
              },
              {
                id: 1779,
                parentId: 1771,
                regionType: 3,
                regionName: '江永县',
                parentIdAll: '1,1663,1771,1779',
                children: []
              },
              {
                id: 1780,
                parentId: 1771,
                regionType: 3,
                regionName: '江华瑶族自治县',
                parentIdAll: '1,1663,1771,1780',
                children: []
              },
              {
                id: 1781,
                parentId: 1771,
                regionType: 3,
                regionName: '宁远县',
                parentIdAll: '1,1663,1771,1781',
                children: []
              },
              {
                id: 1782,
                parentId: 1771,
                regionType: 3,
                regionName: '双牌县',
                parentIdAll: '1,1663,1771,1782',
                children: []
              }
            ]
          },
          {
            id: 1783,
            parentId: 1663,
            regionType: 2,
            regionName: '张家界市',
            parentIdAll: '1,1663,1783',
            children: [
              {
                id: 1784,
                parentId: 1783,
                regionType: 3,
                regionName: '武陵源区',
                parentIdAll: '1,1663,1783,1784',
                children: []
              },
              {
                id: 1785,
                parentId: 1783,
                regionType: 3,
                regionName: '慈利县',
                parentIdAll: '1,1663,1783,1785',
                children: []
              },
              {
                id: 1786,
                parentId: 1783,
                regionType: 3,
                regionName: '桑植县',
                parentIdAll: '1,1663,1783,1786',
                children: []
              },
              {
                id: 1787,
                parentId: 1783,
                regionType: 3,
                regionName: '永定区',
                parentIdAll: '1,1663,1783,1787',
                children: []
              }
            ]
          },
          {
            id: 1788,
            parentId: 1663,
            regionType: 2,
            regionName: '郴州市',
            parentIdAll: '1,1663,1788',
            children: [
              {
                id: 1789,
                parentId: 1788,
                regionType: 3,
                regionName: '安仁县',
                parentIdAll: '1,1663,1788,1789',
                children: []
              },
              {
                id: 1790,
                parentId: 1788,
                regionType: 3,
                regionName: '永兴县',
                parentIdAll: '1,1663,1788,1790',
                children: []
              },
              {
                id: 1791,
                parentId: 1788,
                regionType: 3,
                regionName: '苏仙区',
                parentIdAll: '1,1663,1788,1791',
                children: []
              },
              {
                id: 1792,
                parentId: 1788,
                regionType: 3,
                regionName: '资兴市',
                parentIdAll: '1,1663,1788,1792',
                children: []
              },
              {
                id: 1793,
                parentId: 1788,
                regionType: 3,
                regionName: '桂东县',
                parentIdAll: '1,1663,1788,1793',
                children: []
              },
              {
                id: 1794,
                parentId: 1788,
                regionType: 3,
                regionName: '汝城县',
                parentIdAll: '1,1663,1788,1794',
                children: []
              },
              {
                id: 1795,
                parentId: 1788,
                regionType: 3,
                regionName: '北湖区',
                parentIdAll: '1,1663,1788,1795',
                children: []
              },
              {
                id: 1796,
                parentId: 1788,
                regionType: 3,
                regionName: '临武县',
                parentIdAll: '1,1663,1788,1796',
                children: []
              },
              {
                id: 1797,
                parentId: 1788,
                regionType: 3,
                regionName: '嘉禾县',
                parentIdAll: '1,1663,1788,1797',
                children: []
              },
              {
                id: 1798,
                parentId: 1788,
                regionType: 3,
                regionName: '桂阳县',
                parentIdAll: '1,1663,1788,1798',
                children: []
              },
              {
                id: 1799,
                parentId: 1788,
                regionType: 3,
                regionName: '宜章县',
                parentIdAll: '1,1663,1788,1799',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1800,
        parentId: 1,
        regionType: 1,
        regionName: '海南省',
        parentIdAll: '1,1800',
        children: [
          {
            id: 1801,
            parentId: 1800,
            regionType: 2,
            regionName: '屯昌县',
            parentIdAll: '1,1800,1801',
            children: [
              {
                id: 3276,
                parentId: 1801,
                regionType: 3,
                regionName: '屯昌县',
                parentIdAll: '1,1800,1801,3276',
                children: []
              }
            ]
          },
          {
            id: 1802,
            parentId: 1800,
            regionType: 2,
            regionName: '琼海市',
            parentIdAll: '1,1800,1802',
            children: [
              {
                id: 3277,
                parentId: 1802,
                regionType: 3,
                regionName: '琼海市',
                parentIdAll: '1,1800,1802,3277',
                children: []
              }
            ]
          },
          {
            id: 1803,
            parentId: 1800,
            regionType: 2,
            regionName: '昌江黎族自治县',
            parentIdAll: '1,1800,1803',
            children: [
              {
                id: 3278,
                parentId: 1803,
                regionType: 3,
                regionName: '昌江黎族自治县',
                parentIdAll: '1,1800,1803,3278',
                children: []
              }
            ]
          },
          {
            id: 1804,
            parentId: 1800,
            regionType: 2,
            regionName: '临高县',
            parentIdAll: '1,1800,1804',
            children: [
              {
                id: 3279,
                parentId: 1804,
                regionType: 3,
                regionName: '临高县',
                parentIdAll: '1,1800,1804,3279',
                children: []
              }
            ]
          },
          {
            id: 1805,
            parentId: 1800,
            regionType: 2,
            regionName: '定安县',
            parentIdAll: '1,1800,1805',
            children: [
              {
                id: 3280,
                parentId: 1805,
                regionType: 3,
                regionName: '定安县',
                parentIdAll: '1,1800,1805,3280',
                children: []
              }
            ]
          },
          {
            id: 1806,
            parentId: 1800,
            regionType: 2,
            regionName: '琼中黎族苗族自治县',
            parentIdAll: '1,1800,1806',
            children: [
              {
                id: 3281,
                parentId: 1806,
                regionType: 3,
                regionName: '琼中黎族苗族自治县',
                parentIdAll: '1,1800,1806,3281',
                children: []
              }
            ]
          },
          {
            id: 1807,
            parentId: 1800,
            regionType: 2,
            regionName: '东方市',
            parentIdAll: '1,1800,1807',
            children: [
              {
                id: 3282,
                parentId: 1807,
                regionType: 3,
                regionName: '东方市',
                parentIdAll: '1,1800,1807,3282',
                children: []
              }
            ]
          },
          {
            id: 1808,
            parentId: 1800,
            regionType: 2,
            regionName: '白沙黎族自治县',
            parentIdAll: '1,1800,1808',
            children: [
              {
                id: 3283,
                parentId: 1808,
                regionType: 3,
                regionName: '白沙黎族自治县',
                parentIdAll: '1,1800,1808,3283',
                children: []
              }
            ]
          },
          {
            id: 1809,
            parentId: 1800,
            regionType: 2,
            regionName: '五指山市',
            parentIdAll: '1,1800,1809',
            children: [
              {
                id: 3284,
                parentId: 1809,
                regionType: 3,
                regionName: '五指山市',
                parentIdAll: '1,1800,1809,3284',
                children: []
              }
            ]
          },
          {
            id: 1810,
            parentId: 1800,
            regionType: 2,
            regionName: '万宁市',
            parentIdAll: '1,1800,1810',
            children: [
              {
                id: 3285,
                parentId: 1810,
                regionType: 3,
                regionName: '万宁市',
                parentIdAll: '1,1800,1810,3285',
                children: []
              }
            ]
          },
          {
            id: 1811,
            parentId: 1800,
            regionType: 2,
            regionName: '乐东黎族自治县',
            parentIdAll: '1,1800,1811',
            children: [
              {
                id: 3286,
                parentId: 1811,
                regionType: 3,
                regionName: '乐东黎族自治县',
                parentIdAll: '1,1800,1811,3286',
                children: []
              }
            ]
          },
          {
            id: 1812,
            parentId: 1800,
            regionType: 2,
            regionName: '文昌市',
            parentIdAll: '1,1800,1812',
            children: [
              {
                id: 3287,
                parentId: 1812,
                regionType: 3,
                regionName: '文昌市',
                parentIdAll: '1,1800,1812,3287',
                children: []
              }
            ]
          },
          {
            id: 1813,
            parentId: 1800,
            regionType: 2,
            regionName: '保亭黎族苗族自治县',
            parentIdAll: '1,1800,1813',
            children: [
              {
                id: 3288,
                parentId: 1813,
                regionType: 3,
                regionName: '保亭黎族苗族自治县',
                parentIdAll: '1,1800,1813,3288',
                children: []
              }
            ]
          },
          {
            id: 1814,
            parentId: 1800,
            regionType: 2,
            regionName: '澄迈县',
            parentIdAll: '1,1800,1814',
            children: [
              {
                id: 3289,
                parentId: 1814,
                regionType: 3,
                regionName: '澄迈县',
                parentIdAll: '1,1800,1814,3289',
                children: []
              }
            ]
          },
          {
            id: 1815,
            parentId: 1800,
            regionType: 2,
            regionName: '三亚市',
            parentIdAll: '1,1800,1815',
            children: [
              {
                id: 1816,
                parentId: 1815,
                regionType: 3,
                regionName: '崖州区',
                parentIdAll: '1,1800,1815,1816',
                children: []
              },
              {
                id: 1817,
                parentId: 1815,
                regionType: 3,
                regionName: '天涯区',
                parentIdAll: '1,1800,1815,1817',
                children: []
              },
              {
                id: 1818,
                parentId: 1815,
                regionType: 3,
                regionName: '海棠区',
                parentIdAll: '1,1800,1815,1818',
                children: []
              },
              {
                id: 1819,
                parentId: 1815,
                regionType: 3,
                regionName: '吉阳区',
                parentIdAll: '1,1800,1815,1819',
                children: []
              }
            ]
          },
          {
            id: 1820,
            parentId: 1800,
            regionType: 2,
            regionName: '陵水黎族自治县',
            parentIdAll: '1,1800,1820',
            children: [
              {
                id: 3290,
                parentId: 1820,
                regionType: 3,
                regionName: '陵水黎族自治县',
                parentIdAll: '1,1800,1820,3290',
                children: []
              }
            ]
          },
          {
            id: 1821,
            parentId: 1800,
            regionType: 2,
            regionName: '三沙市',
            parentIdAll: '1,1800,1821',
            children: [
              {
                id: 1822,
                parentId: 1821,
                regionType: 3,
                regionName: '南沙群岛',
                parentIdAll: '1,1800,1821,1822',
                children: []
              },
              {
                id: 1823,
                parentId: 1821,
                regionType: 3,
                regionName: '西沙群岛',
                parentIdAll: '1,1800,1821,1823',
                children: []
              },
              {
                id: 1824,
                parentId: 1821,
                regionType: 3,
                regionName: '中沙群岛的岛礁及其海域',
                parentIdAll: '1,1800,1821,1824',
                children: []
              }
            ]
          },
          {
            id: 1825,
            parentId: 1800,
            regionType: 2,
            regionName: '儋州市',
            parentIdAll: '1,1800,1825',
            children: [
              {
                id: 3291,
                parentId: 1825,
                regionType: 3,
                regionName: '儋州市',
                parentIdAll: '1,1800,1825,3291',
                children: []
              }
            ]
          },
          {
            id: 1826,
            parentId: 1800,
            regionType: 2,
            regionName: '海口市',
            parentIdAll: '1,1800,1826',
            children: [
              {
                id: 1827,
                parentId: 1826,
                regionType: 3,
                regionName: '美兰区',
                parentIdAll: '1,1800,1826,1827',
                children: []
              },
              {
                id: 1828,
                parentId: 1826,
                regionType: 3,
                regionName: '琼山区',
                parentIdAll: '1,1800,1826,1828',
                children: []
              },
              {
                id: 1829,
                parentId: 1826,
                regionType: 3,
                regionName: '龙华区',
                parentIdAll: '1,1800,1826,1829',
                children: []
              },
              {
                id: 1830,
                parentId: 1826,
                regionType: 3,
                regionName: '秀英区',
                parentIdAll: '1,1800,1826,1830',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1831,
        parentId: 1,
        regionType: 1,
        regionName: '江苏省',
        parentIdAll: '1,1831',
        children: [
          {
            id: 1832,
            parentId: 1831,
            regionType: 2,
            regionName: '扬州市',
            parentIdAll: '1,1831,1832',
            children: [
              {
                id: 1833,
                parentId: 1832,
                regionType: 3,
                regionName: '江都区',
                parentIdAll: '1,1831,1832,1833',
                children: []
              },
              {
                id: 1834,
                parentId: 1832,
                regionType: 3,
                regionName: '宝应县',
                parentIdAll: '1,1831,1832,1834',
                children: []
              },
              {
                id: 1835,
                parentId: 1832,
                regionType: 3,
                regionName: '邗江区',
                parentIdAll: '1,1831,1832,1835',
                children: []
              },
              {
                id: 1836,
                parentId: 1832,
                regionType: 3,
                regionName: '广陵区',
                parentIdAll: '1,1831,1832,1836',
                children: []
              },
              {
                id: 1837,
                parentId: 1832,
                regionType: 3,
                regionName: '高邮市',
                parentIdAll: '1,1831,1832,1837',
                children: []
              },
              {
                id: 1838,
                parentId: 1832,
                regionType: 3,
                regionName: '仪征市',
                parentIdAll: '1,1831,1832,1838',
                children: []
              }
            ]
          },
          {
            id: 1839,
            parentId: 1831,
            regionType: 2,
            regionName: '南京市',
            parentIdAll: '1,1831,1839',
            children: [
              {
                id: 1840,
                parentId: 1839,
                regionType: 3,
                regionName: '浦口区',
                parentIdAll: '1,1831,1839,1840',
                children: []
              },
              {
                id: 1841,
                parentId: 1839,
                regionType: 3,
                regionName: '雨花台区',
                parentIdAll: '1,1831,1839,1841',
                children: []
              },
              {
                id: 1842,
                parentId: 1839,
                regionType: 3,
                regionName: '溧水区',
                parentIdAll: '1,1831,1839,1842',
                children: []
              },
              {
                id: 1843,
                parentId: 1839,
                regionType: 3,
                regionName: '江宁区',
                parentIdAll: '1,1831,1839,1843',
                children: []
              },
              {
                id: 1844,
                parentId: 1839,
                regionType: 3,
                regionName: '玄武区',
                parentIdAll: '1,1831,1839,1844',
                children: []
              },
              {
                id: 1845,
                parentId: 1839,
                regionType: 3,
                regionName: '栖霞区',
                parentIdAll: '1,1831,1839,1845',
                children: []
              },
              {
                id: 1846,
                parentId: 1839,
                regionType: 3,
                regionName: '六合区',
                parentIdAll: '1,1831,1839,1846',
                children: []
              },
              {
                id: 1847,
                parentId: 1839,
                regionType: 3,
                regionName: '秦淮区',
                parentIdAll: '1,1831,1839,1847',
                children: []
              },
              {
                id: 1848,
                parentId: 1839,
                regionType: 3,
                regionName: '高淳区',
                parentIdAll: '1,1831,1839,1848',
                children: []
              },
              {
                id: 1849,
                parentId: 1839,
                regionType: 3,
                regionName: '建邺区',
                parentIdAll: '1,1831,1839,1849',
                children: []
              },
              {
                id: 1850,
                parentId: 1839,
                regionType: 3,
                regionName: '鼓楼区',
                parentIdAll: '1,1831,1839,1850',
                children: []
              }
            ]
          },
          {
            id: 1851,
            parentId: 1831,
            regionType: 2,
            regionName: '宿迁市',
            parentIdAll: '1,1831,1851',
            children: [
              {
                id: 1852,
                parentId: 1851,
                regionType: 3,
                regionName: '沭阳县',
                parentIdAll: '1,1831,1851,1852',
                children: []
              },
              {
                id: 1853,
                parentId: 1851,
                regionType: 3,
                regionName: '泗阳县',
                parentIdAll: '1,1831,1851,1853',
                children: []
              },
              {
                id: 1854,
                parentId: 1851,
                regionType: 3,
                regionName: '泗洪县',
                parentIdAll: '1,1831,1851,1854',
                children: []
              },
              {
                id: 1855,
                parentId: 1851,
                regionType: 3,
                regionName: '宿城区',
                parentIdAll: '1,1831,1851,1855',
                children: []
              },
              {
                id: 1856,
                parentId: 1851,
                regionType: 3,
                regionName: '宿豫区',
                parentIdAll: '1,1831,1851,1856',
                children: []
              }
            ]
          },
          {
            id: 1857,
            parentId: 1831,
            regionType: 2,
            regionName: '连云港市',
            parentIdAll: '1,1831,1857',
            children: [
              {
                id: 1858,
                parentId: 1857,
                regionType: 3,
                regionName: '东海县',
                parentIdAll: '1,1831,1857,1858',
                children: []
              },
              {
                id: 1859,
                parentId: 1857,
                regionType: 3,
                regionName: '灌云县',
                parentIdAll: '1,1831,1857,1859',
                children: []
              },
              {
                id: 1860,
                parentId: 1857,
                regionType: 3,
                regionName: '连云区',
                parentIdAll: '1,1831,1857,1860',
                children: []
              },
              {
                id: 1861,
                parentId: 1857,
                regionType: 3,
                regionName: '灌南县',
                parentIdAll: '1,1831,1857,1861',
                children: []
              },
              {
                id: 1862,
                parentId: 1857,
                regionType: 3,
                regionName: '赣榆区',
                parentIdAll: '1,1831,1857,1862',
                children: []
              },
              {
                id: 1863,
                parentId: 1857,
                regionType: 3,
                regionName: '海州区',
                parentIdAll: '1,1831,1857,1863',
                children: []
              }
            ]
          },
          {
            id: 1864,
            parentId: 1831,
            regionType: 2,
            regionName: '镇江市',
            parentIdAll: '1,1831,1864',
            children: [
              {
                id: 1865,
                parentId: 1864,
                regionType: 3,
                regionName: '扬中市',
                parentIdAll: '1,1831,1864,1865',
                children: []
              },
              {
                id: 1866,
                parentId: 1864,
                regionType: 3,
                regionName: '丹阳市',
                parentIdAll: '1,1831,1864,1866',
                children: []
              },
              {
                id: 1867,
                parentId: 1864,
                regionType: 3,
                regionName: '润州区',
                parentIdAll: '1,1831,1864,1867',
                children: []
              },
              {
                id: 1868,
                parentId: 1864,
                regionType: 3,
                regionName: '句容市',
                parentIdAll: '1,1831,1864,1868',
                children: []
              },
              {
                id: 1869,
                parentId: 1864,
                regionType: 3,
                regionName: '丹徒区',
                parentIdAll: '1,1831,1864,1869',
                children: []
              },
              {
                id: 1870,
                parentId: 1864,
                regionType: 3,
                regionName: '京口区',
                parentIdAll: '1,1831,1864,1870',
                children: []
              }
            ]
          },
          {
            id: 1871,
            parentId: 1831,
            regionType: 2,
            regionName: '南通市',
            parentIdAll: '1,1831,1871',
            children: [
              {
                id: 1872,
                parentId: 1871,
                regionType: 3,
                regionName: '海门市',
                parentIdAll: '1,1831,1871,1872',
                children: []
              },
              {
                id: 1873,
                parentId: 1871,
                regionType: 3,
                regionName: '如皋市',
                parentIdAll: '1,1831,1871,1873',
                children: []
              },
              {
                id: 1874,
                parentId: 1871,
                regionType: 3,
                regionName: '海安县',
                parentIdAll: '1,1831,1871,1874',
                children: []
              },
              {
                id: 1875,
                parentId: 1871,
                regionType: 3,
                regionName: '启东市',
                parentIdAll: '1,1831,1871,1875',
                children: []
              },
              {
                id: 1876,
                parentId: 1871,
                regionType: 3,
                regionName: '如东县',
                parentIdAll: '1,1831,1871,1876',
                children: []
              },
              {
                id: 1877,
                parentId: 1871,
                regionType: 3,
                regionName: '通州区',
                parentIdAll: '1,1831,1871,1877',
                children: []
              },
              {
                id: 1878,
                parentId: 1871,
                regionType: 3,
                regionName: '崇川区',
                parentIdAll: '1,1831,1871,1878',
                children: []
              },
              {
                id: 1879,
                parentId: 1871,
                regionType: 3,
                regionName: '港闸区',
                parentIdAll: '1,1831,1871,1879',
                children: []
              }
            ]
          },
          {
            id: 1880,
            parentId: 1831,
            regionType: 2,
            regionName: '无锡市',
            parentIdAll: '1,1831,1880',
            children: [
              {
                id: 1881,
                parentId: 1880,
                regionType: 3,
                regionName: '滨湖区',
                parentIdAll: '1,1831,1880,1881',
                children: []
              },
              {
                id: 1882,
                parentId: 1880,
                regionType: 3,
                regionName: '惠山区',
                parentIdAll: '1,1831,1880,1882',
                children: []
              },
              {
                id: 1883,
                parentId: 1880,
                regionType: 3,
                regionName: '江阴市',
                parentIdAll: '1,1831,1880,1883',
                children: []
              },
              {
                id: 1884,
                parentId: 1880,
                regionType: 3,
                regionName: '梁溪区',
                parentIdAll: '1,1831,1880,1884',
                children: []
              },
              {
                id: 1885,
                parentId: 1880,
                regionType: 3,
                regionName: '锡山区',
                parentIdAll: '1,1831,1880,1885',
                children: []
              },
              {
                id: 1886,
                parentId: 1880,
                regionType: 3,
                regionName: '新吴区',
                parentIdAll: '1,1831,1880,1886',
                children: []
              },
              {
                id: 1887,
                parentId: 1880,
                regionType: 3,
                regionName: '宜兴市',
                parentIdAll: '1,1831,1880,1887',
                children: []
              }
            ]
          },
          {
            id: 1888,
            parentId: 1831,
            regionType: 2,
            regionName: '淮安市',
            parentIdAll: '1,1831,1888',
            children: [
              {
                id: 1889,
                parentId: 1888,
                regionType: 3,
                regionName: '洪泽区',
                parentIdAll: '1,1831,1888,1889',
                children: []
              },
              {
                id: 1890,
                parentId: 1888,
                regionType: 3,
                regionName: '淮安区',
                parentIdAll: '1,1831,1888,1890',
                children: []
              },
              {
                id: 1891,
                parentId: 1888,
                regionType: 3,
                regionName: '淮阴区',
                parentIdAll: '1,1831,1888,1891',
                children: []
              },
              {
                id: 1892,
                parentId: 1888,
                regionType: 3,
                regionName: '金湖县',
                parentIdAll: '1,1831,1888,1892',
                children: []
              },
              {
                id: 1893,
                parentId: 1888,
                regionType: 3,
                regionName: '涟水县',
                parentIdAll: '1,1831,1888,1893',
                children: []
              },
              {
                id: 1894,
                parentId: 1888,
                regionType: 3,
                regionName: '清江浦区',
                parentIdAll: '1,1831,1888,1894',
                children: []
              },
              {
                id: 1895,
                parentId: 1888,
                regionType: 3,
                regionName: '盱眙县',
                parentIdAll: '1,1831,1888,1895',
                children: []
              }
            ]
          },
          {
            id: 1896,
            parentId: 1831,
            regionType: 2,
            regionName: '泰州市',
            parentIdAll: '1,1831,1896',
            children: [
              {
                id: 1897,
                parentId: 1896,
                regionType: 3,
                regionName: '兴化市',
                parentIdAll: '1,1831,1896,1897',
                children: []
              },
              {
                id: 1898,
                parentId: 1896,
                regionType: 3,
                regionName: '靖江市',
                parentIdAll: '1,1831,1896,1898',
                children: []
              },
              {
                id: 1899,
                parentId: 1896,
                regionType: 3,
                regionName: '海陵区',
                parentIdAll: '1,1831,1896,1899',
                children: []
              },
              {
                id: 1900,
                parentId: 1896,
                regionType: 3,
                regionName: '高港区',
                parentIdAll: '1,1831,1896,1900',
                children: []
              },
              {
                id: 1901,
                parentId: 1896,
                regionType: 3,
                regionName: '泰兴市',
                parentIdAll: '1,1831,1896,1901',
                children: []
              },
              {
                id: 1902,
                parentId: 1896,
                regionType: 3,
                regionName: '姜堰区',
                parentIdAll: '1,1831,1896,1902',
                children: []
              }
            ]
          },
          {
            id: 1903,
            parentId: 1831,
            regionType: 2,
            regionName: '徐州市',
            parentIdAll: '1,1831,1903',
            children: [
              {
                id: 1904,
                parentId: 1903,
                regionType: 3,
                regionName: '丰县',
                parentIdAll: '1,1831,1903,1904',
                children: []
              },
              {
                id: 1905,
                parentId: 1903,
                regionType: 3,
                regionName: '鼓楼区',
                parentIdAll: '1,1831,1903,1905',
                children: []
              },
              {
                id: 1906,
                parentId: 1903,
                regionType: 3,
                regionName: '贾汪区',
                parentIdAll: '1,1831,1903,1906',
                children: []
              },
              {
                id: 1907,
                parentId: 1903,
                regionType: 3,
                regionName: '沛县',
                parentIdAll: '1,1831,1903,1907',
                children: []
              },
              {
                id: 1908,
                parentId: 1903,
                regionType: 3,
                regionName: '邳州市',
                parentIdAll: '1,1831,1903,1908',
                children: []
              },
              {
                id: 1909,
                parentId: 1903,
                regionType: 3,
                regionName: '泉山区',
                parentIdAll: '1,1831,1903,1909',
                children: []
              },
              {
                id: 1910,
                parentId: 1903,
                regionType: 3,
                regionName: '睢宁县',
                parentIdAll: '1,1831,1903,1910',
                children: []
              },
              {
                id: 1911,
                parentId: 1903,
                regionType: 3,
                regionName: '铜山区',
                parentIdAll: '1,1831,1903,1911',
                children: []
              },
              {
                id: 1912,
                parentId: 1903,
                regionType: 3,
                regionName: '新沂市',
                parentIdAll: '1,1831,1903,1912',
                children: []
              },
              {
                id: 1913,
                parentId: 1903,
                regionType: 3,
                regionName: '云龙区',
                parentIdAll: '1,1831,1903,1913',
                children: []
              }
            ]
          },
          {
            id: 1914,
            parentId: 1831,
            regionType: 2,
            regionName: '苏州市',
            parentIdAll: '1,1831,1914',
            children: [
              {
                id: 1915,
                parentId: 1914,
                regionType: 3,
                regionName: '常熟市',
                parentIdAll: '1,1831,1914,1915',
                children: []
              },
              {
                id: 1916,
                parentId: 1914,
                regionType: 3,
                regionName: '姑苏区',
                parentIdAll: '1,1831,1914,1916',
                children: []
              },
              {
                id: 1917,
                parentId: 1914,
                regionType: 3,
                regionName: '虎丘区',
                parentIdAll: '1,1831,1914,1917',
                children: []
              },
              {
                id: 1918,
                parentId: 1914,
                regionType: 3,
                regionName: '昆山市',
                parentIdAll: '1,1831,1914,1918',
                children: []
              },
              {
                id: 1919,
                parentId: 1914,
                regionType: 3,
                regionName: '太仓市',
                parentIdAll: '1,1831,1914,1919',
                children: []
              },
              {
                id: 1920,
                parentId: 1914,
                regionType: 3,
                regionName: '吴江区',
                parentIdAll: '1,1831,1914,1920',
                children: []
              },
              {
                id: 1921,
                parentId: 1914,
                regionType: 3,
                regionName: '吴中区',
                parentIdAll: '1,1831,1914,1921',
                children: []
              },
              {
                id: 1922,
                parentId: 1914,
                regionType: 3,
                regionName: '相城区',
                parentIdAll: '1,1831,1914,1922',
                children: []
              },
              {
                id: 1923,
                parentId: 1914,
                regionType: 3,
                regionName: '张家港市',
                parentIdAll: '1,1831,1914,1923',
                children: []
              }
            ]
          },
          {
            id: 1924,
            parentId: 1831,
            regionType: 2,
            regionName: '常州市',
            parentIdAll: '1,1831,1924',
            children: [
              {
                id: 1925,
                parentId: 1924,
                regionType: 3,
                regionName: '金坛区',
                parentIdAll: '1,1831,1924,1925',
                children: []
              },
              {
                id: 1926,
                parentId: 1924,
                regionType: 3,
                regionName: '武进区',
                parentIdAll: '1,1831,1924,1926',
                children: []
              },
              {
                id: 1927,
                parentId: 1924,
                regionType: 3,
                regionName: '钟楼区',
                parentIdAll: '1,1831,1924,1927',
                children: []
              },
              {
                id: 1928,
                parentId: 1924,
                regionType: 3,
                regionName: '天宁区',
                parentIdAll: '1,1831,1924,1928',
                children: []
              },
              {
                id: 1929,
                parentId: 1924,
                regionType: 3,
                regionName: '新北区',
                parentIdAll: '1,1831,1924,1929',
                children: []
              },
              {
                id: 1930,
                parentId: 1924,
                regionType: 3,
                regionName: '溧阳市',
                parentIdAll: '1,1831,1924,1930',
                children: []
              }
            ]
          },
          {
            id: 1931,
            parentId: 1831,
            regionType: 2,
            regionName: '盐城市',
            parentIdAll: '1,1831,1931',
            children: [
              {
                id: 1932,
                parentId: 1931,
                regionType: 3,
                regionName: '滨海县',
                parentIdAll: '1,1831,1931,1932',
                children: []
              },
              {
                id: 1933,
                parentId: 1931,
                regionType: 3,
                regionName: '大丰区',
                parentIdAll: '1,1831,1931,1933',
                children: []
              },
              {
                id: 1934,
                parentId: 1931,
                regionType: 3,
                regionName: '东台市',
                parentIdAll: '1,1831,1931,1934',
                children: []
              },
              {
                id: 1935,
                parentId: 1931,
                regionType: 3,
                regionName: '阜宁县',
                parentIdAll: '1,1831,1931,1935',
                children: []
              },
              {
                id: 1936,
                parentId: 1931,
                regionType: 3,
                regionName: '建湖县',
                parentIdAll: '1,1831,1931,1936',
                children: []
              },
              {
                id: 1937,
                parentId: 1931,
                regionType: 3,
                regionName: '射阳县',
                parentIdAll: '1,1831,1931,1937',
                children: []
              },
              {
                id: 1938,
                parentId: 1931,
                regionType: 3,
                regionName: '亭湖区',
                parentIdAll: '1,1831,1931,1938',
                children: []
              },
              {
                id: 1939,
                parentId: 1931,
                regionType: 3,
                regionName: '响水县',
                parentIdAll: '1,1831,1931,1939',
                children: []
              },
              {
                id: 1940,
                parentId: 1931,
                regionType: 3,
                regionName: '盐都区',
                parentIdAll: '1,1831,1931,1940',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1941,
        parentId: 1,
        regionType: 1,
        regionName: '青海省',
        parentIdAll: '1,1941',
        children: [
          {
            id: 1942,
            parentId: 1941,
            regionType: 2,
            regionName: '海东市',
            parentIdAll: '1,1941,1942',
            children: [
              {
                id: 1943,
                parentId: 1942,
                regionType: 3,
                regionName: '化隆回族自治县',
                parentIdAll: '1,1941,1942,1943',
                children: []
              },
              {
                id: 1944,
                parentId: 1942,
                regionType: 3,
                regionName: '循化撒拉族自治县',
                parentIdAll: '1,1941,1942,1944',
                children: []
              },
              {
                id: 1945,
                parentId: 1942,
                regionType: 3,
                regionName: '乐都区',
                parentIdAll: '1,1941,1942,1945',
                children: []
              },
              {
                id: 1946,
                parentId: 1942,
                regionType: 3,
                regionName: '民和回族土族自治县',
                parentIdAll: '1,1941,1942,1946',
                children: []
              },
              {
                id: 1947,
                parentId: 1942,
                regionType: 3,
                regionName: '平安区',
                parentIdAll: '1,1941,1942,1947',
                children: []
              },
              {
                id: 1948,
                parentId: 1942,
                regionType: 3,
                regionName: '互助土族自治县',
                parentIdAll: '1,1941,1942,1948',
                children: []
              }
            ]
          },
          {
            id: 1949,
            parentId: 1941,
            regionType: 2,
            regionName: '海南藏族自治州',
            parentIdAll: '1,1941,1949',
            children: [
              {
                id: 1950,
                parentId: 1949,
                regionType: 3,
                regionName: '同德县',
                parentIdAll: '1,1941,1949,1950',
                children: []
              },
              {
                id: 1951,
                parentId: 1949,
                regionType: 3,
                regionName: '贵南县',
                parentIdAll: '1,1941,1949,1951',
                children: []
              },
              {
                id: 1952,
                parentId: 1949,
                regionType: 3,
                regionName: '贵德县',
                parentIdAll: '1,1941,1949,1952',
                children: []
              },
              {
                id: 1953,
                parentId: 1949,
                regionType: 3,
                regionName: '共和县',
                parentIdAll: '1,1941,1949,1953',
                children: []
              },
              {
                id: 1954,
                parentId: 1949,
                regionType: 3,
                regionName: '兴海县',
                parentIdAll: '1,1941,1949,1954',
                children: []
              }
            ]
          },
          {
            id: 1955,
            parentId: 1941,
            regionType: 2,
            regionName: '海西蒙古族藏族自治州',
            parentIdAll: '1,1941,1955',
            children: [
              {
                id: 1956,
                parentId: 1955,
                regionType: 3,
                regionName: '德令哈市',
                parentIdAll: '1,1941,1955,1956',
                children: []
              },
              {
                id: 1957,
                parentId: 1955,
                regionType: 3,
                regionName: '天峻县',
                parentIdAll: '1,1941,1955,1957',
                children: []
              },
              {
                id: 1958,
                parentId: 1955,
                regionType: 3,
                regionName: '格尔木市',
                parentIdAll: '1,1941,1955,1958',
                children: []
              },
              {
                id: 1959,
                parentId: 1955,
                regionType: 3,
                regionName: '都兰县',
                parentIdAll: '1,1941,1955,1959',
                children: []
              },
              {
                id: 1960,
                parentId: 1955,
                regionType: 3,
                regionName: '乌兰县',
                parentIdAll: '1,1941,1955,1960',
                children: []
              },
              {
                id: 1961,
                parentId: 1955,
                regionType: 3,
                regionName: '海西蒙古族藏族自治州直辖',
                parentIdAll: '1,1941,1955,1961',
                children: []
              }
            ]
          },
          {
            id: 1962,
            parentId: 1941,
            regionType: 2,
            regionName: '玉树藏族自治州',
            parentIdAll: '1,1941,1962',
            children: [
              {
                id: 1963,
                parentId: 1962,
                regionType: 3,
                regionName: '曲麻莱县',
                parentIdAll: '1,1941,1962,1963',
                children: []
              },
              {
                id: 1964,
                parentId: 1962,
                regionType: 3,
                regionName: '治多县',
                parentIdAll: '1,1941,1962,1964',
                children: []
              },
              {
                id: 1965,
                parentId: 1962,
                regionType: 3,
                regionName: '称多县',
                parentIdAll: '1,1941,1962,1965',
                children: []
              },
              {
                id: 1966,
                parentId: 1962,
                regionType: 3,
                regionName: '囊谦县',
                parentIdAll: '1,1941,1962,1966',
                children: []
              },
              {
                id: 1967,
                parentId: 1962,
                regionType: 3,
                regionName: '玉树市',
                parentIdAll: '1,1941,1962,1967',
                children: []
              },
              {
                id: 1968,
                parentId: 1962,
                regionType: 3,
                regionName: '杂多县',
                parentIdAll: '1,1941,1962,1968',
                children: []
              }
            ]
          },
          {
            id: 1969,
            parentId: 1941,
            regionType: 2,
            regionName: '果洛藏族自治州',
            parentIdAll: '1,1941,1969',
            children: [
              {
                id: 1970,
                parentId: 1969,
                regionType: 3,
                regionName: '玛沁县',
                parentIdAll: '1,1941,1969,1970',
                children: []
              },
              {
                id: 1971,
                parentId: 1969,
                regionType: 3,
                regionName: '久治县',
                parentIdAll: '1,1941,1969,1971',
                children: []
              },
              {
                id: 1972,
                parentId: 1969,
                regionType: 3,
                regionName: '达日县',
                parentIdAll: '1,1941,1969,1972',
                children: []
              },
              {
                id: 1973,
                parentId: 1969,
                regionType: 3,
                regionName: '甘德县',
                parentIdAll: '1,1941,1969,1973',
                children: []
              },
              {
                id: 1974,
                parentId: 1969,
                regionType: 3,
                regionName: '班玛县',
                parentIdAll: '1,1941,1969,1974',
                children: []
              },
              {
                id: 1975,
                parentId: 1969,
                regionType: 3,
                regionName: '玛多县',
                parentIdAll: '1,1941,1969,1975',
                children: []
              }
            ]
          },
          {
            id: 1976,
            parentId: 1941,
            regionType: 2,
            regionName: '黄南藏族自治州',
            parentIdAll: '1,1941,1976',
            children: [
              {
                id: 1977,
                parentId: 1976,
                regionType: 3,
                regionName: '同仁县',
                parentIdAll: '1,1941,1976,1977',
                children: []
              },
              {
                id: 1978,
                parentId: 1976,
                regionType: 3,
                regionName: '尖扎县',
                parentIdAll: '1,1941,1976,1978',
                children: []
              },
              {
                id: 1979,
                parentId: 1976,
                regionType: 3,
                regionName: '泽库县',
                parentIdAll: '1,1941,1976,1979',
                children: []
              },
              {
                id: 1980,
                parentId: 1976,
                regionType: 3,
                regionName: '河南蒙古族自治县',
                parentIdAll: '1,1941,1976,1980',
                children: []
              }
            ]
          },
          {
            id: 1981,
            parentId: 1941,
            regionType: 2,
            regionName: '海北藏族自治州',
            parentIdAll: '1,1941,1981',
            children: [
              {
                id: 1982,
                parentId: 1981,
                regionType: 3,
                regionName: '海晏县',
                parentIdAll: '1,1941,1981,1982',
                children: []
              },
              {
                id: 1983,
                parentId: 1981,
                regionType: 3,
                regionName: '门源回族自治县',
                parentIdAll: '1,1941,1981,1983',
                children: []
              },
              {
                id: 1984,
                parentId: 1981,
                regionType: 3,
                regionName: '刚察县',
                parentIdAll: '1,1941,1981,1984',
                children: []
              },
              {
                id: 1985,
                parentId: 1981,
                regionType: 3,
                regionName: '祁连县',
                parentIdAll: '1,1941,1981,1985',
                children: []
              }
            ]
          },
          {
            id: 1986,
            parentId: 1941,
            regionType: 2,
            regionName: '西宁市',
            parentIdAll: '1,1941,1986',
            children: [
              {
                id: 1987,
                parentId: 1986,
                regionType: 3,
                regionName: '湟源县',
                parentIdAll: '1,1941,1986,1987',
                children: []
              },
              {
                id: 1988,
                parentId: 1986,
                regionType: 3,
                regionName: '大通回族土族自治县',
                parentIdAll: '1,1941,1986,1988',
                children: []
              },
              {
                id: 1989,
                parentId: 1986,
                regionType: 3,
                regionName: '城东区',
                parentIdAll: '1,1941,1986,1989',
                children: []
              },
              {
                id: 1990,
                parentId: 1986,
                regionType: 3,
                regionName: '城中区',
                parentIdAll: '1,1941,1986,1990',
                children: []
              },
              {
                id: 1991,
                parentId: 1986,
                regionType: 3,
                regionName: '城西区',
                parentIdAll: '1,1941,1986,1991',
                children: []
              },
              {
                id: 1992,
                parentId: 1986,
                regionType: 3,
                regionName: '湟中县',
                parentIdAll: '1,1941,1986,1992',
                children: []
              },
              {
                id: 1993,
                parentId: 1986,
                regionType: 3,
                regionName: '城北区',
                parentIdAll: '1,1941,1986,1993',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 1994,
        parentId: 1,
        regionType: 1,
        regionName: '广西壮族自治区',
        parentIdAll: '1,1994',
        children: [
          {
            id: 1995,
            parentId: 1994,
            regionType: 2,
            regionName: '桂林市',
            parentIdAll: '1,1994,1995',
            children: [
              {
                id: 1996,
                parentId: 1995,
                regionType: 3,
                regionName: '七星区',
                parentIdAll: '1,1994,1995,1996',
                children: []
              },
              {
                id: 1997,
                parentId: 1995,
                regionType: 3,
                regionName: '兴安县',
                parentIdAll: '1,1994,1995,1997',
                children: []
              },
              {
                id: 1998,
                parentId: 1995,
                regionType: 3,
                regionName: '恭城瑶族自治县',
                parentIdAll: '1,1994,1995,1998',
                children: []
              },
              {
                id: 1999,
                parentId: 1995,
                regionType: 3,
                regionName: '阳朔县',
                parentIdAll: '1,1994,1995,1999',
                children: []
              },
              {
                id: 2000,
                parentId: 1995,
                regionType: 3,
                regionName: '资源县',
                parentIdAll: '1,1994,1995,2000',
                children: []
              },
              {
                id: 2001,
                parentId: 1995,
                regionType: 3,
                regionName: '荔浦县',
                parentIdAll: '1,1994,1995,2001',
                children: []
              },
              {
                id: 2002,
                parentId: 1995,
                regionType: 3,
                regionName: '灵川县',
                parentIdAll: '1,1994,1995,2002',
                children: []
              },
              {
                id: 2003,
                parentId: 1995,
                regionType: 3,
                regionName: '平乐县',
                parentIdAll: '1,1994,1995,2003',
                children: []
              },
              {
                id: 2004,
                parentId: 1995,
                regionType: 3,
                regionName: '临桂区',
                parentIdAll: '1,1994,1995,2004',
                children: []
              },
              {
                id: 2005,
                parentId: 1995,
                regionType: 3,
                regionName: '雁山区',
                parentIdAll: '1,1994,1995,2005',
                children: []
              },
              {
                id: 2006,
                parentId: 1995,
                regionType: 3,
                regionName: '永福县',
                parentIdAll: '1,1994,1995,2006',
                children: []
              },
              {
                id: 2007,
                parentId: 1995,
                regionType: 3,
                regionName: '叠彩区',
                parentIdAll: '1,1994,1995,2007',
                children: []
              },
              {
                id: 2008,
                parentId: 1995,
                regionType: 3,
                regionName: '龙胜各族自治县',
                parentIdAll: '1,1994,1995,2008',
                children: []
              },
              {
                id: 2009,
                parentId: 1995,
                regionType: 3,
                regionName: '秀峰区',
                parentIdAll: '1,1994,1995,2009',
                children: []
              },
              {
                id: 2010,
                parentId: 1995,
                regionType: 3,
                regionName: '象山区',
                parentIdAll: '1,1994,1995,2010',
                children: []
              },
              {
                id: 2011,
                parentId: 1995,
                regionType: 3,
                regionName: '全州县',
                parentIdAll: '1,1994,1995,2011',
                children: []
              },
              {
                id: 2012,
                parentId: 1995,
                regionType: 3,
                regionName: '灌阳县',
                parentIdAll: '1,1994,1995,2012',
                children: []
              }
            ]
          },
          {
            id: 2013,
            parentId: 1994,
            regionType: 2,
            regionName: '钦州市',
            parentIdAll: '1,1994,2013',
            children: [
              {
                id: 2014,
                parentId: 2013,
                regionType: 3,
                regionName: '钦南区',
                parentIdAll: '1,1994,2013,2014',
                children: []
              },
              {
                id: 2015,
                parentId: 2013,
                regionType: 3,
                regionName: '灵山县',
                parentIdAll: '1,1994,2013,2015',
                children: []
              },
              {
                id: 2016,
                parentId: 2013,
                regionType: 3,
                regionName: '浦北县',
                parentIdAll: '1,1994,2013,2016',
                children: []
              },
              {
                id: 2017,
                parentId: 2013,
                regionType: 3,
                regionName: '钦北区',
                parentIdAll: '1,1994,2013,2017',
                children: []
              }
            ]
          },
          {
            id: 2018,
            parentId: 1994,
            regionType: 2,
            regionName: '百色市',
            parentIdAll: '1,1994,2018',
            children: [
              {
                id: 2019,
                parentId: 2018,
                regionType: 3,
                regionName: '乐业县',
                parentIdAll: '1,1994,2018,2019',
                children: []
              },
              {
                id: 2020,
                parentId: 2018,
                regionType: 3,
                regionName: '隆林各族自治县',
                parentIdAll: '1,1994,2018,2020',
                children: []
              },
              {
                id: 2021,
                parentId: 2018,
                regionType: 3,
                regionName: '西林县',
                parentIdAll: '1,1994,2018,2021',
                children: []
              },
              {
                id: 2022,
                parentId: 2018,
                regionType: 3,
                regionName: '田东县',
                parentIdAll: '1,1994,2018,2022',
                children: []
              },
              {
                id: 2023,
                parentId: 2018,
                regionType: 3,
                regionName: '田阳区',
                parentIdAll: '1,1994,2018,2023',
                children: []
              },
              {
                id: 2024,
                parentId: 2018,
                regionType: 3,
                regionName: '平果县',
                parentIdAll: '1,1994,2018,2024',
                children: []
              },
              {
                id: 2025,
                parentId: 2018,
                regionType: 3,
                regionName: '凌云县',
                parentIdAll: '1,1994,2018,2025',
                children: []
              },
              {
                id: 2026,
                parentId: 2018,
                regionType: 3,
                regionName: '右江区',
                parentIdAll: '1,1994,2018,2026',
                children: []
              },
              {
                id: 2027,
                parentId: 2018,
                regionType: 3,
                regionName: '德保县',
                parentIdAll: '1,1994,2018,2027',
                children: []
              },
              {
                id: 2028,
                parentId: 2018,
                regionType: 3,
                regionName: '田林县',
                parentIdAll: '1,1994,2018,2028',
                children: []
              },
              {
                id: 2029,
                parentId: 2018,
                regionType: 3,
                regionName: '靖西市',
                parentIdAll: '1,1994,2018,2029',
                children: []
              },
              {
                id: 2030,
                parentId: 2018,
                regionType: 3,
                regionName: '那坡县',
                parentIdAll: '1,1994,2018,2030',
                children: []
              }
            ]
          },
          {
            id: 2031,
            parentId: 1994,
            regionType: 2,
            regionName: '北海市',
            parentIdAll: '1,1994,2031',
            children: [
              {
                id: 2032,
                parentId: 2031,
                regionType: 3,
                regionName: '海城区',
                parentIdAll: '1,1994,2031,2032',
                children: []
              },
              {
                id: 2033,
                parentId: 2031,
                regionType: 3,
                regionName: '合浦县',
                parentIdAll: '1,1994,2031,2033',
                children: []
              },
              {
                id: 2034,
                parentId: 2031,
                regionType: 3,
                regionName: '银海区',
                parentIdAll: '1,1994,2031,2034',
                children: []
              },
              {
                id: 2035,
                parentId: 2031,
                regionType: 3,
                regionName: '铁山港区',
                parentIdAll: '1,1994,2031,2035',
                children: []
              }
            ]
          },
          {
            id: 2036,
            parentId: 1994,
            regionType: 2,
            regionName: '贵港市',
            parentIdAll: '1,1994,2036',
            children: [
              {
                id: 2037,
                parentId: 2036,
                regionType: 3,
                regionName: '平南县',
                parentIdAll: '1,1994,2036,2037',
                children: []
              },
              {
                id: 2038,
                parentId: 2036,
                regionType: 3,
                regionName: '桂平市',
                parentIdAll: '1,1994,2036,2038',
                children: []
              },
              {
                id: 2039,
                parentId: 2036,
                regionType: 3,
                regionName: '覃塘区',
                parentIdAll: '1,1994,2036,2039',
                children: []
              },
              {
                id: 2040,
                parentId: 2036,
                regionType: 3,
                regionName: '港北区',
                parentIdAll: '1,1994,2036,2040',
                children: []
              },
              {
                id: 2041,
                parentId: 2036,
                regionType: 3,
                regionName: '港南区',
                parentIdAll: '1,1994,2036,2041',
                children: []
              }
            ]
          },
          {
            id: 2042,
            parentId: 1994,
            regionType: 2,
            regionName: '河池市',
            parentIdAll: '1,1994,2042',
            children: [
              {
                id: 2043,
                parentId: 2042,
                regionType: 3,
                regionName: '金城江区',
                parentIdAll: '1,1994,2042,2043',
                children: []
              },
              {
                id: 2044,
                parentId: 2042,
                regionType: 3,
                regionName: '环江毛南族自治县',
                parentIdAll: '1,1994,2042,2044',
                children: []
              },
              {
                id: 2045,
                parentId: 2042,
                regionType: 3,
                regionName: '罗城仫佬族自治县',
                parentIdAll: '1,1994,2042,2045',
                children: []
              },
              {
                id: 2046,
                parentId: 2042,
                regionType: 3,
                regionName: '宜州区',
                parentIdAll: '1,1994,2042,2046',
                children: []
              },
              {
                id: 2047,
                parentId: 2042,
                regionType: 3,
                regionName: '凤山县',
                parentIdAll: '1,1994,2042,2047',
                children: []
              },
              {
                id: 2048,
                parentId: 2042,
                regionType: 3,
                regionName: '大化瑶族自治县',
                parentIdAll: '1,1994,2042,2048',
                children: []
              },
              {
                id: 2049,
                parentId: 2042,
                regionType: 3,
                regionName: '天峨县',
                parentIdAll: '1,1994,2042,2049',
                children: []
              },
              {
                id: 2050,
                parentId: 2042,
                regionType: 3,
                regionName: '巴马瑶族自治县',
                parentIdAll: '1,1994,2042,2050',
                children: []
              },
              {
                id: 2051,
                parentId: 2042,
                regionType: 3,
                regionName: '都安瑶族自治县',
                parentIdAll: '1,1994,2042,2051',
                children: []
              },
              {
                id: 2052,
                parentId: 2042,
                regionType: 3,
                regionName: '南丹县',
                parentIdAll: '1,1994,2042,2052',
                children: []
              },
              {
                id: 2053,
                parentId: 2042,
                regionType: 3,
                regionName: '东兰县',
                parentIdAll: '1,1994,2042,2053',
                children: []
              }
            ]
          },
          {
            id: 2054,
            parentId: 1994,
            regionType: 2,
            regionName: '柳州市',
            parentIdAll: '1,1994,2054',
            children: [
              {
                id: 2055,
                parentId: 2054,
                regionType: 3,
                regionName: '融安县',
                parentIdAll: '1,1994,2054,2055',
                children: []
              },
              {
                id: 2056,
                parentId: 2054,
                regionType: 3,
                regionName: '融水苗族自治县',
                parentIdAll: '1,1994,2054,2056',
                children: []
              },
              {
                id: 2057,
                parentId: 2054,
                regionType: 3,
                regionName: '三江侗族自治县',
                parentIdAll: '1,1994,2054,2057',
                children: []
              },
              {
                id: 2058,
                parentId: 2054,
                regionType: 3,
                regionName: '城中区',
                parentIdAll: '1,1994,2054,2058',
                children: []
              },
              {
                id: 2059,
                parentId: 2054,
                regionType: 3,
                regionName: '鹿寨县',
                parentIdAll: '1,1994,2054,2059',
                children: []
              },
              {
                id: 2060,
                parentId: 2054,
                regionType: 3,
                regionName: '柳城县',
                parentIdAll: '1,1994,2054,2060',
                children: []
              },
              {
                id: 2061,
                parentId: 2054,
                regionType: 3,
                regionName: '柳北区',
                parentIdAll: '1,1994,2054,2061',
                children: []
              },
              {
                id: 2062,
                parentId: 2054,
                regionType: 3,
                regionName: '鱼峰区',
                parentIdAll: '1,1994,2054,2062',
                children: []
              },
              {
                id: 2063,
                parentId: 2054,
                regionType: 3,
                regionName: '柳南区',
                parentIdAll: '1,1994,2054,2063',
                children: []
              },
              {
                id: 2064,
                parentId: 2054,
                regionType: 3,
                regionName: '柳江区',
                parentIdAll: '1,1994,2054,2064',
                children: []
              }
            ]
          },
          {
            id: 2065,
            parentId: 1994,
            regionType: 2,
            regionName: '来宾市',
            parentIdAll: '1,1994,2065',
            children: [
              {
                id: 2066,
                parentId: 2065,
                regionType: 3,
                regionName: '兴宾区',
                parentIdAll: '1,1994,2065,2066',
                children: []
              },
              {
                id: 2067,
                parentId: 2065,
                regionType: 3,
                regionName: '金秀瑶族自治县',
                parentIdAll: '1,1994,2065,2067',
                children: []
              },
              {
                id: 2068,
                parentId: 2065,
                regionType: 3,
                regionName: '象州县',
                parentIdAll: '1,1994,2065,2068',
                children: []
              },
              {
                id: 2069,
                parentId: 2065,
                regionType: 3,
                regionName: '武宣县',
                parentIdAll: '1,1994,2065,2069',
                children: []
              },
              {
                id: 2070,
                parentId: 2065,
                regionType: 3,
                regionName: '合山市',
                parentIdAll: '1,1994,2065,2070',
                children: []
              },
              {
                id: 2071,
                parentId: 2065,
                regionType: 3,
                regionName: '忻城县',
                parentIdAll: '1,1994,2065,2071',
                children: []
              }
            ]
          },
          {
            id: 2072,
            parentId: 1994,
            regionType: 2,
            regionName: '南宁市',
            parentIdAll: '1,1994,2072',
            children: [
              {
                id: 2073,
                parentId: 2072,
                regionType: 3,
                regionName: '上林县',
                parentIdAll: '1,1994,2072,2073',
                children: []
              },
              {
                id: 2074,
                parentId: 2072,
                regionType: 3,
                regionName: '马山县',
                parentIdAll: '1,1994,2072,2074',
                children: []
              },
              {
                id: 2075,
                parentId: 2072,
                regionType: 3,
                regionName: '横县',
                parentIdAll: '1,1994,2072,2075',
                children: []
              },
              {
                id: 2076,
                parentId: 2072,
                regionType: 3,
                regionName: '宾阳县',
                parentIdAll: '1,1994,2072,2076',
                children: []
              },
              {
                id: 2077,
                parentId: 2072,
                regionType: 3,
                regionName: '邕宁区',
                parentIdAll: '1,1994,2072,2077',
                children: []
              },
              {
                id: 2078,
                parentId: 2072,
                regionType: 3,
                regionName: '西乡塘区',
                parentIdAll: '1,1994,2072,2078',
                children: []
              },
              {
                id: 2079,
                parentId: 2072,
                regionType: 3,
                regionName: '良庆区',
                parentIdAll: '1,1994,2072,2079',
                children: []
              },
              {
                id: 2080,
                parentId: 2072,
                regionType: 3,
                regionName: '江南区',
                parentIdAll: '1,1994,2072,2080',
                children: []
              },
              {
                id: 2081,
                parentId: 2072,
                regionType: 3,
                regionName: '青秀区',
                parentIdAll: '1,1994,2072,2081',
                children: []
              },
              {
                id: 2082,
                parentId: 2072,
                regionType: 3,
                regionName: '隆安县',
                parentIdAll: '1,1994,2072,2082',
                children: []
              },
              {
                id: 2083,
                parentId: 2072,
                regionType: 3,
                regionName: '兴宁区',
                parentIdAll: '1,1994,2072,2083',
                children: []
              },
              {
                id: 2084,
                parentId: 2072,
                regionType: 3,
                regionName: '武鸣区',
                parentIdAll: '1,1994,2072,2084',
                children: []
              }
            ]
          },
          {
            id: 2085,
            parentId: 1994,
            regionType: 2,
            regionName: '崇左市',
            parentIdAll: '1,1994,2085',
            children: [
              {
                id: 2086,
                parentId: 2085,
                regionType: 3,
                regionName: '宁明县',
                parentIdAll: '1,1994,2085,2086',
                children: []
              },
              {
                id: 2087,
                parentId: 2085,
                regionType: 3,
                regionName: '扶绥县',
                parentIdAll: '1,1994,2085,2087',
                children: []
              },
              {
                id: 2088,
                parentId: 2085,
                regionType: 3,
                regionName: '凭祥市',
                parentIdAll: '1,1994,2085,2088',
                children: []
              },
              {
                id: 2089,
                parentId: 2085,
                regionType: 3,
                regionName: '大新县',
                parentIdAll: '1,1994,2085,2089',
                children: []
              },
              {
                id: 2090,
                parentId: 2085,
                regionType: 3,
                regionName: '天等县',
                parentIdAll: '1,1994,2085,2090',
                children: []
              },
              {
                id: 2091,
                parentId: 2085,
                regionType: 3,
                regionName: '龙州县',
                parentIdAll: '1,1994,2085,2091',
                children: []
              },
              {
                id: 2092,
                parentId: 2085,
                regionType: 3,
                regionName: '江州区',
                parentIdAll: '1,1994,2085,2092',
                children: []
              }
            ]
          },
          {
            id: 2093,
            parentId: 1994,
            regionType: 2,
            regionName: '防城港市',
            parentIdAll: '1,1994,2093',
            children: [
              {
                id: 2094,
                parentId: 2093,
                regionType: 3,
                regionName: '上思县',
                parentIdAll: '1,1994,2093,2094',
                children: []
              },
              {
                id: 2095,
                parentId: 2093,
                regionType: 3,
                regionName: '东兴市',
                parentIdAll: '1,1994,2093,2095',
                children: []
              },
              {
                id: 2096,
                parentId: 2093,
                regionType: 3,
                regionName: '港口区',
                parentIdAll: '1,1994,2093,2096',
                children: []
              },
              {
                id: 2097,
                parentId: 2093,
                regionType: 3,
                regionName: '防城区',
                parentIdAll: '1,1994,2093,2097',
                children: []
              }
            ]
          },
          {
            id: 2098,
            parentId: 1994,
            regionType: 2,
            regionName: '贺州市',
            parentIdAll: '1,1994,2098',
            children: [
              {
                id: 2099,
                parentId: 2098,
                regionType: 3,
                regionName: '富川瑶族自治县',
                parentIdAll: '1,1994,2098,2099',
                children: []
              },
              {
                id: 2100,
                parentId: 2098,
                regionType: 3,
                regionName: '昭平县',
                parentIdAll: '1,1994,2098,2100',
                children: []
              },
              {
                id: 2101,
                parentId: 2098,
                regionType: 3,
                regionName: '钟山县',
                parentIdAll: '1,1994,2098,2101',
                children: []
              },
              {
                id: 2102,
                parentId: 2098,
                regionType: 3,
                regionName: '八步区',
                parentIdAll: '1,1994,2098,2102',
                children: []
              },
              {
                id: 2103,
                parentId: 2098,
                regionType: 3,
                regionName: '平桂区',
                parentIdAll: '1,1994,2098,2103',
                children: []
              }
            ]
          },
          {
            id: 2104,
            parentId: 1994,
            regionType: 2,
            regionName: '梧州市',
            parentIdAll: '1,1994,2104',
            children: [
              {
                id: 2105,
                parentId: 2104,
                regionType: 3,
                regionName: '蒙山县',
                parentIdAll: '1,1994,2104,2105',
                children: []
              },
              {
                id: 2106,
                parentId: 2104,
                regionType: 3,
                regionName: '藤县',
                parentIdAll: '1,1994,2104,2106',
                children: []
              },
              {
                id: 2107,
                parentId: 2104,
                regionType: 3,
                regionName: '岑溪市',
                parentIdAll: '1,1994,2104,2107',
                children: []
              },
              {
                id: 2108,
                parentId: 2104,
                regionType: 3,
                regionName: '龙圩区',
                parentIdAll: '1,1994,2104,2108',
                children: []
              },
              {
                id: 2109,
                parentId: 2104,
                regionType: 3,
                regionName: '万秀区',
                parentIdAll: '1,1994,2104,2109',
                children: []
              },
              {
                id: 2110,
                parentId: 2104,
                regionType: 3,
                regionName: '长洲区',
                parentIdAll: '1,1994,2104,2110',
                children: []
              },
              {
                id: 2111,
                parentId: 2104,
                regionType: 3,
                regionName: '苍梧县',
                parentIdAll: '1,1994,2104,2111',
                children: []
              }
            ]
          },
          {
            id: 2112,
            parentId: 1994,
            regionType: 2,
            regionName: '玉林市',
            parentIdAll: '1,1994,2112',
            children: [
              {
                id: 2113,
                parentId: 2112,
                regionType: 3,
                regionName: '玉州区',
                parentIdAll: '1,1994,2112,2113',
                children: []
              },
              {
                id: 2114,
                parentId: 2112,
                regionType: 3,
                regionName: '福绵区',
                parentIdAll: '1,1994,2112,2114',
                children: []
              },
              {
                id: 2115,
                parentId: 2112,
                regionType: 3,
                regionName: '兴业县',
                parentIdAll: '1,1994,2112,2115',
                children: []
              },
              {
                id: 2116,
                parentId: 2112,
                regionType: 3,
                regionName: '博白县',
                parentIdAll: '1,1994,2112,2116',
                children: []
              },
              {
                id: 2117,
                parentId: 2112,
                regionType: 3,
                regionName: '陆川县',
                parentIdAll: '1,1994,2112,2117',
                children: []
              },
              {
                id: 2118,
                parentId: 2112,
                regionType: 3,
                regionName: '容县',
                parentIdAll: '1,1994,2112,2118',
                children: []
              },
              {
                id: 2119,
                parentId: 2112,
                regionType: 3,
                regionName: '北流市',
                parentIdAll: '1,1994,2112,2119',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2120,
        parentId: 1,
        regionType: 1,
        regionName: '宁夏回族自治区',
        parentIdAll: '1,2120',
        children: [
          {
            id: 2121,
            parentId: 2120,
            regionType: 2,
            regionName: '中卫市',
            parentIdAll: '1,2120,2121',
            children: [
              {
                id: 2122,
                parentId: 2121,
                regionType: 3,
                regionName: '沙坡头区',
                parentIdAll: '1,2120,2121,2122',
                children: []
              },
              {
                id: 2123,
                parentId: 2121,
                regionType: 3,
                regionName: '海原县',
                parentIdAll: '1,2120,2121,2123',
                children: []
              },
              {
                id: 2124,
                parentId: 2121,
                regionType: 3,
                regionName: '中宁县',
                parentIdAll: '1,2120,2121,2124',
                children: []
              }
            ]
          },
          {
            id: 2125,
            parentId: 2120,
            regionType: 2,
            regionName: '吴忠市',
            parentIdAll: '1,2120,2125',
            children: [
              {
                id: 2126,
                parentId: 2125,
                regionType: 3,
                regionName: '同心县',
                parentIdAll: '1,2120,2125,2126',
                children: []
              },
              {
                id: 2127,
                parentId: 2125,
                regionType: 3,
                regionName: '利通区',
                parentIdAll: '1,2120,2125,2127',
                children: []
              },
              {
                id: 2128,
                parentId: 2125,
                regionType: 3,
                regionName: '盐池县',
                parentIdAll: '1,2120,2125,2128',
                children: []
              },
              {
                id: 2129,
                parentId: 2125,
                regionType: 3,
                regionName: '红寺堡区',
                parentIdAll: '1,2120,2125,2129',
                children: []
              },
              {
                id: 2130,
                parentId: 2125,
                regionType: 3,
                regionName: '青铜峡市',
                parentIdAll: '1,2120,2125,2130',
                children: []
              }
            ]
          },
          {
            id: 2131,
            parentId: 2120,
            regionType: 2,
            regionName: '固原市',
            parentIdAll: '1,2120,2131',
            children: [
              {
                id: 2132,
                parentId: 2131,
                regionType: 3,
                regionName: '泾源县',
                parentIdAll: '1,2120,2131,2132',
                children: []
              },
              {
                id: 2133,
                parentId: 2131,
                regionType: 3,
                regionName: '隆德县',
                parentIdAll: '1,2120,2131,2133',
                children: []
              },
              {
                id: 2134,
                parentId: 2131,
                regionType: 3,
                regionName: '原州区',
                parentIdAll: '1,2120,2131,2134',
                children: []
              },
              {
                id: 2135,
                parentId: 2131,
                regionType: 3,
                regionName: '西吉县',
                parentIdAll: '1,2120,2131,2135',
                children: []
              },
              {
                id: 2136,
                parentId: 2131,
                regionType: 3,
                regionName: '彭阳县',
                parentIdAll: '1,2120,2131,2136',
                children: []
              }
            ]
          },
          {
            id: 2137,
            parentId: 2120,
            regionType: 2,
            regionName: '银川市',
            parentIdAll: '1,2120,2137',
            children: [
              {
                id: 2138,
                parentId: 2137,
                regionType: 3,
                regionName: '西夏区',
                parentIdAll: '1,2120,2137,2138',
                children: []
              },
              {
                id: 2139,
                parentId: 2137,
                regionType: 3,
                regionName: '永宁县',
                parentIdAll: '1,2120,2137,2139',
                children: []
              },
              {
                id: 2140,
                parentId: 2137,
                regionType: 3,
                regionName: '兴庆区',
                parentIdAll: '1,2120,2137,2140',
                children: []
              },
              {
                id: 2141,
                parentId: 2137,
                regionType: 3,
                regionName: '灵武市',
                parentIdAll: '1,2120,2137,2141',
                children: []
              },
              {
                id: 2142,
                parentId: 2137,
                regionType: 3,
                regionName: '贺兰县',
                parentIdAll: '1,2120,2137,2142',
                children: []
              },
              {
                id: 2143,
                parentId: 2137,
                regionType: 3,
                regionName: '金凤区',
                parentIdAll: '1,2120,2137,2143',
                children: []
              }
            ]
          },
          {
            id: 2144,
            parentId: 2120,
            regionType: 2,
            regionName: '石嘴山市',
            parentIdAll: '1,2120,2144',
            children: [
              {
                id: 2145,
                parentId: 2144,
                regionType: 3,
                regionName: '惠农区',
                parentIdAll: '1,2120,2144,2145',
                children: []
              },
              {
                id: 2146,
                parentId: 2144,
                regionType: 3,
                regionName: '平罗县',
                parentIdAll: '1,2120,2144,2146',
                children: []
              },
              {
                id: 2147,
                parentId: 2144,
                regionType: 3,
                regionName: '大武口区',
                parentIdAll: '1,2120,2144,2147',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2148,
        parentId: 1,
        regionType: 1,
        regionName: '江西省',
        parentIdAll: '1,2148',
        children: [
          {
            id: 2149,
            parentId: 2148,
            regionType: 2,
            regionName: '景德镇市',
            parentIdAll: '1,2148,2149',
            children: [
              {
                id: 2150,
                parentId: 2149,
                regionType: 3,
                regionName: '昌江区',
                parentIdAll: '1,2148,2149,2150',
                children: []
              },
              {
                id: 2151,
                parentId: 2149,
                regionType: 3,
                regionName: '浮梁县',
                parentIdAll: '1,2148,2149,2151',
                children: []
              },
              {
                id: 2152,
                parentId: 2149,
                regionType: 3,
                regionName: '乐平市',
                parentIdAll: '1,2148,2149,2152',
                children: []
              },
              {
                id: 2153,
                parentId: 2149,
                regionType: 3,
                regionName: '珠山区',
                parentIdAll: '1,2148,2149,2153',
                children: []
              }
            ]
          },
          {
            id: 2154,
            parentId: 2148,
            regionType: 2,
            regionName: '九江市',
            parentIdAll: '1,2148,2154',
            children: [
              {
                id: 2155,
                parentId: 2154,
                regionType: 3,
                regionName: '武宁县',
                parentIdAll: '1,2148,2154,2155',
                children: []
              },
              {
                id: 2156,
                parentId: 2154,
                regionType: 3,
                regionName: '都昌县',
                parentIdAll: '1,2148,2154,2156',
                children: []
              },
              {
                id: 2157,
                parentId: 2154,
                regionType: 3,
                regionName: '彭泽县',
                parentIdAll: '1,2148,2154,2157',
                children: []
              },
              {
                id: 2158,
                parentId: 2154,
                regionType: 3,
                regionName: '瑞昌市',
                parentIdAll: '1,2148,2154,2158',
                children: []
              },
              {
                id: 2159,
                parentId: 2154,
                regionType: 3,
                regionName: '湖口县',
                parentIdAll: '1,2148,2154,2159',
                children: []
              },
              {
                id: 2160,
                parentId: 2154,
                regionType: 3,
                regionName: '柴桑区',
                parentIdAll: '1,2148,2154,2160',
                children: []
              },
              {
                id: 2161,
                parentId: 2154,
                regionType: 3,
                regionName: '修水县',
                parentIdAll: '1,2148,2154,2161',
                children: []
              },
              {
                id: 2162,
                parentId: 2154,
                regionType: 3,
                regionName: '庐山市',
                parentIdAll: '1,2148,2154,2162',
                children: []
              },
              {
                id: 2163,
                parentId: 2154,
                regionType: 3,
                regionName: '濂溪区',
                parentIdAll: '1,2148,2154,2163',
                children: []
              },
              {
                id: 2164,
                parentId: 2154,
                regionType: 3,
                regionName: '德安县',
                parentIdAll: '1,2148,2154,2164',
                children: []
              },
              {
                id: 2165,
                parentId: 2154,
                regionType: 3,
                regionName: '浔阳区',
                parentIdAll: '1,2148,2154,2165',
                children: []
              },
              {
                id: 2166,
                parentId: 2154,
                regionType: 3,
                regionName: '永修县',
                parentIdAll: '1,2148,2154,2166',
                children: []
              },
              {
                id: 2167,
                parentId: 2154,
                regionType: 3,
                regionName: '共青城市',
                parentIdAll: '1,2148,2154,2167',
                children: []
              }
            ]
          },
          {
            id: 2168,
            parentId: 2148,
            regionType: 2,
            regionName: '抚州市',
            parentIdAll: '1,2148,2168',
            children: [
              {
                id: 2169,
                parentId: 2168,
                regionType: 3,
                regionName: '东乡区',
                parentIdAll: '1,2148,2168,2169',
                children: []
              },
              {
                id: 2170,
                parentId: 2168,
                regionType: 3,
                regionName: '崇仁县',
                parentIdAll: '1,2148,2168,2170',
                children: []
              },
              {
                id: 2171,
                parentId: 2168,
                regionType: 3,
                regionName: '金溪县',
                parentIdAll: '1,2148,2168,2171',
                children: []
              },
              {
                id: 2172,
                parentId: 2168,
                regionType: 3,
                regionName: '资溪县',
                parentIdAll: '1,2148,2168,2172',
                children: []
              },
              {
                id: 2173,
                parentId: 2168,
                regionType: 3,
                regionName: '临川区',
                parentIdAll: '1,2148,2168,2173',
                children: []
              },
              {
                id: 2174,
                parentId: 2168,
                regionType: 3,
                regionName: '乐安县',
                parentIdAll: '1,2148,2168,2174',
                children: []
              },
              {
                id: 2175,
                parentId: 2168,
                regionType: 3,
                regionName: '宜黄县',
                parentIdAll: '1,2148,2168,2175',
                children: []
              },
              {
                id: 2176,
                parentId: 2168,
                regionType: 3,
                regionName: '黎川县',
                parentIdAll: '1,2148,2168,2176',
                children: []
              },
              {
                id: 2177,
                parentId: 2168,
                regionType: 3,
                regionName: '南丰县',
                parentIdAll: '1,2148,2168,2177',
                children: []
              },
              {
                id: 2178,
                parentId: 2168,
                regionType: 3,
                regionName: '南城县',
                parentIdAll: '1,2148,2168,2178',
                children: []
              },
              {
                id: 2179,
                parentId: 2168,
                regionType: 3,
                regionName: '广昌县',
                parentIdAll: '1,2148,2168,2179',
                children: []
              }
            ]
          },
          {
            id: 2180,
            parentId: 2148,
            regionType: 2,
            regionName: '上饶市',
            parentIdAll: '1,2148,2180',
            children: [
              {
                id: 2181,
                parentId: 2180,
                regionType: 3,
                regionName: '万年县',
                parentIdAll: '1,2148,2180,2181',
                children: []
              },
              {
                id: 2182,
                parentId: 2180,
                regionType: 3,
                regionName: '横峰县',
                parentIdAll: '1,2148,2180,2182',
                children: []
              },
              {
                id: 2183,
                parentId: 2180,
                regionType: 3,
                regionName: '铅山县',
                parentIdAll: '1,2148,2180,2183',
                children: []
              },
              {
                id: 2184,
                parentId: 2180,
                regionType: 3,
                regionName: '弋阳县',
                parentIdAll: '1,2148,2180,2184',
                children: []
              },
              {
                id: 2185,
                parentId: 2180,
                regionType: 3,
                regionName: '婺源县',
                parentIdAll: '1,2148,2180,2185',
                children: []
              },
              {
                id: 2186,
                parentId: 2180,
                regionType: 3,
                regionName: '德兴市',
                parentIdAll: '1,2148,2180,2186',
                children: []
              },
              {
                id: 2187,
                parentId: 2180,
                regionType: 3,
                regionName: '信州区',
                parentIdAll: '1,2148,2180,2187',
                children: []
              },
              {
                id: 2188,
                parentId: 2180,
                regionType: 3,
                regionName: '广丰区',
                parentIdAll: '1,2148,2180,2188',
                children: []
              },
              {
                id: 2189,
                parentId: 2180,
                regionType: 3,
                regionName: '余干县',
                parentIdAll: '1,2148,2180,2189',
                children: []
              },
              {
                id: 2190,
                parentId: 2180,
                regionType: 3,
                regionName: '鄱阳县',
                parentIdAll: '1,2148,2180,2190',
                children: []
              },
              {
                id: 2191,
                parentId: 2180,
                regionType: 3,
                regionName: '广信区',
                parentIdAll: '1,2148,2180,2191',
                children: []
              },
              {
                id: 2192,
                parentId: 2180,
                regionType: 3,
                regionName: '玉山县',
                parentIdAll: '1,2148,2180,2192',
                children: []
              }
            ]
          },
          {
            id: 2193,
            parentId: 2148,
            regionType: 2,
            regionName: '赣州市',
            parentIdAll: '1,2148,2193',
            children: [
              {
                id: 2194,
                parentId: 2193,
                regionType: 3,
                regionName: '上犹县',
                parentIdAll: '1,2148,2193,2194',
                children: []
              },
              {
                id: 2195,
                parentId: 2193,
                regionType: 3,
                regionName: '大余县',
                parentIdAll: '1,2148,2193,2195',
                children: []
              },
              {
                id: 2196,
                parentId: 2193,
                regionType: 3,
                regionName: '瑞金市',
                parentIdAll: '1,2148,2193,2196',
                children: []
              },
              {
                id: 2197,
                parentId: 2193,
                regionType: 3,
                regionName: '全南县',
                parentIdAll: '1,2148,2193,2197',
                children: []
              },
              {
                id: 2198,
                parentId: 2193,
                regionType: 3,
                regionName: '于都县',
                parentIdAll: '1,2148,2193,2198',
                children: []
              },
              {
                id: 2199,
                parentId: 2193,
                regionType: 3,
                regionName: '兴国县',
                parentIdAll: '1,2148,2193,2199',
                children: []
              },
              {
                id: 2200,
                parentId: 2193,
                regionType: 3,
                regionName: '信丰县',
                parentIdAll: '1,2148,2193,2200',
                children: []
              },
              {
                id: 2201,
                parentId: 2193,
                regionType: 3,
                regionName: '定南县',
                parentIdAll: '1,2148,2193,2201',
                children: []
              },
              {
                id: 2202,
                parentId: 2193,
                regionType: 3,
                regionName: '宁都县',
                parentIdAll: '1,2148,2193,2202',
                children: []
              },
              {
                id: 2203,
                parentId: 2193,
                regionType: 3,
                regionName: '会昌县',
                parentIdAll: '1,2148,2193,2203',
                children: []
              },
              {
                id: 2204,
                parentId: 2193,
                regionType: 3,
                regionName: '龙南县',
                parentIdAll: '1,2148,2193,2204',
                children: []
              },
              {
                id: 2205,
                parentId: 2193,
                regionType: 3,
                regionName: '安远县',
                parentIdAll: '1,2148,2193,2205',
                children: []
              },
              {
                id: 2206,
                parentId: 2193,
                regionType: 3,
                regionName: '寻乌县',
                parentIdAll: '1,2148,2193,2206',
                children: []
              },
              {
                id: 2207,
                parentId: 2193,
                regionType: 3,
                regionName: '石城县',
                parentIdAll: '1,2148,2193,2207',
                children: []
              },
              {
                id: 2208,
                parentId: 2193,
                regionType: 3,
                regionName: '崇义县',
                parentIdAll: '1,2148,2193,2208',
                children: []
              },
              {
                id: 2209,
                parentId: 2193,
                regionType: 3,
                regionName: '南康区',
                parentIdAll: '1,2148,2193,2209',
                children: []
              },
              {
                id: 2210,
                parentId: 2193,
                regionType: 3,
                regionName: '赣县区',
                parentIdAll: '1,2148,2193,2210',
                children: []
              },
              {
                id: 2211,
                parentId: 2193,
                regionType: 3,
                regionName: '章贡区',
                parentIdAll: '1,2148,2193,2211',
                children: []
              }
            ]
          },
          {
            id: 2212,
            parentId: 2148,
            regionType: 2,
            regionName: '新余市',
            parentIdAll: '1,2148,2212',
            children: [
              {
                id: 2213,
                parentId: 2212,
                regionType: 3,
                regionName: '分宜县',
                parentIdAll: '1,2148,2212,2213',
                children: []
              },
              {
                id: 2214,
                parentId: 2212,
                regionType: 3,
                regionName: '渝水区',
                parentIdAll: '1,2148,2212,2214',
                children: []
              }
            ]
          },
          {
            id: 2215,
            parentId: 2148,
            regionType: 2,
            regionName: '鹰潭市',
            parentIdAll: '1,2148,2215',
            children: [
              {
                id: 2216,
                parentId: 2215,
                regionType: 3,
                regionName: '贵溪市',
                parentIdAll: '1,2148,2215,2216',
                children: []
              },
              {
                id: 2217,
                parentId: 2215,
                regionType: 3,
                regionName: '余江县',
                parentIdAll: '1,2148,2215,2217',
                children: []
              },
              {
                id: 2218,
                parentId: 2215,
                regionType: 3,
                regionName: '月湖区',
                parentIdAll: '1,2148,2215,2218',
                children: []
              }
            ]
          },
          {
            id: 2219,
            parentId: 2148,
            regionType: 2,
            regionName: '南昌市',
            parentIdAll: '1,2148,2219',
            children: [
              {
                id: 2220,
                parentId: 2219,
                regionType: 3,
                regionName: '安义县',
                parentIdAll: '1,2148,2219,2220',
                children: []
              },
              {
                id: 2221,
                parentId: 2219,
                regionType: 3,
                regionName: '进贤县',
                parentIdAll: '1,2148,2219,2221',
                children: []
              },
              {
                id: 2222,
                parentId: 2219,
                regionType: 3,
                regionName: '青云谱区',
                parentIdAll: '1,2148,2219,2222',
                children: []
              },
              {
                id: 2223,
                parentId: 2219,
                regionType: 3,
                regionName: '南昌县',
                parentIdAll: '1,2148,2219,2223',
                children: []
              },
              {
                id: 2224,
                parentId: 2219,
                regionType: 3,
                regionName: '新建区',
                parentIdAll: '1,2148,2219,2224',
                children: []
              },
              {
                id: 2225,
                parentId: 2219,
                regionType: 3,
                regionName: '青山湖区',
                parentIdAll: '1,2148,2219,2225',
                children: []
              },
              {
                id: 2226,
                parentId: 2219,
                regionType: 3,
                regionName: '湾里区',
                parentIdAll: '1,2148,2219,2226',
                children: []
              },
              {
                id: 2227,
                parentId: 2219,
                regionType: 3,
                regionName: '西湖区',
                parentIdAll: '1,2148,2219,2227',
                children: []
              },
              {
                id: 2228,
                parentId: 2219,
                regionType: 3,
                regionName: '东湖区',
                parentIdAll: '1,2148,2219,2228',
                children: []
              }
            ]
          },
          {
            id: 2229,
            parentId: 2148,
            regionType: 2,
            regionName: '吉安市',
            parentIdAll: '1,2148,2229',
            children: [
              {
                id: 2230,
                parentId: 2229,
                regionType: 3,
                regionName: '吉水县',
                parentIdAll: '1,2148,2229,2230',
                children: []
              },
              {
                id: 2231,
                parentId: 2229,
                regionType: 3,
                regionName: '遂川县',
                parentIdAll: '1,2148,2229,2231',
                children: []
              },
              {
                id: 2232,
                parentId: 2229,
                regionType: 3,
                regionName: '永新县',
                parentIdAll: '1,2148,2229,2232',
                children: []
              },
              {
                id: 2233,
                parentId: 2229,
                regionType: 3,
                regionName: '永丰县',
                parentIdAll: '1,2148,2229,2233',
                children: []
              },
              {
                id: 2234,
                parentId: 2229,
                regionType: 3,
                regionName: '吉州区',
                parentIdAll: '1,2148,2229,2234',
                children: []
              },
              {
                id: 2235,
                parentId: 2229,
                regionType: 3,
                regionName: '青原区',
                parentIdAll: '1,2148,2229,2235',
                children: []
              },
              {
                id: 2236,
                parentId: 2229,
                regionType: 3,
                regionName: '吉安县',
                parentIdAll: '1,2148,2229,2236',
                children: []
              },
              {
                id: 2237,
                parentId: 2229,
                regionType: 3,
                regionName: '万安县',
                parentIdAll: '1,2148,2229,2237',
                children: []
              },
              {
                id: 2238,
                parentId: 2229,
                regionType: 3,
                regionName: '峡江县',
                parentIdAll: '1,2148,2229,2238',
                children: []
              },
              {
                id: 2239,
                parentId: 2229,
                regionType: 3,
                regionName: '泰和县',
                parentIdAll: '1,2148,2229,2239',
                children: []
              },
              {
                id: 2240,
                parentId: 2229,
                regionType: 3,
                regionName: '井冈山市',
                parentIdAll: '1,2148,2229,2240',
                children: []
              },
              {
                id: 2241,
                parentId: 2229,
                regionType: 3,
                regionName: '新干县',
                parentIdAll: '1,2148,2229,2241',
                children: []
              },
              {
                id: 2242,
                parentId: 2229,
                regionType: 3,
                regionName: '安福县',
                parentIdAll: '1,2148,2229,2242',
                children: []
              }
            ]
          },
          {
            id: 2243,
            parentId: 2148,
            regionType: 2,
            regionName: '宜春市',
            parentIdAll: '1,2148,2243',
            children: [
              {
                id: 2244,
                parentId: 2243,
                regionType: 3,
                regionName: '宜丰县',
                parentIdAll: '1,2148,2243,2244',
                children: []
              },
              {
                id: 2245,
                parentId: 2243,
                regionType: 3,
                regionName: '上高县',
                parentIdAll: '1,2148,2243,2245',
                children: []
              },
              {
                id: 2246,
                parentId: 2243,
                regionType: 3,
                regionName: '丰城市',
                parentIdAll: '1,2148,2243,2246',
                children: []
              },
              {
                id: 2247,
                parentId: 2243,
                regionType: 3,
                regionName: '靖安县',
                parentIdAll: '1,2148,2243,2247',
                children: []
              },
              {
                id: 2248,
                parentId: 2243,
                regionType: 3,
                regionName: '高安市',
                parentIdAll: '1,2148,2243,2248',
                children: []
              },
              {
                id: 2249,
                parentId: 2243,
                regionType: 3,
                regionName: '奉新县',
                parentIdAll: '1,2148,2243,2249',
                children: []
              },
              {
                id: 2250,
                parentId: 2243,
                regionType: 3,
                regionName: '万载县',
                parentIdAll: '1,2148,2243,2250',
                children: []
              },
              {
                id: 2251,
                parentId: 2243,
                regionType: 3,
                regionName: '铜鼓县',
                parentIdAll: '1,2148,2243,2251',
                children: []
              },
              {
                id: 2252,
                parentId: 2243,
                regionType: 3,
                regionName: '袁州区',
                parentIdAll: '1,2148,2243,2252',
                children: []
              },
              {
                id: 2253,
                parentId: 2243,
                regionType: 3,
                regionName: '樟树市',
                parentIdAll: '1,2148,2243,2253',
                children: []
              }
            ]
          },
          {
            id: 2254,
            parentId: 2148,
            regionType: 2,
            regionName: '萍乡市',
            parentIdAll: '1,2148,2254',
            children: [
              {
                id: 2255,
                parentId: 2254,
                regionType: 3,
                regionName: '湘东区',
                parentIdAll: '1,2148,2254,2255',
                children: []
              },
              {
                id: 2256,
                parentId: 2254,
                regionType: 3,
                regionName: '安源区',
                parentIdAll: '1,2148,2254,2256',
                children: []
              },
              {
                id: 2257,
                parentId: 2254,
                regionType: 3,
                regionName: '上栗县',
                parentIdAll: '1,2148,2254,2257',
                children: []
              },
              {
                id: 2258,
                parentId: 2254,
                regionType: 3,
                regionName: '芦溪县',
                parentIdAll: '1,2148,2254,2258',
                children: []
              },
              {
                id: 2259,
                parentId: 2254,
                regionType: 3,
                regionName: '莲花县',
                parentIdAll: '1,2148,2254,2259',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2260,
        parentId: 1,
        regionType: 1,
        regionName: '浙江省',
        parentIdAll: '1,2260',
        children: [
          {
            id: 2261,
            parentId: 2260,
            regionType: 2,
            regionName: '宁波市',
            parentIdAll: '1,2260,2261',
            children: [
              {
                id: 2262,
                parentId: 2261,
                regionType: 3,
                regionName: '北仑区',
                parentIdAll: '1,2260,2261,2262',
                children: []
              },
              {
                id: 2263,
                parentId: 2261,
                regionType: 3,
                regionName: '象山县',
                parentIdAll: '1,2260,2261,2263',
                children: []
              },
              {
                id: 2264,
                parentId: 2261,
                regionType: 3,
                regionName: '镇海区',
                parentIdAll: '1,2260,2261,2264',
                children: []
              },
              {
                id: 2265,
                parentId: 2261,
                regionType: 3,
                regionName: '江北区',
                parentIdAll: '1,2260,2261,2265',
                children: []
              },
              {
                id: 2266,
                parentId: 2261,
                regionType: 3,
                regionName: '宁海县',
                parentIdAll: '1,2260,2261,2266',
                children: []
              },
              {
                id: 2267,
                parentId: 2261,
                regionType: 3,
                regionName: '奉化区',
                parentIdAll: '1,2260,2261,2267',
                children: []
              },
              {
                id: 2268,
                parentId: 2261,
                regionType: 3,
                regionName: '余姚市',
                parentIdAll: '1,2260,2261,2268',
                children: []
              },
              {
                id: 2269,
                parentId: 2261,
                regionType: 3,
                regionName: '海曙区',
                parentIdAll: '1,2260,2261,2269',
                children: []
              },
              {
                id: 2270,
                parentId: 2261,
                regionType: 3,
                regionName: '鄞州区',
                parentIdAll: '1,2260,2261,2270',
                children: []
              },
              {
                id: 2271,
                parentId: 2261,
                regionType: 3,
                regionName: '慈溪市',
                parentIdAll: '1,2260,2261,2271',
                children: []
              }
            ]
          },
          {
            id: 2272,
            parentId: 2260,
            regionType: 2,
            regionName: '台州市',
            parentIdAll: '1,2260,2272',
            children: [
              {
                id: 2273,
                parentId: 2272,
                regionType: 3,
                regionName: '路桥区',
                parentIdAll: '1,2260,2272,2273',
                children: []
              },
              {
                id: 2274,
                parentId: 2272,
                regionType: 3,
                regionName: '临海市',
                parentIdAll: '1,2260,2272,2274',
                children: []
              },
              {
                id: 2275,
                parentId: 2272,
                regionType: 3,
                regionName: '玉环市',
                parentIdAll: '1,2260,2272,2275',
                children: []
              },
              {
                id: 2276,
                parentId: 2272,
                regionType: 3,
                regionName: '椒江区',
                parentIdAll: '1,2260,2272,2276',
                children: []
              },
              {
                id: 2277,
                parentId: 2272,
                regionType: 3,
                regionName: '三门县',
                parentIdAll: '1,2260,2272,2277',
                children: []
              },
              {
                id: 2278,
                parentId: 2272,
                regionType: 3,
                regionName: '天台县',
                parentIdAll: '1,2260,2272,2278',
                children: []
              },
              {
                id: 2279,
                parentId: 2272,
                regionType: 3,
                regionName: '温岭市',
                parentIdAll: '1,2260,2272,2279',
                children: []
              },
              {
                id: 2280,
                parentId: 2272,
                regionType: 3,
                regionName: '黄岩区',
                parentIdAll: '1,2260,2272,2280',
                children: []
              },
              {
                id: 2281,
                parentId: 2272,
                regionType: 3,
                regionName: '仙居县',
                parentIdAll: '1,2260,2272,2281',
                children: []
              }
            ]
          },
          {
            id: 2282,
            parentId: 2260,
            regionType: 2,
            regionName: '舟山市',
            parentIdAll: '1,2260,2282',
            children: [
              {
                id: 2283,
                parentId: 2282,
                regionType: 3,
                regionName: '普陀区',
                parentIdAll: '1,2260,2282,2283',
                children: []
              },
              {
                id: 2284,
                parentId: 2282,
                regionType: 3,
                regionName: '定海区',
                parentIdAll: '1,2260,2282,2284',
                children: []
              },
              {
                id: 2285,
                parentId: 2282,
                regionType: 3,
                regionName: '嵊泗县',
                parentIdAll: '1,2260,2282,2285',
                children: []
              },
              {
                id: 2286,
                parentId: 2282,
                regionType: 3,
                regionName: '岱山县',
                parentIdAll: '1,2260,2282,2286',
                children: []
              }
            ]
          },
          {
            id: 2287,
            parentId: 2260,
            regionType: 2,
            regionName: '嘉兴市',
            parentIdAll: '1,2260,2287',
            children: [
              {
                id: 2288,
                parentId: 2287,
                regionType: 3,
                regionName: '平湖市',
                parentIdAll: '1,2260,2287,2288',
                children: []
              },
              {
                id: 2289,
                parentId: 2287,
                regionType: 3,
                regionName: '桐乡市',
                parentIdAll: '1,2260,2287,2289',
                children: []
              },
              {
                id: 2290,
                parentId: 2287,
                regionType: 3,
                regionName: '海宁市',
                parentIdAll: '1,2260,2287,2290',
                children: []
              },
              {
                id: 2291,
                parentId: 2287,
                regionType: 3,
                regionName: '秀洲区',
                parentIdAll: '1,2260,2287,2291',
                children: []
              },
              {
                id: 2292,
                parentId: 2287,
                regionType: 3,
                regionName: '南湖区',
                parentIdAll: '1,2260,2287,2292',
                children: []
              },
              {
                id: 2293,
                parentId: 2287,
                regionType: 3,
                regionName: '嘉善县',
                parentIdAll: '1,2260,2287,2293',
                children: []
              },
              {
                id: 2294,
                parentId: 2287,
                regionType: 3,
                regionName: '海盐县',
                parentIdAll: '1,2260,2287,2294',
                children: []
              }
            ]
          },
          {
            id: 2295,
            parentId: 2260,
            regionType: 2,
            regionName: '温州市',
            parentIdAll: '1,2260,2295',
            children: [
              {
                id: 2296,
                parentId: 2295,
                regionType: 3,
                regionName: '洞头区',
                parentIdAll: '1,2260,2295,2296',
                children: []
              },
              {
                id: 2297,
                parentId: 2295,
                regionType: 3,
                regionName: '平阳县',
                parentIdAll: '1,2260,2295,2297',
                children: []
              },
              {
                id: 2298,
                parentId: 2295,
                regionType: 3,
                regionName: '瑞安市',
                parentIdAll: '1,2260,2295,2298',
                children: []
              },
              {
                id: 2299,
                parentId: 2295,
                regionType: 3,
                regionName: '苍南县',
                parentIdAll: '1,2260,2295,2299',
                children: []
              },
              {
                id: 2300,
                parentId: 2295,
                regionType: 3,
                regionName: '鹿城区',
                parentIdAll: '1,2260,2295,2300',
                children: []
              },
              {
                id: 2301,
                parentId: 2295,
                regionType: 3,
                regionName: '泰顺县',
                parentIdAll: '1,2260,2295,2301',
                children: []
              },
              {
                id: 2302,
                parentId: 2295,
                regionType: 3,
                regionName: '乐清市',
                parentIdAll: '1,2260,2295,2302',
                children: []
              },
              {
                id: 2303,
                parentId: 2295,
                regionType: 3,
                regionName: '文成县',
                parentIdAll: '1,2260,2295,2303',
                children: []
              },
              {
                id: 2304,
                parentId: 2295,
                regionType: 3,
                regionName: '永嘉县',
                parentIdAll: '1,2260,2295,2304',
                children: []
              },
              {
                id: 2305,
                parentId: 2295,
                regionType: 3,
                regionName: '龙湾区',
                parentIdAll: '1,2260,2295,2305',
                children: []
              },
              {
                id: 2306,
                parentId: 2295,
                regionType: 3,
                regionName: '瓯海区',
                parentIdAll: '1,2260,2295,2306',
                children: []
              }
            ]
          },
          {
            id: 2307,
            parentId: 2260,
            regionType: 2,
            regionName: '衢州市',
            parentIdAll: '1,2260,2307',
            children: [
              {
                id: 2308,
                parentId: 2307,
                regionType: 3,
                regionName: '柯城区',
                parentIdAll: '1,2260,2307,2308',
                children: []
              },
              {
                id: 2309,
                parentId: 2307,
                regionType: 3,
                regionName: '开化县',
                parentIdAll: '1,2260,2307,2309',
                children: []
              },
              {
                id: 2310,
                parentId: 2307,
                regionType: 3,
                regionName: '衢江区',
                parentIdAll: '1,2260,2307,2310',
                children: []
              },
              {
                id: 2311,
                parentId: 2307,
                regionType: 3,
                regionName: '常山县',
                parentIdAll: '1,2260,2307,2311',
                children: []
              },
              {
                id: 2312,
                parentId: 2307,
                regionType: 3,
                regionName: '龙游县',
                parentIdAll: '1,2260,2307,2312',
                children: []
              },
              {
                id: 2313,
                parentId: 2307,
                regionType: 3,
                regionName: '江山市',
                parentIdAll: '1,2260,2307,2313',
                children: []
              }
            ]
          },
          {
            id: 2314,
            parentId: 2260,
            regionType: 2,
            regionName: '金华市',
            parentIdAll: '1,2260,2314',
            children: [
              {
                id: 2315,
                parentId: 2314,
                regionType: 3,
                regionName: '永康市',
                parentIdAll: '1,2260,2314,2315',
                children: []
              },
              {
                id: 2316,
                parentId: 2314,
                regionType: 3,
                regionName: '浦江县',
                parentIdAll: '1,2260,2314,2316',
                children: []
              },
              {
                id: 2317,
                parentId: 2314,
                regionType: 3,
                regionName: '兰溪市',
                parentIdAll: '1,2260,2314,2317',
                children: []
              },
              {
                id: 2318,
                parentId: 2314,
                regionType: 3,
                regionName: '武义县',
                parentIdAll: '1,2260,2314,2318',
                children: []
              },
              {
                id: 2319,
                parentId: 2314,
                regionType: 3,
                regionName: '东阳市',
                parentIdAll: '1,2260,2314,2319',
                children: []
              },
              {
                id: 2320,
                parentId: 2314,
                regionType: 3,
                regionName: '磐安县',
                parentIdAll: '1,2260,2314,2320',
                children: []
              },
              {
                id: 2321,
                parentId: 2314,
                regionType: 3,
                regionName: '婺城区',
                parentIdAll: '1,2260,2314,2321',
                children: []
              },
              {
                id: 2322,
                parentId: 2314,
                regionType: 3,
                regionName: '金东区',
                parentIdAll: '1,2260,2314,2322',
                children: []
              },
              {
                id: 2323,
                parentId: 2314,
                regionType: 3,
                regionName: '义乌市',
                parentIdAll: '1,2260,2314,2323',
                children: []
              }
            ]
          },
          {
            id: 2324,
            parentId: 2260,
            regionType: 2,
            regionName: '丽水市',
            parentIdAll: '1,2260,2324',
            children: [
              {
                id: 2325,
                parentId: 2324,
                regionType: 3,
                regionName: '松阳县',
                parentIdAll: '1,2260,2324,2325',
                children: []
              },
              {
                id: 2326,
                parentId: 2324,
                regionType: 3,
                regionName: '莲都区',
                parentIdAll: '1,2260,2324,2326',
                children: []
              },
              {
                id: 2327,
                parentId: 2324,
                regionType: 3,
                regionName: '龙泉市',
                parentIdAll: '1,2260,2324,2327',
                children: []
              },
              {
                id: 2328,
                parentId: 2324,
                regionType: 3,
                regionName: '云和县',
                parentIdAll: '1,2260,2324,2328',
                children: []
              },
              {
                id: 2329,
                parentId: 2324,
                regionType: 3,
                regionName: '遂昌县',
                parentIdAll: '1,2260,2324,2329',
                children: []
              },
              {
                id: 2330,
                parentId: 2324,
                regionType: 3,
                regionName: '缙云县',
                parentIdAll: '1,2260,2324,2330',
                children: []
              },
              {
                id: 2331,
                parentId: 2324,
                regionType: 3,
                regionName: '景宁畲族自治县',
                parentIdAll: '1,2260,2324,2331',
                children: []
              },
              {
                id: 2332,
                parentId: 2324,
                regionType: 3,
                regionName: '庆元县',
                parentIdAll: '1,2260,2324,2332',
                children: []
              },
              {
                id: 2333,
                parentId: 2324,
                regionType: 3,
                regionName: '青田县',
                parentIdAll: '1,2260,2324,2333',
                children: []
              }
            ]
          },
          {
            id: 2334,
            parentId: 2260,
            regionType: 2,
            regionName: '杭州市',
            parentIdAll: '1,2260,2334',
            children: [
              {
                id: 2335,
                parentId: 2334,
                regionType: 3,
                regionName: '滨江区',
                parentIdAll: '1,2260,2334,2335',
                children: []
              },
              {
                id: 2336,
                parentId: 2334,
                regionType: 3,
                regionName: '淳安县',
                parentIdAll: '1,2260,2334,2336',
                children: []
              },
              {
                id: 2337,
                parentId: 2334,
                regionType: 3,
                regionName: '富阳区',
                parentIdAll: '1,2260,2334,2337',
                children: []
              },
              {
                id: 2338,
                parentId: 2334,
                regionType: 3,
                regionName: '拱墅区',
                parentIdAll: '1,2260,2334,2338',
                children: []
              },
              {
                id: 2339,
                parentId: 2334,
                regionType: 3,
                regionName: '建德市',
                parentIdAll: '1,2260,2334,2339',
                children: []
              },
              {
                id: 2340,
                parentId: 2334,
                regionType: 3,
                regionName: '江干区',
                parentIdAll: '1,2260,2334,2340',
                children: []
              },
              {
                id: 2341,
                parentId: 2334,
                regionType: 3,
                regionName: '临安区',
                parentIdAll: '1,2260,2334,2341',
                children: []
              },
              {
                id: 2342,
                parentId: 2334,
                regionType: 3,
                regionName: '上城区',
                parentIdAll: '1,2260,2334,2342',
                children: []
              },
              {
                id: 2343,
                parentId: 2334,
                regionType: 3,
                regionName: '桐庐县',
                parentIdAll: '1,2260,2334,2343',
                children: []
              },
              {
                id: 2344,
                parentId: 2334,
                regionType: 3,
                regionName: '西湖区',
                parentIdAll: '1,2260,2334,2344',
                children: []
              },
              {
                id: 2345,
                parentId: 2334,
                regionType: 3,
                regionName: '下城区',
                parentIdAll: '1,2260,2334,2345',
                children: []
              },
              {
                id: 2346,
                parentId: 2334,
                regionType: 3,
                regionName: '萧山区',
                parentIdAll: '1,2260,2334,2346',
                children: []
              },
              {
                id: 2347,
                parentId: 2334,
                regionType: 3,
                regionName: '余杭区',
                parentIdAll: '1,2260,2334,2347',
                children: []
              }
            ]
          },
          {
            id: 2348,
            parentId: 2260,
            regionType: 2,
            regionName: '绍兴市',
            parentIdAll: '1,2260,2348',
            children: [
              {
                id: 2349,
                parentId: 2348,
                regionType: 3,
                regionName: '上虞区',
                parentIdAll: '1,2260,2348,2349',
                children: []
              },
              {
                id: 2350,
                parentId: 2348,
                regionType: 3,
                regionName: '新昌县',
                parentIdAll: '1,2260,2348,2350',
                children: []
              },
              {
                id: 2351,
                parentId: 2348,
                regionType: 3,
                regionName: '嵊州市',
                parentIdAll: '1,2260,2348,2351',
                children: []
              },
              {
                id: 2352,
                parentId: 2348,
                regionType: 3,
                regionName: '越城区',
                parentIdAll: '1,2260,2348,2352',
                children: []
              },
              {
                id: 2353,
                parentId: 2348,
                regionType: 3,
                regionName: '柯桥区',
                parentIdAll: '1,2260,2348,2353',
                children: []
              },
              {
                id: 2354,
                parentId: 2348,
                regionType: 3,
                regionName: '诸暨市',
                parentIdAll: '1,2260,2348,2354',
                children: []
              }
            ]
          },
          {
            id: 2355,
            parentId: 2260,
            regionType: 2,
            regionName: '湖州市',
            parentIdAll: '1,2260,2355',
            children: [
              {
                id: 2356,
                parentId: 2355,
                regionType: 3,
                regionName: '长兴县',
                parentIdAll: '1,2260,2355,2356',
                children: []
              },
              {
                id: 2357,
                parentId: 2355,
                regionType: 3,
                regionName: '德清县',
                parentIdAll: '1,2260,2355,2357',
                children: []
              },
              {
                id: 2358,
                parentId: 2355,
                regionType: 3,
                regionName: '吴兴区',
                parentIdAll: '1,2260,2355,2358',
                children: []
              },
              {
                id: 2359,
                parentId: 2355,
                regionType: 3,
                regionName: '安吉县',
                parentIdAll: '1,2260,2355,2359',
                children: []
              },
              {
                id: 2360,
                parentId: 2355,
                regionType: 3,
                regionName: '南浔区',
                parentIdAll: '1,2260,2355,2360',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2361,
        parentId: 1,
        regionType: 1,
        regionName: '河北省',
        parentIdAll: '1,2361',
        children: [
          {
            id: 2362,
            parentId: 2361,
            regionType: 2,
            regionName: '唐山市',
            parentIdAll: '1,2361,2362',
            children: [
              {
                id: 2363,
                parentId: 2362,
                regionType: 3,
                regionName: '滦南县',
                parentIdAll: '1,2361,2362,2363',
                children: []
              },
              {
                id: 2364,
                parentId: 2362,
                regionType: 3,
                regionName: '迁西县',
                parentIdAll: '1,2361,2362,2364',
                children: []
              },
              {
                id: 2365,
                parentId: 2362,
                regionType: 3,
                regionName: '乐亭县',
                parentIdAll: '1,2361,2362,2365',
                children: []
              },
              {
                id: 2366,
                parentId: 2362,
                regionType: 3,
                regionName: '遵化市',
                parentIdAll: '1,2361,2362,2366',
                children: []
              },
              {
                id: 2367,
                parentId: 2362,
                regionType: 3,
                regionName: '迁安市',
                parentIdAll: '1,2361,2362,2367',
                children: []
              },
              {
                id: 2368,
                parentId: 2362,
                regionType: 3,
                regionName: '滦县',
                parentIdAll: '1,2361,2362,2368',
                children: []
              },
              {
                id: 2369,
                parentId: 2362,
                regionType: 3,
                regionName: '曹妃甸区',
                parentIdAll: '1,2361,2362,2369',
                children: []
              },
              {
                id: 2370,
                parentId: 2362,
                regionType: 3,
                regionName: '玉田县',
                parentIdAll: '1,2361,2362,2370',
                children: []
              },
              {
                id: 2371,
                parentId: 2362,
                regionType: 3,
                regionName: '路南区',
                parentIdAll: '1,2361,2362,2371',
                children: []
              },
              {
                id: 2372,
                parentId: 2362,
                regionType: 3,
                regionName: '丰南区',
                parentIdAll: '1,2361,2362,2372',
                children: []
              },
              {
                id: 2373,
                parentId: 2362,
                regionType: 3,
                regionName: '路北区',
                parentIdAll: '1,2361,2362,2373',
                children: []
              },
              {
                id: 2374,
                parentId: 2362,
                regionType: 3,
                regionName: '丰润区',
                parentIdAll: '1,2361,2362,2374',
                children: []
              },
              {
                id: 2375,
                parentId: 2362,
                regionType: 3,
                regionName: '古冶区',
                parentIdAll: '1,2361,2362,2375',
                children: []
              },
              {
                id: 2376,
                parentId: 2362,
                regionType: 3,
                regionName: '开平区',
                parentIdAll: '1,2361,2362,2376',
                children: []
              }
            ]
          },
          {
            id: 2377,
            parentId: 2361,
            regionType: 2,
            regionName: '邯郸市',
            parentIdAll: '1,2361,2377',
            children: [
              {
                id: 2378,
                parentId: 2377,
                regionType: 3,
                regionName: '广平县',
                parentIdAll: '1,2361,2377,2378',
                children: []
              },
              {
                id: 2379,
                parentId: 2377,
                regionType: 3,
                regionName: '馆陶县',
                parentIdAll: '1,2361,2377,2379',
                children: []
              },
              {
                id: 2380,
                parentId: 2377,
                regionType: 3,
                regionName: '肥乡区',
                parentIdAll: '1,2361,2377,2380',
                children: []
              },
              {
                id: 2381,
                parentId: 2377,
                regionType: 3,
                regionName: '魏县',
                parentIdAll: '1,2361,2377,2381',
                children: []
              },
              {
                id: 2382,
                parentId: 2377,
                regionType: 3,
                regionName: '大名县',
                parentIdAll: '1,2361,2377,2382',
                children: []
              },
              {
                id: 2383,
                parentId: 2377,
                regionType: 3,
                regionName: '武安市',
                parentIdAll: '1,2361,2377,2383',
                children: []
              },
              {
                id: 2384,
                parentId: 2377,
                regionType: 3,
                regionName: '邱县',
                parentIdAll: '1,2361,2377,2384',
                children: []
              },
              {
                id: 2385,
                parentId: 2377,
                regionType: 3,
                regionName: '峰峰矿区',
                parentIdAll: '1,2361,2377,2385',
                children: []
              },
              {
                id: 2386,
                parentId: 2377,
                regionType: 3,
                regionName: '临漳县',
                parentIdAll: '1,2361,2377,2386',
                children: []
              },
              {
                id: 2387,
                parentId: 2377,
                regionType: 3,
                regionName: '涉县',
                parentIdAll: '1,2361,2377,2387',
                children: []
              },
              {
                id: 2388,
                parentId: 2377,
                regionType: 3,
                regionName: '鸡泽县',
                parentIdAll: '1,2361,2377,2388',
                children: []
              },
              {
                id: 2389,
                parentId: 2377,
                regionType: 3,
                regionName: '曲周县',
                parentIdAll: '1,2361,2377,2389',
                children: []
              },
              {
                id: 2390,
                parentId: 2377,
                regionType: 3,
                regionName: '磁县',
                parentIdAll: '1,2361,2377,2390',
                children: []
              },
              {
                id: 2391,
                parentId: 2377,
                regionType: 3,
                regionName: '丛台区',
                parentIdAll: '1,2361,2377,2391',
                children: []
              },
              {
                id: 2392,
                parentId: 2377,
                regionType: 3,
                regionName: '复兴区',
                parentIdAll: '1,2361,2377,2392',
                children: []
              },
              {
                id: 2393,
                parentId: 2377,
                regionType: 3,
                regionName: '永年区',
                parentIdAll: '1,2361,2377,2393',
                children: []
              },
              {
                id: 2394,
                parentId: 2377,
                regionType: 3,
                regionName: '邯山区',
                parentIdAll: '1,2361,2377,2394',
                children: []
              },
              {
                id: 2395,
                parentId: 2377,
                regionType: 3,
                regionName: '成安县',
                parentIdAll: '1,2361,2377,2395',
                children: []
              }
            ]
          },
          {
            id: 2396,
            parentId: 2361,
            regionType: 2,
            regionName: '邢台市',
            parentIdAll: '1,2361,2396',
            children: [
              {
                id: 2397,
                parentId: 2396,
                regionType: 3,
                regionName: '内丘县',
                parentIdAll: '1,2361,2396,2397',
                children: []
              },
              {
                id: 2398,
                parentId: 2396,
                regionType: 3,
                regionName: '隆尧县',
                parentIdAll: '1,2361,2396,2398',
                children: []
              },
              {
                id: 2399,
                parentId: 2396,
                regionType: 3,
                regionName: '新河县',
                parentIdAll: '1,2361,2396,2399',
                children: []
              },
              {
                id: 2400,
                parentId: 2396,
                regionType: 3,
                regionName: '柏乡县',
                parentIdAll: '1,2361,2396,2400',
                children: []
              },
              {
                id: 2401,
                parentId: 2396,
                regionType: 3,
                regionName: '临城县',
                parentIdAll: '1,2361,2396,2401',
                children: []
              },
              {
                id: 2402,
                parentId: 2396,
                regionType: 3,
                regionName: '南宫市',
                parentIdAll: '1,2361,2396,2402',
                children: []
              },
              {
                id: 2403,
                parentId: 2396,
                regionType: 3,
                regionName: '宁晋县',
                parentIdAll: '1,2361,2396,2403',
                children: []
              },
              {
                id: 2404,
                parentId: 2396,
                regionType: 3,
                regionName: '临西县',
                parentIdAll: '1,2361,2396,2404',
                children: []
              },
              {
                id: 2405,
                parentId: 2396,
                regionType: 3,
                regionName: '平乡县',
                parentIdAll: '1,2361,2396,2405',
                children: []
              },
              {
                id: 2406,
                parentId: 2396,
                regionType: 3,
                regionName: '沙河市',
                parentIdAll: '1,2361,2396,2406',
                children: []
              },
              {
                id: 2407,
                parentId: 2396,
                regionType: 3,
                regionName: '清河县',
                parentIdAll: '1,2361,2396,2407',
                children: []
              },
              {
                id: 2408,
                parentId: 2396,
                regionType: 3,
                regionName: '南和县',
                parentIdAll: '1,2361,2396,2408',
                children: []
              },
              {
                id: 2409,
                parentId: 2396,
                regionType: 3,
                regionName: '巨鹿县',
                parentIdAll: '1,2361,2396,2409',
                children: []
              },
              {
                id: 2410,
                parentId: 2396,
                regionType: 3,
                regionName: '广宗县',
                parentIdAll: '1,2361,2396,2410',
                children: []
              },
              {
                id: 2411,
                parentId: 2396,
                regionType: 3,
                regionName: '桥西区',
                parentIdAll: '1,2361,2396,2411',
                children: []
              },
              {
                id: 2412,
                parentId: 2396,
                regionType: 3,
                regionName: '威县',
                parentIdAll: '1,2361,2396,2412',
                children: []
              },
              {
                id: 2413,
                parentId: 2396,
                regionType: 3,
                regionName: '任县',
                parentIdAll: '1,2361,2396,2413',
                children: []
              },
              {
                id: 2414,
                parentId: 2396,
                regionType: 3,
                regionName: '桥东区',
                parentIdAll: '1,2361,2396,2414',
                children: []
              },
              {
                id: 2415,
                parentId: 2396,
                regionType: 3,
                regionName: '邢台县',
                parentIdAll: '1,2361,2396,2415',
                children: []
              }
            ]
          },
          {
            id: 2416,
            parentId: 2361,
            regionType: 2,
            regionName: '承德市',
            parentIdAll: '1,2361,2416',
            children: [
              {
                id: 2417,
                parentId: 2416,
                regionType: 3,
                regionName: '围场满族蒙古族自治县',
                parentIdAll: '1,2361,2416,2417',
                children: []
              },
              {
                id: 2418,
                parentId: 2416,
                regionType: 3,
                regionName: '丰宁满族自治县',
                parentIdAll: '1,2361,2416,2418',
                children: []
              },
              {
                id: 2419,
                parentId: 2416,
                regionType: 3,
                regionName: '鹰手营子矿区',
                parentIdAll: '1,2361,2416,2419',
                children: []
              },
              {
                id: 2420,
                parentId: 2416,
                regionType: 3,
                regionName: '宽城满族自治县',
                parentIdAll: '1,2361,2416,2420',
                children: []
              },
              {
                id: 2421,
                parentId: 2416,
                regionType: 3,
                regionName: '隆化县',
                parentIdAll: '1,2361,2416,2421',
                children: []
              },
              {
                id: 2422,
                parentId: 2416,
                regionType: 3,
                regionName: '平泉市',
                parentIdAll: '1,2361,2416,2422',
                children: []
              },
              {
                id: 2423,
                parentId: 2416,
                regionType: 3,
                regionName: '兴隆县',
                parentIdAll: '1,2361,2416,2423',
                children: []
              },
              {
                id: 2424,
                parentId: 2416,
                regionType: 3,
                regionName: '滦平县',
                parentIdAll: '1,2361,2416,2424',
                children: []
              },
              {
                id: 2425,
                parentId: 2416,
                regionType: 3,
                regionName: '双滦区',
                parentIdAll: '1,2361,2416,2425',
                children: []
              },
              {
                id: 2426,
                parentId: 2416,
                regionType: 3,
                regionName: '承德县',
                parentIdAll: '1,2361,2416,2426',
                children: []
              },
              {
                id: 2427,
                parentId: 2416,
                regionType: 3,
                regionName: '双桥区',
                parentIdAll: '1,2361,2416,2427',
                children: []
              }
            ]
          },
          {
            id: 2428,
            parentId: 2361,
            regionType: 2,
            regionName: '张家口市',
            parentIdAll: '1,2361,2428',
            children: [
              {
                id: 2429,
                parentId: 2428,
                regionType: 3,
                regionName: '崇礼区',
                parentIdAll: '1,2361,2428,2429',
                children: []
              },
              {
                id: 2430,
                parentId: 2428,
                regionType: 3,
                regionName: '阳原县',
                parentIdAll: '1,2361,2428,2430',
                children: []
              },
              {
                id: 2431,
                parentId: 2428,
                regionType: 3,
                regionName: '怀来县',
                parentIdAll: '1,2361,2428,2431',
                children: []
              },
              {
                id: 2432,
                parentId: 2428,
                regionType: 3,
                regionName: '沽源县',
                parentIdAll: '1,2361,2428,2432',
                children: []
              },
              {
                id: 2433,
                parentId: 2428,
                regionType: 3,
                regionName: '张北县',
                parentIdAll: '1,2361,2428,2433',
                children: []
              },
              {
                id: 2434,
                parentId: 2428,
                regionType: 3,
                regionName: '下花园区',
                parentIdAll: '1,2361,2428,2434',
                children: []
              },
              {
                id: 2435,
                parentId: 2428,
                regionType: 3,
                regionName: '怀安县',
                parentIdAll: '1,2361,2428,2435',
                children: []
              },
              {
                id: 2436,
                parentId: 2428,
                regionType: 3,
                regionName: '康保县',
                parentIdAll: '1,2361,2428,2436',
                children: []
              },
              {
                id: 2437,
                parentId: 2428,
                regionType: 3,
                regionName: '蔚县',
                parentIdAll: '1,2361,2428,2437',
                children: []
              },
              {
                id: 2438,
                parentId: 2428,
                regionType: 3,
                regionName: '涿鹿县',
                parentIdAll: '1,2361,2428,2438',
                children: []
              },
              {
                id: 2439,
                parentId: 2428,
                regionType: 3,
                regionName: '赤城县',
                parentIdAll: '1,2361,2428,2439',
                children: []
              },
              {
                id: 2440,
                parentId: 2428,
                regionType: 3,
                regionName: '尚义县',
                parentIdAll: '1,2361,2428,2440',
                children: []
              },
              {
                id: 2441,
                parentId: 2428,
                regionType: 3,
                regionName: '万全区',
                parentIdAll: '1,2361,2428,2441',
                children: []
              },
              {
                id: 2442,
                parentId: 2428,
                regionType: 3,
                regionName: '桥西区',
                parentIdAll: '1,2361,2428,2442',
                children: []
              },
              {
                id: 2443,
                parentId: 2428,
                regionType: 3,
                regionName: '宣化区',
                parentIdAll: '1,2361,2428,2443',
                children: []
              },
              {
                id: 2444,
                parentId: 2428,
                regionType: 3,
                regionName: '桥东区',
                parentIdAll: '1,2361,2428,2444',
                children: []
              }
            ]
          },
          {
            id: 2445,
            parentId: 2361,
            regionType: 2,
            regionName: '廊坊市',
            parentIdAll: '1,2361,2445',
            children: [
              {
                id: 2446,
                parentId: 2445,
                regionType: 3,
                regionName: '大厂回族自治县',
                parentIdAll: '1,2361,2445,2446',
                children: []
              },
              {
                id: 2447,
                parentId: 2445,
                regionType: 3,
                regionName: '霸州市',
                parentIdAll: '1,2361,2445,2447',
                children: []
              },
              {
                id: 2448,
                parentId: 2445,
                regionType: 3,
                regionName: '大城县',
                parentIdAll: '1,2361,2445,2448',
                children: []
              },
              {
                id: 2449,
                parentId: 2445,
                regionType: 3,
                regionName: '永清县',
                parentIdAll: '1,2361,2445,2449',
                children: []
              },
              {
                id: 2450,
                parentId: 2445,
                regionType: 3,
                regionName: '香河县',
                parentIdAll: '1,2361,2445,2450',
                children: []
              },
              {
                id: 2451,
                parentId: 2445,
                regionType: 3,
                regionName: '固安县',
                parentIdAll: '1,2361,2445,2451',
                children: []
              },
              {
                id: 2452,
                parentId: 2445,
                regionType: 3,
                regionName: '文安县',
                parentIdAll: '1,2361,2445,2452',
                children: []
              },
              {
                id: 2453,
                parentId: 2445,
                regionType: 3,
                regionName: '广阳区',
                parentIdAll: '1,2361,2445,2453',
                children: []
              },
              {
                id: 2454,
                parentId: 2445,
                regionType: 3,
                regionName: '安次区',
                parentIdAll: '1,2361,2445,2454',
                children: []
              },
              {
                id: 2455,
                parentId: 2445,
                regionType: 3,
                regionName: '三河市',
                parentIdAll: '1,2361,2445,2455',
                children: []
              }
            ]
          },
          {
            id: 2456,
            parentId: 2361,
            regionType: 2,
            regionName: '沧州市',
            parentIdAll: '1,2361,2456',
            children: [
              {
                id: 2457,
                parentId: 2456,
                regionType: 3,
                regionName: '青县',
                parentIdAll: '1,2361,2456,2457',
                children: []
              },
              {
                id: 2458,
                parentId: 2456,
                regionType: 3,
                regionName: '运河区',
                parentIdAll: '1,2361,2456,2458',
                children: []
              },
              {
                id: 2459,
                parentId: 2456,
                regionType: 3,
                regionName: '任丘市',
                parentIdAll: '1,2361,2456,2459',
                children: []
              },
              {
                id: 2460,
                parentId: 2456,
                regionType: 3,
                regionName: '孟村回族自治县',
                parentIdAll: '1,2361,2456,2460',
                children: []
              },
              {
                id: 2461,
                parentId: 2456,
                regionType: 3,
                regionName: '献县',
                parentIdAll: '1,2361,2456,2461',
                children: []
              },
              {
                id: 2462,
                parentId: 2456,
                regionType: 3,
                regionName: '东光县',
                parentIdAll: '1,2361,2456,2462',
                children: []
              },
              {
                id: 2463,
                parentId: 2456,
                regionType: 3,
                regionName: '新华区',
                parentIdAll: '1,2361,2456,2463',
                children: []
              },
              {
                id: 2464,
                parentId: 2456,
                regionType: 3,
                regionName: '河间市',
                parentIdAll: '1,2361,2456,2464',
                children: []
              },
              {
                id: 2465,
                parentId: 2456,
                regionType: 3,
                regionName: '海兴县',
                parentIdAll: '1,2361,2456,2465',
                children: []
              },
              {
                id: 2466,
                parentId: 2456,
                regionType: 3,
                regionName: '盐山县',
                parentIdAll: '1,2361,2456,2466',
                children: []
              },
              {
                id: 2467,
                parentId: 2456,
                regionType: 3,
                regionName: '南皮县',
                parentIdAll: '1,2361,2456,2467',
                children: []
              },
              {
                id: 2468,
                parentId: 2456,
                regionType: 3,
                regionName: '沧县',
                parentIdAll: '1,2361,2456,2468',
                children: []
              },
              {
                id: 2469,
                parentId: 2456,
                regionType: 3,
                regionName: '肃宁县',
                parentIdAll: '1,2361,2456,2469',
                children: []
              },
              {
                id: 2470,
                parentId: 2456,
                regionType: 3,
                regionName: '吴桥县',
                parentIdAll: '1,2361,2456,2470',
                children: []
              },
              {
                id: 2471,
                parentId: 2456,
                regionType: 3,
                regionName: '黄骅市',
                parentIdAll: '1,2361,2456,2471',
                children: []
              },
              {
                id: 2472,
                parentId: 2456,
                regionType: 3,
                regionName: '泊头市',
                parentIdAll: '1,2361,2456,2472',
                children: []
              }
            ]
          },
          {
            id: 2473,
            parentId: 2361,
            regionType: 2,
            regionName: '衡水市',
            parentIdAll: '1,2361,2473',
            children: [
              {
                id: 2474,
                parentId: 2473,
                regionType: 3,
                regionName: '安平县',
                parentIdAll: '1,2361,2473,2474',
                children: []
              },
              {
                id: 2475,
                parentId: 2473,
                regionType: 3,
                regionName: '枣强县',
                parentIdAll: '1,2361,2473,2475',
                children: []
              },
              {
                id: 2476,
                parentId: 2473,
                regionType: 3,
                regionName: '武强县',
                parentIdAll: '1,2361,2473,2476',
                children: []
              },
              {
                id: 2477,
                parentId: 2473,
                regionType: 3,
                regionName: '桃城区',
                parentIdAll: '1,2361,2473,2477',
                children: []
              },
              {
                id: 2478,
                parentId: 2473,
                regionType: 3,
                regionName: '武邑县',
                parentIdAll: '1,2361,2473,2478',
                children: []
              },
              {
                id: 2479,
                parentId: 2473,
                regionType: 3,
                regionName: '深州市',
                parentIdAll: '1,2361,2473,2479',
                children: []
              },
              {
                id: 2480,
                parentId: 2473,
                regionType: 3,
                regionName: '饶阳县',
                parentIdAll: '1,2361,2473,2480',
                children: []
              },
              {
                id: 2481,
                parentId: 2473,
                regionType: 3,
                regionName: '冀州区',
                parentIdAll: '1,2361,2473,2481',
                children: []
              },
              {
                id: 2482,
                parentId: 2473,
                regionType: 3,
                regionName: '景县',
                parentIdAll: '1,2361,2473,2482',
                children: []
              },
              {
                id: 2483,
                parentId: 2473,
                regionType: 3,
                regionName: '故城县',
                parentIdAll: '1,2361,2473,2483',
                children: []
              },
              {
                id: 2484,
                parentId: 2473,
                regionType: 3,
                regionName: '阜城县',
                parentIdAll: '1,2361,2473,2484',
                children: []
              }
            ]
          },
          {
            id: 2485,
            parentId: 2361,
            regionType: 2,
            regionName: '秦皇岛市',
            parentIdAll: '1,2361,2485',
            children: [
              {
                id: 2486,
                parentId: 2485,
                regionType: 3,
                regionName: '卢龙县',
                parentIdAll: '1,2361,2485,2486',
                children: []
              },
              {
                id: 2487,
                parentId: 2485,
                regionType: 3,
                regionName: '青龙满族自治县',
                parentIdAll: '1,2361,2485,2487',
                children: []
              },
              {
                id: 2488,
                parentId: 2485,
                regionType: 3,
                regionName: '山海关区',
                parentIdAll: '1,2361,2485,2488',
                children: []
              },
              {
                id: 2489,
                parentId: 2485,
                regionType: 3,
                regionName: '北戴河区',
                parentIdAll: '1,2361,2485,2489',
                children: []
              },
              {
                id: 2490,
                parentId: 2485,
                regionType: 3,
                regionName: '海港区',
                parentIdAll: '1,2361,2485,2490',
                children: []
              },
              {
                id: 2491,
                parentId: 2485,
                regionType: 3,
                regionName: '抚宁区',
                parentIdAll: '1,2361,2485,2491',
                children: []
              },
              {
                id: 2492,
                parentId: 2485,
                regionType: 3,
                regionName: '昌黎县',
                parentIdAll: '1,2361,2485,2492',
                children: []
              }
            ]
          },
          {
            id: 2493,
            parentId: 2361,
            regionType: 2,
            regionName: '保定市',
            parentIdAll: '1,2361,2493',
            children: [
              {
                id: 2494,
                parentId: 2493,
                regionType: 3,
                regionName: '顺平县',
                parentIdAll: '1,2361,2493,2494',
                children: []
              },
              {
                id: 2495,
                parentId: 2493,
                regionType: 3,
                regionName: '望都县',
                parentIdAll: '1,2361,2493,2495',
                children: []
              },
              {
                id: 2496,
                parentId: 2493,
                regionType: 3,
                regionName: '雄县',
                parentIdAll: '1,2361,2493,2496',
                children: []
              },
              {
                id: 2497,
                parentId: 2493,
                regionType: 3,
                regionName: '高阳县',
                parentIdAll: '1,2361,2493,2497',
                children: []
              },
              {
                id: 2498,
                parentId: 2493,
                regionType: 3,
                regionName: '安新县',
                parentIdAll: '1,2361,2493,2498',
                children: []
              },
              {
                id: 2499,
                parentId: 2493,
                regionType: 3,
                regionName: '定兴县',
                parentIdAll: '1,2361,2493,2499',
                children: []
              },
              {
                id: 2500,
                parentId: 2493,
                regionType: 3,
                regionName: '涞源县',
                parentIdAll: '1,2361,2493,2500',
                children: []
              },
              {
                id: 2501,
                parentId: 2493,
                regionType: 3,
                regionName: '徐水区',
                parentIdAll: '1,2361,2493,2501',
                children: []
              },
              {
                id: 2502,
                parentId: 2493,
                regionType: 3,
                regionName: '阜平县',
                parentIdAll: '1,2361,2493,2502',
                children: []
              },
              {
                id: 2503,
                parentId: 2493,
                regionType: 3,
                regionName: '易县',
                parentIdAll: '1,2361,2493,2503',
                children: []
              },
              {
                id: 2504,
                parentId: 2493,
                regionType: 3,
                regionName: '容城县',
                parentIdAll: '1,2361,2493,2504',
                children: []
              },
              {
                id: 2505,
                parentId: 2493,
                regionType: 3,
                regionName: '莲池区',
                parentIdAll: '1,2361,2493,2505',
                children: []
              },
              {
                id: 2506,
                parentId: 2493,
                regionType: 3,
                regionName: '竞秀区',
                parentIdAll: '1,2361,2493,2506',
                children: []
              },
              {
                id: 2507,
                parentId: 2493,
                regionType: 3,
                regionName: '蠡县',
                parentIdAll: '1,2361,2493,2507',
                children: []
              },
              {
                id: 2508,
                parentId: 2493,
                regionType: 3,
                regionName: '博野县',
                parentIdAll: '1,2361,2493,2508',
                children: []
              },
              {
                id: 2509,
                parentId: 2493,
                regionType: 3,
                regionName: '涿州市',
                parentIdAll: '1,2361,2493,2509',
                children: []
              },
              {
                id: 2510,
                parentId: 2493,
                regionType: 3,
                regionName: '唐县',
                parentIdAll: '1,2361,2493,2510',
                children: []
              },
              {
                id: 2511,
                parentId: 2493,
                regionType: 3,
                regionName: '高碑店市',
                parentIdAll: '1,2361,2493,2511',
                children: []
              },
              {
                id: 2512,
                parentId: 2493,
                regionType: 3,
                regionName: '涞水县',
                parentIdAll: '1,2361,2493,2512',
                children: []
              },
              {
                id: 2513,
                parentId: 2493,
                regionType: 3,
                regionName: '曲阳县',
                parentIdAll: '1,2361,2493,2513',
                children: []
              },
              {
                id: 2514,
                parentId: 2493,
                regionType: 3,
                regionName: '满城区',
                parentIdAll: '1,2361,2493,2514',
                children: []
              },
              {
                id: 2515,
                parentId: 2493,
                regionType: 3,
                regionName: '清苑区',
                parentIdAll: '1,2361,2493,2515',
                children: []
              },
              {
                id: 2516,
                parentId: 2493,
                regionType: 3,
                regionName: '定州市',
                parentIdAll: '1,2361,2493,2516',
                children: []
              },
              {
                id: 2517,
                parentId: 2493,
                regionType: 3,
                regionName: '安国市',
                parentIdAll: '1,2361,2493,2517',
                children: []
              }
            ]
          },
          {
            id: 2518,
            parentId: 2361,
            regionType: 2,
            regionName: '石家庄市',
            parentIdAll: '1,2361,2518',
            children: [
              {
                id: 2519,
                parentId: 2518,
                regionType: 3,
                regionName: '平山县',
                parentIdAll: '1,2361,2518,2519',
                children: []
              },
              {
                id: 2520,
                parentId: 2518,
                regionType: 3,
                regionName: '元氏县',
                parentIdAll: '1,2361,2518,2520',
                children: []
              },
              {
                id: 2521,
                parentId: 2518,
                regionType: 3,
                regionName: '行唐县',
                parentIdAll: '1,2361,2518,2521',
                children: []
              },
              {
                id: 2522,
                parentId: 2518,
                regionType: 3,
                regionName: '藁城区',
                parentIdAll: '1,2361,2518,2522',
                children: []
              },
              {
                id: 2523,
                parentId: 2518,
                regionType: 3,
                regionName: '灵寿县',
                parentIdAll: '1,2361,2518,2523',
                children: []
              },
              {
                id: 2524,
                parentId: 2518,
                regionType: 3,
                regionName: '晋州市',
                parentIdAll: '1,2361,2518,2524',
                children: []
              },
              {
                id: 2525,
                parentId: 2518,
                regionType: 3,
                regionName: '高邑县',
                parentIdAll: '1,2361,2518,2525',
                children: []
              },
              {
                id: 2526,
                parentId: 2518,
                regionType: 3,
                regionName: '鹿泉区',
                parentIdAll: '1,2361,2518,2526',
                children: []
              },
              {
                id: 2527,
                parentId: 2518,
                regionType: 3,
                regionName: '赵县',
                parentIdAll: '1,2361,2518,2527',
                children: []
              },
              {
                id: 2528,
                parentId: 2518,
                regionType: 3,
                regionName: '无极县',
                parentIdAll: '1,2361,2518,2528',
                children: []
              },
              {
                id: 2529,
                parentId: 2518,
                regionType: 3,
                regionName: '赞皇县',
                parentIdAll: '1,2361,2518,2529',
                children: []
              },
              {
                id: 2530,
                parentId: 2518,
                regionType: 3,
                regionName: '正定县',
                parentIdAll: '1,2361,2518,2530',
                children: []
              },
              {
                id: 2531,
                parentId: 2518,
                regionType: 3,
                regionName: '栾城区',
                parentIdAll: '1,2361,2518,2531',
                children: []
              },
              {
                id: 2532,
                parentId: 2518,
                regionType: 3,
                regionName: '裕华区',
                parentIdAll: '1,2361,2518,2532',
                children: []
              },
              {
                id: 2533,
                parentId: 2518,
                regionType: 3,
                regionName: '井陉矿区',
                parentIdAll: '1,2361,2518,2533',
                children: []
              },
              {
                id: 2534,
                parentId: 2518,
                regionType: 3,
                regionName: '新乐市',
                parentIdAll: '1,2361,2518,2534',
                children: []
              },
              {
                id: 2535,
                parentId: 2518,
                regionType: 3,
                regionName: '长安区',
                parentIdAll: '1,2361,2518,2535',
                children: []
              },
              {
                id: 2536,
                parentId: 2518,
                regionType: 3,
                regionName: '辛集市',
                parentIdAll: '1,2361,2518,2536',
                children: []
              },
              {
                id: 2537,
                parentId: 2518,
                regionType: 3,
                regionName: '新华区',
                parentIdAll: '1,2361,2518,2537',
                children: []
              },
              {
                id: 2538,
                parentId: 2518,
                regionType: 3,
                regionName: '井陉县',
                parentIdAll: '1,2361,2518,2538',
                children: []
              },
              {
                id: 2539,
                parentId: 2518,
                regionType: 3,
                regionName: '桥西区',
                parentIdAll: '1,2361,2518,2539',
                children: []
              },
              {
                id: 2540,
                parentId: 2518,
                regionType: 3,
                regionName: '深泽县',
                parentIdAll: '1,2361,2518,2540',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2541,
        parentId: 1,
        regionType: 1,
        regionName: '山西省',
        parentIdAll: '1,2541',
        children: [
          {
            id: 2542,
            parentId: 2541,
            regionType: 2,
            regionName: '太原市',
            parentIdAll: '1,2541,2542',
            children: [
              {
                id: 2543,
                parentId: 2542,
                regionType: 3,
                regionName: '古交市',
                parentIdAll: '1,2541,2542,2543',
                children: []
              },
              {
                id: 2544,
                parentId: 2542,
                regionType: 3,
                regionName: '尖草坪区',
                parentIdAll: '1,2541,2542,2544',
                children: []
              },
              {
                id: 2545,
                parentId: 2542,
                regionType: 3,
                regionName: '晋源区',
                parentIdAll: '1,2541,2542,2545',
                children: []
              },
              {
                id: 2546,
                parentId: 2542,
                regionType: 3,
                regionName: '娄烦县',
                parentIdAll: '1,2541,2542,2546',
                children: []
              },
              {
                id: 2547,
                parentId: 2542,
                regionType: 3,
                regionName: '清徐县',
                parentIdAll: '1,2541,2542,2547',
                children: []
              },
              {
                id: 2548,
                parentId: 2542,
                regionType: 3,
                regionName: '万柏林区',
                parentIdAll: '1,2541,2542,2548',
                children: []
              },
              {
                id: 2549,
                parentId: 2542,
                regionType: 3,
                regionName: '小店区',
                parentIdAll: '1,2541,2542,2549',
                children: []
              },
              {
                id: 2550,
                parentId: 2542,
                regionType: 3,
                regionName: '杏花岭区',
                parentIdAll: '1,2541,2542,2550',
                children: []
              },
              {
                id: 2551,
                parentId: 2542,
                regionType: 3,
                regionName: '阳曲县',
                parentIdAll: '1,2541,2542,2551',
                children: []
              },
              {
                id: 2552,
                parentId: 2542,
                regionType: 3,
                regionName: '迎泽区',
                parentIdAll: '1,2541,2542,2552',
                children: []
              }
            ]
          },
          {
            id: 2553,
            parentId: 2541,
            regionType: 2,
            regionName: '阳泉市',
            parentIdAll: '1,2541,2553',
            children: [
              {
                id: 2554,
                parentId: 2553,
                regionType: 3,
                regionName: '平定县',
                parentIdAll: '1,2541,2553,2554',
                children: []
              },
              {
                id: 2555,
                parentId: 2553,
                regionType: 3,
                regionName: '盂县',
                parentIdAll: '1,2541,2553,2555',
                children: []
              },
              {
                id: 2556,
                parentId: 2553,
                regionType: 3,
                regionName: '郊区',
                parentIdAll: '1,2541,2553,2556',
                children: []
              },
              {
                id: 2557,
                parentId: 2553,
                regionType: 3,
                regionName: '城区',
                parentIdAll: '1,2541,2553,2557',
                children: []
              },
              {
                id: 2558,
                parentId: 2553,
                regionType: 3,
                regionName: '矿区',
                parentIdAll: '1,2541,2553,2558',
                children: []
              }
            ]
          },
          {
            id: 2559,
            parentId: 2541,
            regionType: 2,
            regionName: '临汾市',
            parentIdAll: '1,2541,2559',
            children: [
              {
                id: 2560,
                parentId: 2559,
                regionType: 3,
                regionName: '蒲县',
                parentIdAll: '1,2541,2559,2560',
                children: []
              },
              {
                id: 2561,
                parentId: 2559,
                regionType: 3,
                regionName: '洪洞县',
                parentIdAll: '1,2541,2559,2561',
                children: []
              },
              {
                id: 2562,
                parentId: 2559,
                regionType: 3,
                regionName: '汾西县',
                parentIdAll: '1,2541,2559,2562',
                children: []
              },
              {
                id: 2563,
                parentId: 2559,
                regionType: 3,
                regionName: '安泽县',
                parentIdAll: '1,2541,2559,2563',
                children: []
              },
              {
                id: 2564,
                parentId: 2559,
                regionType: 3,
                regionName: '隰县',
                parentIdAll: '1,2541,2559,2564',
                children: []
              },
              {
                id: 2565,
                parentId: 2559,
                regionType: 3,
                regionName: '古县',
                parentIdAll: '1,2541,2559,2565',
                children: []
              },
              {
                id: 2566,
                parentId: 2559,
                regionType: 3,
                regionName: '大宁县',
                parentIdAll: '1,2541,2559,2566',
                children: []
              },
              {
                id: 2567,
                parentId: 2559,
                regionType: 3,
                regionName: '尧都区',
                parentIdAll: '1,2541,2559,2567',
                children: []
              },
              {
                id: 2568,
                parentId: 2559,
                regionType: 3,
                regionName: '吉县',
                parentIdAll: '1,2541,2559,2568',
                children: []
              },
              {
                id: 2569,
                parentId: 2559,
                regionType: 3,
                regionName: '霍州市',
                parentIdAll: '1,2541,2559,2569',
                children: []
              },
              {
                id: 2570,
                parentId: 2559,
                regionType: 3,
                regionName: '永和县',
                parentIdAll: '1,2541,2559,2570',
                children: []
              },
              {
                id: 2571,
                parentId: 2559,
                regionType: 3,
                regionName: '浮山县',
                parentIdAll: '1,2541,2559,2571',
                children: []
              },
              {
                id: 2572,
                parentId: 2559,
                regionType: 3,
                regionName: '侯马市',
                parentIdAll: '1,2541,2559,2572',
                children: []
              },
              {
                id: 2573,
                parentId: 2559,
                regionType: 3,
                regionName: '襄汾县',
                parentIdAll: '1,2541,2559,2573',
                children: []
              },
              {
                id: 2574,
                parentId: 2559,
                regionType: 3,
                regionName: '翼城县',
                parentIdAll: '1,2541,2559,2574',
                children: []
              },
              {
                id: 2575,
                parentId: 2559,
                regionType: 3,
                regionName: '曲沃县',
                parentIdAll: '1,2541,2559,2575',
                children: []
              },
              {
                id: 2576,
                parentId: 2559,
                regionType: 3,
                regionName: '乡宁县',
                parentIdAll: '1,2541,2559,2576',
                children: []
              }
            ]
          },
          {
            id: 2577,
            parentId: 2541,
            regionType: 2,
            regionName: '大同市',
            parentIdAll: '1,2541,2577',
            children: [
              {
                id: 2578,
                parentId: 2577,
                regionType: 3,
                regionName: '新荣区',
                parentIdAll: '1,2541,2577,2578',
                children: []
              },
              {
                id: 2579,
                parentId: 2577,
                regionType: 3,
                regionName: '广灵县',
                parentIdAll: '1,2541,2577,2579',
                children: []
              },
              {
                id: 2580,
                parentId: 2577,
                regionType: 3,
                regionName: '灵丘县',
                parentIdAll: '1,2541,2577,2580',
                children: []
              },
              {
                id: 2581,
                parentId: 2577,
                regionType: 3,
                regionName: '阳高县',
                parentIdAll: '1,2541,2577,2581',
                children: []
              },
              {
                id: 2582,
                parentId: 2577,
                regionType: 3,
                regionName: '浑源县',
                parentIdAll: '1,2541,2577,2582',
                children: []
              },
              {
                id: 2583,
                parentId: 2577,
                regionType: 3,
                regionName: '天镇县',
                parentIdAll: '1,2541,2577,2583',
                children: []
              },
              {
                id: 2584,
                parentId: 2577,
                regionType: 3,
                regionName: '大同县',
                parentIdAll: '1,2541,2577,2584',
                children: []
              },
              {
                id: 2585,
                parentId: 2577,
                regionType: 3,
                regionName: '左云县',
                parentIdAll: '1,2541,2577,2585',
                children: []
              },
              {
                id: 2586,
                parentId: 2577,
                regionType: 3,
                regionName: '城区',
                parentIdAll: '1,2541,2577,2586',
                children: []
              },
              {
                id: 2587,
                parentId: 2577,
                regionType: 3,
                regionName: '南郊区',
                parentIdAll: '1,2541,2577,2587',
                children: []
              },
              {
                id: 2588,
                parentId: 2577,
                regionType: 3,
                regionName: '矿区',
                parentIdAll: '1,2541,2577,2588',
                children: []
              }
            ]
          },
          {
            id: 2589,
            parentId: 2541,
            regionType: 2,
            regionName: '晋城市',
            parentIdAll: '1,2541,2589',
            children: [
              {
                id: 2590,
                parentId: 2589,
                regionType: 3,
                regionName: '高平市',
                parentIdAll: '1,2541,2589,2590',
                children: []
              },
              {
                id: 2591,
                parentId: 2589,
                regionType: 3,
                regionName: '城区',
                parentIdAll: '1,2541,2589,2591',
                children: []
              },
              {
                id: 2592,
                parentId: 2589,
                regionType: 3,
                regionName: '泽州县',
                parentIdAll: '1,2541,2589,2592',
                children: []
              },
              {
                id: 2593,
                parentId: 2589,
                regionType: 3,
                regionName: '陵川县',
                parentIdAll: '1,2541,2589,2593',
                children: []
              },
              {
                id: 2594,
                parentId: 2589,
                regionType: 3,
                regionName: '阳城县',
                parentIdAll: '1,2541,2589,2594',
                children: []
              },
              {
                id: 2595,
                parentId: 2589,
                regionType: 3,
                regionName: '沁水县',
                parentIdAll: '1,2541,2589,2595',
                children: []
              }
            ]
          },
          {
            id: 2596,
            parentId: 2541,
            regionType: 2,
            regionName: '忻州市',
            parentIdAll: '1,2541,2596',
            children: [
              {
                id: 2597,
                parentId: 2596,
                regionType: 3,
                regionName: '五寨县',
                parentIdAll: '1,2541,2596,2597',
                children: []
              },
              {
                id: 2598,
                parentId: 2596,
                regionType: 3,
                regionName: '河曲县',
                parentIdAll: '1,2541,2596,2598',
                children: []
              },
              {
                id: 2599,
                parentId: 2596,
                regionType: 3,
                regionName: '五台县',
                parentIdAll: '1,2541,2596,2599',
                children: []
              },
              {
                id: 2600,
                parentId: 2596,
                regionType: 3,
                regionName: '岢岚县',
                parentIdAll: '1,2541,2596,2600',
                children: []
              },
              {
                id: 2601,
                parentId: 2596,
                regionType: 3,
                regionName: '代县',
                parentIdAll: '1,2541,2596,2601',
                children: []
              },
              {
                id: 2602,
                parentId: 2596,
                regionType: 3,
                regionName: '静乐县',
                parentIdAll: '1,2541,2596,2602',
                children: []
              },
              {
                id: 2603,
                parentId: 2596,
                regionType: 3,
                regionName: '宁武县',
                parentIdAll: '1,2541,2596,2603',
                children: []
              },
              {
                id: 2604,
                parentId: 2596,
                regionType: 3,
                regionName: '偏关县',
                parentIdAll: '1,2541,2596,2604',
                children: []
              },
              {
                id: 2605,
                parentId: 2596,
                regionType: 3,
                regionName: '定襄县',
                parentIdAll: '1,2541,2596,2605',
                children: []
              },
              {
                id: 2606,
                parentId: 2596,
                regionType: 3,
                regionName: '忻府区',
                parentIdAll: '1,2541,2596,2606',
                children: []
              },
              {
                id: 2607,
                parentId: 2596,
                regionType: 3,
                regionName: '原平市',
                parentIdAll: '1,2541,2596,2607',
                children: []
              },
              {
                id: 2608,
                parentId: 2596,
                regionType: 3,
                regionName: '保德县',
                parentIdAll: '1,2541,2596,2608',
                children: []
              },
              {
                id: 2609,
                parentId: 2596,
                regionType: 3,
                regionName: '神池县',
                parentIdAll: '1,2541,2596,2609',
                children: []
              },
              {
                id: 2610,
                parentId: 2596,
                regionType: 3,
                regionName: '繁峙县',
                parentIdAll: '1,2541,2596,2610',
                children: []
              }
            ]
          },
          {
            id: 2611,
            parentId: 2541,
            regionType: 2,
            regionName: '朔州市',
            parentIdAll: '1,2541,2611',
            children: [
              {
                id: 2612,
                parentId: 2611,
                regionType: 3,
                regionName: '平鲁区',
                parentIdAll: '1,2541,2611,2612',
                children: []
              },
              {
                id: 2613,
                parentId: 2611,
                regionType: 3,
                regionName: '右玉县',
                parentIdAll: '1,2541,2611,2613',
                children: []
              },
              {
                id: 2614,
                parentId: 2611,
                regionType: 3,
                regionName: '朔城区',
                parentIdAll: '1,2541,2611,2614',
                children: []
              },
              {
                id: 2615,
                parentId: 2611,
                regionType: 3,
                regionName: '山阴县',
                parentIdAll: '1,2541,2611,2615',
                children: []
              },
              {
                id: 2616,
                parentId: 2611,
                regionType: 3,
                regionName: '怀仁县',
                parentIdAll: '1,2541,2611,2616',
                children: []
              },
              {
                id: 2617,
                parentId: 2611,
                regionType: 3,
                regionName: '应县',
                parentIdAll: '1,2541,2611,2617',
                children: []
              }
            ]
          },
          {
            id: 2618,
            parentId: 2541,
            regionType: 2,
            regionName: '运城市',
            parentIdAll: '1,2541,2618',
            children: [
              {
                id: 2619,
                parentId: 2618,
                regionType: 3,
                regionName: '盐湖区',
                parentIdAll: '1,2541,2618,2619',
                children: []
              },
              {
                id: 2620,
                parentId: 2618,
                regionType: 3,
                regionName: '临猗县',
                parentIdAll: '1,2541,2618,2620',
                children: []
              },
              {
                id: 2621,
                parentId: 2618,
                regionType: 3,
                regionName: '闻喜县',
                parentIdAll: '1,2541,2618,2621',
                children: []
              },
              {
                id: 2622,
                parentId: 2618,
                regionType: 3,
                regionName: '平陆县',
                parentIdAll: '1,2541,2618,2622',
                children: []
              },
              {
                id: 2623,
                parentId: 2618,
                regionType: 3,
                regionName: '万荣县',
                parentIdAll: '1,2541,2618,2623',
                children: []
              },
              {
                id: 2624,
                parentId: 2618,
                regionType: 3,
                regionName: '夏县',
                parentIdAll: '1,2541,2618,2624',
                children: []
              },
              {
                id: 2625,
                parentId: 2618,
                regionType: 3,
                regionName: '永济市',
                parentIdAll: '1,2541,2618,2625',
                children: []
              },
              {
                id: 2626,
                parentId: 2618,
                regionType: 3,
                regionName: '垣曲县',
                parentIdAll: '1,2541,2618,2626',
                children: []
              },
              {
                id: 2627,
                parentId: 2618,
                regionType: 3,
                regionName: '芮城县',
                parentIdAll: '1,2541,2618,2627',
                children: []
              },
              {
                id: 2628,
                parentId: 2618,
                regionType: 3,
                regionName: '新绛县',
                parentIdAll: '1,2541,2618,2628',
                children: []
              },
              {
                id: 2629,
                parentId: 2618,
                regionType: 3,
                regionName: '绛县',
                parentIdAll: '1,2541,2618,2629',
                children: []
              },
              {
                id: 2630,
                parentId: 2618,
                regionType: 3,
                regionName: '稷山县',
                parentIdAll: '1,2541,2618,2630',
                children: []
              },
              {
                id: 2631,
                parentId: 2618,
                regionType: 3,
                regionName: '河津市',
                parentIdAll: '1,2541,2618,2631',
                children: []
              }
            ]
          },
          {
            id: 2632,
            parentId: 2541,
            regionType: 2,
            regionName: '长治市',
            parentIdAll: '1,2541,2632',
            children: [
              {
                id: 2633,
                parentId: 2632,
                regionType: 3,
                regionName: '潞城市',
                parentIdAll: '1,2541,2632,2633',
                children: []
              },
              {
                id: 2634,
                parentId: 2632,
                regionType: 3,
                regionName: '黎城县',
                parentIdAll: '1,2541,2632,2634',
                children: []
              },
              {
                id: 2635,
                parentId: 2632,
                regionType: 3,
                regionName: '郊区',
                parentIdAll: '1,2541,2632,2635',
                children: []
              },
              {
                id: 2636,
                parentId: 2632,
                regionType: 3,
                regionName: '城区',
                parentIdAll: '1,2541,2632,2636',
                children: []
              },
              {
                id: 2637,
                parentId: 2632,
                regionType: 3,
                regionName: '长治县',
                parentIdAll: '1,2541,2632,2637',
                children: []
              },
              {
                id: 2638,
                parentId: 2632,
                regionType: 3,
                regionName: '壶关县',
                parentIdAll: '1,2541,2632,2638',
                children: []
              },
              {
                id: 2639,
                parentId: 2632,
                regionType: 3,
                regionName: '长子县',
                parentIdAll: '1,2541,2632,2639',
                children: []
              },
              {
                id: 2640,
                parentId: 2632,
                regionType: 3,
                regionName: '沁县',
                parentIdAll: '1,2541,2632,2640',
                children: []
              },
              {
                id: 2641,
                parentId: 2632,
                regionType: 3,
                regionName: '屯留县',
                parentIdAll: '1,2541,2632,2641',
                children: []
              },
              {
                id: 2642,
                parentId: 2632,
                regionType: 3,
                regionName: '沁源县',
                parentIdAll: '1,2541,2632,2642',
                children: []
              },
              {
                id: 2643,
                parentId: 2632,
                regionType: 3,
                regionName: '武乡县',
                parentIdAll: '1,2541,2632,2643',
                children: []
              },
              {
                id: 2644,
                parentId: 2632,
                regionType: 3,
                regionName: '平顺县',
                parentIdAll: '1,2541,2632,2644',
                children: []
              },
              {
                id: 2645,
                parentId: 2632,
                regionType: 3,
                regionName: '襄垣县',
                parentIdAll: '1,2541,2632,2645',
                children: []
              }
            ]
          },
          {
            id: 2646,
            parentId: 2541,
            regionType: 2,
            regionName: '晋中市',
            parentIdAll: '1,2541,2646',
            children: [
              {
                id: 2647,
                parentId: 2646,
                regionType: 3,
                regionName: '平遥县',
                parentIdAll: '1,2541,2646,2647',
                children: []
              },
              {
                id: 2648,
                parentId: 2646,
                regionType: 3,
                regionName: '昔阳县',
                parentIdAll: '1,2541,2646,2648',
                children: []
              },
              {
                id: 2649,
                parentId: 2646,
                regionType: 3,
                regionName: '和顺县',
                parentIdAll: '1,2541,2646,2649',
                children: []
              },
              {
                id: 2650,
                parentId: 2646,
                regionType: 3,
                regionName: '太谷县',
                parentIdAll: '1,2541,2646,2650',
                children: []
              },
              {
                id: 2651,
                parentId: 2646,
                regionType: 3,
                regionName: '介休市',
                parentIdAll: '1,2541,2646,2651',
                children: []
              },
              {
                id: 2652,
                parentId: 2646,
                regionType: 3,
                regionName: '左权县',
                parentIdAll: '1,2541,2646,2652',
                children: []
              },
              {
                id: 2653,
                parentId: 2646,
                regionType: 3,
                regionName: '灵石县',
                parentIdAll: '1,2541,2646,2653',
                children: []
              },
              {
                id: 2654,
                parentId: 2646,
                regionType: 3,
                regionName: '寿阳县',
                parentIdAll: '1,2541,2646,2654',
                children: []
              },
              {
                id: 2655,
                parentId: 2646,
                regionType: 3,
                regionName: '榆社县',
                parentIdAll: '1,2541,2646,2655',
                children: []
              },
              {
                id: 2656,
                parentId: 2646,
                regionType: 3,
                regionName: '榆次区',
                parentIdAll: '1,2541,2646,2656',
                children: []
              },
              {
                id: 2657,
                parentId: 2646,
                regionType: 3,
                regionName: '祁县',
                parentIdAll: '1,2541,2646,2657',
                children: []
              }
            ]
          },
          {
            id: 2658,
            parentId: 2541,
            regionType: 2,
            regionName: '吕梁市',
            parentIdAll: '1,2541,2658',
            children: [
              {
                id: 2659,
                parentId: 2658,
                regionType: 3,
                regionName: '中阳县',
                parentIdAll: '1,2541,2658,2659',
                children: []
              },
              {
                id: 2660,
                parentId: 2658,
                regionType: 3,
                regionName: '临县',
                parentIdAll: '1,2541,2658,2660',
                children: []
              },
              {
                id: 2661,
                parentId: 2658,
                regionType: 3,
                regionName: '岚县',
                parentIdAll: '1,2541,2658,2661',
                children: []
              },
              {
                id: 2662,
                parentId: 2658,
                regionType: 3,
                regionName: '交口县',
                parentIdAll: '1,2541,2658,2662',
                children: []
              },
              {
                id: 2663,
                parentId: 2658,
                regionType: 3,
                regionName: '离石区',
                parentIdAll: '1,2541,2658,2663',
                children: []
              },
              {
                id: 2664,
                parentId: 2658,
                regionType: 3,
                regionName: '柳林县',
                parentIdAll: '1,2541,2658,2664',
                children: []
              },
              {
                id: 2665,
                parentId: 2658,
                regionType: 3,
                regionName: '汾阳市',
                parentIdAll: '1,2541,2658,2665',
                children: []
              },
              {
                id: 2666,
                parentId: 2658,
                regionType: 3,
                regionName: '方山县',
                parentIdAll: '1,2541,2658,2666',
                children: []
              },
              {
                id: 2667,
                parentId: 2658,
                regionType: 3,
                regionName: '兴县',
                parentIdAll: '1,2541,2658,2667',
                children: []
              },
              {
                id: 2668,
                parentId: 2658,
                regionType: 3,
                regionName: '石楼县',
                parentIdAll: '1,2541,2658,2668',
                children: []
              },
              {
                id: 2669,
                parentId: 2658,
                regionType: 3,
                regionName: '交城县',
                parentIdAll: '1,2541,2658,2669',
                children: []
              },
              {
                id: 2670,
                parentId: 2658,
                regionType: 3,
                regionName: '孝义市',
                parentIdAll: '1,2541,2658,2670',
                children: []
              },
              {
                id: 2671,
                parentId: 2658,
                regionType: 3,
                regionName: '文水县',
                parentIdAll: '1,2541,2658,2671',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2672,
        parentId: 1,
        regionType: 1,
        regionName: '香港特别行政区',
        parentIdAll: '1,2672',
        children: [
          {
            id: 2673,
            parentId: 2672,
            regionType: 2,
            regionName: '屯门区',
            parentIdAll: '1,2672,2673',
            children: [
              {
                id: 3292,
                parentId: 2673,
                regionType: 3,
                regionName: '屯门区',
                parentIdAll: '1,2672,2673,3292',
                children: []
              }
            ]
          },
          {
            id: 2674,
            parentId: 2672,
            regionType: 2,
            regionName: '湾仔区',
            parentIdAll: '1,2672,2674',
            children: [
              {
                id: 3293,
                parentId: 2674,
                regionType: 3,
                regionName: '湾仔区',
                parentIdAll: '1,2672,2674,3293',
                children: []
              }
            ]
          },
          {
            id: 2675,
            parentId: 2672,
            regionType: 2,
            regionName: '元朗区',
            parentIdAll: '1,2672,2675',
            children: [
              {
                id: 3294,
                parentId: 2675,
                regionType: 3,
                regionName: '元朗区',
                parentIdAll: '1,2672,2675,3294',
                children: []
              }
            ]
          },
          {
            id: 2676,
            parentId: 2672,
            regionType: 2,
            regionName: '中西区',
            parentIdAll: '1,2672,2676',
            children: [
              {
                id: 3295,
                parentId: 2676,
                regionType: 3,
                regionName: '中西区',
                parentIdAll: '1,2672,2676,3295',
                children: []
              }
            ]
          },
          {
            id: 2677,
            parentId: 2672,
            regionType: 2,
            regionName: '九龙城区',
            parentIdAll: '1,2672,2677',
            children: [
              {
                id: 3296,
                parentId: 2677,
                regionType: 3,
                regionName: '九龙城区',
                parentIdAll: '1,2672,2677,3296',
                children: []
              }
            ]
          },
          {
            id: 2678,
            parentId: 2672,
            regionType: 2,
            regionName: '黄大仙区',
            parentIdAll: '1,2672,2678',
            children: [
              {
                id: 3297,
                parentId: 2678,
                regionType: 3,
                regionName: '黄大仙区',
                parentIdAll: '1,2672,2678,3297',
                children: []
              }
            ]
          },
          {
            id: 2679,
            parentId: 2672,
            regionType: 2,
            regionName: '东区',
            parentIdAll: '1,2672,2679',
            children: [
              {
                id: 3298,
                parentId: 2679,
                regionType: 3,
                regionName: '东区',
                parentIdAll: '1,2672,2679,3298',
                children: []
              }
            ]
          },
          {
            id: 2680,
            parentId: 2672,
            regionType: 2,
            regionName: '油尖旺区',
            parentIdAll: '1,2672,2680',
            children: [
              {
                id: 3299,
                parentId: 2680,
                regionType: 3,
                regionName: '油尖旺区',
                parentIdAll: '1,2672,2680,3299',
                children: []
              }
            ]
          },
          {
            id: 2681,
            parentId: 2672,
            regionType: 2,
            regionName: '深水埗区',
            parentIdAll: '1,2672,2681',
            children: [
              {
                id: 3300,
                parentId: 2681,
                regionType: 3,
                regionName: '深水埗区',
                parentIdAll: '1,2672,2681,3300',
                children: []
              }
            ]
          },
          {
            id: 2682,
            parentId: 2672,
            regionType: 2,
            regionName: '西贡区',
            parentIdAll: '1,2672,2682',
            children: [
              {
                id: 3301,
                parentId: 2682,
                regionType: 3,
                regionName: '西贡区',
                parentIdAll: '1,2672,2682,3301',
                children: []
              }
            ]
          },
          {
            id: 2683,
            parentId: 2672,
            regionType: 2,
            regionName: '北区',
            parentIdAll: '1,2672,2683',
            children: [
              {
                id: 3302,
                parentId: 2683,
                regionType: 3,
                regionName: '北区',
                parentIdAll: '1,2672,2683,3302',
                children: []
              }
            ]
          },
          {
            id: 2684,
            parentId: 2672,
            regionType: 2,
            regionName: '观塘区',
            parentIdAll: '1,2672,2684',
            children: [
              {
                id: 3303,
                parentId: 2684,
                regionType: 3,
                regionName: '观塘区',
                parentIdAll: '1,2672,2684,3303',
                children: []
              }
            ]
          },
          {
            id: 2685,
            parentId: 2672,
            regionType: 2,
            regionName: '大埔区',
            parentIdAll: '1,2672,2685',
            children: [
              {
                id: 3304,
                parentId: 2685,
                regionType: 3,
                regionName: '大埔区',
                parentIdAll: '1,2672,2685,3304',
                children: []
              }
            ]
          },
          {
            id: 2686,
            parentId: 2672,
            regionType: 2,
            regionName: '沙田区',
            parentIdAll: '1,2672,2686',
            children: [
              {
                id: 3305,
                parentId: 2686,
                regionType: 3,
                regionName: '沙田区',
                parentIdAll: '1,2672,2686,3305',
                children: []
              }
            ]
          },
          {
            id: 2687,
            parentId: 2672,
            regionType: 2,
            regionName: '南区',
            parentIdAll: '1,2672,2687',
            children: [
              {
                id: 3306,
                parentId: 2687,
                regionType: 3,
                regionName: '南区',
                parentIdAll: '1,2672,2687,3306',
                children: []
              }
            ]
          },
          {
            id: 2688,
            parentId: 2672,
            regionType: 2,
            regionName: '离岛区',
            parentIdAll: '1,2672,2688',
            children: [
              {
                id: 3307,
                parentId: 2688,
                regionType: 3,
                regionName: '离岛区',
                parentIdAll: '1,2672,2688,3307',
                children: []
              }
            ]
          },
          {
            id: 2689,
            parentId: 2672,
            regionType: 2,
            regionName: '荃湾区',
            parentIdAll: '1,2672,2689',
            children: [
              {
                id: 3308,
                parentId: 2689,
                regionType: 3,
                regionName: '荃湾区',
                parentIdAll: '1,2672,2689,3308',
                children: []
              }
            ]
          },
          {
            id: 2690,
            parentId: 2672,
            regionType: 2,
            regionName: '葵青区',
            parentIdAll: '1,2672,2690',
            children: [
              {
                id: 3309,
                parentId: 2690,
                regionType: 3,
                regionName: '葵青区',
                parentIdAll: '1,2672,2690,3309',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2691,
        parentId: 1,
        regionType: 1,
        regionName: '台湾省',
        parentIdAll: '1,2691',
        children: []
      },
      {
        id: 2692,
        parentId: 1,
        regionType: 1,
        regionName: '澳门特别行政区',
        parentIdAll: '1,2692',
        children: [
          {
            id: 2693,
            parentId: 2692,
            regionType: 2,
            regionName: '风顺堂区',
            parentIdAll: '1,2692,2693',
            children: [
              {
                id: 3310,
                parentId: 2693,
                regionType: 3,
                regionName: '风顺堂区',
                parentIdAll: '1,2692,2693,3310',
                children: []
              }
            ]
          },
          {
            id: 2694,
            parentId: 2692,
            regionType: 2,
            regionName: '路凼填海区',
            parentIdAll: '1,2692,2694',
            children: [
              {
                id: 3311,
                parentId: 2694,
                regionType: 3,
                regionName: '路凼填海区',
                parentIdAll: '1,2692,2694,3311',
                children: []
              }
            ]
          },
          {
            id: 2695,
            parentId: 2692,
            regionType: 2,
            regionName: '圣方济各堂区',
            parentIdAll: '1,2692,2695',
            children: [
              {
                id: 3312,
                parentId: 2695,
                regionType: 3,
                regionName: '圣方济各堂区',
                parentIdAll: '1,2692,2695,3312',
                children: []
              }
            ]
          },
          {
            id: 2696,
            parentId: 2692,
            regionType: 2,
            regionName: '花王堂区',
            parentIdAll: '1,2692,2696',
            children: [
              {
                id: 3313,
                parentId: 2696,
                regionType: 3,
                regionName: '花王堂区',
                parentIdAll: '1,2692,2696,3313',
                children: []
              }
            ]
          },
          {
            id: 2697,
            parentId: 2692,
            regionType: 2,
            regionName: '大堂区',
            parentIdAll: '1,2692,2697',
            children: [
              {
                id: 3314,
                parentId: 2697,
                regionType: 3,
                regionName: '大堂区',
                parentIdAll: '1,2692,2697,3314',
                children: []
              }
            ]
          },
          {
            id: 2698,
            parentId: 2692,
            regionType: 2,
            regionName: '嘉模堂区',
            parentIdAll: '1,2692,2698',
            children: [
              {
                id: 3315,
                parentId: 2698,
                regionType: 3,
                regionName: '嘉模堂区',
                parentIdAll: '1,2692,2698,3315',
                children: []
              }
            ]
          },
          {
            id: 2699,
            parentId: 2692,
            regionType: 2,
            regionName: '望德堂区',
            parentIdAll: '1,2692,2699',
            children: [
              {
                id: 3316,
                parentId: 2699,
                regionType: 3,
                regionName: '望德堂区',
                parentIdAll: '1,2692,2699,3316',
                children: []
              }
            ]
          },
          {
            id: 2700,
            parentId: 2692,
            regionType: 2,
            regionName: '花地玛堂区',
            parentIdAll: '1,2692,2700',
            children: [
              {
                id: 3317,
                parentId: 2700,
                regionType: 3,
                regionName: '花地玛堂区',
                parentIdAll: '1,2692,2700,3317',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2701,
        parentId: 1,
        regionType: 1,
        regionName: '甘肃省',
        parentIdAll: '1,2701',
        children: [
          {
            id: 2702,
            parentId: 2701,
            regionType: 2,
            regionName: '兰州市',
            parentIdAll: '1,2701,2702',
            children: [
              {
                id: 2703,
                parentId: 2702,
                regionType: 3,
                regionName: '永登县',
                parentIdAll: '1,2701,2702,2703',
                children: []
              },
              {
                id: 2704,
                parentId: 2702,
                regionType: 3,
                regionName: '皋兰县',
                parentIdAll: '1,2701,2702,2704',
                children: []
              },
              {
                id: 2705,
                parentId: 2702,
                regionType: 3,
                regionName: '西固区',
                parentIdAll: '1,2701,2702,2705',
                children: []
              },
              {
                id: 2706,
                parentId: 2702,
                regionType: 3,
                regionName: '红古区',
                parentIdAll: '1,2701,2702,2706',
                children: []
              },
              {
                id: 2707,
                parentId: 2702,
                regionType: 3,
                regionName: '榆中县',
                parentIdAll: '1,2701,2702,2707',
                children: []
              },
              {
                id: 2708,
                parentId: 2702,
                regionType: 3,
                regionName: '七里河区',
                parentIdAll: '1,2701,2702,2708',
                children: []
              },
              {
                id: 2709,
                parentId: 2702,
                regionType: 3,
                regionName: '安宁区',
                parentIdAll: '1,2701,2702,2709',
                children: []
              },
              {
                id: 2710,
                parentId: 2702,
                regionType: 3,
                regionName: '城关区',
                parentIdAll: '1,2701,2702,2710',
                children: []
              }
            ]
          },
          {
            id: 2711,
            parentId: 2701,
            regionType: 2,
            regionName: '金昌市',
            parentIdAll: '1,2701,2711',
            children: [
              {
                id: 2712,
                parentId: 2711,
                regionType: 3,
                regionName: '金川区',
                parentIdAll: '1,2701,2711,2712',
                children: []
              },
              {
                id: 2713,
                parentId: 2711,
                regionType: 3,
                regionName: '永昌县',
                parentIdAll: '1,2701,2711,2713',
                children: []
              }
            ]
          },
          {
            id: 2714,
            parentId: 2701,
            regionType: 2,
            regionName: '嘉峪关市',
            parentIdAll: '1,2701,2714',
            children: [
              {
                id: 3318,
                parentId: 2714,
                regionType: 3,
                regionName: '嘉峪关市',
                parentIdAll: '1,2701,2714,3318',
                children: []
              }
            ]
          },
          {
            id: 2715,
            parentId: 2701,
            regionType: 2,
            regionName: '酒泉市',
            parentIdAll: '1,2701,2715',
            children: [
              {
                id: 2716,
                parentId: 2715,
                regionType: 3,
                regionName: '玉门市',
                parentIdAll: '1,2701,2715,2716',
                children: []
              },
              {
                id: 2717,
                parentId: 2715,
                regionType: 3,
                regionName: '肃北蒙古族自治县',
                parentIdAll: '1,2701,2715,2717',
                children: []
              },
              {
                id: 2718,
                parentId: 2715,
                regionType: 3,
                regionName: '金塔县',
                parentIdAll: '1,2701,2715,2718',
                children: []
              },
              {
                id: 2719,
                parentId: 2715,
                regionType: 3,
                regionName: '肃州区',
                parentIdAll: '1,2701,2715,2719',
                children: []
              },
              {
                id: 2720,
                parentId: 2715,
                regionType: 3,
                regionName: '敦煌市',
                parentIdAll: '1,2701,2715,2720',
                children: []
              },
              {
                id: 2721,
                parentId: 2715,
                regionType: 3,
                regionName: '瓜州县',
                parentIdAll: '1,2701,2715,2721',
                children: []
              },
              {
                id: 2722,
                parentId: 2715,
                regionType: 3,
                regionName: '阿克塞哈萨克族自治县',
                parentIdAll: '1,2701,2715,2722',
                children: []
              }
            ]
          },
          {
            id: 2723,
            parentId: 2701,
            regionType: 2,
            regionName: '平凉市',
            parentIdAll: '1,2701,2723',
            children: [
              {
                id: 2724,
                parentId: 2723,
                regionType: 3,
                regionName: '崆峒区',
                parentIdAll: '1,2701,2723,2724',
                children: []
              },
              {
                id: 2725,
                parentId: 2723,
                regionType: 3,
                regionName: '庄浪县',
                parentIdAll: '1,2701,2723,2725',
                children: []
              },
              {
                id: 2726,
                parentId: 2723,
                regionType: 3,
                regionName: '崇信县',
                parentIdAll: '1,2701,2723,2726',
                children: []
              },
              {
                id: 2727,
                parentId: 2723,
                regionType: 3,
                regionName: '灵台县',
                parentIdAll: '1,2701,2723,2727',
                children: []
              },
              {
                id: 2728,
                parentId: 2723,
                regionType: 3,
                regionName: '静宁县',
                parentIdAll: '1,2701,2723,2728',
                children: []
              },
              {
                id: 2729,
                parentId: 2723,
                regionType: 3,
                regionName: '华亭县',
                parentIdAll: '1,2701,2723,2729',
                children: []
              },
              {
                id: 2730,
                parentId: 2723,
                regionType: 3,
                regionName: '泾川县',
                parentIdAll: '1,2701,2723,2730',
                children: []
              }
            ]
          },
          {
            id: 2731,
            parentId: 2701,
            regionType: 2,
            regionName: '张掖市',
            parentIdAll: '1,2701,2731',
            children: [
              {
                id: 2732,
                parentId: 2731,
                regionType: 3,
                regionName: '临泽县',
                parentIdAll: '1,2701,2731,2732',
                children: []
              },
              {
                id: 2733,
                parentId: 2731,
                regionType: 3,
                regionName: '甘州区',
                parentIdAll: '1,2701,2731,2733',
                children: []
              },
              {
                id: 2734,
                parentId: 2731,
                regionType: 3,
                regionName: '高台县',
                parentIdAll: '1,2701,2731,2734',
                children: []
              },
              {
                id: 2735,
                parentId: 2731,
                regionType: 3,
                regionName: '肃南裕固族自治县',
                parentIdAll: '1,2701,2731,2735',
                children: []
              },
              {
                id: 2736,
                parentId: 2731,
                regionType: 3,
                regionName: '山丹县',
                parentIdAll: '1,2701,2731,2736',
                children: []
              },
              {
                id: 2737,
                parentId: 2731,
                regionType: 3,
                regionName: '民乐县',
                parentIdAll: '1,2701,2731,2737',
                children: []
              }
            ]
          },
          {
            id: 2738,
            parentId: 2701,
            regionType: 2,
            regionName: '白银市',
            parentIdAll: '1,2701,2738',
            children: [
              {
                id: 2739,
                parentId: 2738,
                regionType: 3,
                regionName: '平川区',
                parentIdAll: '1,2701,2738,2739',
                children: []
              },
              {
                id: 2740,
                parentId: 2738,
                regionType: 3,
                regionName: '靖远县',
                parentIdAll: '1,2701,2738,2740',
                children: []
              },
              {
                id: 2741,
                parentId: 2738,
                regionType: 3,
                regionName: '白银区',
                parentIdAll: '1,2701,2738,2741',
                children: []
              },
              {
                id: 2742,
                parentId: 2738,
                regionType: 3,
                regionName: '景泰县',
                parentIdAll: '1,2701,2738,2742',
                children: []
              },
              {
                id: 2743,
                parentId: 2738,
                regionType: 3,
                regionName: '会宁县',
                parentIdAll: '1,2701,2738,2743',
                children: []
              }
            ]
          },
          {
            id: 2744,
            parentId: 2701,
            regionType: 2,
            regionName: '甘南藏族自治州',
            parentIdAll: '1,2701,2744',
            children: [
              {
                id: 2745,
                parentId: 2744,
                regionType: 3,
                regionName: '合作市',
                parentIdAll: '1,2701,2744,2745',
                children: []
              },
              {
                id: 2746,
                parentId: 2744,
                regionType: 3,
                regionName: '夏河县',
                parentIdAll: '1,2701,2744,2746',
                children: []
              },
              {
                id: 2747,
                parentId: 2744,
                regionType: 3,
                regionName: '舟曲县',
                parentIdAll: '1,2701,2744,2747',
                children: []
              },
              {
                id: 2748,
                parentId: 2744,
                regionType: 3,
                regionName: '迭部县',
                parentIdAll: '1,2701,2744,2748',
                children: []
              },
              {
                id: 2749,
                parentId: 2744,
                regionType: 3,
                regionName: '玛曲县',
                parentIdAll: '1,2701,2744,2749',
                children: []
              },
              {
                id: 2750,
                parentId: 2744,
                regionType: 3,
                regionName: '碌曲县',
                parentIdAll: '1,2701,2744,2750',
                children: []
              },
              {
                id: 2751,
                parentId: 2744,
                regionType: 3,
                regionName: '临潭县',
                parentIdAll: '1,2701,2744,2751',
                children: []
              },
              {
                id: 2752,
                parentId: 2744,
                regionType: 3,
                regionName: '卓尼县',
                parentIdAll: '1,2701,2744,2752',
                children: []
              }
            ]
          },
          {
            id: 2753,
            parentId: 2701,
            regionType: 2,
            regionName: '陇南市',
            parentIdAll: '1,2701,2753',
            children: [
              {
                id: 2754,
                parentId: 2753,
                regionType: 3,
                regionName: '两当县',
                parentIdAll: '1,2701,2753,2754',
                children: []
              },
              {
                id: 2755,
                parentId: 2753,
                regionType: 3,
                regionName: '成县',
                parentIdAll: '1,2701,2753,2755',
                children: []
              },
              {
                id: 2756,
                parentId: 2753,
                regionType: 3,
                regionName: '礼县',
                parentIdAll: '1,2701,2753,2756',
                children: []
              },
              {
                id: 2757,
                parentId: 2753,
                regionType: 3,
                regionName: '徽县',
                parentIdAll: '1,2701,2753,2757',
                children: []
              },
              {
                id: 2758,
                parentId: 2753,
                regionType: 3,
                regionName: '宕昌县',
                parentIdAll: '1,2701,2753,2758',
                children: []
              },
              {
                id: 2759,
                parentId: 2753,
                regionType: 3,
                regionName: '文县',
                parentIdAll: '1,2701,2753,2759',
                children: []
              },
              {
                id: 2760,
                parentId: 2753,
                regionType: 3,
                regionName: '康县',
                parentIdAll: '1,2701,2753,2760',
                children: []
              },
              {
                id: 2761,
                parentId: 2753,
                regionType: 3,
                regionName: '武都区',
                parentIdAll: '1,2701,2753,2761',
                children: []
              },
              {
                id: 2762,
                parentId: 2753,
                regionType: 3,
                regionName: '西和县',
                parentIdAll: '1,2701,2753,2762',
                children: []
              }
            ]
          },
          {
            id: 2763,
            parentId: 2701,
            regionType: 2,
            regionName: '临夏回族自治州',
            parentIdAll: '1,2701,2763',
            children: [
              {
                id: 2764,
                parentId: 2763,
                regionType: 3,
                regionName: '永靖县',
                parentIdAll: '1,2701,2763,2764',
                children: []
              },
              {
                id: 2765,
                parentId: 2763,
                regionType: 3,
                regionName: '临夏县',
                parentIdAll: '1,2701,2763,2765',
                children: []
              },
              {
                id: 2766,
                parentId: 2763,
                regionType: 3,
                regionName: '和政县',
                parentIdAll: '1,2701,2763,2766',
                children: []
              },
              {
                id: 2767,
                parentId: 2763,
                regionType: 3,
                regionName: '东乡族自治县',
                parentIdAll: '1,2701,2763,2767',
                children: []
              },
              {
                id: 2768,
                parentId: 2763,
                regionType: 3,
                regionName: '广河县',
                parentIdAll: '1,2701,2763,2768',
                children: []
              },
              {
                id: 2769,
                parentId: 2763,
                regionType: 3,
                regionName: '康乐县',
                parentIdAll: '1,2701,2763,2769',
                children: []
              },
              {
                id: 2770,
                parentId: 2763,
                regionType: 3,
                regionName: '积石山保安族东乡族撒拉族自治县',
                parentIdAll: '1,2701,2763,2770',
                children: []
              },
              {
                id: 2771,
                parentId: 2763,
                regionType: 3,
                regionName: '临夏市',
                parentIdAll: '1,2701,2763,2771',
                children: []
              }
            ]
          },
          {
            id: 2772,
            parentId: 2701,
            regionType: 2,
            regionName: '天水市',
            parentIdAll: '1,2701,2772',
            children: [
              {
                id: 2773,
                parentId: 2772,
                regionType: 3,
                regionName: '武山县',
                parentIdAll: '1,2701,2772,2773',
                children: []
              },
              {
                id: 2774,
                parentId: 2772,
                regionType: 3,
                regionName: '秦安县',
                parentIdAll: '1,2701,2772,2774',
                children: []
              },
              {
                id: 2775,
                parentId: 2772,
                regionType: 3,
                regionName: '清水县',
                parentIdAll: '1,2701,2772,2775',
                children: []
              },
              {
                id: 2776,
                parentId: 2772,
                regionType: 3,
                regionName: '秦州区',
                parentIdAll: '1,2701,2772,2776',
                children: []
              },
              {
                id: 2777,
                parentId: 2772,
                regionType: 3,
                regionName: '张家川回族自治县',
                parentIdAll: '1,2701,2772,2777',
                children: []
              },
              {
                id: 2778,
                parentId: 2772,
                regionType: 3,
                regionName: '甘谷县',
                parentIdAll: '1,2701,2772,2778',
                children: []
              },
              {
                id: 2779,
                parentId: 2772,
                regionType: 3,
                regionName: '麦积区',
                parentIdAll: '1,2701,2772,2779',
                children: []
              }
            ]
          },
          {
            id: 2780,
            parentId: 2701,
            regionType: 2,
            regionName: '武威市',
            parentIdAll: '1,2701,2780',
            children: [
              {
                id: 2781,
                parentId: 2780,
                regionType: 3,
                regionName: '古浪县',
                parentIdAll: '1,2701,2780,2781',
                children: []
              },
              {
                id: 2782,
                parentId: 2780,
                regionType: 3,
                regionName: '天祝藏族自治县',
                parentIdAll: '1,2701,2780,2782',
                children: []
              },
              {
                id: 2783,
                parentId: 2780,
                regionType: 3,
                regionName: '凉州区',
                parentIdAll: '1,2701,2780,2783',
                children: []
              },
              {
                id: 2784,
                parentId: 2780,
                regionType: 3,
                regionName: '民勤县',
                parentIdAll: '1,2701,2780,2784',
                children: []
              }
            ]
          },
          {
            id: 2785,
            parentId: 2701,
            regionType: 2,
            regionName: '定西市',
            parentIdAll: '1,2701,2785',
            children: [
              {
                id: 2786,
                parentId: 2785,
                regionType: 3,
                regionName: '临洮县',
                parentIdAll: '1,2701,2785,2786',
                children: []
              },
              {
                id: 2787,
                parentId: 2785,
                regionType: 3,
                regionName: '陇西县',
                parentIdAll: '1,2701,2785,2787',
                children: []
              },
              {
                id: 2788,
                parentId: 2785,
                regionType: 3,
                regionName: '安定区',
                parentIdAll: '1,2701,2785,2788',
                children: []
              },
              {
                id: 2789,
                parentId: 2785,
                regionType: 3,
                regionName: '漳县',
                parentIdAll: '1,2701,2785,2789',
                children: []
              },
              {
                id: 2790,
                parentId: 2785,
                regionType: 3,
                regionName: '岷县',
                parentIdAll: '1,2701,2785,2790',
                children: []
              },
              {
                id: 2791,
                parentId: 2785,
                regionType: 3,
                regionName: '通渭县',
                parentIdAll: '1,2701,2785,2791',
                children: []
              },
              {
                id: 2792,
                parentId: 2785,
                regionType: 3,
                regionName: '渭源县',
                parentIdAll: '1,2701,2785,2792',
                children: []
              }
            ]
          },
          {
            id: 2793,
            parentId: 2701,
            regionType: 2,
            regionName: '庆阳市',
            parentIdAll: '1,2701,2793',
            children: [
              {
                id: 2794,
                parentId: 2793,
                regionType: 3,
                regionName: '合水县',
                parentIdAll: '1,2701,2793,2794',
                children: []
              },
              {
                id: 2795,
                parentId: 2793,
                regionType: 3,
                regionName: '庆城县',
                parentIdAll: '1,2701,2793,2795',
                children: []
              },
              {
                id: 2796,
                parentId: 2793,
                regionType: 3,
                regionName: '正宁县',
                parentIdAll: '1,2701,2793,2796',
                children: []
              },
              {
                id: 2797,
                parentId: 2793,
                regionType: 3,
                regionName: '西峰区',
                parentIdAll: '1,2701,2793,2797',
                children: []
              },
              {
                id: 2798,
                parentId: 2793,
                regionType: 3,
                regionName: '宁县',
                parentIdAll: '1,2701,2793,2798',
                children: []
              },
              {
                id: 2799,
                parentId: 2793,
                regionType: 3,
                regionName: '华池县',
                parentIdAll: '1,2701,2793,2799',
                children: []
              },
              {
                id: 2800,
                parentId: 2793,
                regionType: 3,
                regionName: '环县',
                parentIdAll: '1,2701,2793,2800',
                children: []
              },
              {
                id: 2801,
                parentId: 2793,
                regionType: 3,
                regionName: '镇原县',
                parentIdAll: '1,2701,2793,2801',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 2802,
        parentId: 1,
        regionType: 1,
        regionName: '四川省',
        parentIdAll: '1,2802',
        children: [
          {
            id: 2803,
            parentId: 2802,
            regionType: 2,
            regionName: '广元市',
            parentIdAll: '1,2802,2803',
            children: [
              {
                id: 2804,
                parentId: 2803,
                regionType: 3,
                regionName: '苍溪县',
                parentIdAll: '1,2802,2803,2804',
                children: []
              },
              {
                id: 2805,
                parentId: 2803,
                regionType: 3,
                regionName: '朝天区',
                parentIdAll: '1,2802,2803,2805',
                children: []
              },
              {
                id: 2806,
                parentId: 2803,
                regionType: 3,
                regionName: '昭化区',
                parentIdAll: '1,2802,2803,2806',
                children: []
              },
              {
                id: 2807,
                parentId: 2803,
                regionType: 3,
                regionName: '剑阁县',
                parentIdAll: '1,2802,2803,2807',
                children: []
              },
              {
                id: 2808,
                parentId: 2803,
                regionType: 3,
                regionName: '旺苍县',
                parentIdAll: '1,2802,2803,2808',
                children: []
              },
              {
                id: 2809,
                parentId: 2803,
                regionType: 3,
                regionName: '青川县',
                parentIdAll: '1,2802,2803,2809',
                children: []
              },
              {
                id: 2810,
                parentId: 2803,
                regionType: 3,
                regionName: '利州区',
                parentIdAll: '1,2802,2803,2810',
                children: []
              }
            ]
          },
          {
            id: 2811,
            parentId: 2802,
            regionType: 2,
            regionName: '南充市',
            parentIdAll: '1,2802,2811',
            children: [
              {
                id: 2812,
                parentId: 2811,
                regionType: 3,
                regionName: '顺庆区',
                parentIdAll: '1,2802,2811,2812',
                children: []
              },
              {
                id: 2813,
                parentId: 2811,
                regionType: 3,
                regionName: '营山县',
                parentIdAll: '1,2802,2811,2813',
                children: []
              },
              {
                id: 2814,
                parentId: 2811,
                regionType: 3,
                regionName: '蓬安县',
                parentIdAll: '1,2802,2811,2814',
                children: []
              },
              {
                id: 2815,
                parentId: 2811,
                regionType: 3,
                regionName: '南部县',
                parentIdAll: '1,2802,2811,2815',
                children: []
              },
              {
                id: 2816,
                parentId: 2811,
                regionType: 3,
                regionName: '西充县',
                parentIdAll: '1,2802,2811,2816',
                children: []
              },
              {
                id: 2817,
                parentId: 2811,
                regionType: 3,
                regionName: '嘉陵区',
                parentIdAll: '1,2802,2811,2817',
                children: []
              },
              {
                id: 2818,
                parentId: 2811,
                regionType: 3,
                regionName: '阆中市',
                parentIdAll: '1,2802,2811,2818',
                children: []
              },
              {
                id: 2819,
                parentId: 2811,
                regionType: 3,
                regionName: '仪陇县',
                parentIdAll: '1,2802,2811,2819',
                children: []
              },
              {
                id: 2820,
                parentId: 2811,
                regionType: 3,
                regionName: '高坪区',
                parentIdAll: '1,2802,2811,2820',
                children: []
              }
            ]
          },
          {
            id: 2821,
            parentId: 2802,
            regionType: 2,
            regionName: '达州市',
            parentIdAll: '1,2802,2821',
            children: [
              {
                id: 2822,
                parentId: 2821,
                regionType: 3,
                regionName: '宣汉县',
                parentIdAll: '1,2802,2821,2822',
                children: []
              },
              {
                id: 2823,
                parentId: 2821,
                regionType: 3,
                regionName: '大竹县',
                parentIdAll: '1,2802,2821,2823',
                children: []
              },
              {
                id: 2824,
                parentId: 2821,
                regionType: 3,
                regionName: '达川区',
                parentIdAll: '1,2802,2821,2824',
                children: []
              },
              {
                id: 2825,
                parentId: 2821,
                regionType: 3,
                regionName: '通川区',
                parentIdAll: '1,2802,2821,2825',
                children: []
              },
              {
                id: 2826,
                parentId: 2821,
                regionType: 3,
                regionName: '渠县',
                parentIdAll: '1,2802,2821,2826',
                children: []
              },
              {
                id: 2827,
                parentId: 2821,
                regionType: 3,
                regionName: '开江县',
                parentIdAll: '1,2802,2821,2827',
                children: []
              },
              {
                id: 2828,
                parentId: 2821,
                regionType: 3,
                regionName: '万源市',
                parentIdAll: '1,2802,2821,2828',
                children: []
              }
            ]
          },
          {
            id: 2829,
            parentId: 2802,
            regionType: 2,
            regionName: '德阳市',
            parentIdAll: '1,2802,2829',
            children: [
              {
                id: 2830,
                parentId: 2829,
                regionType: 3,
                regionName: '广汉市',
                parentIdAll: '1,2802,2829,2830',
                children: []
              },
              {
                id: 2831,
                parentId: 2829,
                regionType: 3,
                regionName: '中江县',
                parentIdAll: '1,2802,2829,2831',
                children: []
              },
              {
                id: 2832,
                parentId: 2829,
                regionType: 3,
                regionName: '旌阳区',
                parentIdAll: '1,2802,2829,2832',
                children: []
              },
              {
                id: 2833,
                parentId: 2829,
                regionType: 3,
                regionName: '什邡市',
                parentIdAll: '1,2802,2829,2833',
                children: []
              },
              {
                id: 2834,
                parentId: 2829,
                regionType: 3,
                regionName: '罗江区',
                parentIdAll: '1,2802,2829,2834',
                children: []
              },
              {
                id: 2835,
                parentId: 2829,
                regionType: 3,
                regionName: '绵竹市',
                parentIdAll: '1,2802,2829,2835',
                children: []
              }
            ]
          },
          {
            id: 2836,
            parentId: 2802,
            regionType: 2,
            regionName: '巴中市',
            parentIdAll: '1,2802,2836',
            children: [
              {
                id: 2837,
                parentId: 2836,
                regionType: 3,
                regionName: '平昌县',
                parentIdAll: '1,2802,2836,2837',
                children: []
              },
              {
                id: 2838,
                parentId: 2836,
                regionType: 3,
                regionName: '南江县',
                parentIdAll: '1,2802,2836,2838',
                children: []
              },
              {
                id: 2839,
                parentId: 2836,
                regionType: 3,
                regionName: '通江县',
                parentIdAll: '1,2802,2836,2839',
                children: []
              },
              {
                id: 2840,
                parentId: 2836,
                regionType: 3,
                regionName: '巴州区',
                parentIdAll: '1,2802,2836,2840',
                children: []
              },
              {
                id: 2841,
                parentId: 2836,
                regionType: 3,
                regionName: '恩阳区',
                parentIdAll: '1,2802,2836,2841',
                children: []
              }
            ]
          },
          {
            id: 2842,
            parentId: 2802,
            regionType: 2,
            regionName: '遂宁市',
            parentIdAll: '1,2802,2842',
            children: [
              {
                id: 2843,
                parentId: 2842,
                regionType: 3,
                regionName: '蓬溪县',
                parentIdAll: '1,2802,2842,2843',
                children: []
              },
              {
                id: 2844,
                parentId: 2842,
                regionType: 3,
                regionName: '船山区',
                parentIdAll: '1,2802,2842,2844',
                children: []
              },
              {
                id: 2845,
                parentId: 2842,
                regionType: 3,
                regionName: '大英县',
                parentIdAll: '1,2802,2842,2845',
                children: []
              },
              {
                id: 2846,
                parentId: 2842,
                regionType: 3,
                regionName: '射洪县',
                parentIdAll: '1,2802,2842,2846',
                children: []
              },
              {
                id: 2847,
                parentId: 2842,
                regionType: 3,
                regionName: '安居区',
                parentIdAll: '1,2802,2842,2847',
                children: []
              }
            ]
          },
          {
            id: 2848,
            parentId: 2802,
            regionType: 2,
            regionName: '眉山市',
            parentIdAll: '1,2802,2848',
            children: [
              {
                id: 2849,
                parentId: 2848,
                regionType: 3,
                regionName: '洪雅县',
                parentIdAll: '1,2802,2848,2849',
                children: []
              },
              {
                id: 2850,
                parentId: 2848,
                regionType: 3,
                regionName: '丹棱县',
                parentIdAll: '1,2802,2848,2850',
                children: []
              },
              {
                id: 2851,
                parentId: 2848,
                regionType: 3,
                regionName: '仁寿县',
                parentIdAll: '1,2802,2848,2851',
                children: []
              },
              {
                id: 2852,
                parentId: 2848,
                regionType: 3,
                regionName: '青神县',
                parentIdAll: '1,2802,2848,2852',
                children: []
              },
              {
                id: 2853,
                parentId: 2848,
                regionType: 3,
                regionName: '彭山区',
                parentIdAll: '1,2802,2848,2853',
                children: []
              },
              {
                id: 2854,
                parentId: 2848,
                regionType: 3,
                regionName: '东坡区',
                parentIdAll: '1,2802,2848,2854',
                children: []
              }
            ]
          },
          {
            id: 2855,
            parentId: 2802,
            regionType: 2,
            regionName: '资阳市',
            parentIdAll: '1,2802,2855',
            children: [
              {
                id: 2856,
                parentId: 2855,
                regionType: 3,
                regionName: '雁江区',
                parentIdAll: '1,2802,2855,2856',
                children: []
              },
              {
                id: 2857,
                parentId: 2855,
                regionType: 3,
                regionName: '乐至县',
                parentIdAll: '1,2802,2855,2857',
                children: []
              },
              {
                id: 2858,
                parentId: 2855,
                regionType: 3,
                regionName: '安岳县',
                parentIdAll: '1,2802,2855,2858',
                children: []
              }
            ]
          },
          {
            id: 2859,
            parentId: 2802,
            regionType: 2,
            regionName: '广安市',
            parentIdAll: '1,2802,2859',
            children: [
              {
                id: 2860,
                parentId: 2859,
                regionType: 3,
                regionName: '邻水县',
                parentIdAll: '1,2802,2859,2860',
                children: []
              },
              {
                id: 2861,
                parentId: 2859,
                regionType: 3,
                regionName: '武胜县',
                parentIdAll: '1,2802,2859,2861',
                children: []
              },
              {
                id: 2862,
                parentId: 2859,
                regionType: 3,
                regionName: '广安区',
                parentIdAll: '1,2802,2859,2862',
                children: []
              },
              {
                id: 2863,
                parentId: 2859,
                regionType: 3,
                regionName: '岳池县',
                parentIdAll: '1,2802,2859,2863',
                children: []
              },
              {
                id: 2864,
                parentId: 2859,
                regionType: 3,
                regionName: '前锋区',
                parentIdAll: '1,2802,2859,2864',
                children: []
              },
              {
                id: 2865,
                parentId: 2859,
                regionType: 3,
                regionName: '华蓥市',
                parentIdAll: '1,2802,2859,2865',
                children: []
              }
            ]
          },
          {
            id: 2866,
            parentId: 2802,
            regionType: 2,
            regionName: '成都市',
            parentIdAll: '1,2802,2866',
            children: [
              {
                id: 2867,
                parentId: 2866,
                regionType: 3,
                regionName: '成华区',
                parentIdAll: '1,2802,2866,2867',
                children: []
              },
              {
                id: 2868,
                parentId: 2866,
                regionType: 3,
                regionName: '崇州市',
                parentIdAll: '1,2802,2866,2868',
                children: []
              },
              {
                id: 2869,
                parentId: 2866,
                regionType: 3,
                regionName: '大邑县',
                parentIdAll: '1,2802,2866,2869',
                children: []
              },
              {
                id: 2870,
                parentId: 2866,
                regionType: 3,
                regionName: '都江堰市',
                parentIdAll: '1,2802,2866,2870',
                children: []
              },
              {
                id: 2871,
                parentId: 2866,
                regionType: 3,
                regionName: '简阳市',
                parentIdAll: '1,2802,2866,2871',
                children: []
              },
              {
                id: 2872,
                parentId: 2866,
                regionType: 3,
                regionName: '金牛区',
                parentIdAll: '1,2802,2866,2872',
                children: []
              },
              {
                id: 2873,
                parentId: 2866,
                regionType: 3,
                regionName: '金堂县',
                parentIdAll: '1,2802,2866,2873',
                children: []
              },
              {
                id: 2874,
                parentId: 2866,
                regionType: 3,
                regionName: '锦江区',
                parentIdAll: '1,2802,2866,2874',
                children: []
              },
              {
                id: 2875,
                parentId: 2866,
                regionType: 3,
                regionName: '龙泉驿区',
                parentIdAll: '1,2802,2866,2875',
                children: []
              },
              {
                id: 2876,
                parentId: 2866,
                regionType: 3,
                regionName: '彭州市',
                parentIdAll: '1,2802,2866,2876',
                children: []
              },
              {
                id: 2877,
                parentId: 2866,
                regionType: 3,
                regionName: '郫都区',
                parentIdAll: '1,2802,2866,2877',
                children: []
              },
              {
                id: 2878,
                parentId: 2866,
                regionType: 3,
                regionName: '蒲江县',
                parentIdAll: '1,2802,2866,2878',
                children: []
              },
              {
                id: 2879,
                parentId: 2866,
                regionType: 3,
                regionName: '青白江区',
                parentIdAll: '1,2802,2866,2879',
                children: []
              },
              {
                id: 2880,
                parentId: 2866,
                regionType: 3,
                regionName: '青羊区',
                parentIdAll: '1,2802,2866,2880',
                children: []
              },
              {
                id: 2881,
                parentId: 2866,
                regionType: 3,
                regionName: '邛崃市',
                parentIdAll: '1,2802,2866,2881',
                children: []
              },
              {
                id: 2882,
                parentId: 2866,
                regionType: 3,
                regionName: '双流区',
                parentIdAll: '1,2802,2866,2882',
                children: []
              },
              {
                id: 2883,
                parentId: 2866,
                regionType: 3,
                regionName: '温江区',
                parentIdAll: '1,2802,2866,2883',
                children: []
              },
              {
                id: 2884,
                parentId: 2866,
                regionType: 3,
                regionName: '武侯区',
                parentIdAll: '1,2802,2866,2884',
                children: []
              },
              {
                id: 2885,
                parentId: 2866,
                regionType: 3,
                regionName: '新都区',
                parentIdAll: '1,2802,2866,2885',
                children: []
              },
              {
                id: 2886,
                parentId: 2866,
                regionType: 3,
                regionName: '新津县',
                parentIdAll: '1,2802,2866,2886',
                children: []
              }
            ]
          },
          {
            id: 2887,
            parentId: 2802,
            regionType: 2,
            regionName: '绵阳市',
            parentIdAll: '1,2802,2887',
            children: [
              {
                id: 2888,
                parentId: 2887,
                regionType: 3,
                regionName: '梓潼县',
                parentIdAll: '1,2802,2887,2888',
                children: []
              },
              {
                id: 2889,
                parentId: 2887,
                regionType: 3,
                regionName: '江油市',
                parentIdAll: '1,2802,2887,2889',
                children: []
              },
              {
                id: 2890,
                parentId: 2887,
                regionType: 3,
                regionName: '三台县',
                parentIdAll: '1,2802,2887,2890',
                children: []
              },
              {
                id: 2891,
                parentId: 2887,
                regionType: 3,
                regionName: '游仙区',
                parentIdAll: '1,2802,2887,2891',
                children: []
              },
              {
                id: 2892,
                parentId: 2887,
                regionType: 3,
                regionName: '北川羌族自治县',
                parentIdAll: '1,2802,2887,2892',
                children: []
              },
              {
                id: 2893,
                parentId: 2887,
                regionType: 3,
                regionName: '盐亭县',
                parentIdAll: '1,2802,2887,2893',
                children: []
              },
              {
                id: 2894,
                parentId: 2887,
                regionType: 3,
                regionName: '安州区',
                parentIdAll: '1,2802,2887,2894',
                children: []
              },
              {
                id: 2895,
                parentId: 2887,
                regionType: 3,
                regionName: '涪城区',
                parentIdAll: '1,2802,2887,2895',
                children: []
              },
              {
                id: 2896,
                parentId: 2887,
                regionType: 3,
                regionName: '平武县',
                parentIdAll: '1,2802,2887,2896',
                children: []
              }
            ]
          },
          {
            id: 2897,
            parentId: 2802,
            regionType: 2,
            regionName: '内江市',
            parentIdAll: '1,2802,2897',
            children: [
              {
                id: 2898,
                parentId: 2897,
                regionType: 3,
                regionName: '东兴区',
                parentIdAll: '1,2802,2897,2898',
                children: []
              },
              {
                id: 2899,
                parentId: 2897,
                regionType: 3,
                regionName: '威远县',
                parentIdAll: '1,2802,2897,2899',
                children: []
              },
              {
                id: 2900,
                parentId: 2897,
                regionType: 3,
                regionName: '资中县',
                parentIdAll: '1,2802,2897,2900',
                children: []
              },
              {
                id: 2901,
                parentId: 2897,
                regionType: 3,
                regionName: '隆昌市',
                parentIdAll: '1,2802,2897,2901',
                children: []
              },
              {
                id: 2902,
                parentId: 2897,
                regionType: 3,
                regionName: '市中区',
                parentIdAll: '1,2802,2897,2902',
                children: []
              }
            ]
          },
          {
            id: 2903,
            parentId: 2802,
            regionType: 2,
            regionName: '自贡市',
            parentIdAll: '1,2802,2903',
            children: [
              {
                id: 2904,
                parentId: 2903,
                regionType: 3,
                regionName: '沿滩区',
                parentIdAll: '1,2802,2903,2904',
                children: []
              },
              {
                id: 2905,
                parentId: 2903,
                regionType: 3,
                regionName: '荣县',
                parentIdAll: '1,2802,2903,2905',
                children: []
              },
              {
                id: 2906,
                parentId: 2903,
                regionType: 3,
                regionName: '富顺县',
                parentIdAll: '1,2802,2903,2906',
                children: []
              },
              {
                id: 2907,
                parentId: 2903,
                regionType: 3,
                regionName: '贡井区',
                parentIdAll: '1,2802,2903,2907',
                children: []
              },
              {
                id: 2908,
                parentId: 2903,
                regionType: 3,
                regionName: '大安区',
                parentIdAll: '1,2802,2903,2908',
                children: []
              },
              {
                id: 2909,
                parentId: 2903,
                regionType: 3,
                regionName: '自流井区',
                parentIdAll: '1,2802,2903,2909',
                children: []
              }
            ]
          },
          {
            id: 2910,
            parentId: 2802,
            regionType: 2,
            regionName: '雅安市',
            parentIdAll: '1,2802,2910',
            children: [
              {
                id: 2911,
                parentId: 2910,
                regionType: 3,
                regionName: '宝兴县',
                parentIdAll: '1,2802,2910,2911',
                children: []
              },
              {
                id: 2912,
                parentId: 2910,
                regionType: 3,
                regionName: '雨城区',
                parentIdAll: '1,2802,2910,2912',
                children: []
              },
              {
                id: 2913,
                parentId: 2910,
                regionType: 3,
                regionName: '石棉县',
                parentIdAll: '1,2802,2910,2913',
                children: []
              },
              {
                id: 2914,
                parentId: 2910,
                regionType: 3,
                regionName: '芦山县',
                parentIdAll: '1,2802,2910,2914',
                children: []
              },
              {
                id: 2915,
                parentId: 2910,
                regionType: 3,
                regionName: '天全县',
                parentIdAll: '1,2802,2910,2915',
                children: []
              },
              {
                id: 2916,
                parentId: 2910,
                regionType: 3,
                regionName: '荥经县',
                parentIdAll: '1,2802,2910,2916',
                children: []
              },
              {
                id: 2917,
                parentId: 2910,
                regionType: 3,
                regionName: '汉源县',
                parentIdAll: '1,2802,2910,2917',
                children: []
              },
              {
                id: 2918,
                parentId: 2910,
                regionType: 3,
                regionName: '名山区',
                parentIdAll: '1,2802,2910,2918',
                children: []
              }
            ]
          },
          {
            id: 2919,
            parentId: 2802,
            regionType: 2,
            regionName: '宜宾市',
            parentIdAll: '1,2802,2919',
            children: [
              {
                id: 2920,
                parentId: 2919,
                regionType: 3,
                regionName: '翠屏区',
                parentIdAll: '1,2802,2919,2920',
                children: []
              },
              {
                id: 2921,
                parentId: 2919,
                regionType: 3,
                regionName: '南溪区',
                parentIdAll: '1,2802,2919,2921',
                children: []
              },
              {
                id: 2922,
                parentId: 2919,
                regionType: 3,
                regionName: '珙县',
                parentIdAll: '1,2802,2919,2922',
                children: []
              },
              {
                id: 2923,
                parentId: 2919,
                regionType: 3,
                regionName: '高县',
                parentIdAll: '1,2802,2919,2923',
                children: []
              },
              {
                id: 2924,
                parentId: 2919,
                regionType: 3,
                regionName: '长宁县',
                parentIdAll: '1,2802,2919,2924',
                children: []
              },
              {
                id: 2925,
                parentId: 2919,
                regionType: 3,
                regionName: '江安县',
                parentIdAll: '1,2802,2919,2925',
                children: []
              },
              {
                id: 2926,
                parentId: 2919,
                regionType: 3,
                regionName: '筠连县',
                parentIdAll: '1,2802,2919,2926',
                children: []
              },
              {
                id: 2927,
                parentId: 2919,
                regionType: 3,
                regionName: '兴文县',
                parentIdAll: '1,2802,2919,2927',
                children: []
              },
              {
                id: 2928,
                parentId: 2919,
                regionType: 3,
                regionName: '屏山县',
                parentIdAll: '1,2802,2919,2928',
                children: []
              },
              {
                id: 2929,
                parentId: 2919,
                regionType: 3,
                regionName: '宜宾县',
                parentIdAll: '1,2802,2919,2929',
                children: []
              }
            ]
          },
          {
            id: 2930,
            parentId: 2802,
            regionType: 2,
            regionName: '攀枝花市',
            parentIdAll: '1,2802,2930',
            children: [
              {
                id: 2931,
                parentId: 2930,
                regionType: 3,
                regionName: '米易县',
                parentIdAll: '1,2802,2930,2931',
                children: []
              },
              {
                id: 2932,
                parentId: 2930,
                regionType: 3,
                regionName: '西区',
                parentIdAll: '1,2802,2930,2932',
                children: []
              },
              {
                id: 2933,
                parentId: 2930,
                regionType: 3,
                regionName: '东区',
                parentIdAll: '1,2802,2930,2933',
                children: []
              },
              {
                id: 2934,
                parentId: 2930,
                regionType: 3,
                regionName: '仁和区',
                parentIdAll: '1,2802,2930,2934',
                children: []
              },
              {
                id: 2935,
                parentId: 2930,
                regionType: 3,
                regionName: '盐边县',
                parentIdAll: '1,2802,2930,2935',
                children: []
              }
            ]
          },
          {
            id: 2936,
            parentId: 2802,
            regionType: 2,
            regionName: '乐山市',
            parentIdAll: '1,2802,2936',
            children: [
              {
                id: 2937,
                parentId: 2936,
                regionType: 3,
                regionName: '五通桥区',
                parentIdAll: '1,2802,2936,2937',
                children: []
              },
              {
                id: 2938,
                parentId: 2936,
                regionType: 3,
                regionName: '沙湾区',
                parentIdAll: '1,2802,2936,2938',
                children: []
              },
              {
                id: 2939,
                parentId: 2936,
                regionType: 3,
                regionName: '沐川县',
                parentIdAll: '1,2802,2936,2939',
                children: []
              },
              {
                id: 2940,
                parentId: 2936,
                regionType: 3,
                regionName: '犍为县',
                parentIdAll: '1,2802,2936,2940',
                children: []
              },
              {
                id: 2941,
                parentId: 2936,
                regionType: 3,
                regionName: '金口河区',
                parentIdAll: '1,2802,2936,2941',
                children: []
              },
              {
                id: 2942,
                parentId: 2936,
                regionType: 3,
                regionName: '峨边彝族自治县',
                parentIdAll: '1,2802,2936,2942',
                children: []
              },
              {
                id: 2943,
                parentId: 2936,
                regionType: 3,
                regionName: '井研县',
                parentIdAll: '1,2802,2936,2943',
                children: []
              },
              {
                id: 2944,
                parentId: 2936,
                regionType: 3,
                regionName: '夹江县',
                parentIdAll: '1,2802,2936,2944',
                children: []
              },
              {
                id: 2945,
                parentId: 2936,
                regionType: 3,
                regionName: '马边彝族自治县',
                parentIdAll: '1,2802,2936,2945',
                children: []
              },
              {
                id: 2946,
                parentId: 2936,
                regionType: 3,
                regionName: '市中区',
                parentIdAll: '1,2802,2936,2946',
                children: []
              },
              {
                id: 2947,
                parentId: 2936,
                regionType: 3,
                regionName: '峨眉山市',
                parentIdAll: '1,2802,2936,2947',
                children: []
              }
            ]
          },
          {
            id: 2948,
            parentId: 2802,
            regionType: 2,
            regionName: '甘孜藏族自治州',
            parentIdAll: '1,2802,2948',
            children: [
              {
                id: 2949,
                parentId: 2948,
                regionType: 3,
                regionName: '甘孜县',
                parentIdAll: '1,2802,2948,2949',
                children: []
              },
              {
                id: 2950,
                parentId: 2948,
                regionType: 3,
                regionName: '道孚县',
                parentIdAll: '1,2802,2948,2950',
                children: []
              },
              {
                id: 2951,
                parentId: 2948,
                regionType: 3,
                regionName: '炉霍县',
                parentIdAll: '1,2802,2948,2951',
                children: []
              },
              {
                id: 2952,
                parentId: 2948,
                regionType: 3,
                regionName: '德格县',
                parentIdAll: '1,2802,2948,2952',
                children: []
              },
              {
                id: 2953,
                parentId: 2948,
                regionType: 3,
                regionName: '石渠县',
                parentIdAll: '1,2802,2948,2953',
                children: []
              },
              {
                id: 2954,
                parentId: 2948,
                regionType: 3,
                regionName: '新龙县',
                parentIdAll: '1,2802,2948,2954',
                children: []
              },
              {
                id: 2955,
                parentId: 2948,
                regionType: 3,
                regionName: '白玉县',
                parentIdAll: '1,2802,2948,2955',
                children: []
              },
              {
                id: 2956,
                parentId: 2948,
                regionType: 3,
                regionName: '康定市',
                parentIdAll: '1,2802,2948,2956',
                children: []
              },
              {
                id: 2957,
                parentId: 2948,
                regionType: 3,
                regionName: '理塘县',
                parentIdAll: '1,2802,2948,2957',
                children: []
              },
              {
                id: 2958,
                parentId: 2948,
                regionType: 3,
                regionName: '雅江县',
                parentIdAll: '1,2802,2948,2958',
                children: []
              },
              {
                id: 2959,
                parentId: 2948,
                regionType: 3,
                regionName: '九龙县',
                parentIdAll: '1,2802,2948,2959',
                children: []
              },
              {
                id: 2960,
                parentId: 2948,
                regionType: 3,
                regionName: '巴塘县',
                parentIdAll: '1,2802,2948,2960',
                children: []
              },
              {
                id: 2961,
                parentId: 2948,
                regionType: 3,
                regionName: '泸定县',
                parentIdAll: '1,2802,2948,2961',
                children: []
              },
              {
                id: 2962,
                parentId: 2948,
                regionType: 3,
                regionName: '乡城县',
                parentIdAll: '1,2802,2948,2962',
                children: []
              },
              {
                id: 2963,
                parentId: 2948,
                regionType: 3,
                regionName: '稻城县',
                parentIdAll: '1,2802,2948,2963',
                children: []
              },
              {
                id: 2964,
                parentId: 2948,
                regionType: 3,
                regionName: '得荣县',
                parentIdAll: '1,2802,2948,2964',
                children: []
              },
              {
                id: 2965,
                parentId: 2948,
                regionType: 3,
                regionName: '色达县',
                parentIdAll: '1,2802,2948,2965',
                children: []
              },
              {
                id: 2966,
                parentId: 2948,
                regionType: 3,
                regionName: '丹巴县',
                parentIdAll: '1,2802,2948,2966',
                children: []
              }
            ]
          },
          {
            id: 2967,
            parentId: 2802,
            regionType: 2,
            regionName: '凉山彝族自治州',
            parentIdAll: '1,2802,2967',
            children: [
              {
                id: 2968,
                parentId: 2967,
                regionType: 3,
                regionName: '布拖县',
                parentIdAll: '1,2802,2967,2968',
                children: []
              },
              {
                id: 2969,
                parentId: 2967,
                regionType: 3,
                regionName: '喜德县',
                parentIdAll: '1,2802,2967,2969',
                children: []
              },
              {
                id: 2970,
                parentId: 2967,
                regionType: 3,
                regionName: '美姑县',
                parentIdAll: '1,2802,2967,2970',
                children: []
              },
              {
                id: 2971,
                parentId: 2967,
                regionType: 3,
                regionName: '盐源县',
                parentIdAll: '1,2802,2967,2971',
                children: []
              },
              {
                id: 2972,
                parentId: 2967,
                regionType: 3,
                regionName: '冕宁县',
                parentIdAll: '1,2802,2967,2972',
                children: []
              },
              {
                id: 2973,
                parentId: 2967,
                regionType: 3,
                regionName: '木里藏族自治县',
                parentIdAll: '1,2802,2967,2973',
                children: []
              },
              {
                id: 2974,
                parentId: 2967,
                regionType: 3,
                regionName: '越西县',
                parentIdAll: '1,2802,2967,2974',
                children: []
              },
              {
                id: 2975,
                parentId: 2967,
                regionType: 3,
                regionName: '甘洛县',
                parentIdAll: '1,2802,2967,2975',
                children: []
              },
              {
                id: 2976,
                parentId: 2967,
                regionType: 3,
                regionName: '西昌市',
                parentIdAll: '1,2802,2967,2976',
                children: []
              },
              {
                id: 2977,
                parentId: 2967,
                regionType: 3,
                regionName: '昭觉县',
                parentIdAll: '1,2802,2967,2977',
                children: []
              },
              {
                id: 2978,
                parentId: 2967,
                regionType: 3,
                regionName: '会理县',
                parentIdAll: '1,2802,2967,2978',
                children: []
              },
              {
                id: 2979,
                parentId: 2967,
                regionType: 3,
                regionName: '普格县',
                parentIdAll: '1,2802,2967,2979',
                children: []
              },
              {
                id: 2980,
                parentId: 2967,
                regionType: 3,
                regionName: '金阳县',
                parentIdAll: '1,2802,2967,2980',
                children: []
              },
              {
                id: 2981,
                parentId: 2967,
                regionType: 3,
                regionName: '会东县',
                parentIdAll: '1,2802,2967,2981',
                children: []
              },
              {
                id: 2982,
                parentId: 2967,
                regionType: 3,
                regionName: '雷波县',
                parentIdAll: '1,2802,2967,2982',
                children: []
              },
              {
                id: 2983,
                parentId: 2967,
                regionType: 3,
                regionName: '宁南县',
                parentIdAll: '1,2802,2967,2983',
                children: []
              },
              {
                id: 2984,
                parentId: 2967,
                regionType: 3,
                regionName: '德昌县',
                parentIdAll: '1,2802,2967,2984',
                children: []
              }
            ]
          },
          {
            id: 2985,
            parentId: 2802,
            regionType: 2,
            regionName: '泸州市',
            parentIdAll: '1,2802,2985',
            children: [
              {
                id: 2986,
                parentId: 2985,
                regionType: 3,
                regionName: '龙马潭区',
                parentIdAll: '1,2802,2985,2986',
                children: []
              },
              {
                id: 2987,
                parentId: 2985,
                regionType: 3,
                regionName: '泸县',
                parentIdAll: '1,2802,2985,2987',
                children: []
              },
              {
                id: 2988,
                parentId: 2985,
                regionType: 3,
                regionName: '叙永县',
                parentIdAll: '1,2802,2985,2988',
                children: []
              },
              {
                id: 2989,
                parentId: 2985,
                regionType: 3,
                regionName: '纳溪区',
                parentIdAll: '1,2802,2985,2989',
                children: []
              },
              {
                id: 2990,
                parentId: 2985,
                regionType: 3,
                regionName: '古蔺县',
                parentIdAll: '1,2802,2985,2990',
                children: []
              },
              {
                id: 2991,
                parentId: 2985,
                regionType: 3,
                regionName: '江阳区',
                parentIdAll: '1,2802,2985,2991',
                children: []
              },
              {
                id: 2992,
                parentId: 2985,
                regionType: 3,
                regionName: '合江县',
                parentIdAll: '1,2802,2985,2992',
                children: []
              }
            ]
          },
          {
            id: 2993,
            parentId: 2802,
            regionType: 2,
            regionName: '阿坝藏族羌族自治州',
            parentIdAll: '1,2802,2993',
            children: [
              {
                id: 2994,
                parentId: 2993,
                regionType: 3,
                regionName: '九寨沟市',
                parentIdAll: '1,2802,2993,2994',
                children: []
              },
              {
                id: 2995,
                parentId: 2993,
                regionType: 3,
                regionName: '阿坝县',
                parentIdAll: '1,2802,2993,2995',
                children: []
              },
              {
                id: 2996,
                parentId: 2993,
                regionType: 3,
                regionName: '理县',
                parentIdAll: '1,2802,2993,2996',
                children: []
              },
              {
                id: 2997,
                parentId: 2993,
                regionType: 3,
                regionName: '汶川县',
                parentIdAll: '1,2802,2993,2997',
                children: []
              },
              {
                id: 2998,
                parentId: 2993,
                regionType: 3,
                regionName: '马尔康市',
                parentIdAll: '1,2802,2993,2998',
                children: []
              },
              {
                id: 2999,
                parentId: 2993,
                regionType: 3,
                regionName: '若尔盖县',
                parentIdAll: '1,2802,2993,2999',
                children: []
              },
              {
                id: 3000,
                parentId: 2993,
                regionType: 3,
                regionName: '金川县',
                parentIdAll: '1,2802,2993,3000',
                children: []
              },
              {
                id: 3001,
                parentId: 2993,
                regionType: 3,
                regionName: '红原县',
                parentIdAll: '1,2802,2993,3001',
                children: []
              },
              {
                id: 3002,
                parentId: 2993,
                regionType: 3,
                regionName: '黑水县',
                parentIdAll: '1,2802,2993,3002',
                children: []
              },
              {
                id: 3003,
                parentId: 2993,
                regionType: 3,
                regionName: '茂县',
                parentIdAll: '1,2802,2993,3003',
                children: []
              },
              {
                id: 3004,
                parentId: 2993,
                regionType: 3,
                regionName: '松潘县',
                parentIdAll: '1,2802,2993,3004',
                children: []
              },
              {
                id: 3005,
                parentId: 2993,
                regionType: 3,
                regionName: '小金县',
                parentIdAll: '1,2802,2993,3005',
                children: []
              },
              {
                id: 3006,
                parentId: 2993,
                regionType: 3,
                regionName: '壤塘县',
                parentIdAll: '1,2802,2993,3006',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 3007,
        parentId: 1,
        regionType: 1,
        regionName: '云南省',
        parentIdAll: '1,3007',
        children: [
          {
            id: 3008,
            parentId: 3007,
            regionType: 2,
            regionName: '昭通市',
            parentIdAll: '1,3007,3008',
            children: [
              {
                id: 3009,
                parentId: 3008,
                regionType: 3,
                regionName: '水富县',
                parentIdAll: '1,3007,3008,3009',
                children: []
              },
              {
                id: 3010,
                parentId: 3008,
                regionType: 3,
                regionName: '绥江县',
                parentIdAll: '1,3007,3008,3010',
                children: []
              },
              {
                id: 3011,
                parentId: 3008,
                regionType: 3,
                regionName: '威信县',
                parentIdAll: '1,3007,3008,3011',
                children: []
              },
              {
                id: 3012,
                parentId: 3008,
                regionType: 3,
                regionName: '永善县',
                parentIdAll: '1,3007,3008,3012',
                children: []
              },
              {
                id: 3013,
                parentId: 3008,
                regionType: 3,
                regionName: '大关县',
                parentIdAll: '1,3007,3008,3013',
                children: []
              },
              {
                id: 3014,
                parentId: 3008,
                regionType: 3,
                regionName: '巧家县',
                parentIdAll: '1,3007,3008,3014',
                children: []
              },
              {
                id: 3015,
                parentId: 3008,
                regionType: 3,
                regionName: '镇雄县',
                parentIdAll: '1,3007,3008,3015',
                children: []
              },
              {
                id: 3016,
                parentId: 3008,
                regionType: 3,
                regionName: '鲁甸县',
                parentIdAll: '1,3007,3008,3016',
                children: []
              },
              {
                id: 3017,
                parentId: 3008,
                regionType: 3,
                regionName: '彝良县',
                parentIdAll: '1,3007,3008,3017',
                children: []
              },
              {
                id: 3018,
                parentId: 3008,
                regionType: 3,
                regionName: '昭阳区',
                parentIdAll: '1,3007,3008,3018',
                children: []
              },
              {
                id: 3019,
                parentId: 3008,
                regionType: 3,
                regionName: '盐津县',
                parentIdAll: '1,3007,3008,3019',
                children: []
              }
            ]
          },
          {
            id: 3020,
            parentId: 3007,
            regionType: 2,
            regionName: '曲靖市',
            parentIdAll: '1,3007,3020',
            children: [
              {
                id: 3021,
                parentId: 3020,
                regionType: 3,
                regionName: '会泽县',
                parentIdAll: '1,3007,3020,3021',
                children: []
              },
              {
                id: 3022,
                parentId: 3020,
                regionType: 3,
                regionName: '沾益区',
                parentIdAll: '1,3007,3020,3022',
                children: []
              },
              {
                id: 3023,
                parentId: 3020,
                regionType: 3,
                regionName: '马龙县',
                parentIdAll: '1,3007,3020,3023',
                children: []
              },
              {
                id: 3024,
                parentId: 3020,
                regionType: 3,
                regionName: '麒麟区',
                parentIdAll: '1,3007,3020,3024',
                children: []
              },
              {
                id: 3025,
                parentId: 3020,
                regionType: 3,
                regionName: '陆良县',
                parentIdAll: '1,3007,3020,3025',
                children: []
              },
              {
                id: 3026,
                parentId: 3020,
                regionType: 3,
                regionName: '罗平县',
                parentIdAll: '1,3007,3020,3026',
                children: []
              },
              {
                id: 3027,
                parentId: 3020,
                regionType: 3,
                regionName: '师宗县',
                parentIdAll: '1,3007,3020,3027',
                children: []
              },
              {
                id: 3028,
                parentId: 3020,
                regionType: 3,
                regionName: '宣威市',
                parentIdAll: '1,3007,3020,3028',
                children: []
              },
              {
                id: 3029,
                parentId: 3020,
                regionType: 3,
                regionName: '富源县',
                parentIdAll: '1,3007,3020,3029',
                children: []
              }
            ]
          },
          {
            id: 3030,
            parentId: 3007,
            regionType: 2,
            regionName: '红河哈尼族彝族自治州',
            parentIdAll: '1,3007,3030',
            children: [
              {
                id: 3031,
                parentId: 3030,
                regionType: 3,
                regionName: '弥勒市',
                parentIdAll: '1,3007,3030,3031',
                children: []
              },
              {
                id: 3032,
                parentId: 3030,
                regionType: 3,
                regionName: '泸西县',
                parentIdAll: '1,3007,3030,3032',
                children: []
              },
              {
                id: 3033,
                parentId: 3030,
                regionType: 3,
                regionName: '建水县',
                parentIdAll: '1,3007,3030,3033',
                children: []
              },
              {
                id: 3034,
                parentId: 3030,
                regionType: 3,
                regionName: '石屏县',
                parentIdAll: '1,3007,3030,3034',
                children: []
              },
              {
                id: 3035,
                parentId: 3030,
                regionType: 3,
                regionName: '个旧市',
                parentIdAll: '1,3007,3030,3035',
                children: []
              },
              {
                id: 3036,
                parentId: 3030,
                regionType: 3,
                regionName: '开远市',
                parentIdAll: '1,3007,3030,3036',
                children: []
              },
              {
                id: 3037,
                parentId: 3030,
                regionType: 3,
                regionName: '红河县',
                parentIdAll: '1,3007,3030,3037',
                children: []
              },
              {
                id: 3038,
                parentId: 3030,
                regionType: 3,
                regionName: '元阳县',
                parentIdAll: '1,3007,3030,3038',
                children: []
              },
              {
                id: 3039,
                parentId: 3030,
                regionType: 3,
                regionName: '蒙自市',
                parentIdAll: '1,3007,3030,3039',
                children: []
              },
              {
                id: 3040,
                parentId: 3030,
                regionType: 3,
                regionName: '屏边苗族自治县',
                parentIdAll: '1,3007,3030,3040',
                children: []
              },
              {
                id: 3041,
                parentId: 3030,
                regionType: 3,
                regionName: '绿春县',
                parentIdAll: '1,3007,3030,3041',
                children: []
              },
              {
                id: 3042,
                parentId: 3030,
                regionType: 3,
                regionName: '河口瑶族自治县',
                parentIdAll: '1,3007,3030,3042',
                children: []
              },
              {
                id: 3043,
                parentId: 3030,
                regionType: 3,
                regionName: '金平苗族瑶族傣族自治县',
                parentIdAll: '1,3007,3030,3043',
                children: []
              }
            ]
          },
          {
            id: 3044,
            parentId: 3007,
            regionType: 2,
            regionName: '西双版纳傣族自治州',
            parentIdAll: '1,3007,3044',
            children: [
              {
                id: 3045,
                parentId: 3044,
                regionType: 3,
                regionName: '勐腊县',
                parentIdAll: '1,3007,3044,3045',
                children: []
              },
              {
                id: 3046,
                parentId: 3044,
                regionType: 3,
                regionName: '勐海县',
                parentIdAll: '1,3007,3044,3046',
                children: []
              },
              {
                id: 3047,
                parentId: 3044,
                regionType: 3,
                regionName: '景洪市',
                parentIdAll: '1,3007,3044,3047',
                children: []
              }
            ]
          },
          {
            id: 3048,
            parentId: 3007,
            regionType: 2,
            regionName: '保山市',
            parentIdAll: '1,3007,3048',
            children: [
              {
                id: 3049,
                parentId: 3048,
                regionType: 3,
                regionName: '隆阳区',
                parentIdAll: '1,3007,3048,3049',
                children: []
              },
              {
                id: 3050,
                parentId: 3048,
                regionType: 3,
                regionName: '腾冲市',
                parentIdAll: '1,3007,3048,3050',
                children: []
              },
              {
                id: 3051,
                parentId: 3048,
                regionType: 3,
                regionName: '昌宁县',
                parentIdAll: '1,3007,3048,3051',
                children: []
              },
              {
                id: 3052,
                parentId: 3048,
                regionType: 3,
                regionName: '施甸县',
                parentIdAll: '1,3007,3048,3052',
                children: []
              },
              {
                id: 3053,
                parentId: 3048,
                regionType: 3,
                regionName: '龙陵县',
                parentIdAll: '1,3007,3048,3053',
                children: []
              }
            ]
          },
          {
            id: 3054,
            parentId: 3007,
            regionType: 2,
            regionName: '丽江市',
            parentIdAll: '1,3007,3054',
            children: [
              {
                id: 3055,
                parentId: 3054,
                regionType: 3,
                regionName: '宁蒗彝族自治县',
                parentIdAll: '1,3007,3054,3055',
                children: []
              },
              {
                id: 3056,
                parentId: 3054,
                regionType: 3,
                regionName: '玉龙纳西族自治县',
                parentIdAll: '1,3007,3054,3056',
                children: []
              },
              {
                id: 3057,
                parentId: 3054,
                regionType: 3,
                regionName: '永胜县',
                parentIdAll: '1,3007,3054,3057',
                children: []
              },
              {
                id: 3058,
                parentId: 3054,
                regionType: 3,
                regionName: '古城区',
                parentIdAll: '1,3007,3054,3058',
                children: []
              },
              {
                id: 3059,
                parentId: 3054,
                regionType: 3,
                regionName: '华坪县',
                parentIdAll: '1,3007,3054,3059',
                children: []
              }
            ]
          },
          {
            id: 3060,
            parentId: 3007,
            regionType: 2,
            regionName: '怒江傈僳族自治州',
            parentIdAll: '1,3007,3060',
            children: [
              {
                id: 3061,
                parentId: 3060,
                regionType: 3,
                regionName: '贡山独龙族怒族自治县',
                parentIdAll: '1,3007,3060,3061',
                children: []
              },
              {
                id: 3062,
                parentId: 3060,
                regionType: 3,
                regionName: '兰坪白族普米族自治县',
                parentIdAll: '1,3007,3060,3062',
                children: []
              },
              {
                id: 3063,
                parentId: 3060,
                regionType: 3,
                regionName: '福贡县',
                parentIdAll: '1,3007,3060,3063',
                children: []
              },
              {
                id: 3064,
                parentId: 3060,
                regionType: 3,
                regionName: '泸水市',
                parentIdAll: '1,3007,3060,3064',
                children: []
              }
            ]
          },
          {
            id: 3065,
            parentId: 3007,
            regionType: 2,
            regionName: '大理白族自治州',
            parentIdAll: '1,3007,3065',
            children: [
              {
                id: 3066,
                parentId: 3065,
                regionType: 3,
                regionName: '鹤庆县',
                parentIdAll: '1,3007,3065,3066',
                children: []
              },
              {
                id: 3067,
                parentId: 3065,
                regionType: 3,
                regionName: '剑川县',
                parentIdAll: '1,3007,3065,3067',
                children: []
              },
              {
                id: 3068,
                parentId: 3065,
                regionType: 3,
                regionName: '大理市',
                parentIdAll: '1,3007,3065,3068',
                children: []
              },
              {
                id: 3069,
                parentId: 3065,
                regionType: 3,
                regionName: '洱源县',
                parentIdAll: '1,3007,3065,3069',
                children: []
              },
              {
                id: 3070,
                parentId: 3065,
                regionType: 3,
                regionName: '宾川县',
                parentIdAll: '1,3007,3065,3070',
                children: []
              },
              {
                id: 3071,
                parentId: 3065,
                regionType: 3,
                regionName: '云龙县',
                parentIdAll: '1,3007,3065,3071',
                children: []
              },
              {
                id: 3072,
                parentId: 3065,
                regionType: 3,
                regionName: '漾濞彝族自治县',
                parentIdAll: '1,3007,3065,3072',
                children: []
              },
              {
                id: 3073,
                parentId: 3065,
                regionType: 3,
                regionName: '巍山彝族回族自治县',
                parentIdAll: '1,3007,3065,3073',
                children: []
              },
              {
                id: 3074,
                parentId: 3065,
                regionType: 3,
                regionName: '祥云县',
                parentIdAll: '1,3007,3065,3074',
                children: []
              },
              {
                id: 3075,
                parentId: 3065,
                regionType: 3,
                regionName: '永平县',
                parentIdAll: '1,3007,3065,3075',
                children: []
              },
              {
                id: 3076,
                parentId: 3065,
                regionType: 3,
                regionName: '弥渡县',
                parentIdAll: '1,3007,3065,3076',
                children: []
              },
              {
                id: 3077,
                parentId: 3065,
                regionType: 3,
                regionName: '南涧彝族自治县',
                parentIdAll: '1,3007,3065,3077',
                children: []
              }
            ]
          },
          {
            id: 3078,
            parentId: 3007,
            regionType: 2,
            regionName: '文山壮族苗族自治州',
            parentIdAll: '1,3007,3078',
            children: [
              {
                id: 3079,
                parentId: 3078,
                regionType: 3,
                regionName: '丘北县',
                parentIdAll: '1,3007,3078,3079',
                children: []
              },
              {
                id: 3080,
                parentId: 3078,
                regionType: 3,
                regionName: '广南县',
                parentIdAll: '1,3007,3078,3080',
                children: []
              },
              {
                id: 3081,
                parentId: 3078,
                regionType: 3,
                regionName: '文山市',
                parentIdAll: '1,3007,3078,3081',
                children: []
              },
              {
                id: 3082,
                parentId: 3078,
                regionType: 3,
                regionName: '砚山县',
                parentIdAll: '1,3007,3078,3082',
                children: []
              },
              {
                id: 3083,
                parentId: 3078,
                regionType: 3,
                regionName: '麻栗坡县',
                parentIdAll: '1,3007,3078,3083',
                children: []
              },
              {
                id: 3084,
                parentId: 3078,
                regionType: 3,
                regionName: '西畴县',
                parentIdAll: '1,3007,3078,3084',
                children: []
              },
              {
                id: 3085,
                parentId: 3078,
                regionType: 3,
                regionName: '马关县',
                parentIdAll: '1,3007,3078,3085',
                children: []
              },
              {
                id: 3086,
                parentId: 3078,
                regionType: 3,
                regionName: '富宁县',
                parentIdAll: '1,3007,3078,3086',
                children: []
              }
            ]
          },
          {
            id: 3087,
            parentId: 3007,
            regionType: 2,
            regionName: '迪庆藏族自治州',
            parentIdAll: '1,3007,3087',
            children: [
              {
                id: 3088,
                parentId: 3087,
                regionType: 3,
                regionName: '德钦县',
                parentIdAll: '1,3007,3087,3088',
                children: []
              },
              {
                id: 3089,
                parentId: 3087,
                regionType: 3,
                regionName: '香格里拉市',
                parentIdAll: '1,3007,3087,3089',
                children: []
              },
              {
                id: 3090,
                parentId: 3087,
                regionType: 3,
                regionName: '维西傈僳族自治县',
                parentIdAll: '1,3007,3087,3090',
                children: []
              }
            ]
          },
          {
            id: 3091,
            parentId: 3007,
            regionType: 2,
            regionName: '玉溪市',
            parentIdAll: '1,3007,3091',
            children: [
              {
                id: 3092,
                parentId: 3091,
                regionType: 3,
                regionName: '澄江县',
                parentIdAll: '1,3007,3091,3092',
                children: []
              },
              {
                id: 3093,
                parentId: 3091,
                regionType: 3,
                regionName: '易门县',
                parentIdAll: '1,3007,3091,3093',
                children: []
              },
              {
                id: 3094,
                parentId: 3091,
                regionType: 3,
                regionName: '峨山彝族自治县',
                parentIdAll: '1,3007,3091,3094',
                children: []
              },
              {
                id: 3095,
                parentId: 3091,
                regionType: 3,
                regionName: '红塔区',
                parentIdAll: '1,3007,3091,3095',
                children: []
              },
              {
                id: 3096,
                parentId: 3091,
                regionType: 3,
                regionName: '华宁县',
                parentIdAll: '1,3007,3091,3096',
                children: []
              },
              {
                id: 3097,
                parentId: 3091,
                regionType: 3,
                regionName: '江川区',
                parentIdAll: '1,3007,3091,3097',
                children: []
              },
              {
                id: 3098,
                parentId: 3091,
                regionType: 3,
                regionName: '新平彝族傣族自治县',
                parentIdAll: '1,3007,3091,3098',
                children: []
              },
              {
                id: 3099,
                parentId: 3091,
                regionType: 3,
                regionName: '通海县',
                parentIdAll: '1,3007,3091,3099',
                children: []
              },
              {
                id: 3100,
                parentId: 3091,
                regionType: 3,
                regionName: '元江哈尼族彝族傣族自治县',
                parentIdAll: '1,3007,3091,3100',
                children: []
              }
            ]
          },
          {
            id: 3101,
            parentId: 3007,
            regionType: 2,
            regionName: '普洱市',
            parentIdAll: '1,3007,3101',
            children: [
              {
                id: 3102,
                parentId: 3101,
                regionType: 3,
                regionName: '景东彝族自治县',
                parentIdAll: '1,3007,3101,3102',
                children: []
              },
              {
                id: 3103,
                parentId: 3101,
                regionType: 3,
                regionName: '镇沅彝族哈尼族拉祜族自治县',
                parentIdAll: '1,3007,3101,3103',
                children: []
              },
              {
                id: 3104,
                parentId: 3101,
                regionType: 3,
                regionName: '景谷傣族彝族自治县',
                parentIdAll: '1,3007,3101,3104',
                children: []
              },
              {
                id: 3105,
                parentId: 3101,
                regionType: 3,
                regionName: '墨江哈尼族自治县',
                parentIdAll: '1,3007,3101,3105',
                children: []
              },
              {
                id: 3106,
                parentId: 3101,
                regionType: 3,
                regionName: '宁洱哈尼族彝族自治县',
                parentIdAll: '1,3007,3101,3106',
                children: []
              },
              {
                id: 3107,
                parentId: 3101,
                regionType: 3,
                regionName: '澜沧拉祜族自治县',
                parentIdAll: '1,3007,3101,3107',
                children: []
              },
              {
                id: 3108,
                parentId: 3101,
                regionType: 3,
                regionName: '孟连傣族拉祜族佤族自治县',
                parentIdAll: '1,3007,3101,3108',
                children: []
              },
              {
                id: 3109,
                parentId: 3101,
                regionType: 3,
                regionName: '西盟佤族自治县',
                parentIdAll: '1,3007,3101,3109',
                children: []
              },
              {
                id: 3110,
                parentId: 3101,
                regionType: 3,
                regionName: '思茅区',
                parentIdAll: '1,3007,3101,3110',
                children: []
              },
              {
                id: 3111,
                parentId: 3101,
                regionType: 3,
                regionName: '江城哈尼族彝族自治县',
                parentIdAll: '1,3007,3101,3111',
                children: []
              }
            ]
          },
          {
            id: 3112,
            parentId: 3007,
            regionType: 2,
            regionName: '昆明市',
            parentIdAll: '1,3007,3112',
            children: [
              {
                id: 3113,
                parentId: 3112,
                regionType: 3,
                regionName: '东川区',
                parentIdAll: '1,3007,3112,3113',
                children: []
              },
              {
                id: 3114,
                parentId: 3112,
                regionType: 3,
                regionName: '寻甸回族彝族自治县',
                parentIdAll: '1,3007,3112,3114',
                children: []
              },
              {
                id: 3115,
                parentId: 3112,
                regionType: 3,
                regionName: '五华区',
                parentIdAll: '1,3007,3112,3115',
                children: []
              },
              {
                id: 3116,
                parentId: 3112,
                regionType: 3,
                regionName: '西山区',
                parentIdAll: '1,3007,3112,3116',
                children: []
              },
              {
                id: 3117,
                parentId: 3112,
                regionType: 3,
                regionName: '宜良县',
                parentIdAll: '1,3007,3112,3117',
                children: []
              },
              {
                id: 3118,
                parentId: 3112,
                regionType: 3,
                regionName: '呈贡区',
                parentIdAll: '1,3007,3112,3118',
                children: []
              },
              {
                id: 3119,
                parentId: 3112,
                regionType: 3,
                regionName: '石林彝族自治县',
                parentIdAll: '1,3007,3112,3119',
                children: []
              },
              {
                id: 3120,
                parentId: 3112,
                regionType: 3,
                regionName: '晋宁区',
                parentIdAll: '1,3007,3112,3120',
                children: []
              },
              {
                id: 3121,
                parentId: 3112,
                regionType: 3,
                regionName: '禄劝彝族苗族自治县',
                parentIdAll: '1,3007,3112,3121',
                children: []
              },
              {
                id: 3122,
                parentId: 3112,
                regionType: 3,
                regionName: '富民县',
                parentIdAll: '1,3007,3112,3122',
                children: []
              },
              {
                id: 3123,
                parentId: 3112,
                regionType: 3,
                regionName: '安宁市',
                parentIdAll: '1,3007,3112,3123',
                children: []
              },
              {
                id: 3124,
                parentId: 3112,
                regionType: 3,
                regionName: '官渡区',
                parentIdAll: '1,3007,3112,3124',
                children: []
              },
              {
                id: 3125,
                parentId: 3112,
                regionType: 3,
                regionName: '嵩明县',
                parentIdAll: '1,3007,3112,3125',
                children: []
              },
              {
                id: 3126,
                parentId: 3112,
                regionType: 3,
                regionName: '盘龙区',
                parentIdAll: '1,3007,3112,3126',
                children: []
              }
            ]
          },
          {
            id: 3127,
            parentId: 3007,
            regionType: 2,
            regionName: '楚雄彝族自治州',
            parentIdAll: '1,3007,3127',
            children: [
              {
                id: 3128,
                parentId: 3127,
                regionType: 3,
                regionName: '大姚县',
                parentIdAll: '1,3007,3127,3128',
                children: []
              },
              {
                id: 3129,
                parentId: 3127,
                regionType: 3,
                regionName: '元谋县',
                parentIdAll: '1,3007,3127,3129',
                children: []
              },
              {
                id: 3130,
                parentId: 3127,
                regionType: 3,
                regionName: '牟定县',
                parentIdAll: '1,3007,3127,3130',
                children: []
              },
              {
                id: 3131,
                parentId: 3127,
                regionType: 3,
                regionName: '姚安县',
                parentIdAll: '1,3007,3127,3131',
                children: []
              },
              {
                id: 3132,
                parentId: 3127,
                regionType: 3,
                regionName: '禄丰县',
                parentIdAll: '1,3007,3127,3132',
                children: []
              },
              {
                id: 3133,
                parentId: 3127,
                regionType: 3,
                regionName: '南华县',
                parentIdAll: '1,3007,3127,3133',
                children: []
              },
              {
                id: 3134,
                parentId: 3127,
                regionType: 3,
                regionName: '楚雄市',
                parentIdAll: '1,3007,3127,3134',
                children: []
              },
              {
                id: 3135,
                parentId: 3127,
                regionType: 3,
                regionName: '双柏县',
                parentIdAll: '1,3007,3127,3135',
                children: []
              },
              {
                id: 3136,
                parentId: 3127,
                regionType: 3,
                regionName: '武定县',
                parentIdAll: '1,3007,3127,3136',
                children: []
              },
              {
                id: 3137,
                parentId: 3127,
                regionType: 3,
                regionName: '永仁县',
                parentIdAll: '1,3007,3127,3137',
                children: []
              }
            ]
          },
          {
            id: 3138,
            parentId: 3007,
            regionType: 2,
            regionName: '德宏傣族景颇族自治州',
            parentIdAll: '1,3007,3138',
            children: [
              {
                id: 3139,
                parentId: 3138,
                regionType: 3,
                regionName: '盈江县',
                parentIdAll: '1,3007,3138,3139',
                children: []
              },
              {
                id: 3140,
                parentId: 3138,
                regionType: 3,
                regionName: '梁河县',
                parentIdAll: '1,3007,3138,3140',
                children: []
              },
              {
                id: 3141,
                parentId: 3138,
                regionType: 3,
                regionName: '芒市',
                parentIdAll: '1,3007,3138,3141',
                children: []
              },
              {
                id: 3142,
                parentId: 3138,
                regionType: 3,
                regionName: '陇川县',
                parentIdAll: '1,3007,3138,3142',
                children: []
              },
              {
                id: 3143,
                parentId: 3138,
                regionType: 3,
                regionName: '瑞丽市',
                parentIdAll: '1,3007,3138,3143',
                children: []
              }
            ]
          },
          {
            id: 3144,
            parentId: 3007,
            regionType: 2,
            regionName: '临沧市',
            parentIdAll: '1,3007,3144',
            children: [
              {
                id: 3145,
                parentId: 3144,
                regionType: 3,
                regionName: '凤庆县',
                parentIdAll: '1,3007,3144,3145',
                children: []
              },
              {
                id: 3146,
                parentId: 3144,
                regionType: 3,
                regionName: '云县',
                parentIdAll: '1,3007,3144,3146',
                children: []
              },
              {
                id: 3147,
                parentId: 3144,
                regionType: 3,
                regionName: '临翔区',
                parentIdAll: '1,3007,3144,3147',
                children: []
              },
              {
                id: 3148,
                parentId: 3144,
                regionType: 3,
                regionName: '永德县',
                parentIdAll: '1,3007,3144,3148',
                children: []
              },
              {
                id: 3149,
                parentId: 3144,
                regionType: 3,
                regionName: '镇康县',
                parentIdAll: '1,3007,3144,3149',
                children: []
              },
              {
                id: 3150,
                parentId: 3144,
                regionType: 3,
                regionName: '耿马傣族佤族自治县',
                parentIdAll: '1,3007,3144,3150',
                children: []
              },
              {
                id: 3151,
                parentId: 3144,
                regionType: 3,
                regionName: '双江拉祜族佤族布朗族傣族自治县',
                parentIdAll: '1,3007,3144,3151',
                children: []
              },
              {
                id: 3152,
                parentId: 3144,
                regionType: 3,
                regionName: '沧源佤族自治县',
                parentIdAll: '1,3007,3144,3152',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 3153,
        parentId: 1,
        regionType: 1,
        regionName: '北京市',
        parentIdAll: '1,3153',
        children: [
          {
            id: 3154,
            parentId: 3153,
            regionType: 2,
            regionName: '北京城区',
            parentIdAll: '1,3153,3154',
            children: [
              {
                id: 3155,
                parentId: 3154,
                regionType: 3,
                regionName: '怀柔区',
                parentIdAll: '1,3153,3154,3155',
                children: []
              },
              {
                id: 3156,
                parentId: 3154,
                regionType: 3,
                regionName: '密云区',
                parentIdAll: '1,3153,3154,3156',
                children: []
              },
              {
                id: 3157,
                parentId: 3154,
                regionType: 3,
                regionName: '延庆区',
                parentIdAll: '1,3153,3154,3157',
                children: []
              },
              {
                id: 3158,
                parentId: 3154,
                regionType: 3,
                regionName: '丰台区',
                parentIdAll: '1,3153,3154,3158',
                children: []
              },
              {
                id: 3159,
                parentId: 3154,
                regionType: 3,
                regionName: '门头沟区',
                parentIdAll: '1,3153,3154,3159',
                children: []
              },
              {
                id: 3160,
                parentId: 3154,
                regionType: 3,
                regionName: '顺义区',
                parentIdAll: '1,3153,3154,3160',
                children: []
              },
              {
                id: 3161,
                parentId: 3154,
                regionType: 3,
                regionName: '朝阳区',
                parentIdAll: '1,3153,3154,3161',
                children: []
              },
              {
                id: 3162,
                parentId: 3154,
                regionType: 3,
                regionName: '东城区',
                parentIdAll: '1,3153,3154,3162',
                children: []
              },
              {
                id: 3163,
                parentId: 3154,
                regionType: 3,
                regionName: '石景山区',
                parentIdAll: '1,3153,3154,3163',
                children: []
              },
              {
                id: 3164,
                parentId: 3154,
                regionType: 3,
                regionName: '房山区',
                parentIdAll: '1,3153,3154,3164',
                children: []
              },
              {
                id: 3165,
                parentId: 3154,
                regionType: 3,
                regionName: '平谷区',
                parentIdAll: '1,3153,3154,3165',
                children: []
              },
              {
                id: 3166,
                parentId: 3154,
                regionType: 3,
                regionName: '大兴区',
                parentIdAll: '1,3153,3154,3166',
                children: []
              },
              {
                id: 3167,
                parentId: 3154,
                regionType: 3,
                regionName: '通州区',
                parentIdAll: '1,3153,3154,3167',
                children: []
              },
              {
                id: 3168,
                parentId: 3154,
                regionType: 3,
                regionName: '昌平区',
                parentIdAll: '1,3153,3154,3168',
                children: []
              },
              {
                id: 3169,
                parentId: 3154,
                regionType: 3,
                regionName: '海淀区',
                parentIdAll: '1,3153,3154,3169',
                children: []
              },
              {
                id: 3170,
                parentId: 3154,
                regionType: 3,
                regionName: '西城区',
                parentIdAll: '1,3153,3154,3170',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 3171,
        parentId: 1,
        regionType: 1,
        regionName: '天津市',
        parentIdAll: '1,3171',
        children: [
          {
            id: 3172,
            parentId: 3171,
            regionType: 2,
            regionName: '天津城区',
            parentIdAll: '1,3171,3172',
            children: [
              {
                id: 3173,
                parentId: 3172,
                regionType: 3,
                regionName: '河西区',
                parentIdAll: '1,3171,3172,3173',
                children: []
              },
              {
                id: 3174,
                parentId: 3172,
                regionType: 3,
                regionName: '和平区',
                parentIdAll: '1,3171,3172,3174',
                children: []
              },
              {
                id: 3175,
                parentId: 3172,
                regionType: 3,
                regionName: '河北区',
                parentIdAll: '1,3171,3172,3175',
                children: []
              },
              {
                id: 3176,
                parentId: 3172,
                regionType: 3,
                regionName: '河东区',
                parentIdAll: '1,3171,3172,3176',
                children: []
              },
              {
                id: 3177,
                parentId: 3172,
                regionType: 3,
                regionName: '津南区',
                parentIdAll: '1,3171,3172,3177',
                children: []
              },
              {
                id: 3178,
                parentId: 3172,
                regionType: 3,
                regionName: '宝坻区',
                parentIdAll: '1,3171,3172,3178',
                children: []
              },
              {
                id: 3179,
                parentId: 3172,
                regionType: 3,
                regionName: '东丽区',
                parentIdAll: '1,3171,3172,3179',
                children: []
              },
              {
                id: 3180,
                parentId: 3172,
                regionType: 3,
                regionName: '静海区',
                parentIdAll: '1,3171,3172,3180',
                children: []
              },
              {
                id: 3181,
                parentId: 3172,
                regionType: 3,
                regionName: '滨海新区',
                parentIdAll: '1,3171,3172,3181',
                children: []
              },
              {
                id: 3182,
                parentId: 3172,
                regionType: 3,
                regionName: '蓟州区',
                parentIdAll: '1,3171,3172,3182',
                children: []
              },
              {
                id: 3183,
                parentId: 3172,
                regionType: 3,
                regionName: '宁河区',
                parentIdAll: '1,3171,3172,3183',
                children: []
              },
              {
                id: 3184,
                parentId: 3172,
                regionType: 3,
                regionName: '北辰区',
                parentIdAll: '1,3171,3172,3184',
                children: []
              },
              {
                id: 3185,
                parentId: 3172,
                regionType: 3,
                regionName: '武清区',
                parentIdAll: '1,3171,3172,3185',
                children: []
              },
              {
                id: 3186,
                parentId: 3172,
                regionType: 3,
                regionName: '红桥区',
                parentIdAll: '1,3171,3172,3186',
                children: []
              },
              {
                id: 3187,
                parentId: 3172,
                regionType: 3,
                regionName: '西青区',
                parentIdAll: '1,3171,3172,3187',
                children: []
              },
              {
                id: 3188,
                parentId: 3172,
                regionType: 3,
                regionName: '南开区',
                parentIdAll: '1,3171,3172,3188',
                children: []
              }
            ]
          }
        ]
      },
      {
        id: 3189,
        parentId: 1,
        regionType: 1,
        regionName: '吉林省',
        parentIdAll: '1,3189',
        children: [
          {
            id: 3190,
            parentId: 3189,
            regionType: 2,
            regionName: '长春市',
            parentIdAll: '1,3189,3190',
            children: [
              {
                id: 3191,
                parentId: 3190,
                regionType: 3,
                regionName: '德惠市',
                parentIdAll: '1,3189,3190,3191',
                children: []
              },
              {
                id: 3192,
                parentId: 3190,
                regionType: 3,
                regionName: '榆树市',
                parentIdAll: '1,3189,3190,3192',
                children: []
              },
              {
                id: 3193,
                parentId: 3190,
                regionType: 3,
                regionName: '双阳区',
                parentIdAll: '1,3189,3190,3193',
                children: []
              },
              {
                id: 3194,
                parentId: 3190,
                regionType: 3,
                regionName: '农安县',
                parentIdAll: '1,3189,3190,3194',
                children: []
              },
              {
                id: 3195,
                parentId: 3190,
                regionType: 3,
                regionName: '绿园区',
                parentIdAll: '1,3189,3190,3195',
                children: []
              },
              {
                id: 3196,
                parentId: 3190,
                regionType: 3,
                regionName: '朝阳区',
                parentIdAll: '1,3189,3190,3196',
                children: []
              },
              {
                id: 3197,
                parentId: 3190,
                regionType: 3,
                regionName: '二道区',
                parentIdAll: '1,3189,3190,3197',
                children: []
              },
              {
                id: 3198,
                parentId: 3190,
                regionType: 3,
                regionName: '九台区',
                parentIdAll: '1,3189,3190,3198',
                children: []
              },
              {
                id: 3199,
                parentId: 3190,
                regionType: 3,
                regionName: '南关区',
                parentIdAll: '1,3189,3190,3199',
                children: []
              },
              {
                id: 3200,
                parentId: 3190,
                regionType: 3,
                regionName: '宽城区',
                parentIdAll: '1,3189,3190,3200',
                children: []
              }
            ]
          },
          {
            id: 3201,
            parentId: 3189,
            regionType: 2,
            regionName: '辽源市',
            parentIdAll: '1,3189,3201',
            children: [
              {
                id: 3202,
                parentId: 3201,
                regionType: 3,
                regionName: '西安区',
                parentIdAll: '1,3189,3201,3202',
                children: []
              },
              {
                id: 3203,
                parentId: 3201,
                regionType: 3,
                regionName: '龙山区',
                parentIdAll: '1,3189,3201,3203',
                children: []
              },
              {
                id: 3204,
                parentId: 3201,
                regionType: 3,
                regionName: '东辽县',
                parentIdAll: '1,3189,3201,3204',
                children: []
              },
              {
                id: 3205,
                parentId: 3201,
                regionType: 3,
                regionName: '东丰县',
                parentIdAll: '1,3189,3201,3205',
                children: []
              }
            ]
          },
          {
            id: 3206,
            parentId: 3189,
            regionType: 2,
            regionName: '吉林市',
            parentIdAll: '1,3189,3206',
            children: [
              {
                id: 3207,
                parentId: 3206,
                regionType: 3,
                regionName: '舒兰市',
                parentIdAll: '1,3189,3206,3207',
                children: []
              },
              {
                id: 3208,
                parentId: 3206,
                regionType: 3,
                regionName: '船营区',
                parentIdAll: '1,3189,3206,3208',
                children: []
              },
              {
                id: 3209,
                parentId: 3206,
                regionType: 3,
                regionName: '丰满区',
                parentIdAll: '1,3189,3206,3209',
                children: []
              },
              {
                id: 3210,
                parentId: 3206,
                regionType: 3,
                regionName: '桦甸市',
                parentIdAll: '1,3189,3206,3210',
                children: []
              },
              {
                id: 3211,
                parentId: 3206,
                regionType: 3,
                regionName: '昌邑区',
                parentIdAll: '1,3189,3206,3211',
                children: []
              },
              {
                id: 3212,
                parentId: 3206,
                regionType: 3,
                regionName: '蛟河市',
                parentIdAll: '1,3189,3206,3212',
                children: []
              },
              {
                id: 3213,
                parentId: 3206,
                regionType: 3,
                regionName: '龙潭区',
                parentIdAll: '1,3189,3206,3213',
                children: []
              },
              {
                id: 3214,
                parentId: 3206,
                regionType: 3,
                regionName: '永吉县',
                parentIdAll: '1,3189,3206,3214',
                children: []
              },
              {
                id: 3215,
                parentId: 3206,
                regionType: 3,
                regionName: '磐石市',
                parentIdAll: '1,3189,3206,3215',
                children: []
              }
            ]
          },
          {
            id: 3216,
            parentId: 3189,
            regionType: 2,
            regionName: '白城市',
            parentIdAll: '1,3189,3216',
            children: [
              {
                id: 3217,
                parentId: 3216,
                regionType: 3,
                regionName: '大安市',
                parentIdAll: '1,3189,3216,3217',
                children: []
              },
              {
                id: 3218,
                parentId: 3216,
                regionType: 3,
                regionName: '洮南市',
                parentIdAll: '1,3189,3216,3218',
                children: []
              },
              {
                id: 3219,
                parentId: 3216,
                regionType: 3,
                regionName: '通榆县',
                parentIdAll: '1,3189,3216,3219',
                children: []
              },
              {
                id: 3220,
                parentId: 3216,
                regionType: 3,
                regionName: '镇赉县',
                parentIdAll: '1,3189,3216,3220',
                children: []
              },
              {
                id: 3221,
                parentId: 3216,
                regionType: 3,
                regionName: '洮北区',
                parentIdAll: '1,3189,3216,3221',
                children: []
              }
            ]
          },
          {
            id: 3222,
            parentId: 3189,
            regionType: 2,
            regionName: '松原市',
            parentIdAll: '1,3189,3222',
            children: [
              {
                id: 3223,
                parentId: 3222,
                regionType: 3,
                regionName: '扶余市',
                parentIdAll: '1,3189,3222,3223',
                children: []
              },
              {
                id: 3224,
                parentId: 3222,
                regionType: 3,
                regionName: '宁江区',
                parentIdAll: '1,3189,3222,3224',
                children: []
              },
              {
                id: 3225,
                parentId: 3222,
                regionType: 3,
                regionName: '前郭尔罗斯蒙古族自治县',
                parentIdAll: '1,3189,3222,3225',
                children: []
              },
              {
                id: 3226,
                parentId: 3222,
                regionType: 3,
                regionName: '乾安县',
                parentIdAll: '1,3189,3222,3226',
                children: []
              },
              {
                id: 3227,
                parentId: 3222,
                regionType: 3,
                regionName: '长岭县',
                parentIdAll: '1,3189,3222,3227',
                children: []
              }
            ]
          },
          {
            id: 3228,
            parentId: 3189,
            regionType: 2,
            regionName: '四平市',
            parentIdAll: '1,3189,3228',
            children: [
              {
                id: 3229,
                parentId: 3228,
                regionType: 3,
                regionName: '铁东区',
                parentIdAll: '1,3189,3228,3229',
                children: []
              },
              {
                id: 3230,
                parentId: 3228,
                regionType: 3,
                regionName: '双辽市',
                parentIdAll: '1,3189,3228,3230',
                children: []
              },
              {
                id: 3231,
                parentId: 3228,
                regionType: 3,
                regionName: '公主岭市',
                parentIdAll: '1,3189,3228,3231',
                children: []
              },
              {
                id: 3232,
                parentId: 3228,
                regionType: 3,
                regionName: '伊通满族自治县',
                parentIdAll: '1,3189,3228,3232',
                children: []
              },
              {
                id: 3233,
                parentId: 3228,
                regionType: 3,
                regionName: '梨树县',
                parentIdAll: '1,3189,3228,3233',
                children: []
              },
              {
                id: 3234,
                parentId: 3228,
                regionType: 3,
                regionName: '铁西区',
                parentIdAll: '1,3189,3228,3234',
                children: []
              }
            ]
          },
          {
            id: 3235,
            parentId: 3189,
            regionType: 2,
            regionName: '延边朝鲜族自治州',
            parentIdAll: '1,3189,3235',
            children: [
              {
                id: 3236,
                parentId: 3235,
                regionType: 3,
                regionName: '敦化市',
                parentIdAll: '1,3189,3235,3236',
                children: []
              },
              {
                id: 3237,
                parentId: 3235,
                regionType: 3,
                regionName: '图们市',
                parentIdAll: '1,3189,3235,3237',
                children: []
              },
              {
                id: 3238,
                parentId: 3235,
                regionType: 3,
                regionName: '珲春市',
                parentIdAll: '1,3189,3235,3238',
                children: []
              },
              {
                id: 3239,
                parentId: 3235,
                regionType: 3,
                regionName: '和龙市',
                parentIdAll: '1,3189,3235,3239',
                children: []
              },
              {
                id: 3240,
                parentId: 3235,
                regionType: 3,
                regionName: '汪清县',
                parentIdAll: '1,3189,3235,3240',
                children: []
              },
              {
                id: 3241,
                parentId: 3235,
                regionType: 3,
                regionName: '安图县',
                parentIdAll: '1,3189,3235,3241',
                children: []
              },
              {
                id: 3242,
                parentId: 3235,
                regionType: 3,
                regionName: '延吉市',
                parentIdAll: '1,3189,3235,3242',
                children: []
              },
              {
                id: 3243,
                parentId: 3235,
                regionType: 3,
                regionName: '龙井市',
                parentIdAll: '1,3189,3235,3243',
                children: []
              }
            ]
          },
          {
            id: 3244,
            parentId: 3189,
            regionType: 2,
            regionName: '白山市',
            parentIdAll: '1,3189,3244',
            children: [
              {
                id: 3245,
                parentId: 3244,
                regionType: 3,
                regionName: '靖宇县',
                parentIdAll: '1,3189,3244,3245',
                children: []
              },
              {
                id: 3246,
                parentId: 3244,
                regionType: 3,
                regionName: '长白朝鲜族自治县',
                parentIdAll: '1,3189,3244,3246',
                children: []
              },
              {
                id: 3247,
                parentId: 3244,
                regionType: 3,
                regionName: '江源区',
                parentIdAll: '1,3189,3244,3247',
                children: []
              },
              {
                id: 3248,
                parentId: 3244,
                regionType: 3,
                regionName: '抚松县',
                parentIdAll: '1,3189,3244,3248',
                children: []
              },
              {
                id: 3249,
                parentId: 3244,
                regionType: 3,
                regionName: '浑江区',
                parentIdAll: '1,3189,3244,3249',
                children: []
              },
              {
                id: 3250,
                parentId: 3244,
                regionType: 3,
                regionName: '临江市',
                parentIdAll: '1,3189,3244,3250',
                children: []
              }
            ]
          },
          {
            id: 3251,
            parentId: 3189,
            regionType: 2,
            regionName: '通化市',
            parentIdAll: '1,3189,3251',
            children: [
              {
                id: 3252,
                parentId: 3251,
                regionType: 3,
                regionName: '东昌区',
                parentIdAll: '1,3189,3251,3252',
                children: []
              },
              {
                id: 3253,
                parentId: 3251,
                regionType: 3,
                regionName: '二道江区',
                parentIdAll: '1,3189,3251,3253',
                children: []
              },
              {
                id: 3254,
                parentId: 3251,
                regionType: 3,
                regionName: '通化县',
                parentIdAll: '1,3189,3251,3254',
                children: []
              },
              {
                id: 3255,
                parentId: 3251,
                regionType: 3,
                regionName: '柳河县',
                parentIdAll: '1,3189,3251,3255',
                children: []
              },
              {
                id: 3256,
                parentId: 3251,
                regionType: 3,
                regionName: '梅河口市',
                parentIdAll: '1,3189,3251,3256',
                children: []
              },
              {
                id: 3257,
                parentId: 3251,
                regionType: 3,
                regionName: '辉南县',
                parentIdAll: '1,3189,3251,3257',
                children: []
              },
              {
                id: 3258,
                parentId: 3251,
                regionType: 3,
                regionName: '集安市',
                parentIdAll: '1,3189,3251,3258',
                children: []
              }
            ]
          }
        ]
      }
    ]
  }
];