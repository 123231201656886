export const formatTwo = function (num) {
  num = Number(num);
  if (num < 10) {
    return `0${num}`;
  } else {
    return num;
  }
};

export const getUrlParam = function (name) {
  var reg = RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var result = window.location.search.substr(1).match(reg);
  return result ? decodeURIComponent(result[2]) : null;
};

export const setClipboard = function (text) {
  var oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象
  document.execCommand('Copy'); // 执行浏览器复制命令
  document.body.removeChild(oInput);
};
export const byte_format = function (size) {
  let name = '';
  try {
    let byteNum = Number(size);
    if (byteNum < 1024) {
      name = `${byteNum}Byte`;
    } else if (byteNum >= 1024 && byteNum < 1024 * 1024) {
      let kNum = (byteNum / 1024).toFixed(0);
      name = `${kNum}KB`;
    } else if (byteNum >= 1048576) {
      let mNum = (byteNum / 1048576).toFixed(0);
      name = `${mNum}MB`;
    }
  } catch (err) {}
  return name;
};

export const obj2Array = function (obj) {
  let arr = [];

  return arr;
};
export function randomStr(num = 11) {
  let res = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890';
  let resArr = res.split('');
  let len = resArr.length;
  let arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(resArr[Math.floor(Math.random() * len)]);
  }
  return `${arr.join('')}${new Date().getTime()}`;
}
export function isMobile() {
  console.log(navigator.userAgent);
  return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
export function isWeixin() {
  console.log(navigator.userAgent);
  return /MicroMessenger/i.test(navigator.userAgent);
}
