import { formatTwo } from './common';

//时间YYYY-MM-DD 00:00:00
export const formatDate2 = function (date) {
  let year = date.getFullYear();
  let month = formatTwo(date.getMonth() + 1);
  let day = formatTwo(date.getDate());
  let hour = formatTwo(date.getHours());
  let minute = formatTwo(date.getMinutes());
  let second = formatTwo(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};
export const stampFormat = function (stamp) {
  let date = new Date(stamp);
  return formatDate2(date);
};
//时间YYYY-MM-DD
export const formatDate3 = function (date) {
  let year = date.getFullYear();
  let month = formatTwo(date.getMonth() + 1);
  let day = formatTwo(date.getDate());
  return `${year}-${month}-${day}`;
};
//    "2018-09-08 10:10"时间转stamp
export const datetime_to_stamp = function (datetime) {
  let dateTime = datetime.split(' ');
  let date = dateTime[0];
  let time = dateTime[1];
  let dateArr = date.split('-');
  let timeArr = time.split(':');
  let crtDate = new Date();
  crtDate.setFullYear(dateArr[0]);
  crtDate.setMonth(Number(dateArr[1]) - 1);
  crtDate.setDate(dateArr[2]);
  crtDate.setHours(timeArr[0], timeArr[1], timeArr[2], 0);
  return crtDate.getTime();
};

export const dis_stamp_format = function (stamp1, stamp2) {
  let disStamp = Math.abs(stamp1 - stamp2);
  let disSeconds = disStamp / 1000;
  let day = Math.floor(disSeconds / (60 * 60 * 24));
  disSeconds -= day * (60 * 60 * 24);
  let hour = Math.floor(disSeconds / (60 * 60));
  disSeconds -= hour * (60 * 60);
  let min = Math.floor(disSeconds / 60);
  disSeconds -= min * 60;
  return {
    day: day,
    hour: hour,
    min: min,
    second: Math.floor(disSeconds)
  };
};
//    支付倒计时
export const calculate_pay_time = function (payTime, crtStamp) {
  let timeLeft = '';
  let end = false;
  try {
    let now = new Date();
    let nowStamp = now.getTime();
    let secondsLong = Math.abs(payTime * 60 - (nowStamp - crtStamp) / 1000);
    let day = Math.floor(secondsLong / 86400);
    let hour = Math.floor((secondsLong - day * 86400) / 3600);
    let minute = Math.floor((secondsLong - day * 86400 - hour * 3600) / 60);
    let seconds = Math.abs(secondsLong - day * 86400 - hour * 3600 - minute * 60).toFixed(0);
    minute = minute < 10 ? `0${minute}` : minute;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    if (payTime * 60 - (nowStamp - crtStamp) / 1000 < 0) {
      end = true;
      timeLeft = '00分00秒';
    } else {
      timeLeft = `${day}天${hour}时${minute}分${seconds}秒`;
    }
  } catch (err) {}
  return timeLeft;
};

export const day_began = function (date) {
  date.setHours(0, 0, 0, 0);
  return date;
};
export const day_end = function (date) {
  date.setHours(23, 59, 59, 999);
  return date;
};
