/**
 * Created by Administrator on 2018/5/4.
 */
import { Loading } from 'element-ui';
import { isTecent } from '../config/common';
let zipRuler = '?x-oss-process=image/resize,p_10';
let zipRuler50 = '?x-oss-process=image/resize,p_50';
let zipRuler30 = '?x-oss-process=image/resize,p_30';
if (isTecent) {
  zipRuler = '?imageView2/2/w/400/h/400/q/20';
  zipRuler50 = '?imageView2/2/w/500/h/500/q/50';
  zipRuler30 = '?imageView2/2/w/300/h/300/q/30';
}
export default {
  data() {
    return {
      zipRuler: zipRuler, //oss图片压缩
      zipRuler50: zipRuler50, //oss图片压缩
      zipRuler30: zipRuler30, //oss图片压缩
      loadingObj: null,
      $loading: null
    };
  },
  methods: {
    hideLoading() {
      this.$loading.close();
    },
    showWarn(message) {
      this.$message({
        message: message,
        type: 'warning'
      });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: 'success'
      });
    },
    showLoading(options={}) {
      this.loadingObj = Loading.service({
        lock: true,
        text: options.text || '请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.loadingObj) {
        this.loadingObj.close();
      }
    },
    open_window({ path, query = {} } = {}) {
      let queryArr = [];
      for (let key in query) {
        queryArr.push(`${key}=${query[key]}`);
      }
      let queryStr = queryArr.length ? `?${queryArr.join('&')}` : '';
      let Location = window.location || document.location;
      window.open(`${Location.origin}${location.pathname}#${path}${queryStr}`);
    },
    updatePageTitle(title) {
      document.title = title;
      this.$store.dispatch('pageTitle', title);
    }
  }
};
