/**
 * Created by Administrator on 2018/10/12.
 */
import { setCookie } from '@/utils/cookie';
import { menuRouterMap } from '../js/menu';
import appMixin from '../mixins/appMixin';
export default {
  mixins: [appMixin],
  methods: {
    loginSuccess(token) {
      setCookie('token', token);
      this.showLoading();
      this.request_menu()
        .then(({ menu }) => {
          console.log(menu);
          this.hideLoading();
          if (!menu.length) {
            return;
          }
          /* let firstMenu = menu[0];
          if (!firstMenu.subMenu.length) {
            firstUrl = firstMenu.url;
          } else {
            let firstChild = firstMenu.subMenu[0];
            if (!firstChild.subMenu.length) {
              firstUrl = firstChild.url;
            } else {
              let firstGrandson = firstChild.subMenu[0];
              firstUrl = firstGrandson.url;
            }
          } */
          function getFirstUrl(menuArr) {
            let enabledMenu = null;
            menuArr.findIndex((item) => {
              if (item.enabled) {
                enabledMenu = item;
                return true;
              }
            });
            if (!enabledMenu) {
              return null;
            }
            if (!enabledMenu.subMenu.length) {
              return enabledMenu.url;
            } else {
              return getFirstUrl(enabledMenu.subMenu);
            }
          }
          let firstUrl = getFirstUrl(menu);
          if (!firstUrl) {
            this.$message({
              message: '权限异常',
              type: 'warning'
            });
            return;
          }
          let firstRouter = '';
          menuRouterMap.findIndex((item) => {
            if (item.url === firstUrl) {
              firstRouter = item.router;
              return true;
            }
          });
          if (firstRouter) {
            window.location.href = `./index.html${window.location.search}#${firstRouter}`;
          }
        })
        .catch((err) => {
          console.log(err);
          this.hideLoading();
          err.message &&
            this.$message({
              message: err.message,
              type: 'warning'
            });
        });
    },
    request_login({ name, pwd } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/login/login',
          method: 'post',
          data: {
            arg1: name,
            arg2: pwd
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data.access_token);
            } else {
              reject({
                message: '账号或密码错误'
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    initMenu(menu) {
      let arr = [];
      function get_sub_menu(nodes) {
        let arr = [];
        nodes.forEach((node) => {
          let subMenu = [];
          if (node.nodes.length > 0) {
            subMenu = get_sub_menu(node.nodes);
          }
          arr.push({
            name: node.name,
            id: node.id,
            url: node.url,
            enabled: node.enabled,
            subMenu: subMenu
          });
        });
        return arr;
      }
      menu.forEach((item) => {
        let subMenu = [];
        if (item.nodes.length > 0) {
          subMenu = get_sub_menu(item.nodes);
        }
        arr.push({
          name: item.name,
          icon: item.icon,
          id: item.id,
          url: item.url,
          enabled: item.enabled,
          subMenu: subMenu
        });
      });
      return arr;
    },
    request_menu() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/index/getMenu',
          method: 'post'
        })
          .then((res) => {
            if (res.data.success) {
              let result = res.data.data;
              let menu = this.initMenu(result.menuList[0].nodes);
              let systemName = result.menuList[0].name;
              resolve({
                menu: menu,
                systemName: systemName
              });
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    rqRegist(params) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/beautify/register',
          method: 'post',
          data: params
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data.access_token);
            } else {
              reject({
                message: res.data.desc,
                alreadyRegisted: res.data.code === 1100 ? true : false
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
